import { useState, useEffect } from 'react';

// Define the return type of the hook as a string
const useSuggestedSKU = (name: string, model: string): string => {
  const [sku, setSku] = useState<string>('');

  const generateSKU = (name: string, model: string): string => {
    const processName = (nameString: string): string => {
      const words = nameString.split(' ').filter(Boolean);
      if (words.length === 0) return '';
      
      // First word: take first 3 letters
      const firstPart = words[0].substring(0, 3).toUpperCase();

      // Second word: take first 2 letters
      const secondPart = words.length > 1 ? words[1].substring(0, 2).toUpperCase() : '';

      // Third word onwards: take only the first letter
      const remainingParts = words.slice(2).map(word => word[0].toUpperCase()).join('');

      return firstPart + secondPart + remainingParts;
    };

    const processModel = (modelString: string): string => {
      const words = modelString.split(' ').filter(Boolean);
      if (words.length === 0) return '';

      // First word: take first 3 letters
      const firstPart = words[0].substring(0, 3).toUpperCase();

      // Second word onwards: take only the first letter
      const remainingParts = words.slice(1).map(word => word[0].toUpperCase()).join('');

      return firstPart + remainingParts;
    };

    // Process name and model, then concatenate with a dash
    const skuFromName = processName(name);
    const skuFromModel = processModel(model);

    return `${skuFromName}-${skuFromModel}`;
  };

  useEffect(() => {
    if (name && model) {
      setSku(generateSKU(name, model));
    }
  }, [name, model]);

  return sku;
};

export default useSuggestedSKU;
