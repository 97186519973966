import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import axiosClient from 'src/axios_client';
import StatusAlert from 'src/components/shared/AlertResponse'; // Adjust the import path as per your directory structure
import CustomSelect from 'src/components/theme-elements/CustomSelect';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  username: yup.string().required('Username is required'),
  userlevel: yup.string().required('Userlevel is required'),
});

type DialogFormProps = {
  isOpen: boolean;
  isEditing: boolean;
  initialData: {
    id: number;
    name: string;
    email: string;
    username: string;
    userlevel: string;
    oldUsername: string;
    supply_centers: string;
  } | null;
  onClose: () => void;
  onSubmit: (payload: {
    id: number | null;
    name: string;
    email: string;
    username: string;
    userlevel: string;
    oldUsername: string;
    supply_centers: string;
  }) => void;
  refetch: () => void;
};

interface userType {
  value: string;
  label: string;
}

// 'superadmin','admin','purchasing','stockman','encoder','checker','accounting','control'

const countries: userType[] = [
  {
    value: 'checker',
    label: 'Checker',
  },
  {
    value: 'encoder',
    label: 'Encoder',
  },
  {
    value: 'stockman',
    label: 'Stockman',
  },
  {
    value: 'accounting',
    label: 'Accounting Officer',
  },
  {
    value: 'control',
    label: 'Control Officer',
  },
  {
    value: 'purchasing',
    label: 'Purchasing Officer',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'superadmin',
    label: 'Superadmin',
  },
  {
    value: 'approver',
    label: 'Approver',
  },
];

const DialogForm: React.FC<DialogFormProps> = ({
  isOpen,
  isEditing,
  initialData,
  onClose,
  onSubmit,
  refetch,
}) => {
  const [statusCode, setStatusCode] = useState(0);
  const [statusData, setStatusData] = useState([]);

  let apiResponse = 0;
  const formik = useFormik({
    initialValues: {
      id: initialData?.id || null,
      name: initialData?.name || '',
      email: initialData?.email || '',
      username: initialData?.username || '',
      oldUsername: initialData?.username || '',
      userlevel: initialData?.userlevel || '',
      supply_centers: initialData?.supply_centers || '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const payload = {
        id: isEditing ? values.id : 0,
        name: values.name,
        email: values.email,
        username: values.username,
        oldUsername: values.oldUsername, // Add this line
        userlevel: values.userlevel,
        supply_centers: values.supply_centers,
      };

      try {
        if (isEditing) {
          const response = await axiosClient.post(`/system_users/${payload?.id}?_method=PUT`, {
            name: payload.name,
            email: payload.email,
            username: payload.username,
            oldUsername: payload.oldUsername,
            userlevel: payload.userlevel,
            supply_centers: payload.supply_centers,
          });

          const updatedSystemUser = response.data;

          console.log('Server Response:', updatedSystemUser);
          apiResponse = response.status;
        } else {
          const response = await axiosClient.post('/system_users', {
            name: payload.name,
            email: payload.email,
            username: payload.username,
            userlevel: payload.userlevel,
            supply_centers: payload.supply_centers,
          });

          const addedMsp = response.data;
          console.log('Server Response:', addedMsp);
          apiResponse = response.status;
        }

        refetch();
        formik.resetForm();
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          const errorMessage = error.response.data;
          apiResponse = error.response.status;

          setStatusData(errorMessage);
        } else if (error.response && error.response.status) {
          apiResponse = error.response.status;
        }
      }
      setStatusCode(apiResponse);
      onSubmit(payload);
    },
  });

  useEffect(() => {
    formik.setValues({
      id: initialData?.id || null,
      name: initialData?.name || '',
      email: initialData?.email || '',
      username: initialData?.username || '',
      oldUsername: initialData?.username || '',
      userlevel: initialData?.userlevel || '',
      supply_centers: initialData?.supply_centers || '',
    });
  }, [initialData]);

  const [supplyCenters, setSupplyCenters] = useState<any[]>([]);

  useEffect(() => {
    const fetchSupplyCenters = async () => {
      try {
        const response = await axiosClient.get('/supply_centers');
        const transformedData = response.data.data.map((center: any) => ({
          value: center.id,
          label: center.name,
        }));
        setSupplyCenters(transformedData);
      } catch (error) {
        console.error('Error fetching supply centers:', error);
      }
    };

    fetchSupplyCenters();
  }, []);

  return (
    <>
      {(statusCode || statusCode !== 0) && (
        <StatusAlert statusCode={statusCode} statusData={statusData} />
      )}
      <Dialog open={isOpen} onClose={onClose}>
        {/* Render StatusAlert if statusCode is set */}
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            {isEditing ? `Edit SystemUser [${initialData?.name}]` : 'Add SystemUser'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>You are editing the device setup.</DialogContentText>
            <Box mt={2}>
              <CustomTextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                fullWidth
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                autoFocus
                margin="dense"
                id="username"
                name="username"
                label="Username"
                type="text"
                fullWidth
                value={formik.values.username}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="email"
                name="email"
                label="Email"
                type="text"
                fullWidth
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormControl fullWidth variant="outlined">
                <InputLabel id="userlevel-label">User Level</InputLabel>
                <CustomSelect
                  labelId="userlevel-label"
                  id="userlevel"
                  name="userlevel"
                  value={formik.values.userlevel}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.userlevel && Boolean(formik.errors.userlevel)}
                  label="User Level"
                >
                  {countries.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
                {formik.touched.userlevel && formik.errors.userlevel && (
                  <Box component="span" sx={{ color: 'error.main' }}>
                    {formik.errors.userlevel}
                  </Box>
                )}
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel id="supply_centers-label">Supply Center</InputLabel>
                <CustomSelect
                  labelId="supply_centers-label"
                  id="supply_centers"
                  name="supply_centers"
                  value={formik.values.supply_centers}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.supply_centers && Boolean(formik.errors.supply_centers)}
                  label="Select Assigned Supply Center"
                >
                  {supplyCenters.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
                {formik.touched.supply_centers && formik.errors.supply_centers && (
                  <Box component="span" sx={{ color: 'error.main' }}>
                    {formik.errors.supply_centers}
                  </Box>
                )}
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button color="error" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">{isEditing ? 'Update' : 'Add'}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default React.memo(DialogForm);
