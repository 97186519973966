import React from 'react';

import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import ProductTableList from './ProductTableList';
import BlankCard from '../../components/shared/BlankCard';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Product Master Page',
  },
];

const ProductEntry = () => {
  return (
    <PageContainer title="RKCWMS - Product Master Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Product Master Page" items={BCrumb} />
      {/* end breadcrumb */}
      <BlankCard>
        <ProductTableList />
      </BlankCard>
    </PageContainer>
  );
};
export default ProductEntry;
