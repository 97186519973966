import React, { useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import {
  Alert,
  Button,
  Col,
  Divider,
  Input,
  message,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import '@fontsource/roboto';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import './font.css';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
  QuestionCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { TableProps } from 'antd/lib';
import axiosClient from 'src/axios_client';
import TransmittalForm from './components/TransmittalForm';
import TransmittalTransactions, {
  TransmittalTransactionsRef,
} from './components/TransmittalTransactions';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Transmittal Entry Page',
  },
];

interface DataType {
  key: string;
  name: string;
  quantity: number;
  serial_number: string;
  brand: string;
  model: string;
  status: number;
  reference: string;
}

const TransmittalEntry = () => {
  const transactionsTableRef = useRef<TransmittalTransactionsRef>(null);

  const handleRefreshTransactions = () => {
    transactionsTableRef.current?.refetch();
  };

  const { userData } = useEssentialDataContext();

  const userlevel = userData?.userlevel;

  return (
    <PageContainer title="RKCWMS - Transmittal Entry Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Transmittal Entry Page" items={BCrumb} />
      {/* end breadcrumb */}
      <>
        {userlevel === 'superadmin' || userlevel === 'customer' ? (
          <>
            <DashboardCard title="Transmittal Entry">
              <TransmittalForm onTransactionPosted={handleRefreshTransactions} />
            </DashboardCard>
            <Divider />
          </>
        ) : null}
      </>
      <DashboardCard title="Transmittal History" subtitle="See history of Transmittal">
        <TransmittalTransactions ref={transactionsTableRef} />
      </DashboardCard>
    </PageContainer>
  );
};

export default TransmittalEntry;
