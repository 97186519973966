import React from 'react';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import BlankCard from 'src/components/shared/BlankCard';
import { Box, Card } from '@mui/material';
import ReturnProductEntry from './ReturnProductEntry';
import ReturnProductsTable from './ReturnProductsTable';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Quotations',
  },
];

const Returns = () => {
  return (
    <PageContainer title="RKCWMS - Product Adjustment" description="this is Sample page">
      <Card sx={{ mt: { xs: 0, md: 5 } }} elevation={12}>
        <ReturnProductEntry />
      </Card>
      <Box margin={2}></Box>
      <ReturnProductsTable />
    </PageContainer>
  );
};

export default Returns;
