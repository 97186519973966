import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Grid,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  Divider,
  FormControlLabel,
  FormLabel,
  Stack,
  Button,
  Autocomplete,
  Alert,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Table,
  TextField,
  Chip,
  RadioGroup,
  Radio,
  ListItemButton,
  Collapse,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { FastField, Field, FieldProps, FormikProvider, useFormik } from 'formik';
import axiosClient from 'src/axios_client';
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Close, KeyboardArrowDown, KeyboardArrowUp, Percent } from '@mui/icons-material';
import { QueryClient, QueryClientProvider, keepPreviousData, useQuery } from '@tanstack/react-query';
import CustomCheckbox from 'src/components/theme-elements/CustomCheckbox';
import { NumericFormatCustom, adjustAndFormat, formatCurrency } from 'src/globals/NumericFormat';
import BlankCard from 'src/components/shared/BlankCard';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useSelector } from 'react-redux';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import { LogoDark, LogoLight } from '../../globals/LogoExports';
import { AppState } from 'src/store/Store';
import { fromPairs } from 'lodash';
import { IconFile3d, IconTrash } from '@tabler/icons';

type Adjustment = {
  id: number;
  product_id: number;
  product_name: string;
  sku: string;
  is_price_adjustment: number;
  is_quantity_adjustment: number;
  previous_selling: number,
  previous_buying: number;
  status: any;
  reason: string;
};

type QuotationApiResponse = {
  data: Array<Adjustment>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type ProductOptions = {
  id: string,
  name: any,
  sku: any,
  has_serial: any,
  unit: any,
  description: any,
  model: any,
  brand: any,
  image: any,
  buying_price: any,
  selling_price: any,
  qty_before: any,
  quantity: any
};

type ProductTransmital = {
  id: any,
  transmittal_id: any,
  name: any,
  brand: any,
  model: any,
  quantity: any,
  quantity_returned: any,
  remaining_quantity: any,
  serial_number: any,
  reference_number: any,
  transaction: [],
}
type TransmitalOptions = {
  id: string,
  products: ProductTransmital[],
};

type ReturnTransactions = {
  product_id: any,
  product_name: any,
  product_image: any,
  sku: any,
  serial_data: SerialData[],
  quantity: any,
  transmital_id: any,
  return_product_id: any,
  return_product_model: any,
  return_product_name: any,
  return_product_quantity: any,
};
type SerialData = {
  value: any;
  inSystem: any
  id: any;
};
const ProductReturnStatusOptions = [
  { value: 'good', label: 'Good' },
  { value: 'repair', label: 'For Repair' },
  { value: 'broken', label: 'Broken' },
];
const ProductLocationDivider = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
];
const ProductLocationFloor = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];



const MainComponent = () => {


  const [isSearching, setIsSearching] = useState(false);
  const [isEmptySearch, setIsEmptySearch] = useState(false);
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [productOptions, setProductOptions] = useState<ProductOptions[]>([]);
  const [transmitalOptions, setTransmitalOptions] = useState<TransmitalOptions[]>([]);

  const [transmitalRequestProducts, setTransmitalRequestProducts] = useState<ProductTransmital[]>([]);

  const [serialOptions, setSerialOptions] = useState<SerialData[]>([]);
  const [returnList, setReturnList] = useState<ReturnTransactions[]>([]);
  const returnListRef = useRef(returnList);
  const transmitalRequestRef = useRef(transmitalRequestProducts);
  const [sucess, setSucess] = useState('');
  const [error, setError] = useState('');

  const [isAutoPrint, setIsAutoPrint] = useState(true);
  const [transmitalID, setTransmitalID] = useState('');

  const [maxSerials, setMaxSerials] = useState(0);
  const [returningProduct, setReturningProduct] = useState<ProductTransmital | null>(null);
  const validationSchema = Yup.object().shape({
    product_id: Yup.string().required('Please provide product'),
    product_status: Yup.string().nullable().required('Please provide the status of the product'),
    return_price: Yup.number().required('Please provide the value of product'),
    transmital_id: Yup.string().required('Please provide a transmital ID'),
    return_serials: Yup.string().test(
      'unique-check',
      'Serial number is already added',
      function (value) {
        const { serial_data, return_quantity } = this.parent;
        if (productHasSerial && return_quantity) {

          if (inSystemValue == '1') {
            if (maxSerials > 0 && serial_data.length == maxSerials) {
              setError('Serial Numbers max limit reached');
              return true;
            }
            else if (serial_data.length < maxSerials && !serial_data.some((item: any) => item.value === value)) {
              return this.createError({ message: 'Please add more serial numbers' });
            }
            else if (serial_data.length > maxSerials) {
              return this.createError({ message: 'Please make sure Quantity is equal to Serial Numbers' });
            }
            else {
              console.log("Hello din");
              return !serial_data.some((item: any) => item.value === value) && serial_data.length < maxSerials;
            }
          }
          else {
            if (serial_data.length < maxSerials && !serial_data.some((item: any) => item.value === value)) {
              return this.createError({ message: 'Please add more serial numbers' });
            }
            else if (serial_data.length > maxSerials) {
              return this.createError({ message: 'Please remove some serial numbers' });
            }
            else {
              return true;
            }
          }

        }
        else {
          return true;
        }

      }
    ),
    return_quantity: Yup.number()
      .required('Please provide a quantity')
      .test(
        'quantity-check', // A unique name for the test
        function (value) {
          if (!returningProduct?.quantity) {

            console.log(returningProduct);

            return this.createError({
              message: 'Please select a product to return first',
            });
          }

          if ((value ?? 0) > (returningProduct?.remaining_quantity || 0)) {
            console.log("Has error");
            return this.createError({
              message: `Quantity exceeds available (${returningProduct?.remaining_quantity || 0})`,
            });
          }
          if (value == 0) {
            return this.createError({
              message: 'Quantity must be greater than zero',
            });
          }

          setMaxSerials(value ?? 0);
          return true;
        }
      ),
    serial_data: Yup.array().of(Yup.object()),
    location_divider: Yup.string().nullable().required('Provide this'),
    location_floor: Yup.string().nullable().required('Provide this'),
    return_location: Yup.string().nullable().required('Provide the location'),
  });
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      product_id: '',
      transmital_id: '',
      product_status: null,
      return_quantity: '',
      product_image: '',
      return_serials: '',
      serial_data: [],
      return_price: 0,
      return_condition: '',
      return_location: '',
      product_name: '',
      location_divider: '',
      location_floor: '',
      transaction_number: '',
      sku: '',
      has_serial: 0,
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      if (formik.isValid) {
        console.log("FORM SUBMITED");
      }
      else {
        console.log("Form invalid");
      }
    },
  });
  const autocompleteSearchChange = (searchText: any, apiText: any) => {
    setIsSearching(true);
    setIsEmptySearch(false);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      const value = searchText.target.value;

      fetchOptions(value, apiText);
    }, 500);
  };

  const fetchOptions = async (searchText: string, apiText: string) => {
    try {
      const filter = [
        { "id": "name", "value": searchText },
        // { "id": "is_adjusting", "value": 1 }
      ];
      const { status, data } = await axiosClient.get(`/${apiText == 'serial' ? 'check_serial_number' : apiText}`, {
        params: apiText == 'serial' ? { globalFilter: searchText, product_id: formik.values.product_id, serial_status: 'issued', per_page: 10 } : { filters: JSON.stringify(filter), per_page: 10 },
      });

      console.log(data.data);
      if (status == 200) {
        setIsSearching(false);
        if (data.data.length == 0) {
          setIsEmptySearch(true);
        }

        if (apiText == 'serial') {
          const fetchedData = data.data.map((innerData: any) => ({
            id: innerData.id,
            value: innerData.serial_number,
            inSystem: true,
          }));
          setSerialOptions(fetchedData);
        }
        else if (apiText == 'check_valid_transmittal') {
          const fetchedData = data.data.map((innerData: any) => {
            return {
              id: innerData.id,
              products: innerData.products,
            };
          });

          setTransmitalOptions(fetchedData);
        }
        else if (apiText == 'locations') {
          const fetchedData = data.data.map((innerData: any) => ({
            id: innerData.id,
            value: innerData.name,
          }));

          console.log(fetchedData);
          setLocationOptions(fetchedData);

        }
        else {
          const fetchedData = data.data.map((innerData: any) => ({
            id: innerData.id,
            name: innerData.name,
            description: innerData.description,
            has_serial: innerData.has_serial,
            image: innerData.image,
            unit: innerData.unit,
            sku: innerData.sku,
            model: innerData.model,
            brand: innerData.brand_name,
            qty_before: innerData.qty_before,
            buying_price: innerData.buying_price,
            selling_price: innerData.selling_price,
            quantity: innerData.quantity
          }));
          setProductOptions(fetchedData);
        }


      }

    } catch (error) {
      console.error('Failed to fetch product options:', error);
    }
  };

  const handlePriceCheckbox = (event: any) => {
    setIsAutoPrint(event.target.checked);

  }

  const addReturnProductClick = async () => {
    const errors = await formik.validateForm();

    console.log(formik.values);
    formik.setTouched({ ['transmital_id']: true, ['product_id']: true, ['return_quantity']: true, ['return_price']: true, ['return_serials']: true, ['location_divider']: true, ['location_floor']: true, ['product_status']: true, ['return_location']: true });

    if (Object.keys(errors).length === 0) {
      const formikVals = formik.values;

      // const detectUnique = returnList.some((item: any) => item.product_id == formik.values.product_id);

      const detectUnique = false;
      if (detectUnique) {
        setError("Duplicate Product is selected. Please select different product");
      }

      else {

        // const JsonToSet = {
        //   product_id: formikVals.product_id,
        //   product_name: formikVals.product_name,
        //   product_image: formikVals.product_image,
        //   sku: formikVals.sku,
        //   has_serial: formikVals.has_serial,
        //   serial_data: formikVals.serial_data,
        //   quantity: formikVals.return_quantity,
        //   transmital_id: formikVals.transmital_id,
        //   return_product_status: formikVals.product_status,
        //   return_product_value: formikVals.return_price,
        //   return_product_id: returningProduct?.id,
        //   return_product_model: returningProduct?.model,
        //   return_product_name: returningProduct?.name,
        //   return_product_quantity: returningProduct?.quantity,
        // };


        const JsonToSetTable = {
          product_id: formikVals.product_id,
          product_name: formikVals.product_name,
          product_image: formikVals.product_image,
          sku: formikVals.sku,
          has_serial: formikVals.has_serial,
          serial_data: formikVals.serial_data,
          quantity: formikVals.return_quantity,
          transmital_id: formikVals.transmital_id,
          return_product_value: formikVals.return_price,
          return_product_status: formikVals.product_status,
          return_product_id: returningProduct?.id,
          return_product_model: returningProduct?.model,
          return_product_name: returningProduct?.name,
          return_product_quantity: returningProduct?.quantity,

          return_location_string: locationString,
          return_product_location: formikVals.return_location,
          return_product_divider: formikVals.location_divider,
          return_product_floor: formikVals.location_floor,
        }

        const retList = returnList.filter((item: any) => item.return_product_id == returningProduct?.id).map((list) => {
          return list;
        })
        const totalQuantity = returnList
          .filter((item: any) => item.return_product_id == returningProduct?.id)
          .reduce((acc, list) => acc + list.quantity, 0);

        if ((totalQuantity + formikVals.return_quantity) > returningProduct?.quantity) {
          setError(`You will exceed the maximum quantity you can return on product ${returningProduct?.name}`);
          return;
        }


        retList.push(JsonToSetTable);

        const set_this = transmitalRequestProducts.map((item: any) =>
          item.id === returningProduct?.id ? { ...item, transaction: retList } : item
        );
        // console.log(set_this);

        // console.log(JsonToSet);
        // console.log(returningProduct);
        // console.log(formik.values);

        setTransmitalRequestProducts(set_this);
        setReturnList([...returnList, JsonToSetTable]);


        formik.resetForm({
          values: {
            ...formikVals,
            product_id: '',
            return_quantity: '',
            serial_data: [],
            return_price: 0,
            product_status: null,
            return_location: '',
            location_divider: '',
            location_floor: '',
          },
        });

        setserial_data([]);
        setMaxSerials(0);
        setReturningProduct(null);
        setError('');


        // setReturnList([...returnList, JsonToSet]);


      }

    }
    console.log(errors);
  };

  useEffect(() => {

    returnListRef.current = returnList;

  }, [returnList])

  useEffect(() => {
    transmitalRequestRef.current = transmitalRequestProducts;
  }, [transmitalRequestProducts]);
  const [isSubmitting, setSubmitting] = useState(false);

  const postTransaction = () => {

    if (returnList.length > 0) {

      const formData = new FormData();

      setSubmitting(true);
      formData.append('transaction_number', formik.values.transaction_number);
      formData.append('transmital_id', formik.values.transmital_id);

      console.log(returnList);

      returnList.forEach((list: any, index: any) => {
        Object.keys(list).forEach(key => {
          if (key === "serial_data") {
            const serialData = list[key];

            if (serialData.length > 0) {
              serialData.forEach((serialItem: any, serialIndex: any) => {
                // Append each key-value pair individually to maintain structure
                Object.keys(serialItem).forEach(serialKey => {
                  formData.append(
                    `arrayReturns[${index}][${key}][${serialIndex}][${serialKey}]`,
                    serialItem[serialKey]
                  );
                });
              });
            }
            else {
              formData.append(`arrayReturns[${index}][${key}]`, list[key]);
            }

          } else {
            formData.append(`arrayReturns[${index}][${key}]`, list[key]);
          }
        });
      });

      axiosClient.post(`/ptr_transmital_transaction`, formData)
        .then((response) => {

          if (response.status === 201) {
            setSucess(response.data.message);
            setError('');
            setReturnList([]);
            setTransmitalID('');
            setProductHasSerial(false);
            setReturningProduct(null);
            setTransmitalRequestProducts([]);
            setSubmitting(false);
            setTimeout(() => {
              setSucess('');
            }, 5000);
            formik.resetForm();
          }
          else if (response.status === 500) {
            setSubmitting(false);
            setError(response.data.message);
          }
          console.log(response);
        })
        .catch((error) => {
          setSubmitting(false);
          setError(error.response.data.message);
          console.log(error);
        });

    } else {
      setError('Please check fields and return list to proceed');
    }
  }

  const clearTransaction = () => {
    setSucess('');
    setError('');
    setReturnList([]);
    setTransmitalID('');
    setProductHasSerial(false);
    setReturningProduct(null);
    setTransmitalRequestProducts([]);
    formik.resetForm();

  }
  const handleRemoveClick = React.useCallback((passedAdj: any) => {
    console.log(passedAdj);
    removeAdjustment(passedAdj.product_id)
  }, []);

  const removeAdjustment = (id: any) => {
    const updatedArray = returnListRef.current.filter((item: any) => item.product_id !== id);
    const updatedData = transmitalRequestRef.current.map((item: any) => {
      if (item.transaction) {
        return {
          ...item,
          transaction: item.transaction.filter((t: any) => t.product_id !== id),
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setReturnList(updatedArray);
    setTransmitalRequestProducts(updatedData);

  }

  const [fromDate, setFromDate] = useState(dayjs());
  const handleFromDatechange = (newDate: any) => {
    setFromDate(newDate);
  };

  const [inSystemValue, setInSystemValue] = React.useState('0');

  const [productHasSerial, setProductHasSerial] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    formik.setFieldValue('serial_data', []);
    setError('');
    setserial_data([]);
    setInSystemValue((event.target as HTMLInputElement).value);
  };

  const [serial_data, setserial_data] = useState<SerialData[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [serialError, setSerialError] = useState<string | null>(null);

  const handleSerialInputChange = (e: any) => {
    setError('');
    formik.setFieldValue('return_serials', e.target.value);
    formik.setFieldError('return_serials', ''); // Clear error when input changes
  };

  const handleSerialKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setError('');
      const value = formik.values.return_serials.trim();
      if (value) {
        if (serial_data.some(item => item.value === value)) {
          console.log("Item added");
          formik.setFieldError('return_serials', 'Item is already added.');
          formik.setFieldTouched('return_serials', true);
        } else if (serial_data.length >= maxSerials) {
          formik.setFieldError('return_serials', `You can only add up to ${maxSerials} items.`);
          formik.setFieldTouched('return_serials', true);

        } else {
          const updatedChips = [...serial_data, { value: value, id: null, inSystem: inSystemValue == '0' ? true : false }];
          setserial_data(updatedChips);

          formik.setFieldValue('serial_data', updatedChips);
          formik.setFieldValue('return_serials', '');
        }

      }
    }
  };

  const handleDelete = (chipToDelete: any) => () => {

    console.log(chipToDelete);
    const updatedChips = serial_data.filter((chip) => chip !== chipToDelete);
    setError('');
    setserial_data(updatedChips);
    formik.setFieldValue('serial_data', updatedChips);
  };


  const addReturnClick = (returnDetails: any) => {

    if (returnDetails) {
      setReturningProduct(returnDetails);
    }

  }

  const [locationOptions, setLocationOptions] = useState<
    { value: string; id: string; }[]
  >([]);

  const [locationString, setLocationString] = useState('');

  return (
    <Box>

      <Backdrop
        sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FormikProvider value={formik}>

        <form onSubmit={formik.handleSubmit}>
          <Grid container padding={3} columnSpacing={2}>
            <Grid item sm={12} lg={4}>
              {sucess &&
                <Alert
                  variant="filled"
                  severity="success"
                  sx={{ alignItems: "center" }}
                >
                  {sucess}
                </Alert>
              }

              {error &&
                <Alert
                  variant="filled"
                  severity="error"
                  sx={{ alignItems: "center" }}
                >
                  {error}
                </Alert>
              }
              <FormLabel component="legend">
                <Typography variant={'h4'}>Transaction Details</Typography>
              </FormLabel>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={isAutoPrint}
                        color="primary"
                        onChange={handlePriceCheckbox}
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                      />
                    }
                    label="Auto Print"
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    id="return_number"
                    size="small"
                    disabled
                    variant="standard"
                    label="Return Number"
                    defaultValue="Auto Generated"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={fromDate}
                      disabled
                      onChange={handleFromDatechange}
                      slotProps={{ textField: { size: 'small', variant: "standard" } }}
                      sx={{ width: '100%' }}
                      label="Requested Date"
                      views={['year', 'month', 'day', 'hours', 'minutes']}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6}>
                  <CustomTextField
                    id="user_id"
                    size="small"
                    disabled
                    variant="standard"
                    label="Current User"
                    defaultValue="Username here"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="transmital_id"
                    noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                    options={transmitalOptions}
                    value={transmitalOptions.find(option => option.id === formik.values.transmital_id) || null}
                    getOptionLabel={(option) => {
                      // Display "TET-000001" in the dropdown options
                      return `${option.id.toString().padStart(5, '0')}`;
                    }}

                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {
                      // const selectedProduct = productOptions.find(p => p.id === newValue);
                      if (newValue) {
                        setTransmitalRequestProducts(newValue.products);
                        setTransmitalID(newValue.id);
                        formik.setFieldValue('transmital_id', newValue.id);
                      } else {
                        formik.setFieldValue('transmital_id', null);
                      }

                      if (newValue === null) {
                        formik.resetForm();
                        setTransmitalOptions([]);
                      }
                    }}

                    onBlur={() => formik.setFieldTouched('transmital_id', true)}

                    fullWidth

                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: <InputAdornment position="start">TET - </InputAdornment>,
                        }}
                        error={formik.touched.transmital_id && Boolean(formik.errors.transmital_id)}
                        helperText={formik.touched.transmital_id && formik.errors.transmital_id}
                        onChange={(e: any) => autocompleteSearchChange(e, 'check_valid_transmittal')}
                        variant="standard"
                        label="Transmital ID"
                        placeholder="Transmital ID"
                        aria-label="Transmital ID"
                      />
                    )}
                  />


                  {/* <CustomTextField
                  id="transmital_id"
                  size="small"
                  variant="standard"
                  label="Transmital ID"
                  defaultValue=""
                  fullWidth
                /> */}
                </Grid>
              </Grid>


              <FormLabel component="legend" sx={{ mt: 3 }}>

                <Grid container direction={'row'} columnSpacing={1}>
                  <Grid item>
                    <Typography variant={'h4'}>Product Return Details</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'h4'}>-</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'h4'}>{returningProduct?.name ?? 'Item Name'}</Typography>
                  </Grid>

                </Grid>

              </FormLabel>
              <Grid container spacing={2}>


                <Grid item xs={12}>
                  <Autocomplete
                    sx={{ mt: 1 }}
                    id="product_id"
                    disabled={!transmitalID}
                    noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                    value={formik.values.product_id || null}
                    onChange={async (_, newValue) => {
                      formik.setFieldValue('product_id', newValue);
                      formik.setFieldValue('serial_data', []);
                      const selectedProduct = productOptions.find(p => p.id === newValue);
                      if (selectedProduct) {
                        if (selectedProduct.has_serial) {
                          setProductHasSerial(true);
                        }
                        else {
                          setserial_data([]);
                          await formik.setFieldValue('serial_data', []);
                          formik.setFieldTouched('serial_data', true);
                          setProductHasSerial(false);
                        }
                        formik.setFieldValue('sku', selectedProduct.sku);
                        formik.setFieldValue('unit', selectedProduct.unit);
                        formik.setFieldValue('has_serial', selectedProduct.has_serial);
                        formik.setFieldValue('product_image', selectedProduct.image);
                        formik.setFieldValue('product_name', selectedProduct.name);
                      }
                      if (newValue === null) {
                        setProductHasSerial(false);
                        setProductOptions([]);
                      }
                    }}
                    options={productOptions.map(p => p.id)}
                    fullWidth
                    getOptionLabel={(option) => {
                      const productOpt = productOptions.find(p => p.id === option);
                      return productOpt ? `${productOpt.name} [Model: ${productOpt.model}] [SKU: ${productOpt.sku}] [Brand: ${productOpt.brand}]` : '';
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={formik.touched.product_id && Boolean(formik.errors.product_id)}
                        helperText={formik.touched.product_id && formik.errors.product_id}
                        onChange={(e: any) => autocompleteSearchChange(e, 'products')}
                        variant="standard"
                        placeholder="Product name"
                        aria-label="Product name"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Field
                    validateOnBlur
                    validateOnChange
                    name="return_quantity"
                    render={({ field, form }: FieldProps) => (
                      <CustomTextField
                        {...field}
                        id="return_quantity"
                        size="small"
                        variant="standard"
                        type="number"
                        label="Quantity"
                        disabled={!transmitalID}
                        value={form.values.return_quantity}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        error={form.touched.return_quantity && Boolean(form.errors.return_quantity)}
                        helperText={form.touched.return_quantity && form.errors.return_quantity}
                        fullWidth
                      />
                    )}
                  />

                  {/* <CustomTextField
                    id="return_quantity"
                    size="small"
                    variant="standard"
                    type="number"
                    label="Quantity"
                    disabled={!transmitalID}
                    value={formik.values.return_quantity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.return_quantity && Boolean(formik.errors.return_quantity)}
                    helperText={formik.touched.return_quantity && formik.errors.return_quantity}
                    fullWidth
                  /> */}

                </Grid>

                {inSystemValue == '1' &&
                  <Grid item xs={6}>
                    <CustomTextField
                      id='return_serials'
                      size="small"
                      disabled={!transmitalID && !productHasSerial}
                      value={formik.values.return_serials}
                      onChange={handleSerialInputChange}
                      onKeyDown={handleSerialKeyDown}
                      label="Serial Number Input"
                      placeholder="Type and press enter or space"
                      variant="standard"
                      fullWidth
                      error={formik.touched.return_serials && Boolean(formik.errors.return_serials)}
                      helperText={formik.touched.return_serials && formik.errors.return_serials}
                    />
                  </Grid>
                }

                {inSystemValue == '0' &&
                  <Grid item xs={6}>
                    <Autocomplete
                      multiple
                      disabled={!productHasSerial}
                      id='return_serials'
                      value={serial_data}
                      noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                      size='small'
                      fullWidth
                      options={serialOptions}
                      getOptionLabel={(option) => option.value} // Display label in the dropdown
                      getOptionDisabled={(options) => {

                        const IsTrue = serial_data.length >= parseInt(formik.values.return_quantity);
                        return IsTrue;
                      }}
                      onChange={async (_, newValue, reason, details) => {
                        console.log(newValue);
                        setError('');
                        if (details) {
                          console.log("Hello");
                          await formik.setFieldValue('serial_data', newValue);

                          setserial_data(newValue);
                        }

                        if (!details) {
                          setserial_data([]);
                          await formik.setFieldValue('serial_data', []);
                          setSerialOptions([]);
                        }
                        formik.setFieldTouched('return_serials', true);
                      }}
                      renderInput={(params) => {

                        console.log(params);

                        return (
                          <TextField
                            {...params}
                            variant='standard'
                            error={formik.touched.return_serials && Boolean(formik.errors.return_serials)}
                            helperText={formik.touched.return_serials && formik.errors.return_serials}
                            onChange={(e: any) => {
                              autocompleteSearchChange(e, 'serial')

                            }}
                            label="Serial Numbers"
                            placeholder="Favorites"
                          />
                        )
                      }}
                    />
                  </Grid>
                }

                {productHasSerial &&
                  <>
                    <Grid item xs={12}>

                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={inSystemValue}
                        onChange={handleChange}
                      >
                        <FormControlLabel value="0" control={<Radio />} label="Is in system" />
                        <FormControlLabel value="1" control={<Radio />} label="Is not in system" />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ marginTop: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {serial_data.map((chip, index) => (
                          <Chip
                            key={index}
                            label={chip.value}
                            onDelete={
                              inSystemValue == '1' // Replace with your actual condition
                                ? handleDelete(chip) // or directly use handleDelete(chip) if it doesn't require event handling
                                : undefined
                            }
                          />
                        ))}
                      </Box>
                      <Typography variant="caption" color="textSecondary" sx={{ marginTop: 1 }}>
                        {`You have added ${serial_data.length}/${maxSerials} items.`}
                      </Typography>
                    </Grid>
                  </>
                }

                <Grid item xs={12}>
                  <CustomTextField
                    id="sku"
                    size="small"
                    disabled
                    variant="filled"
                    label="Sku"
                    value={formik.values.sku}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.sku && Boolean(formik.errors.sku)}
                    helperText={formik.touched.sku && formik.errors.sku}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <CustomTextField
                    id="return_price"
                    size="small"
                    variant="standard"
                    label="Return Value"
                    disabled={!transmitalID}
                    value={formik.values.return_price}
                    onChange={(e: any) => {
                      const reqBuying = parseFloat(e.target.value);
                      formik.setFieldValue("return_price", reqBuying + (reqBuying * 0.10));
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.return_price && Boolean(formik.errors.return_price)}
                    helperText={formik.touched.return_price && formik.errors.return_price}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                      inputProps: {
                        name: "return_price",
                      },
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    disabled={!transmitalID}
                    id='product_status'
                    value={ProductReturnStatusOptions.find(option => option.value === formik.values.product_status) || null}
                    size='small'
                    fullWidth
                    options={ProductReturnStatusOptions}
                    getOptionLabel={(option) => {
                      return `${option.label}`;
                    }}
                    onBlur={formik.handleBlur}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        formik.setFieldValue('product_status', newValue.value);
                      }

                      if (newValue === null) {
                        formik.setFieldValue('product_status', '');
                      }

                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='standard'
                        error={formik.touched.product_status && Boolean(formik.errors.product_status)}
                        helperText={formik.touched.product_status && formik.errors.product_status}
                        label="Product status"
                        placeholder="Favorites"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    id="return_location"
                    disabled={!transmitalID}
                    size='small'
                    noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                    options={locationOptions}
                    value={locationOptions.find(option => option.id === formik.values.return_location) || null}
                    getOptionLabel={(option) => {
                      // Display "TET-000001" in the dropdown options
                      return option.value;
                    }}

                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {

                      console.log(newValue);
                      // const selectedProduct = productOptions.find(p => p.id === newValue);
                      if (newValue) {
                        setLocationString(newValue.value);
                        formik.setFieldValue('return_location', newValue.id);
                      } else {
                        formik.setFieldValue('return_location', null);
                      }
                      if (newValue === null) {
                        formik.resetForm();
                        setLocationOptions([]);
                      }
                    }}
                    onBlur={() => formik.setFieldTouched('return_location', true)}
                    fullWidth
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={formik.touched.return_location && Boolean(formik.errors.return_location)}
                        helperText={formik.touched.return_location && formik.errors.return_location}
                        onChange={(e: any) => autocompleteSearchChange(e, 'locations')}
                        variant="standard"
                        label="Location Stored"
                        placeholder="Location Stored"
                        aria-label="Location Stored"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    disabled={!transmitalID}
                    id='location_divider'
                    value={ProductLocationDivider.find(option => option.value === formik.values.location_divider) || null}
                    size='small'
                    fullWidth
                    options={ProductLocationDivider}
                    getOptionLabel={(option) => {
                      return `${option.label}`;
                    }}
                    onBlur={formik.handleBlur}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        formik.setFieldValue('location_divider', newValue.value);
                      }

                      if (newValue === null) {
                        formik.setFieldValue('location_divider', '');
                      }

                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='standard'
                        error={formik.touched.location_divider && Boolean(formik.errors.location_divider)}
                        helperText={formik.touched.location_divider && formik.errors.location_divider}
                        label="Divider"
                        placeholder="Divider"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    disabled={!transmitalID}
                    id='location_floor'
                    value={ProductLocationFloor.find(option => option.value === formik.values.location_floor) || null}
                    size='small'
                    fullWidth
                    options={ProductLocationFloor}
                    getOptionLabel={(option) => {
                      return `${option.label}`;
                    }}
                    onBlur={formik.handleBlur}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        formik.setFieldValue('location_floor', newValue.value);
                      }

                      if (newValue === null) {
                        formik.setFieldValue('location_floor', '');
                      }

                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='standard'
                        error={formik.touched.location_floor && Boolean(formik.errors.location_floor)}
                        helperText={formik.touched.location_floor && formik.errors.location_floor}
                        label="Floor"
                        placeholder="Floor"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container gap={2}>
                    <Button variant="contained" color="primary" onClick={addReturnProductClick}>
                      Add
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => {
                      formik.resetForm();
                      setTransmitalID('');
                      setProductHasSerial(false);
                      setReturningProduct(null);
                      setTransmitalRequestProducts([]);

                    }}>
                      Reset
                    </Button>
                  </Grid>
                </Grid>


              </Grid>
            </Grid>

            <Grid item sm={12} lg={8} mt={{ xs: 2, md: 0 }}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>

                    <Button onClick={postTransaction} startIcon={<IconFile3d />} >
                      Post Transaction
                    </Button>

                    <Button color='error' startIcon={<IconTrash />} onClick={clearTransaction} >
                      Clear Transaction
                    </Button>

                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <BlankCard>
                    <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
                      <TableHead sx={{ backgroundColor: 'grey.200' }}>
                        <TableRow>
                          <TableCell sx={{ py: 1, color: 'text.secondary' }}></TableCell>
                          <TableCell sx={{ py: 1, color: 'text.secondary' }}>Product Name</TableCell>
                          <TableCell sx={{ py: 1, color: 'text.secondary' }}>Brand</TableCell>
                          <TableCell sx={{ py: 1, color: 'text.secondary' }}>Model</TableCell>
                          <TableCell sx={{ py: 1, color: 'text.secondary' }}>Quantity</TableCell>
                          <TableCell sx={{ py: 1, color: 'text.secondary' }}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transmitalRequestProducts.length > 0 && transmitalRequestProducts.map((transaction: any, index: any) => {

                          return (

                            <RowComponent returnDetails={transaction} onAddReturnClick={addReturnClick} onRemoveClick={handleRemoveClick} key={index} />

                            // <TableRow key={index}>
                            //   <TableCell sx={{ py: 1, color: 'text.secondary' }}>{transaction.name}</TableCell>
                            //   <TableCell sx={{ py: 1, color: 'text.secondary' }}>{transaction.brand}</TableCell>
                            //   <TableCell sx={{ py: 1, color: 'text.secondary' }}>{transaction.model}</TableCell>
                            //   <TableCell sx={{ py: 1, color: 'text.secondary' }}>{transaction.quantity}</TableCell>
                            //   <TableCell sx={{ py: 1, color: 'text.secondary' }}>
                            //     <Button variant='outlined' size='small' onClick={() => addReturnClick(transaction)}>
                            //       Add Return
                            //     </Button>
                            //   </TableCell>
                            // </TableRow>
                          );
                        })}


                      </TableBody>
                    </Table>
                  </BlankCard>
                </Grid>

                {/* <Grid item xs={12}>
                <BlankCard>
                  <Grid item xs={12} sm={12} padding={2}>
                    <Stack direction="row" spacing={2}>
                      <Button variant="contained" color="primary" onClick={postTransaction}>
                        POST
                      </Button>
                      <Button variant="text" color="error">
                        CLEAR
                      </Button>
                    </Stack>
                  </Grid>

                  <List
                    subheader={
                      <ListItem >
                        <ListItemAvatar >
                          <Box width={20} height={20} >
                          </Box>
                        </ListItemAvatar>
                        <Grid container columnSpacing={1}>
                          <Grid item xs={3}>
                            <ListItemText primary="Item Details" />
                          </Grid>
                          <Grid item xs={3}>
                            <ListItemText primary="Quantity" />
                          </Grid>
                          <Grid item xs={3}>
                            <ListItemText primary="Selling Price" />
                          </Grid>
                          <Grid item xs={3}>
                            <ListItemText primary="Buying Price" />
                          </Grid>

                        </Grid>

                      </ListItem>
                    }
                  >

                    {
                      returnList.map(
                        (ret: any) => {
                          return (
                            <>
                              <ReturnListComponent returnDetails={ret} onRemoveClick={handleRemoveClick} />
                            </>
                          )
                        }
                      )
                    }

                  </List>
                </BlankCard>
              </Grid> */}
              </Grid>

            </Grid>

          </Grid>
        </form>
      </FormikProvider>



    </Box >
  );
};
const queryClient = new QueryClient();

interface GridItemProps {
  data: any;
  copy: any;
}
const ModalReceipt: FC<GridItemProps> = ({ data, copy }) => {
  const { formatWithPrefix } = useFormattedNumber();

  // const theme = useTheme();
  const customizer = useSelector((state: AppState) => state.customizer);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          {customizer.activeMode === 'dark' ? (
            <LogoLight height={'auto'} />
          ) : (
            <LogoDark height={'auto'} />
          )}
          {/* <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            <RECEIPT
              style={{
                fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
              }}
              className="printable-logo"
            />
          </Box> */}
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {copy}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            INVOICE #{data?.slip_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data?.reference_number} <br />
            Issuance Date: {data?.date_of_issuance}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {data?.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Customer Code: {data?.customer_code}
            <br />
            Address: {data?.customer_address}
            <br />
            Contact Person: {data?.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>Location</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>UNIT</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>COST</TableCell>
            {/* <TableCell sx={{ py: 1, color: 'text.secondary' }}>QTY</TableCell> */}
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>AMOUNT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 && data?.transactions.map((transaction: any, index: any) => {
            const stockLoc = transaction.stock_location
              ? transaction.stock_location.filter((result: any) => result.quantity != 0)
              : [];

            console.log(stockLoc);

            const serialNumbers = transaction.serial_numbers.filter(
              (opt: any) => opt.product_id === transaction.product_id,
            );

            let serialIndex = 0;
            let remainingQuantity = transaction.quantity;
            const result = [];
            for (const item of stockLoc) {
              if (remainingQuantity <= 0) break;
              const serialsTaken: any = [];
              let takenQuantity;

              if (item.quantity >= remainingQuantity) {
                takenQuantity = remainingQuantity;
                serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
                result.push({
                  ...item,
                  takenQuantity: remainingQuantity,
                  serial_numbers: serialsTaken,
                });
                remainingQuantity = 0;
              } else {
                takenQuantity = item.quantity;
                serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
                result.push({
                  ...item,
                  takenQuantity: item.quantity,
                  serial_numbers: serialsTaken,
                });
                remainingQuantity -= item.quantity;
              }
              serialIndex += takenQuantity;
            }
            if (remainingQuantity > 0) {
              console.log('Not enough stock available');
            }

            return result.map((item) => (
              <TableRow key={item.id}>
                <TableCell sx={{ py: 1 }}>{transaction.product_name}</TableCell>
                <TableCell sx={{ py: 1 }}>
                  {item.location.name}
                  {item.divider} Floor {item.floor}
                  {item.serial_numbers.length > 0 && (
                    <>
                      <br />
                      Serial Numbers:
                      {item.serial_numbers.map((serials: any) => {
                        return ' ' + serials.value + ', ';
                      })}
                    </>
                  )}
                </TableCell>
                <TableCell sx={{ py: 1 }}>
                  {item.takenQuantity} {transaction.product_unit}
                </TableCell>
                <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.cost)}</TableCell>
                {/* <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell> */}
                <TableCell sx={{ py: 1 }}>
                  {formatWithPrefix(item.takenQuantity * transaction.cost)}
                </TableCell>
              </TableRow>
            ));
          })}
        </TableBody>
      </Table>

      <Divider sx={{ borderColor: '#acacac', display: 'none', displayPrint: 'block' }} />
      <Grid
        container
        columnSpacing={5}
        sx={{ fontSize: { md: '8pt', lg: '12pt' }, display: 'none', displayPrint: 'block' }}
      >
        <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={2.5} className="fontSize8pt">
          Prepared By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columnSpacing={1}>
            <Grid item className="fontSize8pt">
              Checked By:
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={2}>
                <Grid item className="fontSize8pt">
                  Checker
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Hauler Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Customer Authorized Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container columnSpacing={1} justifyContent={'between'}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Received the above goods in full and good condition.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Received By:
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
        mt={1}
      >
        <Divider sx={{ borderColor: 'black' }} />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
        mt={1}
      >
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
              {dayjs().format('MM/DD/YYYY h:mm:ss A')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              User: name of user
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const RowComponent: React.FC<{
  returnDetails: any;
  onAddReturnClick: any;
  onRemoveClick: any;
}> = React.memo(({ returnDetails, onAddReturnClick, onRemoveClick }) => {

  const [open, setOpen] = React.useState(false);

  const addReturnClick = (details: any) => {
    onAddReturnClick(details);
  }

  const handleRemoveClick = (details: any) => {
    onRemoveClick(details);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ py: 1, color: 'text.secondary' }}>{returnDetails.name}</TableCell>
        <TableCell sx={{ py: 1, color: 'text.secondary' }}>{returnDetails.brand}</TableCell>
        <TableCell sx={{ py: 1, color: 'text.secondary' }}>{returnDetails.model}</TableCell>
        <TableCell sx={{ py: 1, color: 'text.secondary' }}>{returnDetails.remaining_quantity}</TableCell>
        <TableCell sx={{ py: 1, color: 'text.secondary', display: returnDetails.remaining_quantity == 0 ? 'none' : 'flex' }}>
          <Button variant='outlined' size='small' onClick={() => addReturnClick(returnDetails)}>
            Add Return
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>

              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Return Items
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Product Condition</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Serial Number</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {returnDetails.transaction && returnDetails.transaction.map((trans: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">
                          <Avatar>
                            <img src={trans.product_image} alt='' height={'auto'} width={'100%'}></img>
                          </Avatar>
                        </TableCell>
                        <TableCell> {trans.product_name} </TableCell>
                        <TableCell>{trans.return_product_status}</TableCell>
                        <TableCell>{trans.return_location_string}{trans.return_product_divider}, Floor {trans.return_product_location}</TableCell>

                        <TableCell>{
                          trans.serial_data ?
                            trans.serial_data.map((serial: any) => {
                              return `${serial.value}, `;
                            })
                            : 'N/A'

                        }</TableCell>
                        <TableCell>{trans.quantity}</TableCell>
                        <TableCell>
                          <IconButton edge="end" aria-label="comments" onClick={() => handleRemoveClick(trans)}>
                            <Close width={20} height={20} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});

const ReturnListComponent: React.FC<{
  returnDetails: any;
  onRemoveClick: any;
}> = React.memo(({ returnDetails, onRemoveClick }) => {


  const handleRemoveClick = () => {
    onRemoveClick(returnDetails);
  };

  const baseQuant = returnDetails.current_quantity ? returnDetails.current_quantity : 0;
  const reqQuant = returnDetails.quotation_quantity ? returnDetails.quotation_quantity : 0;

  const baseQuantity = typeof baseQuant === 'string' ? parseFloat(baseQuant) : baseQuant;
  const quantityAdjustment = typeof reqQuant === 'string' ? parseFloat(reqQuant) : reqQuant;

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItem
          secondaryAction={
            <IconButton edge="end" aria-label="comments" onClick={handleRemoveClick}>
              <Close width={20} height={20} />
            </IconButton>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <img src={returnDetails.product_image} height={'auto'} width={'100%'}></img>
            </Avatar>
          </ListItemAvatar>
          <Grid container columnSpacing={1}>
            <Grid item xs={3}>
              <ListItemText primary={returnDetails.product_name} secondary={returnDetails.product_description} />
            </Grid>
            <Grid item xs={3}>
              <ListItemText
                primary={
                  <>
                    {/* <Typography variant="body2">
                    Current: {returnDetails.current_quantity}
                  </Typography> */}
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Qty: {returnDetails.quotation_quantity ? returnDetails.quotation_quantity : 0}
                    </Typography>

                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Markup: {returnDetails.quotation_percentage ? returnDetails.quotation_percentage : 0}%
                    </Typography>

                    {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>-
                    New Quantity: {baseQuantity + quantityAdjustment}
                  </Typography> */}
                  </>
                }
              />
            </Grid>
            <Grid item xs={3}>
              <ListItemText
                primary={
                  <>
                    <Typography variant="body2">
                      Item Price: {formatCurrency(returnDetails.current_selling)}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Markup Price: {formatCurrency(parseFloat(returnDetails.quotation_selling))}
                    </Typography>
                    {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    New Price: {adjustAndFormat(returnDetails.current_selling, returnDetails.quotation_selling)}
                  </Typography> */}
                  </>
                }
              />
            </Grid>
            <Grid item xs={3}>
              <ListItemText
                primary={
                  <>
                    <Typography variant="body2">
                      Item Price: {formatCurrency(returnDetails.current_buying)}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Markup Price: {formatCurrency(returnDetails.quotation_buying)}
                    </Typography>
                    {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    New Price: {adjustAndFormat(returnDetails.current_buying, returnDetails.quotation_buying)}
                  </Typography> */}
                  </>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText primary="Starred" />
          </ListItemButton>
        </List>
      </Collapse>
      <Divider component="li" />
    </>
  );
});
const ReturnProductEntry = () => {
  return (
    <MainComponent />
  );
};
export default ReturnProductEntry;
