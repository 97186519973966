import React from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import PaymentTermsTable from './components/PaymentTermsTable';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Payment Terms Page',
  },
];

const PaymentTerms = () => {
  return (
    <PageContainer title="RKCWMS - Payment Terms Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Payment Terms Page" items={BCrumb} />
      {/* end breadcrumb */}
      <PaymentTermsTable />
    </PageContainer>
  );
};

export default PaymentTerms;
