import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  Button,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import axiosClient from 'src/axios_client';
import StatusAlert from 'src/components/shared/AlertResponse';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
});

type DialogFormProps = {
  isOpen: boolean;
  isEditing: boolean;
  initialData: { id: number; name: string; description: string } | null;
  onClose: () => void;
  onSubmit: (payload: { id: number | null; name: string; description: string }) => void;
  refetch: () => void;
};

const DialogForm: React.FC<DialogFormProps> = ({
  isOpen,
  isEditing,
  initialData,
  onClose,
  onSubmit,
  refetch,
}) => {
  const [statusCode, setStatusCode] = useState<number | null>(null);
  const [statusData, setStatusData] = useState([]);

  const resetStatus = () => {
    setStatusCode(null);
    setStatusData([]);
  };

  useEffect(() => {
    if (isOpen) {
      resetStatus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (statusCode) {
      const timer = setTimeout(() => {
        resetStatus();
      }, 5000); // Reset after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [statusCode]);

  const formik = useFormik({
    initialValues: {
      id: initialData?.id || null,
      name: initialData?.name || '',
      description: initialData?.description || '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        id: isEditing ? values.id : 0,
        name: values.name,
        description: values.description,
      };

      try {
        let response;
        if (isEditing) {
          response = await axiosClient.put(`/ipo_purposes/${payload.id}`, {
            name: payload.name,
            description: payload.description,
          });
        } else {
          response = await axiosClient.post('/ipo_purposes', {
            name: payload.name,
            description: payload.description,
          });
        }

        console.log('Server Response:', response.data);
        setStatusCode(response.status);
        onSubmit(payload);

        resetForm();

        refetch();

        onClose();
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          setStatusData(error.response.data);
        }
        setStatusCode(error.response?.status || 500);
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      id: initialData?.id || null,
      name: initialData?.name || '',
      description: initialData?.description || '',
    });
  }, [initialData]);

  const handleClose = () => {
    resetStatus();
    onClose();
  };

  return (
    <>
      {statusCode && <StatusAlert statusCode={statusCode} statusData={statusData} />}
      <Dialog open={isOpen} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>{isEditing ? `Edit IPO Purpose [${initialData?.name}]` : 'Add IPO Purpose'}</DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>

            <Box mt={2}>
              <CustomTextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                fullWidth
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                fullWidth
                value={formik.values.description}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit">{isEditing ? 'Update' : 'Add'}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default React.memo(DialogForm);
