import {
  AccountCircle,
  Adjust,
  ArrowForward,
  RefreshOutlined,
  Send,
  ThumbDown,
  ThumbUp,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { IconEdit, IconTrash } from '@tabler/icons';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import React, { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from 'src/axios_client';

type Props = {};
const queryClient = new QueryClient();
type Adjustment = {
  id: number;
  transaction_number: number;
  reference_number: string;
  adjustment_status: number;
  image: number;
  reason: number;
  products: number;
  created_at: number;
  updated_at: any;
};

type AdjustmentApiResponse = {
  data: Array<Adjustment>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};
const ProductTableList = () => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<AdjustmentApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<AdjustmentApiResponse>('/adjustments_transaction', {
          params,
        });

        console.log(response.data);

        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<Adjustment>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'DB ID',
        size: 80,
      },
      {
        accessorKey: 'transaction_number',
        header: 'Transaction number',
      },
      {
        accessorKey: 'reference_number',
        header: 'Reference Number',
      },
      {
        accessorKey: 'adjustment_status',
        header: 'Adjustment Status',
        size: 120,
        Cell: ({ row }) => {
          const status = row.original.adjustment_status;
          const statusText =
            status >= 1 && status <= 3
              ? 'Pending'
              : status >= 4 && status <= 6
              ? 'Approved'
              : 'Unknown';

          return (
            <Chip
              label={statusText}
              color={
                statusText == 'Pending'
                  ? 'secondary'
                  : statusText == 'Approved'
                  ? 'success'
                  : 'error'
              }
            />
          );
        },
      },
      {
        accessorKey: 'reason',
        header: 'Reason',
      },
      {
        accessorKey: 'products',
        header: 'No. of Products',
        enableSorting: false,
        enableColumnDragging: false,
        enableColumnFilter: false,
        size: 80,
      },

      // {
      //     accessorKey: 'actions',
      //     header: 'Actions',
      //     size: 120,
      //     enableSorting: false,
      //     enableColumnActions: false,
      //     Cell: ({ row }) => (
      //         <Grid container spacing={2}>
      //             <Grid item>
      //                 <Button onClick={() => console.log("Edit clicked")} variant="outlined">
      //                     <IconEdit />
      //                 </Button>
      //             </Grid>
      //             <Grid item>
      //                 <Button onClick={() => console.log("Delete clicked")} variant="outlined">
      //                     <IconTrash />
      //                 </Button>
      //             </Grid>
      //         </Grid>
      //     ),
      // },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: 'grid',
    enableRowActions: true,
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 9001, //allow columns to get larger than default
      size: 180, //make columns wider by default
    },
    initialState: {
      showColumnFilters: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      return [
        <MenuItem
          key={0}
          disabled={
            row.original.adjustment_status == 4 ||
            row.original.adjustment_status == 5 ||
            row.original.adjustment_status == 6
          }
          onClick={() => {
            axiosClient
              .post(`/adjustments_transaction/process/${row.original.id}`)
              .then(({ data, status }) => {
                refetch();
                closeMenu();
              })
              .catch((error) => {
                console.log(error);
              });
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <ThumbUp />
          </ListItemIcon>
          Convert To Adjusted
        </MenuItem>,
        <MenuItem
          key={1}
          disabled={
            row.original.adjustment_status == 4 ||
            row.original.adjustment_status == 5 ||
            row.original.adjustment_status == 6
          }
          onClick={() => {
            // Send email logic...
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <ThumbDown />
          </ListItemIcon>
          Decline
        </MenuItem>,
      ];
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
        <Button
          variant="contained"
          size="small"
          color="primary"
          to="/product-adjustment"
          component={Link}
        >
          Product Adjustment
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          to="/product_serial_adjustment"
          component={Link}
        >
          Serial Adjustment
        </Button>
      </Box>
    ),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.log(row.original);
        navigate(`/adjustment-transactions/view/${row.original.transaction_number}`);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });
  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};
function AdjustmentTransactionTable({}: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <ProductTableList />
    </QueryClientProvider>
  );
}

export default AdjustmentTransactionTable;
