import { RefreshOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import axiosClient from 'src/axios_client';
import { formatDate } from 'src/globals/PublicFunctions';

type Props = {};

type TransmitalItem = {
  id: number;
  product_id: number;
  return_transaction_id: number;
  location_id: number;
  product_transmital_id: number;
  floor: string;
  divider: string;
  serial_numbers: string | null;
  quantity: number;
  value: number;
  condition: string;
  created_at: string;
  updated_at: string;
};

type CreatedBy = {
  id: number;
  name: string;
  username: string;
  email: string;
  email_verified_at: string | null;
  userlevel: string;
  is_customer: boolean | null;
  created_by: number | null;
  updated_by: number | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

type Transaction = {
  id: number;
  transaction_number: string;
  transmital_id: number;
  transmital_items: TransmitalItem;
  created_by: CreatedBy;
  created_at: any;
};

type ReturnTransactionsApiResponse = {
  data: Array<Transaction>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

const queryClient = new QueryClient();

const RetTable = () => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<ReturnTransactionsApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<ReturnTransactionsApiResponse>(
          '/ptr_transmital_transaction',
          { params },
        );

        console.log(response.data);

        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },

    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<Transaction>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'DB ID',
        size: 80,
      },
      // {
      //     accessorKey: 'transaction_number',
      //     header: 'Return Transaction',
      // },
      {
        accessorKey: 'created_by',
        header: 'Created By',
        size: 120,
        Cell: ({ row }) => {
          return <Typography variant="subtitle1">{row.original.created_by.name}</Typography>;
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Created At',
        size: 120,
        Cell: ({ row }) => {
          return <Typography variant="subtitle1">{formatDate(row.original.created_at)}</Typography>;
        },
      },

      // {
      //     accessorKey: 'actions',
      //     header: 'Actions',
      //     size: 120,
      //     enableSorting: false,
      //     enableColumnActions: false,
      //     Cell: ({ row }) => (
      //         <Grid container spacing={2}>
      //             <Grid item>
      //                 <Button onClick={() => console.log("Edit clicked")} variant="outlined">
      //                     <IconEdit />
      //                 </Button>
      //             </Grid>
      //             <Grid item>
      //                 <Button onClick={() => console.log("Delete clicked")} variant="outlined">
      //                     <IconTrash />
      //                 </Button>
      //             </Grid>
      //         </Grid>
      //     ),
      // },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: 'grid',
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 9001, //allow columns to get larger than default
      size: 180, //make columns wider by default
    },
    initialState: {
      showColumnFilters: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
      },
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    // enableColumnOrdering: false,
    // enableColumnActions: false,
    // enableSorting: false,
    // enableTopToolbar: false,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.log(row.original);
        // navigate(`/adjustment-transactions/view/${row.original.transaction_number}`);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });
  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};
function ReturnProductsTable({}: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <RetTable />
    </QueryClientProvider>
  );
}

export default ReturnProductsTable;
