import { Box, Button, Card, CardActions, CardContent, Grid, Skeleton, Typography } from '@mui/material'
import { Image } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import axiosClient from 'src/axios_client'
import PageContainer from 'src/components/container/PageContainer'

type Props = {}

function ProductDetailsQr({ }: Props) {

    const { encodedId } = useParams<{ encodedId: string }>(); // Ensure `encodedId` is a string
    const [decodedId, setDecodedId] = useState<string | null>(null);

    const [productDetails, setProductDetails] = useState(null);
    useEffect(() => {
        if (encodedId) { // Check if `encodedId` is defined
            try {
                const decoded = atob(encodedId); // Decode the ID

                getProductDetails(decoded);
                setDecodedId(decoded);
            } catch (error) {
                console.error("Error decoding the ID:", error);
                setDecodedId(null); // Set to null if decoding fails
            }
        } else {
            console.warn("No encoded ID found in URL parameters.");
            setDecodedId(null);
        }
    }, [encodedId]);

    const getProductDetails = async (decoded: any) => {
        const result = await axiosClient.get(`http://localhost:8000/api/product-qr-scan/${decoded}`);
        if (result.status == 200) {
            setProductDetails(result.data);
        }
    }
    const navigate = useNavigate();

    return (
        <PageContainer
            title="RKC WMS IS - Your warehouse management utility"
            description="this is Login page"
        >
            <Box
                sx={{
                    position: 'relative',
                    '&:before': {
                        content: '""',
                        background: 'radial-gradient(#d2f1df, #d3d7fa, #bad8f4)',
                        backgroundSize: '400% 400%',
                        animation: 'gradient 15s ease infinite',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        opacity: '0.3',
                    },
                }}
            >
                <Grid
                    container
                    spacing={0}
                    justifyContent="center"
                    sx={{ height: '100vh', padding: '10px' }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={5}
                        xl={4}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Card
                            elevation={9}
                            sx={{ p: { xs: '20px', md: '20px' }, zIndex: 1, width: '100%', maxWidth: '450px' }}
                        >

                            {productDetails ?
                                <CardContent sx={{ position: 'relative' }}>
                                    <Box sx={{
                                        position: 'absolute',
                                        top: '24px',
                                        right: '24px'
                                    }}>
                                        <Image
                                            width={75}
                                            height={75}
                                            alt=""
                                            src={productDetails['image_url']}
                                            preview={{
                                                width: 'auto',
                                                height: 'auto',
                                                src: productDetails['image_url'],
                                            }}
                                        />
                                    </Box>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Product Details
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {productDetails['product_name']}
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
                                        {productDetails['sku']}
                                    </Typography>
                                    <Typography variant="body2">
                                        <Box
                                            component="span"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            Product Quantity
                                        </Box>: {productDetails['product_quantity']}
                                    </Typography>
                                    <Typography variant="body2">
                                        <Box
                                            component="span"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            Warehouse
                                        </Box>: {productDetails['warehouse']}
                                    </Typography>
                                    <Typography variant="body2">
                                        <Box
                                            component="span"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            Average Issuance (30d)
                                        </Box>: {parseFloat(productDetails['average_consumption']).toFixed(2)}
                                    </Typography>

                                </CardContent>
                                :
                                <CardContent sx={{ position: 'relative' }}>
                                    <Box sx={{
                                        position: 'absolute',
                                        top: '24px',
                                        right: '24px'
                                    }}>
                                        <Skeleton variant="rectangular" width={75} height={75} />
                                    </Box>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
                                    </Typography>
                                    <Typography variant="body2">
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
                                    </Typography>
                                    <Typography variant="body2">
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
                                    </Typography>
                                    <Typography variant="body2">
                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
                                    </Typography>
                                </CardContent>
                            }
                            <CardActions>
                                <Button size="small" onClick={() => {
                                     navigate(`/product-master/view/${decodedId}`);
                                }}>Learn More</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </PageContainer>
    )
}

export default ProductDetailsQr