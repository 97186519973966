import axios from 'axios';

// Get environment variables
const PRIMARY_SERVER = process.env.REACT_APP_API_SERVER;
const BACKUP_SERVER = process.env.REACT_APP_API_SERVER_BACKUP;
const DEV_SERVER = process.env.REACT_APP_API_SERVER_DEV;

// Function to determine the appropriate base URL
const getBaseUrl = () => {
  const currentUrl = window.location.hostname;
  
  // Check if running locally
  if (currentUrl === 'localhost' || currentUrl === '127.0.0.1') {
    return DEV_SERVER;
  }
  
  // Check if URL contains wms1 or wms2
  if (currentUrl.includes('wms1')) {
    return PRIMARY_SERVER;
  }
  if (currentUrl.includes('wms2')) {
    return BACKUP_SERVER;
  }
  
  // Default to primary server if no matches
  return PRIMARY_SERVER;
};

// Initialize axios client with dynamic base URL
const axiosClient = axios.create({
  baseURL: getBaseUrl(),
});

// Request interceptor
axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.Accept = 'application/json';
  config.headers.ContentType = 'application/json';
  return config;
});

// Response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    
    if (response && response.status === 401) {
      // Clear all auth-related items from localStorage
      const itemsToRemove = [
        'ACCESS_TOKEN',
        'admin',
        'userDetails',
        'userAllDetails',
        'token',
        'pusherTransportNonTLS'
      ];
      itemsToRemove.forEach(item => localStorage.removeItem(item));
    }
    
    throw error;
  }
);

export default axiosClient;