import React, { FC } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'src/store/Store';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { AppState } from './store/Store';
import { CombinedProviders } from 'src/contexts/contextProviders';
import { useEssentialDataContext } from './contexts/EssentialDataContext';

interface RouteObject {
  path: string;
  element: JSX.Element;
  uac: string[];
  children?: RouteObject[];
}

const App: FC = () => {
  return (
    <CombinedProviders>
      <AppContent />
    </CombinedProviders>
  );
};

const AppContent = React.memo(() => {
  const theme = ThemeSettings();
  const customizer = useSelector((state: AppState) => state.customizer);
  const { userData } = useEssentialDataContext();


  const filterRoutes = React.useCallback(
    (routes: RouteObject[]): RouteObject[] => {
      const userLevel = userData?.userlevel;
      
      return routes.map((route) => {
        let children: RouteObject[] | undefined = undefined;
        if (route.children) {
          children = filterRoutes(route.children);
        }
        const isAccessible =
          route.uac.length === 0 ||
          (userLevel === 'encoder' && route.uac.includes('encoder')) ||
          (userLevel === 'purchasing' && route.uac.includes('purchasing')) ||
          (userLevel === 'stockman' && route.uac.includes('stockman')) ||
          (userLevel === 'control' && route.uac.includes('control')) ||
          (userLevel === 'accounting' && route.uac.includes('accounting')) ||
          (userLevel === 'admin' && route.uac.includes('admin')) ||
          (userLevel === 'checker' && route.uac.includes('checker')) ||
          (userLevel === 'customer' && route.uac.includes('customer')) ||
          (userLevel === 'approver' && route.uac.includes('approver')) ||
          userLevel === 'superadmin';
        if (!isAccessible) {
          return { ...route, element: <Navigate to="/error/403" />, children };
        }
        return { ...route, children };
      });
    },
    [userData],
  );

  const filteredRoutes = React.useMemo(() => filterRoutes(Router), [filterRoutes]);
  const routing = useRoutes(filteredRoutes);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <ScrollToTop>{routing}</ScrollToTop>
      </RTL>
    </ThemeProvider>
  );
});

export default App;
