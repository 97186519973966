import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Divider,
} from '@mui/material';
import axiosClient from 'src/axios_client';
import dayjs from 'dayjs';
import Invoice from './Invoice'; // Adjust the import path as needed

interface DataType {
  key: string;
  id: number;
  name: string;
  description: string;
  unit: string;
  quantity: number;
  unit_price: number;
  amount: number;
  serial_numbers: { id: string; serial_number: string }[];
  model: any;
  sku: any;
}

interface Supplier {
  id: string;
  code: string;
  name: string;
  address: string;
  contact_person: string;
}

const initialData: DataType[] = [];

interface PurchaseEntryFormStaticProps {
  transactionId: number | null;
}

const PurchaseEntryFormStatic: FC<PurchaseEntryFormStaticProps> = ({ transactionId }) => {
  const [data, setData] = useState(initialData);
  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const [transaction, setTransaction] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(`pe_transactions/${transactionId}`);
        const transactionData = response.data.data;
        setTransaction(transactionData);

        const combinedData: { [key: string]: DataType } = {};
        transactionData.transactions.stocks.forEach((stock: any) => {
          if (!combinedData[stock.product_id]) {
            combinedData[stock.product_id] = {
              key: stock.id,
              id: stock.product_id,
              name: stock.product_name,
              description: stock.product_description,
              model: stock.product_model,
              sku: stock.product_sku,
              unit: stock.product_unit,
              quantity: stock.quantity,
              unit_price: stock.product_entered_price,
              amount: stock.quantity * stock.product_entered_price,
              serial_numbers: [],
            };
          } else {
            combinedData[stock.product_id].quantity += stock.quantity;
            combinedData[stock.product_id].amount += stock.quantity * stock.product_entered_price;
          }
        });

        transactionData.transactions.serial_numbers.forEach((serial: any) => {
          if (!combinedData[serial.product_id]) {
            combinedData[serial.product_id] = {
              key: serial.id,
              id: serial.product_id,
              name: serial.product_name,
              model: serial.product_model,
              sku: serial.product_sku,
              description: `${serial.product_description}`,
              unit: serial.product_unit,
              quantity: 1,
              unit_price: serial.product_entered_price,
              amount: serial.product_entered_price,
              serial_numbers: [{ id: serial.id, serial_number: serial.serial_number }],
            };
          } else {
            combinedData[serial.product_id].quantity += 1;
            combinedData[serial.product_id].amount += serial.product_entered_price;
            combinedData[serial.product_id].description;
            // combinedData[serial.product_id].description += `, ${serial.serial_number}`;
            combinedData[serial.product_id].serial_numbers.push({
              id: serial.id,
              serial_number: serial.serial_number,
            });
          }
        });

        setData(Object.values(combinedData));
        setSupplier(transactionData.supplier);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [transactionId]);

  const invoiceData = {
    id: transaction?.id || '',
    invoice_number: transaction?.invoice_number || '',
    po_number: transaction?.po_number || '',
    reference_number: transaction?.reference_number || '',
    purchase_date: transaction?.date_of_purchase || '',
    created_by_userid: transaction?.created_by.id || '',
    created_by_name: transaction?.created_by.name || '',
    supplier_code: supplier?.code || '',
    supplier_name: supplier?.name || '',
    supplier_address: supplier?.address || '',
    supplier_contact_person: supplier?.contact_person || '',
    transactions: data.map((item) => ({
      product_name: item.name,
      description: item.description,
      model: item.model,
      sku: item.sku,
      unit: item.unit,
      cost: item.unit_price,
      quantity: item.quantity,
      amount: item.amount,
      serial_numbers:
        item.serial_numbers.length > 0
          ? item.serial_numbers.map((sn) => sn.serial_number).join(', ')
          : undefined,
    })),
    subtotal: data.reduce((sum, item) => sum + item.amount, 0),
  };

  return (
    <>
      <Invoice data={invoiceData} />
    </>
  );
};

export default PurchaseEntryFormStatic;
