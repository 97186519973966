import {
  Add,
  CheckCircleOutlineRounded,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  NotificationsOutlined,
  Replay,
} from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Button as ANTButton, Image, Popover } from 'antd';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Field, FieldProps, FormikProvider, useFormik } from 'formik';
import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import axiosClient from 'src/axios_client';
import PageContainer from 'src/components/container/PageContainer';
import BlankCard from 'src/components/shared/BlankCard';
import DashboardCard from 'src/components/shared/DashboardCard';
import CustomCheckbox from 'src/components/theme-elements/CustomCheckbox';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { LogoDark, LogoLight } from 'src/globals/LogoExports';
import { formatCurrency, NumericFormatCustom } from 'src/globals/NumericFormat';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import { AppState } from 'src/store/Store';
import * as Yup from 'yup';
import IpoIssuanceTableView, { IssuanceEntryTransactions } from './IpoIssuanceTable';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import { IconFile3d, IconPencil, IconTrash } from '@tabler/icons';
import ReactToPrint from 'react-to-print';

type Props = {};

type SerialData = {
  value: any;
  is_used: any;
  price: any;
  notice: any;
  status: any;
  inSystem: any;
  id: any;
};
type ProductOptions = {
  id: string;
  name: any;
  sku: any;
  has_serial: any;
  unit: any;
  description: any;
  floating_quantity: any;
  is_adjusting: any;
  model: any;
  brand: any;
  category: any;
  image: any;
  buying_price: any;
  selling_price: any;
  qty_before: any;
  quantity: any;
};

type CustomerOptions = {
  id: any;
  name: any;
  customer_code: any;
  customer_address: any;
  contact_address: any;
  pure_name: any;
};

type ProductTransmital = {
  id: any;
  transmittal_id: any;
  product_name: any;
  brand: any;
  model: any;
  quantity: any;
  remaining_quantity: any;
  serial_number: any;
  reference_number: any;
  transaction: [];
};
type TransmitalOptions = {
  id: string;
  ipo_id: any;
  user_id: any;
  customer_code: any;
  customer_details: any;
  products: IPORequest[];
};

type ReturnTransactions = {
  product_id: any;
  product_name: any;
  product_image: any;
  product_description: any;
  product_unit: any;
  product_model: any,
  product_brand: any,
  cost: any;
  sku: any;
  notice: any;
  serial_data: SerialData[];
  quantity: any;
  ipo_number: any;
  has_serial: any;
  ipo_issuance_id: any;
  ipo_issuance_name: any;
  ipo_issuance_quantity: any;
  floating_quantity: any;
  is_used_first: any;
  product_available_stock: any;
  stock_location: any;
  initial_location: any;
  initial_quantity: any;
  used_stocks: any;
};
type ReceiptData = {
  slip_number: any;
  reference_number: any;
  date_of_issuance: any;
  customer_code: any;
  customer_name: any;
  customer_address: any;
  customer_contact_person: any;
  transactions: ReturnTransactions[];
};

type IPORequest = {
  id: number;
  issued_quantity: number;
  product_name: string;
  transaction: any;
  quantity: any;
  remaining_quantity: number;
  transaction_id: number;
};
interface IssuanceEntryFormProps {
  selectedTransaction?: {
    id: number;
    reference_number: string;
    slip_number: string;
    hauler: string;
    customer_id: number;
    date_of_issuance: string;
    customer: {
      id: number;
      name: string;
      code: string;
      address: string;
      contact_person: string;
    };
    ipo_transaction: {
      approved_by: string;
      created_at: string;
      department: string;
      destination: string;
      id: any;
      image_name: string;
      image_url: string;
      ipo_number: string;
      ipo_requests: IPORequest[];
      project_site: string;
      purpose: string;
      requested_by: string;
      status: number;
      user_id: string;
    };
    transactions: {
      stocks: {
        id: number;
        product_id: number;
        product_name: string;
        product_description: string;
        product_unit: string;
        product_available_stock: number;
        product_has_serial: number;
        quantity: number;
        is_used_first: any;
        floating_quantity: any;
        initial_quantity: any;
        price: number;
        notice: any;
        note: any;
      }[];
      serial_numbers: {
        id: number;
        product_id: number;
        product_image: any;
        is_used_first: any;
        product_name: string;
        product_description: string;
        product_unit: string;
        product_available_stock: number;
        product_has_serial: number;
        serial_number: string;
        price: number;
        is_used: any;
        floor: any;
        divider: any;
        location_id: any;
        serial_number_id: number;
        notice: any;
        note: any;
      }[];
      stock_locations: any;
      initial_location: any;
      used_stocks: any;
    };
    status: string;
  } | null;
  refreshTable: () => void;
  finalizeData: (isFinalized: any, printData: any) => void;
}

const MainComponent = forwardRef<IssuanceEntryFormHandles, IssuanceEntryFormProps>(
  ({ selectedTransaction, refreshTable, finalizeData }, ref) => {
    const selectedTransactionRef = useRef(selectedTransaction);
    useEffect(() => {
      console.log('SELECTED TRANSACTION', selectedTransaction);

      if (selectedTransaction?.customer) {
        const customerOptions = [
          {
            id: selectedTransaction?.customer.id,
            name: `${selectedTransaction?.customer.code}(${selectedTransaction?.customer.name})`,
            customer_code: selectedTransaction?.customer.code,
            customer_address: selectedTransaction?.customer.address,
            contact_address: selectedTransaction?.customer.contact_person,
            pure_name: selectedTransaction?.customer.name,
          },
        ];
        setCustomerOptions(customerOptions);

        const ipoOptions = [
          {
            id: selectedTransaction?.ipo_transaction.id,
            ipo_id: selectedTransaction?.ipo_transaction.id,
            customer_code: null,
            user_id: null,
            customer_details: [],
            products: selectedTransaction?.ipo_transaction.ipo_requests,
          },
        ];
        setIPOOptions(ipoOptions);
        setIssuanceIPORequest(selectedTransaction?.ipo_transaction.ipo_requests);
        setSlipID(selectedTransaction?.ipo_transaction.id);

        formik.setFieldValue('ipo_number', selectedTransaction?.ipo_transaction.id);
        formik.setFieldValue('customer_id', selectedTransaction.customer_id);
        formik.setFieldValue('customer_name', selectedTransaction?.customer.name);
        formik.setFieldValue('hauler', selectedTransaction.hauler);

        let productList: any = [];
        let serialNumbers: any = [];

        const unique = [
          ...new Set(
            selectedTransaction.transactions.serial_numbers.map((item: any) => item.product_id),
          ),
        ];

        const transactions: any = [];

        console.log(selectedTransaction);

        const testRequest = selectedTransaction?.ipo_transaction.ipo_requests.map((item: any) => {
          productList = selectedTransaction.transactions.stocks
            .filter((stocksItem: any) => stocksItem.ipo_requests_id == item.id)
            .map((list) => {
              return list;
            });
          serialNumbers = selectedTransaction.transactions.serial_numbers
            .filter((serialItem: any) => serialItem.ipo_requests_id == item.id)
            .map((list) => {
              return list;
            });
          if (productList.length > 0) {
            let minusMeInIssued = 0;
            const putMeInTransaction = productList.map((prodItems: any) => {

              const initialLocation = selectedTransaction.transactions.initial_location.filter(
                (loc: any) => loc.product_id == prodItems.product_id,
              );
              const initialUsed = selectedTransaction.transactions.used_stocks.filter(
                (usedLoc: any) => usedLoc.product_id == prodItems.product_id,
              );
              const stock_location = selectedTransaction.transactions.stock_locations.filter(
                (stockLoc: any) => stockLoc.product_id == prodItems.product_id,
              );

              minusMeInIssued = minusMeInIssued += prodItems.initial_quantity;

              return {
                cost: prodItems.product_selling_price,
                has_serial: prodItems.product_has_serial,
                notice: prodItems.notice,
                initial_location: initialLocation ? initialLocation : [],
                stock_location: stock_location,
                initial_used: initialUsed ? initialUsed : [],
                initial_quantity: prodItems.initial_quantity,
                ipo_issuance_id: item.id,
                ipo_issuance_name: item.name,
                ipo_issuance_quantity: item.quantity,
                floating_quantity: prodItems.floating_quantity,
                ipo_number: selectedTransaction?.ipo_transaction.ipo_number,
                is_used_first: prodItems.is_used_first,
                product_available_stock: prodItems.product_available_stock,
                product_id: prodItems.product_id,
                product_sku: prodItems.product_sku,
                product_image: prodItems.product_image,
                product_name: prodItems.product_name,
                product_description: prodItems.product_description,
                product_unit: prodItems.product_unit,
                quantity: prodItems.quantity,
                serial_data: [],
                note: prodItems.note,
                used_stocks: initialUsed,
              };
            });

            const returnThis = {
              ...item,
              issued_quantity: item.issued_quantity - minusMeInIssued,
              remaining_quantity: item.remaining_quantity + minusMeInIssued,
              transaction: putMeInTransaction,
            };

            putMeInTransaction.map((test: any) => {
              transactions.push(test);
            });

            return returnThis;
          } else if (serialNumbers.length > 0) {
            let minusMeInIssued = 0;

            console.log(unique);

            const pushThisSerials: any = [];

            const putMeInTransaction = unique.map((uniqueID) => {
              const serialPerProduct = serialNumbers.filter((sn: any) => sn.product_id == uniqueID);

              const initialLocation = selectedTransaction.transactions.initial_location.filter(
                (loc: any) => loc.product_id == uniqueID,
              );
              const initialUsed = selectedTransaction.transactions.used_stocks.filter(
                (usedLoc: any) => usedLoc.product_id == uniqueID,
              );
              const stock_location = selectedTransaction.transactions.stock_locations.filter(
                (stockLoc: any) => stockLoc.product_id == uniqueID,
              );

              if (serialPerProduct.length > 0) {
                minusMeInIssued = minusMeInIssued += serialPerProduct.length;

                const serData = {
                  cost: serialPerProduct[0].product_selling_price,
                  has_serial: 1,
                  initial_location: initialLocation ? initialLocation : [],
                  stock_location: stock_location,
                  initial_used: initialUsed ? initialUsed : [],
                  initial_quantity: serialPerProduct.length,
                  ipo_issuance_id: item.id,
                  ipo_issuance_name: item.name,
                  ipo_issuance_quantity: item.quantity,
                  ipo_number: selectedTransaction?.ipo_transaction.ipo_number,
                  is_used_first: serialPerProduct[0].is_used_first,
                  product_available_stock: serialPerProduct[0].product_available_stock,
                  product_id: serialPerProduct[0].product_id,
                  product_sku: serialPerProduct[0].product_sku,
                  product_image: serialPerProduct[0].product_image,
                  product_name: serialPerProduct[0].product_name,
                  product_description: serialPerProduct[0].product_description,
                  product_unit: serialPerProduct[0].product_unit,
                  quantity: serialPerProduct.length,
                  serial_data: serialPerProduct.map((serialNumber: any) => {
                    return {
                      id: serialNumber.serial_number_id,
                      product_id: serialNumber.product_id,
                      floor: serialNumber.floor,
                      notice: serialNumber.notice,
                      note: serialNumber.note,
                      divider: serialNumber.divider,
                      location_id: serialNumber.location_id,
                      is_used: serialNumber.is_used,
                      serial_number_id: serialNumber.serial_number_id,
                      price: serialNumber.price,
                      product_price: serialNumber.product_selling_price,
                      value: serialNumber.serial_number,
                    };
                  }),
                  used_stocks: initialUsed,
                };
                pushThisSerials.push(serData);

                return serData;
              } else {
                return [];
              }
            });

            const returnThis = {
              ...item,
              issued_quantity: item.issued_quantity - minusMeInIssued,
              remaining_quantity: item.remaining_quantity + minusMeInIssued,
              transaction: pushThisSerials,
            };

            pushThisSerials.map((test: any) => {
              transactions.push(test);
            });

            return returnThis;
          } else {
            const returnThis = {
              ...item,
            };
            return returnThis;
          }
        });

        const invoiceData: any = {
          date_of_issuance: dayjs().toISOString,
          customer_code: selectedTransaction?.customer.code,
          customer_name: selectedTransaction?.customer.name,
          customer_address: selectedTransaction?.customer.address,
          customer_contact_person: selectedTransaction?.customer.contact_person,
          transactions: [...transactions],
        };

        setReceiptData(invoiceData);
        setIssuanceIPORequest(testRequest);

        setIPOList(transactions);

        // const retList = IPOList.filter((item: any) => item.ipo_issuance_id == issuanceIPO?.id).map((list) => {
        //     return list;
        // })

        // retList.push(JsonToSetTable);

        // const set_this = issuanceIPORequest.map((item: any) =>
        //     item.id === issuanceIPO?.id ? { ...item, transaction: retList } : item
        // );

        // const customerOpt = customerOptions.find(p => p.id === formik.values.customer_id);
      }
      selectedTransactionRef.current = selectedTransaction;
    }, [selectedTransaction]);
    useImperativeHandle(ref, () => ({
      postTransaction,
      clearTransaction,
      updateTransaction,
    }));

    const clearTransaction = () => {
      console.log('hello');
    };

    const [isSearching, setIsSearching] = useState(false);
    const [isEmptySearch, setIsEmptySearch] = useState(false);
    const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [productOptions, setProductOptions] = useState<ProductOptions[]>([]);
    const [customerOptions, setCustomerOptions] = useState<CustomerOptions[]>([]);
    const [IPOOptions, setIPOOptions] = useState<TransmitalOptions[]>([]);
    const [serialOptions, setSerialOptions] = useState<SerialData[]>([]);
    const [issuanceIPORequest, setIssuanceIPORequest] = useState<IPORequest[]>([]);
    const [IPOList, setIPOList] = useState<ReturnTransactions[]>([]);
    const returnListRef = useRef(IPOList);
    const issuanceIPORef = useRef(issuanceIPORequest);
    const [sucess, setSucess] = useState('');
    const [error, setError] = useState('');
    const [slipID, setSlipID] = useState('');
    const [maxSerials, setMaxSerials] = useState(0);
    const [issuanceIPO, setIssuanceIPO] = useState<IPORequest | null>(null);
    const [stocksLocation, setStocksLocation] = useState([]);
    const [usedStocks, setUsedStocks] = useState([]);
    const [receiptData, setReceiptData] = useState<ReceiptData[]>([]);
    const [inSystemValue, setInSystemValue] = React.useState('0');
    const [productHasSerial, setProductHasSerial] = useState(false);
    const [serial_data, setserial_data] = useState<SerialData[]>([]);
    const [isCheckedFinalize, setIsCheckedFinalize] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isFetchingLocation, setFetchingLocation] = useState(false);
    const [isAutoPrint, setIsAutoPrint] = useState(true);
    const [printData, setPrintData] = useState<any>([]);
    const [printAuto, setPrintAuto] = useState(false);

    useEffect(() => {
      returnListRef.current = IPOList;
    }, [IPOList]);
    useEffect(() => {
      issuanceIPORef.current = issuanceIPORequest;
    }, [issuanceIPORequest]);

    const validationSchema = Yup.object().shape({
      product_id: Yup.string().required('Please provide product'),
      ipo_number: Yup.string().required('Please provide a IPO number'),
      customer_id: Yup.string().required('Please provide a Customer Code'),
      floating_quantity: Yup.number(),
      hauler: Yup.string().required('Please provide a hauler'),
      total_quantity: Yup.number(),
      product_quantity: Yup.number().test(
        'greaterthan-floating',
        'Quantity exceeds available stock',
        function (value) {
          const { total_quantity, floating_quantity, product_id } = this.parent;

          const trueValue = value ?? 0;
          const totalQuant = total_quantity ?? 0;
          const floatingQuant = floating_quantity ?? 0;

          if (trueValue <= 0 || value == null) {
            return this.createError({
              message: 'Quantity must be greater than zero',
            });
          }

          if (!product_id) {
            return this.createError({
              message: 'Product ID is needed',
            });
          }
          if (!issuanceIPO?.remaining_quantity) {
            return this.createError({
              message: 'Please select an IPO Item to be issued',
            });
          }

          if ((value ?? 0) > (issuanceIPO?.remaining_quantity || 0)) {
            console.log('Has error');
            return this.createError({
              message: `Quantity exceeds available (${issuanceIPO?.remaining_quantity || 0})`,
            });
          }

          if (trueValue > totalQuant - floatingQuant) {
            return false;
          } else {
            setMaxSerials(value ?? 0);

            return true;
          }
        },
      ),
      issuance_serials: Yup.string().test(
        'unique-check',
        'Serial number is already added',
        function (value) {
          const { serial_data, product_quantity } = this.parent;
          if (productHasSerial && product_quantity) {
            if (inSystemValue == '1') {
              if (maxSerials > 0 && serial_data.length == maxSerials) {
                setError('Serial Numbers max limit reached');
                return true;
              } else if (
                serial_data.length < maxSerials &&
                !serial_data.some((item: any) => item.value === value)
              ) {
                return this.createError({ message: 'Please add more serial numbers' });
              } else if (serial_data.length > maxSerials) {
                return this.createError({
                  message: 'Please make sure Quantity is equal to Serial Numbers',
                });
              } else {
                return (
                  !serial_data.some((item: any) => item.value === value) &&
                  serial_data.length < maxSerials
                );
              }
            } else {
              if (
                serial_data.length < maxSerials &&
                !serial_data.some((item: any) => item.value === value)
              ) {
                return this.createError({ message: 'Please add more serial numbers' });
              } else if (serial_data.length > maxSerials) {
                return this.createError({ message: 'Please remove some serial numbers' });
              } else {
                return true;
              }
            }
          } else {
            return true;
          }
        },
      ),
    });
    const formik = useFormik({
      enableReinitialize: false,
      initialValues: {
        hauler: '',
        product_id: '',
        product_description: '',
        customer_id: '',
        customer_name: '',
        ipo_number: '',
        is_used_first: 1,
        product_status: null,
        product_quantity: '',
        product_image: '',
        unit: '',
        product_price: '',
        product_name: '',
        issuance_serials: '',
        serial_data: [],
        return_price: 0,
        return_condition: '',
        return_location: '',
        location_divider: '',
        location_floor: '',
        transaction_number: '',
        sku: '',
        has_serial: 0,
        total_quantity: '',
        total_amount: 0,
        floating_quantity: '',
      },
      validateOnChange: false,
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        if (formik.isValid) {
          console.log('FORM SUBMITED');
        } else {
          console.log('Form invalid');
        }
      },
    });
    const autocompleteSearchChange = (searchText: any, apiText: any) => {
      setIsSearching(true);
      setIsEmptySearch(false);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        const value = searchText.target.value;

        fetchOptions(value, apiText);
      }, 500);
    };

    const fetchOptions = async (searchText: string, apiText: string) => {
      try {
        const filter = [
          { id: 'name', value: searchText },

          // { "id": "is_adjusting", "value": 1 }
        ];

        const { status, data } = await axiosClient.get(
          `/${apiText == 'serial' ? 'check_serial_number' : apiText}`,
          {
            params:
              apiText == 'serial'
                ? { globalFilter: searchText, product_id: formik.values.product_id, per_page: 10 }
                : apiText == 'customers'
                  ? {
                    globalFilter: searchText,
                    per_page: 10,
                  }
                  : apiText == 'ipo_transaction'
                    ? {
                      filters: JSON.stringify(filter),
                      is_search: true,
                      per_page: 10,
                    }
                    : {
                      globalFilter: searchText,
                      per_page: 10,
                    },
          },
        );

        console.log(data.data);
        if (status == 200) {
          setIsSearching(false);
          if (data.data.length == 0) {
            setIsEmptySearch(true);
          }

          if (apiText == 'serial') {
            const fetchedData = data.data.map((innerData: any) => ({
              id: innerData.id,
              product_id: innerData.product_id,
              floor: innerData.floor,
              status: innerData.status,
              divider: innerData.divider,
              location_id: innerData.location_id,
              is_used: innerData.is_used,
              serial_number_id: innerData.id,
              price: innerData.product_entered_price,
              product_price: innerData.product_selling_price,
              value: innerData.serial_number,
              inSystem: true,
            }));
            setSerialOptions(fetchedData);
          } else if (apiText == 'customers') {
            const fetchedData = data.data.map((innerData: any) => ({
              id: innerData.id,
              name: `${innerData.code}(${innerData.name})`,
              customer_code: innerData.code,
              customer_address: innerData.address,
              contact_address: innerData.contact_person,
              pure_name: innerData.name,
            }));
            setCustomerOptions(fetchedData);
          } else if (apiText == 'ipo_transaction') {
            const fetchedData = data.data.map((innerData: any) => {
              return {
                id: innerData.id,
                ipo_id: innerData.id,
                user_id: innerData.user_id,
                customer_code: innerData.customer_code,
                customer_details: innerData.customer_details,
                products: innerData.ipo_requests,
              };
            });

            setIPOOptions(fetchedData);
          } else {
            const fetchedData = data.data.map((innerData: any) => ({
              id: innerData.id,
              name: innerData.name,
              description: innerData.description,
              has_serial: innerData.has_serial,
              image: innerData.image,
              unit: innerData.unit,
              sku: innerData.sku,
              model: innerData.model,
              is_adjusting: innerData.is_adjusting,
              brand: innerData.brand_name,
              category: innerData.category_name,
              floating_quantity: innerData.floating_quantity,
              qty_before: innerData.qty_before,
              buying_price: innerData.buying_price,
              selling_price: innerData.selling_price,
              quantity: innerData.quantity,
            }));
            setProductOptions(fetchedData);
          }
        }
      } catch (error) {
        console.error('Failed to fetch product options:', error);
      }
    };
    const handlePrintCheckbox = (event: any) => {
      setIsAutoPrint(event.target.checked);
    };
    const getProductLocations = async (productId: any) => {
      try {
        const response = await axiosClient.get(`/stocks_location/product/${productId}`);
        console.log(response, "THIS RESPONSE");
        console.log("HEHEKJGHKEROJFHDKLJGHDKLJFGHKLJCGHKLDSGHKLSDFHGKLGHJKGHSDFKLGHSDFGHKL");
        if (response.status == 200 || response.status == 201) {
          setStocksLocation(response.data.stock_location);
          setUsedStocks(response.data.used_stocks);
          setFetchingLocation(false);
        }
        else {
          formik.resetForm({
            values: {
              ...formik.values,
              product_id: '',
              product_quantity: '',
              serial_data: [],
              unit: '',
              total_quantity: '',
              floating_quantity: '',
              product_price: '',
              total_amount: 0,
            },
            touched: formik.touched,
            errors: formik.errors,
          });
          setFetchingLocation(false);
          setTimeout(() => {
            setError('');
          }, 3000)
          setError(`Can't fetch stock locations in server`);
        }
      }
      catch (error) {
        formik.resetForm({
          values: {
            ...formik.values,
            product_id: '',
            product_quantity: '',
            serial_data: [],
            unit: '',
            total_quantity: '',
            floating_quantity: '',
            product_price: '',
            total_amount: 0,
          },
          touched: formik.touched,
          errors: formik.errors,
        });
        setFetchingLocation(false);
        setTimeout(() => {
          setError('');
        }, 3000)
        setError(`Can't fetch stock locations in server`);
      }
    };
    const addIssuanceIPOClick = async () => {

      const errors = await formik.validateForm();
      setError('');

      formik.setTouched({
        ['ipo_number']: true,
        ['product_id']: true,
        ['product_quantity']: true,
        ['issuance_serials']: true,
        ['customer_id']: true,
      });

      if (Object.keys(errors).length === 0) {
        const formikVals = formik.values;

        const detectUnique = IPOList.some(
          (item: any) => item.product_id == formik.values.product_id,
        );
        const existingProductIndex = IPOList.findIndex(
          (item) => item.product_id === formik.values.product_id,
        );

        const selectedProduct = productOptions.find((p) => p.id === formikVals.product_id);

        const initialUsed: any = [];
        const initialLocation: any = [];

        if (existingProductIndex !== -1) {

          if (editKey) {
            const editData = IPOList.find((item: any) => item.product_id == editKey.product_id);
            const updatedArray = IPOList.filter((item: any) => item.product_id !== editKey.product_id);
            const updatedData = issuanceIPORequest.map((item: any) => {
              if (item.transaction) {
                return {
                  ...item,
                  transaction: item.transaction.filter((t: any) => t.product_id !== editKey.product_id),
                };
              } else {
                return {
                  ...item,
                };
              }
            });

            if (editData) {
              editData.initial_location.map((location: any) => {
                initialLocation.push(location);
              });
            }
            else {
              setError('No Location Found, Please try to contact administrator');
              return;
            }

            const JsonToSetTable = {
              product_id: formikVals.product_id,
              product_name: formikVals.product_name,
              product_image: formikVals.product_image,
              product_description: formikVals.product_description,
              product_model: selectedProduct?.model,
              product_brand: selectedProduct?.brand,
              product_unit: formikVals.unit,
              floating_quantity: formikVals.floating_quantity,
              sku: formikVals.sku,
              has_serial: formikVals.has_serial,
              serial_data: formikVals.serial_data,
              quantity: formikVals.product_quantity,
              ipo_number: formikVals.ipo_number,
              ipo_issuance_id: issuanceIPO?.id,
              ipo_issuance_name: issuanceIPO?.product_name,
              ipo_issuance_quantity: issuanceIPO?.quantity,
              is_used_first: isUsedFirst,
              notice: null,
              cost: formikVals.product_price,
              product_available_stock: selectedProduct?.quantity,
              stock_location: stocksLocation,
              initial_location: initialLocation
                ? initialLocation.concat(stocksLocation)
                : stocksLocation,
              initial_quantity: selectedProduct?.quantity,
              initial_used: initialUsed ? initialUsed.concat(usedStocks) : usedStocks,
              used_stocks: initialUsed ? initialUsed.concat(usedStocks) : usedStocks,
            };

            const retList = updatedArray.filter(
              (item: any) => item.ipo_issuance_id == issuanceIPO?.id,
            ).map((list) => {
              return list;
            });
            const totalQuantity = updatedArray.filter(
              (item: any) => item.ipo_issuance_id == issuanceIPO?.id,
            ).reduce((acc, list) => acc + list.quantity, 0);
            if (totalQuantity + formikVals.product_quantity > issuanceIPO?.quantity) {
              setError(
                `You will exceed the maximum quantity you can return on product ${issuanceIPO?.product_name}`,
              );
              return;
            }
            retList.push(JsonToSetTable);
            const set_this = updatedData.map((item: any) =>
              item.id === issuanceIPO?.id ? { ...item, transaction: retList } : item,
            );
            const customerOpt = customerOptions.find((p) => p.id === formik.values.customer_id);
            const invoiceData: any = {
              date_of_issuance: dayjs().toISOString,
              customer_code: customerOpt?.customer_code,
              customer_name: customerOpt?.customer_code,
              customer_address: customerOpt?.customer_address,
              customer_contact_person: customerOpt?.contact_address,
              transactions: [...updatedArray, JsonToSetTable],
            };


            setReceiptData(invoiceData);
            setIssuanceIPORequest(set_this);
            setIPOList([...updatedArray, JsonToSetTable]);

            formik.resetForm({
              values: {
                ...formik.values,
                product_id: '',
                product_quantity: '',
                serial_data: [],
                unit: '',
                total_quantity: '',
                floating_quantity: '',
                product_price: '',
                total_amount: 0,
              },
              touched: formik.touched,
              errors: formik.errors,
            });

            setProductOptions([]);
            setSerialOptions([]);
            setserial_data([]);
            setMaxSerials(0);
            setIssuanceIPO(null);
            setError('');
            setEditKey('');
            return;
          }

          const existingProduct = IPOList[existingProductIndex];
          const availableStock = formik.values.total_quantity;
          const floatingStock = formik.values.floating_quantity;

          setError('Duplicate Product is selected. Please select different product');
        } else {

          const JsonToSetTable = {
            product_id: formikVals.product_id,
            product_name: formikVals.product_name,
            product_image: formikVals.product_image,
            product_description: formikVals.product_description,
            product_model: selectedProduct?.model,
            product_brand: selectedProduct?.brand,
            product_unit: formikVals.unit,
            floating_quantity: formikVals.floating_quantity,
            sku: formikVals.sku,
            has_serial: formikVals.has_serial,
            serial_data: formikVals.serial_data,
            quantity: formikVals.product_quantity,
            ipo_number: formikVals.ipo_number,
            ipo_issuance_id: issuanceIPO?.id,
            ipo_issuance_name: issuanceIPO?.product_name,
            ipo_issuance_quantity: issuanceIPO?.quantity,
            is_used_first: isUsedFirst,
            notice: null,
            cost: formikVals.product_price,
            product_available_stock: selectedProduct?.quantity,
            stock_location: stocksLocation,
            initial_location: initialLocation
              ? initialLocation.concat(stocksLocation)
              : stocksLocation,
            initial_quantity: selectedProduct?.quantity,
            initial_used: initialUsed ? initialUsed.concat(usedStocks) : usedStocks,
            used_stocks: initialUsed ? initialUsed.concat(usedStocks) : usedStocks,
          };

          const retList = IPOList.filter(
            (item: any) => item.ipo_issuance_id == issuanceIPO?.id,
          ).map((list) => {
            return list;
          });

          const totalQuantity = IPOList.filter(
            (item: any) => item.ipo_issuance_id == issuanceIPO?.id,
          ).reduce((acc, list) => acc + list.quantity, 0);

          if (totalQuantity + formikVals.product_quantity > issuanceIPO?.quantity) {
            setError(
              `You will exceed the maximum quantity you can return on product ${issuanceIPO?.product_name}`,
            );
            return;
          }

          retList.push(JsonToSetTable);

          const set_this = issuanceIPORequest.map((item: any) =>
            item.id === issuanceIPO?.id ? { ...item, transaction: retList } : item,
          );

          const customerOpt = customerOptions.find((p) => p.id === formik.values.customer_id);

          const invoiceData: any = {
            date_of_issuance: dayjs().toISOString,
            customer_code: customerOpt?.customer_code,
            customer_name: customerOpt?.customer_code,
            customer_address: customerOpt?.customer_address,
            customer_contact_person: customerOpt?.contact_address,
            transactions: [...IPOList, JsonToSetTable],
          };

          setReceiptData(invoiceData);

          setIssuanceIPORequest(set_this);

          setIPOList([...IPOList, JsonToSetTable]);

          console.log(formikVals);

          formik.resetForm({
            values: {
              ...formik.values,
              product_id: '',
              product_quantity: '',
              serial_data: [],
              unit: '',
              total_quantity: '',
              floating_quantity: '',
              product_price: '',
              total_amount: 0,
            },
            touched: formik.touched,
            errors: formik.errors,
          });

          setProductOptions([]);
          setSerialOptions([]);
          setserial_data([]);
          setMaxSerials(0);
          setIssuanceIPO(null);
          setError('');

          // setIPOList([...IPOList, JsonToSet]);
        }
      }
      console.log(errors);
    };
    function onChangeFinalize(e: any) {
      const checked = e.target.checked;
      setIsCheckedFinalize(checked);
    }


    const postTransaction = () => {
      if (isCheckedFinalize) {
        setError("You can't finalize this issuance when it's not yet checked");
        return;
      }

      if (IPOList.length > 0) {
        setSubmitting(true);
        let totalAmount = 0;

        // ipo_number: formikVals.ipo_number,
        // ipo_issuance_id: issuanceIPO?.id,
        // ipo_issuance_name: issuanceIPO?.product_name,
        // ipo_issuance_quantity: issuanceIPO?.quantity,

        const transactionData = {
          hauler: formik.values.hauler,
          is_finalized: isCheckedFinalize,
          slip_number: formik.values.ipo_number,
          date_of_issuance: dayjs().format('YYYY-MM-DD'),
          customer_id: formik.values.customer_id,
          customer_name: formik.values.customer_name,
          customer_address: '',
          ipo_id: slipID,
          products: IPOList.map((ipoList) => {
            const stockLoc = ipoList.stock_location
              ? ipoList.stock_location.filter((result: any) => result.quantity != 0)
              : [];
            let remainingQuantity = ipoList.quantity;
            let remaining = 0;
            const result = [];
            const getUsedStockResult = [];

            const uniqueMap: any = new Map();

            if (ipoList.serial_data.length > 0) {
              ipoList.serial_data.forEach((item: any) => {
                // Generate a key based on the unique properties
                const key = `${item.product_id}-${item.floor}-${item.divider}-${item.price}-${item.location_id}-${item.is_used}`;

                if (uniqueMap.has(key)) {
                  const existingItem = uniqueMap.get(key);
                  existingItem.quantity++;
                  existingItem.serial_numbers.push(item);
                } else {
                  uniqueMap.set(key, { ...item, quantity: 1, serial_numbers: [item] });
                }
              });
              const uniqueItems: any = Array.from(uniqueMap.values());

              for (const item of uniqueItems) {
                const location = stockLoc?.find(
                  (loc: any) =>
                    loc.location_id === item.location_id && loc.product_id === item.product_id,
                );
                console.log(location);

                totalAmount = totalAmount + ((item.is_used ? item.price : ipoList.cost) * item.quantity);


                // totalAmount = totalAmount + item.price * item.quantity;

                result.push({
                  id: item.id,
                  floor: item.floor,
                  divider: item.divider,
                  product_id: item.product_id,
                  location_id: item.location_id,
                  takenNew: item.is_used == 0 ? item.quantity : 0,
                  takenQuantity: item.quantity,
                  takenUsed: item.is_used == 1 ? item.quantity : 0,
                  used_quantity: item.is_used == 1 ? item.quantity : 0,
                  value: item.price,
                  quantity: item.quantity,
                  location: location?.location,
                  serial_numbers: item.serial_numbers,
                });
              }
            } else {
              if (ipoList.is_used_first && ipoList.serial_data.length == 0) {
                for (const itemDetail of ipoList.used_stocks) {
                  if (remainingQuantity <= 0) break;

                  let takenUsed = 0;
                  const serialsTaken: any = [];

                  if (itemDetail.quantity > 0) {
                    const locationDetails = stockLoc?.find(
                      (loc: any) =>
                        loc.location_id === itemDetail.location_id &&
                        loc.product_id === itemDetail.product_id,
                    );

                    if (itemDetail.quantity >= remainingQuantity) {
                      takenUsed = remainingQuantity;
                      result.push({
                        ...itemDetail,
                        location: locationDetails?.location,
                        takenUsed,
                        takenNew: 0,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });

                      getUsedStockResult.push({
                        ...itemDetail,
                        quantity: takenUsed,
                      });

                      remainingQuantity = 0;
                    } else {
                      takenUsed = itemDetail.quantity;
                      result.push({
                        ...itemDetail,
                        location: locationDetails?.location,
                        takenUsed,
                        takenNew: 0,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });


                      getUsedStockResult.push({
                        ...itemDetail,
                        quantity: takenUsed,
                      });

                      remainingQuantity -= takenUsed;
                      remaining = remaining += takenUsed;
                    }

                  }

                  totalAmount = totalAmount + (ipoList.cost * takenUsed);


                }

                if (remainingQuantity > 0) {
                  for (const item of stockLoc) {
                    if (remainingQuantity <= 0) break;

                    let takenNew = 0;
                    const serialsTaken: any = [];

                    if (item.quantity > 0) {
                      if (item.quantity >= remainingQuantity) {
                        takenNew = remainingQuantity;
                        result.push({
                          ...item,
                          takenUsed: 0,
                          takenNew,
                          takenQuantity: takenNew,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity = 0;
                      } else {
                        takenNew = item.quantity;
                        result.push({
                          ...item,
                          takenUsed: 0,
                          takenNew,
                          takenQuantity: takenNew,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity -= takenNew;
                        remaining = remaining += takenNew;
                      }

                    }

                    totalAmount = totalAmount + (ipoList.cost * takenNew);

                  }
                }
              } else {
                for (const item of stockLoc) {
                  if (remainingQuantity <= 0) break;

                  let takenNew = 0;
                  const serialsTaken: any = [];

                  if (item.quantity > 0) {
                    if (item.quantity >= remainingQuantity) {
                      takenNew = remainingQuantity;
                      result.push({
                        ...item,
                        takenUsed: 0,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenNew = item.quantity;
                      result.push({
                        ...item,
                        takenUsed: 0,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });

                      remaining = remaining += takenNew;
                      remainingQuantity -= takenNew;
                    }

                  }

                  totalAmount = totalAmount + (ipoList.cost * takenNew);

                }

                if (remainingQuantity > 0) {
                  for (const itemDetail of ipoList.used_stocks) {
                    if (remainingQuantity <= 0) break;

                    let takenUsed = 0;
                    const serialsTaken: any = [];

                    if (itemDetail.quantity > 0) {
                      const locationDetails = stockLoc?.find(
                        (loc: any) =>
                          loc.location_id === itemDetail.location_id &&
                          loc.product_id === itemDetail.product_id,
                      );

                      if (itemDetail.quantity >= remainingQuantity) {
                        takenUsed = remainingQuantity;
                        result.push({
                          ...itemDetail,
                          location: locationDetails?.location,
                          takenUsed,
                          takenNew: 0,
                          takenQuantity: takenUsed,
                          serial_numbers: serialsTaken,
                        });

                        getUsedStockResult.push({
                          ...itemDetail,
                          quantity: takenUsed,
                        });
                        remainingQuantity = 0;
                      } else {
                        takenUsed = itemDetail.quantity;
                        result.push({
                          ...itemDetail,
                          location: locationDetails?.location,
                          takenUsed,
                          takenNew: 0,
                          takenQuantity: takenUsed,
                          serial_numbers: serialsTaken,
                        });
                        getUsedStockResult.push({
                          ...itemDetail,
                          quantity: takenUsed,
                        });

                        remainingQuantity -= takenUsed;
                        remaining = remaining += takenUsed;
                      }
                    }

                    totalAmount = totalAmount + (ipoList.cost * takenUsed);

                  }
                }
              }
            }

            if (remainingQuantity > 0) {
              totalAmount = totalAmount + (ipoList.cost * remainingQuantity);
            }

            return {
              id: ipoList.product_id,
              product_id: ipoList.product_id,
              name: ipoList.product_name,
              quantity: ipoList.quantity,
              unit_price: ipoList.cost,
              amount: totalAmount,
              floating_quantity: ipoList.floating_quantity,
              taken_results: result,
              remainingQuantity: remainingQuantity,
              used_stocks: getUsedStockResult,
              is_used_first: ipoList.is_used_first ? 1 : 0,
              ipo_issuance_request_id: ipoList.ipo_issuance_id,
              serial_numbers: ipoList.serial_data.map((serial) => ({
                id: serial.id,
                is_used: serial.is_used,
                value: serial.value,
                price: serial.price,
              })),
            };
          }),
          total_amount: totalAmount,
        };

        axiosClient
          .post('/ie_transactions', transactionData)
          .then((response) => {
            console.log(response);

            if (response.status == 201 || response.status == 200) {
              formik.resetForm();
              setIPOOptions([]);
              setIPOList([]);
              setSlipID('');
              setIssuanceIPO(null);
              setIssuanceIPORequest([]);
              setReceiptData([]);
              setserial_data([]);
              setProductOptions([]);
              setSubmitting(false);
              setError('');
              setSucess('Issuance posted successfully');
              refreshTable();
              setActiveStep(0);
              setTimeout(() => {
                setSucess('');
              }, 3000);
            } else if (response.status == 500) {
              setSubmitting(false);
              setError(response.data.message);
            }
            setOpen(false);
          })
          .catch((error) => {
            setSubmitting(false);
            setError(error.message);
            console.error('Failed to post transaction:', error);
          });
      } else {
        setError('Please check fields and IPO List to proceed');
      }
    };



    const updateTransaction = () => {
      let isValid = true;
      let totalAmount = 0;

      if (IPOList.length == 0) {
        setError(`You can't submit empty transaction!`);
        return;
      } else {
        setSubmitting(true);
      }

      const updatedTransactionData = {
        is_finalized: isCheckedFinalize,
        id: selectedTransaction?.id,
        slip_number: formik.values.ipo_number,
        date_of_issuance: dayjs().format('YYYY-MM-DD'),
        customer_id: formik.values.customer_id,
        customer_name: formik.values.customer_name,
        hauler: formik.values.hauler,
        customer_address: '',
        ipo_id: slipID,
        products: IPOList.map((ipoList) => {
          const stockLoc = ipoList.initial_location
            ? ipoList.initial_location.filter((result: any) => result.quantity != 0)
            : [];

          if (ipoList.stock_location) {
            const stock = ipoList.stock_location.filter((result: any) => result.quantity != 0);

            if (stock) {
              stockLoc.concat(stock);
            }
          }

          // const stockLoc = ipoList.stock_location
          //     ? ipoList.stock_location.filter((result: any) => result.quantity != 0)
          //     : [];
          let remainingQuantity = ipoList.quantity;
          const result = [];
          const getUsedStockResult = [];

          const uniqueMap: any = new Map();

          if (ipoList.serial_data.length > 0) {
            ipoList.serial_data.forEach((item: any) => {
              // Generate a key based on the unique properties
              const key = `${item.product_id}-${item.floor}-${item.divider}-${item.price}-${item.location_id}-${item.is_used}`;

              if (uniqueMap.has(key)) {
                const existingItem = uniqueMap.get(key);
                existingItem.quantity++;
                existingItem.serial_numbers.push(item);
              } else {
                uniqueMap.set(key, { ...item, quantity: 1, serial_numbers: [item] });
              }
            });
            const uniqueItems: any = Array.from(uniqueMap.values());

            for (const item of uniqueItems) {
              const location = stockLoc?.find(
                (loc: any) =>
                  loc.location_id === item.location_id && loc.product_id === item.product_id,
              );

              totalAmount = totalAmount + ((item.is_used ? item.price : ipoList.cost) * item.quantity);

              result.push({
                id: item.id,
                floor: item.floor,
                divider: item.divider,
                product_id: item.product_id,
                location_id: item.location_id,
                takenNew: item.is_used == 0 ? item.quantity : 0,
                takenQuantity: item.quantity,
                takenUsed: item.is_used == 1 ? item.quantity : 0,
                used_quantity: item.is_used == 1 ? item.quantity : 0,
                value: item.price,
                quantity: item.quantity,
                location: location?.location,
                serial_numbers: item.serial_numbers,
              });
            }
          } else {
            if (ipoList.is_used_first && ipoList.serial_data.length == 0) {
              for (const itemDetail of ipoList.used_stocks) {
                if (remainingQuantity <= 0) break;

                let takenUsed = 0;
                const serialsTaken: any = [];
                if (itemDetail.quantity > 0) {
                  const locationDetails = stockLoc?.find(
                    (loc: any) =>
                      loc.location_id === itemDetail.location_id &&
                      loc.product_id === itemDetail.product_id,
                  );

                  if (itemDetail.quantity >= remainingQuantity) {
                    takenUsed = remainingQuantity;

                    totalAmount = totalAmount + (takenUsed * itemDetail.value);

                    result.push({
                      ...itemDetail,
                      location: locationDetails?.location,
                      takenUsed,
                      takenNew: 0,
                      takenQuantity: takenUsed,
                      serial_numbers: serialsTaken,
                    });

                    getUsedStockResult.push({
                      ...itemDetail,
                      quantity: takenUsed,
                    });

                    remainingQuantity = 0;
                  } else {
                    takenUsed = itemDetail.quantity;
                    totalAmount = totalAmount + (takenUsed * itemDetail.value);
                    result.push({
                      ...itemDetail,
                      location: locationDetails?.location,
                      takenUsed,
                      takenNew: 0,
                      takenQuantity: takenUsed,
                      serial_numbers: serialsTaken,
                    });

                    getUsedStockResult.push({
                      ...itemDetail,
                      quantity: takenUsed,
                    });

                    remainingQuantity -= takenUsed;
                  }

                }
              }

              if (remainingQuantity > 0) {
                for (const item of stockLoc) {
                  if (remainingQuantity <= 0) break;

                  let takenNew = 0;
                  const serialsTaken: any = [];

                  if (item.quantity > 0) {
                    if (item.quantity >= remainingQuantity) {
                      takenNew = remainingQuantity;
                      totalAmount = totalAmount + (takenNew * ipoList.cost);
                      result.push({
                        ...item,
                        takenUsed: 0,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenNew = item.quantity;
                      totalAmount = totalAmount + (takenNew * ipoList.cost);

                      result.push({
                        ...item,
                        takenUsed: 0,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity -= takenNew;
                    }
                  }
                }

              }
            } else {
              for (const item of stockLoc) {
                if (remainingQuantity <= 0) break;

                let takenNew = 0;
                const serialsTaken: any = [];

                if (item.quantity > 0) {
                  if (item.quantity >= remainingQuantity) {
                    takenNew = remainingQuantity;
                    result.push({
                      ...item,
                      takenUsed: 0,
                      takenNew,
                      takenQuantity: takenNew,
                      serial_numbers: serialsTaken,
                    });
                    totalAmount = totalAmount + (ipoList.cost * takenNew)
                    remainingQuantity = 0;
                  } else {
                    takenNew = item.quantity;
                    result.push({
                      ...item,
                      takenUsed: 0,
                      takenNew,
                      takenQuantity: takenNew,
                      serial_numbers: serialsTaken,
                    });

                    totalAmount = totalAmount + (ipoList.cost * takenNew)
                    remainingQuantity -= takenNew;
                  }
                }


              }

              if (remainingQuantity > 0) {
                for (const itemDetail of ipoList.used_stocks) {
                  if (remainingQuantity <= 0) break;

                  let takenUsed = 0;
                  const serialsTaken: any = [];

                  if (itemDetail.quantity > 0) {
                    const locationDetails = stockLoc?.find(
                      (loc: any) =>
                        loc.location_id === itemDetail.location_id &&
                        loc.product_id === itemDetail.product_id,
                    );

                    if (itemDetail.quantity >= remainingQuantity) {
                      takenUsed = remainingQuantity;

                      totalAmount = totalAmount + (takenUsed * itemDetail.value);
                      result.push({
                        ...itemDetail,
                        location: locationDetails?.location,
                        takenUsed,
                        takenNew: 0,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });

                      getUsedStockResult.push({
                        ...itemDetail,
                        quantity: takenUsed,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenUsed = itemDetail.quantity;
                      totalAmount = totalAmount + (takenUsed * itemDetail.value);
                      result.push({
                        ...itemDetail,
                        location: locationDetails?.location,
                        takenUsed,
                        takenNew: 0,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });
                      getUsedStockResult.push({
                        ...itemDetail,
                        quantity: takenUsed,
                      });
                      remainingQuantity -= takenUsed;
                    }
                  }
                }

              }
            }
          }

          if (ipoList.has_serial == 0) {
            if (isCheckedFinalize) {
              if (ipoList.notice !== 0) {
                isValid = false;
                setError("You can't finalize this issuance when it's not yet checked");
                return;
              }
            }
          }

          if (remainingQuantity > 0) {
            totalAmount = totalAmount + (ipoList.cost * remainingQuantity);
          }

          return {
            id: ipoList.product_id,
            name: ipoList.product_name,
            quantity: ipoList.quantity,
            notice: ipoList.notice ? ipoList.notice : null,
            remainingQuantity: remainingQuantity,
            unit_price: ipoList.cost,
            amount: totalAmount,
            floating_quantity: ipoList.floating_quantity,
            taken_results: result,
            used_stocks: getUsedStockResult,
            is_used_first: ipoList.is_used_first ? 1 : 0,
            ipo_issuance_request_id: ipoList.ipo_issuance_id,
            serial_numbers: ipoList.serial_data.map((serial) => {
              if (serial.notice !== 0) {
                if (isCheckedFinalize) {
                  isValid = false;
                  setError("You can't finalize this issuance when it's not yet checked");
                  return;
                }
              }
              return {
                id: serial.id,
                notice: serial.notice ? serial.notice : null,
                is_used: serial.is_used,
                value: serial.value,
                price: serial.price,
              };
            }),
          };
        }),
        total_amount: totalAmount,
      };

      const customerOpt = customerOptions.find((p) => p.id === formik.values.customer_id);

      const invoiceData: any = {
        id: '', // Assuming ID is generated on the server side or not required here
        reference_number: formik.values.ipo_number,
        slip_number: formik.values.ipo_number,
        hauler: formik.values.hauler,
        date_of_issuance: dayjs().format('YYYY-MM-DD'),
        customer_code: customerOpt?.customer_code,
        customer_name: customerOpt?.pure_name,
        customer_address: customerOpt?.customer_address,
        customer_contact_person: customerOpt?.contact_address, // Add the contact person if available
        transactions: IPOList.map((ipoList) => {
          const stockLoc = ipoList.initial_location
            ? ipoList.initial_location.filter((result: any) => result.quantity != 0)
            : [];

          if (ipoList.stock_location) {
            const stock = ipoList.stock_location.filter((result: any) => result.quantity != 0);

            if (stock) {
              stockLoc.concat(stock)
            }
          }

          // const stockLoc = ipoList.stock_location
          //     ? ipoList.stock_location.filter((result: any) => result.quantity != 0)
          //     : [];
          let remainingQuantity = ipoList.quantity;
          const result = [];
          const getUsedStockResult = [];

          const uniqueMap: any = new Map();
          if (ipoList.serial_data.length > 0) {
            ipoList.serial_data.forEach((item: any) => {
              // Generate a key based on the unique properties
              const key = `${item.product_id}-${item.floor}-${item.divider}-${item.price}-${item.location_id}-${item.is_used}`;

              if (uniqueMap.has(key)) {
                const existingItem = uniqueMap.get(key);
                existingItem.quantity++;
                existingItem.serial_numbers.push(item);
              } else {
                uniqueMap.set(key, { ...item, quantity: 1, serial_numbers: [item] });
              }
            });
            const uniqueItems: any = Array.from(uniqueMap.values());

            for (const item of uniqueItems) {

              const location = stockLoc?.find((loc: any) =>
                loc.location_id === item.location_id && loc.product_id === item.product_id
              );

              totalAmount = totalAmount + (item.price * item.quantity);

              result.push({
                id: item.id,
                floor: item.floor,
                divider: item.divider,
                product_id: item.product_id,
                location_id: item.location_id,
                takenNew: item.is_used == 0 ? item.quantity : 0,
                takenQuantity: item.quantity,
                takenUsed: item.is_used == 1 ? item.quantity : 0,
                used_quantity: item.is_used == 1 ? item.quantity : 0,
                value: item.price,
                quantity: item.quantity,
                location: location?.location,
                serial_numbers: item.serial_numbers,
              });

            }
          }

          else {
            if (ipoList.is_used_first && ipoList.serial_data.length == 0) {

              for (const itemDetail of ipoList.used_stocks) {
                if (remainingQuantity <= 0) break;

                let takenUsed = 0;
                const serialsTaken: any = [];

                if (itemDetail.quantity > 0) {

                  const locationDetails = stockLoc?.find((loc: any) =>
                    loc.location_id === itemDetail.location_id && loc.product_id === itemDetail.product_id
                  );


                  if (itemDetail.quantity >= remainingQuantity) {
                    takenUsed = remainingQuantity;
                    result.push({
                      ...itemDetail,
                      location: locationDetails?.location,
                      takenUsed,
                      takenNew: 0,
                      takenQuantity: takenUsed,
                      serial_numbers: serialsTaken,
                    });

                    getUsedStockResult.push({
                      ...itemDetail,
                      quantity: takenUsed,
                    });

                    remainingQuantity = 0;
                  } else {
                    takenUsed = itemDetail.quantity;
                    result.push({
                      ...itemDetail,
                      location: locationDetails?.location,
                      takenUsed,
                      takenNew: 0,
                      takenQuantity: takenUsed,
                      serial_numbers: serialsTaken,
                    });

                    getUsedStockResult.push({
                      ...itemDetail,
                      quantity: takenUsed,
                    });

                    remainingQuantity -= takenUsed;
                  }
                }
              }

              if (remainingQuantity > 0) {
                for (const item of stockLoc) {
                  if (remainingQuantity <= 0) break;

                  let takenNew = 0;
                  const serialsTaken: any = [];

                  if (item.quantity > 0) {
                    if (item.quantity >= remainingQuantity) {
                      takenNew = remainingQuantity;
                      result.push({
                        ...item,
                        takenUsed: 0,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenNew = item.quantity;
                      result.push({
                        ...item,
                        takenUsed: 0,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity -= takenNew;
                    }
                  }
                }
              }
            }
            else {

              for (const item of stockLoc) {
                if (remainingQuantity <= 0) break;

                let takenNew = 0;
                const serialsTaken: any = [];

                if (item.quantity > 0) {
                  if (item.quantity >= remainingQuantity) {
                    takenNew = remainingQuantity;
                    result.push({
                      ...item,
                      takenUsed: 0,
                      takenNew,
                      takenQuantity: takenNew,
                      serial_numbers: serialsTaken,
                    });
                    remainingQuantity = 0;
                  } else {
                    takenNew = item.quantity;
                    result.push({
                      ...item,
                      takenUsed: 0,
                      takenNew,
                      takenQuantity: takenNew,
                      serial_numbers: serialsTaken,
                    });
                    remainingQuantity -= takenNew;
                  }
                }
              }

              if (remainingQuantity > 0) {
                for (const itemDetail of ipoList.used_stocks) {
                  if (remainingQuantity <= 0) break;

                  let takenUsed = 0;
                  const serialsTaken: any = [];

                  if (itemDetail.quantity > 0) {

                    const locationDetails = stockLoc?.find((loc: any) =>
                      loc.location_id === itemDetail.location_id && loc.product_id === itemDetail.product_id
                    );


                    if (itemDetail.quantity >= remainingQuantity) {
                      takenUsed = remainingQuantity;
                      result.push({
                        ...itemDetail,
                        location: locationDetails?.location,
                        takenUsed,
                        takenNew: 0,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });

                      getUsedStockResult.push({
                        ...itemDetail,
                        quantity: takenUsed,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenUsed = itemDetail.quantity;
                      result.push({
                        ...itemDetail,
                        location: locationDetails?.location,
                        takenUsed,
                        takenNew: 0,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });
                      getUsedStockResult.push({
                        ...itemDetail,
                        quantity: takenUsed,
                      });
                      remainingQuantity -= takenUsed;
                    }
                  }
                }
              }
            }
          }

          if (ipoList.has_serial == 0) {
            if (isCheckedFinalize) {
              if (ipoList.notice !== 0) {
                isValid = false;
                setError("You can't finalize this issuance when it's not yet checked");
                return;
              }
            }
          }
          return {
            product_name: ipoList.product_name,
            is_used_first: ipoList.is_used_first,
            description: ipoList.product_description, // Assuming you have description in item
            product_id: ipoList.product_id,
            product_unit: ipoList.product_unit, // Assuming you have unit in item
            cost: ipoList.cost,
            quantity: ipoList.quantity,
            used_stocks: getUsedStockResult,
            floating_quantity: ipoList.floating_quantity,
            stock_location: ipoList.stock_location,
            initial_location: ipoList.initial_location,
            initial_used: ipoList.used_stocks,
            serial_data: ipoList.serial_data.map((serial) => {

              if (serial.notice !== 0) {
                if (isCheckedFinalize) {
                  isValid = false;
                  setError("You can't finalize this issuance when it's not yet checked");
                  return;
                }
              }
              return {
                id: serial.id,
                notice: serial.notice ? serial.notice : null,
                is_used: serial.is_used,
                value: serial.value,
                price: serial.price,
              }
            }),
          };
        }),

        processor: '',
        subtotal: totalAmount,
      };

      if (!isValid) {
        setSubmitting(false);

        return;
      }
      axiosClient
        .put(`/ie_transactions/${selectedTransaction?.id}`, updatedTransactionData)
        .then((response) => {
          // clearTransactionNoConfirm();
          // onRefreshTransactions();

          if (response.status == 200) {
            formik.resetForm();
            setSubmitting(false);
            setIPOOptions([]);
            setIPOList([]);
            setIssuanceIPORequest([]);
            setSlipID('');
            setIssuanceIPO(null);
            setReceiptData([]);
            setserial_data([]);
            setProductOptions([]);
            setError('');
            setSucess('Issuance updated successfully');
            refreshTable();

            setTimeout(() => {
              setSucess('');
            }, 3000);

            setActiveStep(0);
            if (isCheckedFinalize && isAutoPrint) {
              finalizeData(true, invoiceData);
              // setPrintData(invoiceData);
              // setPrintAuto(true);
            }
          } else if (response.status == 500) {
            setSubmitting(false);
            setError(response.data.message);
          }

          setOpen(false);
        })
        .catch((error) => {
          setSubmitting(false);
          setError(error.message);
          console.error('Failed to update transaction:', error);
        });
    };

    const handleRemoveClick = React.useCallback((passedAdj: any) => {
      removeIssuanceProduct(passedAdj.product_id);
    }, []);

    const [editKey, setEditKey] = useState<any>(null);
    const handleEditClick = React.useCallback((passedData: any, returnDetails: any) => {

      setIssuanceIPO(returnDetails);
      setProductHasSerial(passedData.has_serial);

      setStocksLocation(passedData.stock_location);
      setUsedStocks(passedData.used_stocks);

      setProductOptions([{
        id: passedData.product_id,
        name: passedData.product_name,
        description: passedData.product_description,
        has_serial: passedData.has_serial,
        image: passedData.product_image,
        unit: passedData.product_unit,
        category: passedData.category,
        sku: passedData.sku,
        is_adjusting: passedData.is_adjusting,
        model: passedData.product_model,
        brand: passedData.product_brand,
        floating_quantity: passedData.floating_quantity,
        qty_before: passedData.quantity,
        buying_price: passedData.cost,
        selling_price: passedData.cost,
        quantity: passedData.initial_quantity,
      }]);
      formik.setFieldValue('sku', passedData.sku);
      formik.setFieldValue('unit', passedData.product_unit);
      formik.setFieldValue('has_serial', passedData.has_serial);
      formik.setFieldValue('product_image', passedData.product_image);
      formik.setFieldValue('total_quantity', passedData.product_available_stock);
      formik.setFieldValue('product_price', passedData.cost);
      formik.setFieldValue('product_description', passedData.product_description);
      formik.setFieldValue('total_amount', passedData.quantity * passedData.cost);
      formik.setFieldValue('floating_quantity', passedData.floating_quantity ? passedData.floating_quantity - passedData.quantity : 0);
      formik.setFieldValue('product_name', passedData.product_name);
      formik.setFieldValue('product_id', passedData.product_id);
      formik.setFieldValue('product_quantity', passedData.quantity);

      if (passedData.has_serial) {
        setMaxSerials(passedData.quantity);
        setSerialOptions(passedData.serial_data.map((serials: any) => {
          return serials;
        }));


        setserial_data(passedData.serial_data);

        formik.setFieldValue('serial_data', passedData.serial_data);

      }

      setEditKey({
        product_id: passedData.product_id,
        issuance_id: passedData.ipo_issuance_id
      });
    }, []);
    const resetClick = () => {

      formik.resetForm({
        values: {
          ...formik.values,
          product_id: '',
          product_quantity: '',
          serial_data: [],
          unit: '',
          total_quantity: '',
          floating_quantity: '',
          product_price: '',
          total_amount: 0,
        },
        touched: formik.touched,
        errors: formik.errors,
      });

      setProductOptions([]);
      setSerialOptions([]);
      setserial_data([]);
      setMaxSerials(0);
      setIssuanceIPO(null);
      setError('');
      setEditKey('');
    }
    const removeIssuanceProduct = (id: any) => {
      console.log(issuanceIPORef);

      const updatedArray = returnListRef.current.filter((item: any) => item.product_id !== id);

      const updatedData = issuanceIPORef.current.map((item: any) => {
        if (item.transaction) {
          return {
            ...item,
            transaction: item.transaction.filter((t: any) => t.product_id !== id),
          };
        } else {
          return {
            ...item,
          };
        }
      });


      const invoiceData: any = {
        ...receiptData,
        transactions: updatedArray,
      };

      setReceiptData(invoiceData);

      setIPOList(updatedArray);
      setIssuanceIPORequest(updatedData);
      console.log("Done");
    };

    const [fromDate, setFromDate] = useState(dayjs());
    const handleFromDatechange = (newDate: any) => {
      setFromDate(newDate);
    };

    const handleDelete = (chipToDelete: any) => () => {
      console.log(chipToDelete);
      const updatedChips = serial_data.filter((chip) => chip !== chipToDelete);
      setError('');
      setserial_data(updatedChips);
      formik.setFieldValue('serial_data', updatedChips);
    };

    const addReturnClick = (returnDetails: any) => {
      console.log(returnDetails);
      if (returnDetails) {
        setIssuanceIPO(returnDetails);
      }
    };

    const [activeStep, setActiveStep] = useState<number>(0);

    const handleStepClick = (index: number) => async () => {
      formik.setTouched({ ipo_number: true, customer_id: true, hauler: true });
      const errors = await formik.validateForm();
      if (index == 1) {
        if (errors.ipo_number || errors.customer_id || errors.hauler) {
          setError('Please fill in the required fields');
          setTimeout(() => {
            setError('');
          }, 3000)
          return;
        }
      }
      setActiveStep(index);
    };
    const steps = ['Transaction Detail', 'Product Details'];

    const [isUsedFirst, setIsUsedFirst] = useState(true);

    const handleIsUsedFirst = (event: any) => {
      setIsUsedFirst(event.target.checked);
      if (event.target.checked) {
        formik.setFieldValue('is_used_first', 1);
      } else {
        formik.setFieldValue('is_used_first', 0);
      }
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      console.log(IPOList);

      if (IPOList.length > 0) {
        setOpen(true);
      } else {
        setError('Please check fields and IPO list to proceed');
      }
    };
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      if (printAuto == true) {
        const printButton = document.getElementById('print-button');
        if (printButton) {
          printButton.click();
          setPrintAuto(false);
        }
      }
    }, [printAuto]);


    return (
      <Box>


        <Backdrop
          sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={isFetchingLocation}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container padding={3} columnSpacing={2}>
              <Grid item sm={12} lg={4}>
                {sucess && (
                  <Alert variant="filled" severity="success" sx={{ alignItems: 'center', mb: 1 }}>
                    {sucess}
                  </Alert>
                )}

                {error && (
                  <Alert variant="filled" severity="error" sx={{ alignItems: 'center', mb: 1 }}>
                    {error}
                  </Alert>
                )}

                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label} onClick={handleStepClick(index)} sx={{ cursor: 'pointer' }}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>

                <Grid container spacing={2} style={{ display: activeStep === 1 ? 'none' : 'flex' }}>
                  <Grid item xs={12}>
                    <FormLabel component="legend" sx={{ mt: 3 }}>
                      <Typography variant={'h4'}>Ipo Details</Typography>
                    </FormLabel>
                  </Grid>

                  <Grid item xs={6}>
                    <CustomTextField
                      id="issuance_number"
                      size="small"
                      disabled
                      variant="standard"
                      label="Issuance Number"
                      defaultValue="Auto Generated"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        value={fromDate}
                        disabled
                        onChange={handleFromDatechange}
                        slotProps={{ textField: { size: 'small', variant: 'standard' } }}
                        sx={{ width: '100%' }}
                        label="Issuance Date"
                        views={['year', 'month', 'day', 'hours', 'minutes']}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={6}>
                    <Autocomplete
                      id="ipo_number"
                      disabled={selectedTransaction ? true : false}
                      noOptionsText={
                        isSearching
                          ? 'Searching...'
                          : isEmptySearch
                            ? `Can't find search word`
                            : 'Type to search'
                      }
                      options={IPOOptions}
                      value={
                        IPOOptions.find((option) => option.id === formik.values.ipo_number) || null
                      }
                      getOptionLabel={(option) => {
                        // Display "TET-000001" in the dropdown options
                        return `${option.id.toString().padStart(5, '0')}`;
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(_, newValue) => {
                        // const selectedProduct = productOptions.find(p => p.id === newValue);
                        if (newValue) {
                          setIssuanceIPORequest(newValue.products);
                          setSlipID(newValue.ipo_id);

                          formik.setFieldValue('ipo_number', newValue.id);

                          const customerOptions = [
                            {
                              id: newValue.customer_details.id,
                              name: `${newValue.customer_code}(${newValue.customer_details.name})`,
                              customer_code: newValue.customer_code,
                              customer_address: newValue.customer_details.address,
                              contact_address: newValue.customer_details.contact_person,
                              pure_name: newValue.customer_details.name,
                            },
                          ];
                          setCustomerOptions(customerOptions);

                          formik.setFieldValue('customer_id', newValue.customer_details.id);
                          formik.setFieldValue('customer_name', newValue.customer_details.name);
                        } else {
                          formik.setFieldValue('ipo_number', null);
                        }

                        if (newValue === null) {
                          formik.resetForm();
                          setIPOOptions([]);
                        }
                      }}
                      onBlur={() => formik.setFieldTouched('ipo_number', true)}
                      fullWidth
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">IPO - </InputAdornment>
                            ),
                          }}
                          error={formik.touched.ipo_number && Boolean(formik.errors.ipo_number)}
                          helperText={formik.touched.ipo_number && formik.errors.ipo_number}
                          onChange={(e: any) => autocompleteSearchChange(e, 'ipo_transaction')}
                          variant="standard"
                          size="small"
                          label="IPO Number"
                          placeholder="IPO Number"
                          aria-label="IPO Number"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <CustomTextField
                      id="slip_number"
                      size="small"
                      disabled
                      variant="standard"
                      label="Slip Number"
                      defaultValue="Username here"
                      value={formik.values.ipo_number}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Autocomplete
                      id="customer_id"
                      noOptionsText={
                        isSearching
                          ? 'Searching...'
                          : isEmptySearch
                            ? `Can't find search word`
                            : 'Type to search'
                      }
                      value={formik.values.customer_id || null}
                      disabled
                      onChange={async (_, newValue) => {
                        formik.setFieldValue('customer_id', newValue);
                        const customerOpt = customerOptions.find((p) => p.id === newValue);

                        if (customerOpt) {
                          formik.setFieldValue('customer_name', customerOpt.pure_name);
                        }
                        if (newValue === null) {
                          setCustomerOptions([]);
                        }
                      }}
                      options={customerOptions.map((p) => p.id)}
                      fullWidth
                      getOptionLabel={(option) => {
                        const customerOpt = customerOptions.find((p) => p.id === option);

                        return customerOpt ? `${customerOpt.name}` : '';
                      }}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          error={formik.touched.customer_id && Boolean(formik.errors.customer_id)}
                          helperText={formik.touched.customer_id && formik.errors.customer_id}
                          onChange={(e: any) => autocompleteSearchChange(e, 'customers')}
                          variant="standard"
                          label="Auto Generated"
                          size="small"
                          placeholder="Customer Code"
                          aria-label="Customer Code"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <CustomTextField
                      id="customer_name"
                      size="small"
                      value={formik.values.customer_name}
                      disabled
                      variant="standard"
                      label="Customer Name"
                      defaultValue="No Customer Selected"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      id="hauler"
                      name="hauler" // Add this line
                      size="small"
                      value={formik.values.hauler}
                      onChange={formik.handleChange} // Add this line
                      error={formik.touched.hauler && Boolean(formik.errors.hauler)}
                      helperText={formik.touched.hauler && formik.errors.hauler}
                      onBlur={formik.handleBlur} // Optional, add this line for validation on blur
                      variant="standard"
                      label="Hauler"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ display: activeStep === 0 ? 'none' : 'flex' }}>
                  <Grid item xs={12}>
                    <FormLabel component="legend" sx={{ mt: 3 }}>
                      <Grid container direction={'row'} columnSpacing={1}>
                        <Grid item>
                          <Typography variant={'h4'}>Issuance Of Item</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant={'h4'}>-</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant={'h4'}>
                            {issuanceIPO?.product_name ?? 'Item Name'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </FormLabel>
                  </Grid>

                  <Grid item xs={10}>
                    <Autocomplete
                      id="product_id"
                      disabled={!slipID}
                      noOptionsText={
                        isSearching
                          ? 'Searching...'
                          : isEmptySearch
                            ? `Can't find search word`
                            : 'Type to search'
                      }
                      value={formik.values.product_id || null}
                      onChange={async (_, newValue) => {
                        formik.resetForm({
                          values: {
                            ...formik.values,
                            product_id: '',
                            product_quantity: '',
                            serial_data: [],
                            unit: '',
                            total_quantity: '',
                            floating_quantity: '',
                            product_price: '',
                            total_amount: 0,
                          },
                          touched: formik.touched,
                          errors: formik.errors,
                        });
                        setserial_data([]);
                        setSerialOptions([]);
                        setProductHasSerial(false);

                        formik.setFieldValue('product_id', newValue);
                        formik.setFieldValue('serial_data', []);
                        const selectedProduct = productOptions.find((p) => p.id === newValue);
                        if (selectedProduct) {
                          console.log(selectedProduct);
                          if (selectedProduct.has_serial) {
                            setProductHasSerial(true);
                          } else {
                            setserial_data([]);
                            await formik.setFieldValue('serial_data', []);
                            formik.setFieldTouched('serial_data', true);
                            setProductHasSerial(false);
                          }
                          formik.setFieldValue('sku', selectedProduct.sku);
                          formik.setFieldValue('unit', selectedProduct.unit);
                          formik.setFieldValue('has_serial', selectedProduct.has_serial);
                          formik.setFieldValue('product_image', selectedProduct.image);
                          formik.setFieldValue('total_quantity', selectedProduct.quantity);
                          formik.setFieldValue('product_price', selectedProduct.selling_price);
                          formik.setFieldValue('product_description', selectedProduct.description);
                          formik.setFieldValue('floating_quantity', selectedProduct.floating_quantity);
                          formik.setFieldValue('product_name', selectedProduct.name);
                          setFetchingLocation(true);
                          getProductLocations(selectedProduct.id);
                        }
                        if (newValue === null) {
                          setProductOptions([]);
                        }
                      }}
                      options={productOptions.map((p) => p.id)}
                      fullWidth
                      getOptionLabel={(option) => {
                        const productOpt = productOptions.find((p) => p.id === option);
                        return productOpt ? `${productOpt.name} [Model: ${productOpt.model}] [SKU: ${productOpt.sku}] [Brand: ${productOpt.brand}]  [Category: ${productOpt.category}]` : '';
                      }}

                      renderOption={(props, option) => {
                        const productOpt = productOptions.find((p) => p.id === option);

                        return (
                          <li {...props} key={option}>
                            {productOpt && (
                              <>
                                {productOpt.name} [Model: {productOpt.model}] [SKU: {productOpt.sku}] [Brand: {productOpt.brand}] [Category: {productOpt.category}]
                                {productOpt.is_adjusting ? <span>
                                  <Chip
                                    label="Adjusting"
                                    color="info"
                                    size="small"
                                    sx={{
                                      fontSize: '0.8rem',
                                      height: '22px',
                                      minWidth: '50px',
                                    }}
                                  />
                                </span> : ''}

                              </>
                            )}

                          </li>
                        );
                      }}

                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          error={formik.touched.product_id && Boolean(formik.errors.product_id)}
                          helperText={formik.touched.product_id && formik.errors.product_id}
                          onChange={(e: any) => autocompleteSearchChange(e, 'products')}
                          variant="standard"
                          size="small"
                          label="Product name"
                          placeholder="Product name"
                          aria-label="Product name"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomTextField
                      id="product_unit"
                      size="small"
                      variant="standard"
                      label="Unit Type"
                      value={formik.values.unit}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Field
                          validateOnBlur
                          validateOnChange
                          name="product_quantity"
                          render={({ field, form }: FieldProps) => (
                            <CustomTextField
                              {...field}
                              id="product_quantity"
                              size="small"
                              variant="standard"
                              type="number"
                              label="Quantity"
                              disabled={!slipID}
                              value={form.values.product_quantity}
                              onChange={(e: any) => {
                                const reqBuying = parseFloat(e.target.value || 0);
                                const current_selling = parseFloat(
                                  String(formik.values.product_price || 0),
                                );
                                formik.setFieldValue('total_amount', reqBuying * current_selling);
                                form.handleChange(e);
                              }}
                              onBlur={form.handleBlur}
                              error={
                                form.touched.product_quantity &&
                                Boolean(form.errors.product_quantity)
                              }
                              helperText={
                                form.touched.product_quantity && form.errors.product_quantity
                              }
                              fullWidth
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <CustomTextField
                          id="total_stock"
                          size="small"
                          variant="standard"
                          type="number"
                          label="Total Stock"
                          value={formik.values.total_quantity}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CustomTextField
                          id="floating_quantity"
                          size="small"
                          variant="standard"
                          type="number"
                          label="Floating Stock"
                          value={formik.values.floating_quantity}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CustomTextField
                          id="product_price"
                          size="small"
                          variant="standard"
                          value={formatCurrency(formik.values.product_price)}
                          label="Unit Price"
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CustomTextField
                          id="total_amount"
                          size="small"
                          variant="standard"
                          label="Total Amount"
                          value={formatCurrency(formik.values.total_amount)}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      {inSystemValue == '0' ? (
                        <Grid item xs={9}>
                          <Autocomplete
                            multiple
                            filterSelectedOptions={true}
                            disabled={!productHasSerial}
                            id="issuance_serials"
                            value={serial_data}
                            noOptionsText={
                              isSearching
                                ? 'Searching...'
                                : isEmptySearch
                                  ? `Can't find search word`
                                  : 'Type to search'
                            }
                            size="small"
                            fullWidth
                            options={serialOptions}
                            getOptionLabel={(option) => option.value} // Display label in the dropdown
                            renderOption={(props, option) => {

                              return (
                                <li {...props}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>{option.value}</span>

                                    {
                                      option.is_used ? <Chip
                                        label="Old Stock"
                                        color='warning'
                                        size="small"
                                        sx={{
                                          ml: 1,
                                          fontSize: '0.8rem',
                                          height: '22px',
                                          minWidth: '50px',
                                        }}
                                      /> : ''
                                    }
                                    {
                                      option.status == 'issued' ? <Chip
                                        label="Issued"
                                        color='success'
                                        size="small"
                                        sx={{
                                          ml: 1,
                                          fontSize: '0.8rem',
                                          height: '22px',
                                          minWidth: '50px',
                                        }}
                                      /> : ''
                                    }
                                    {
                                      option.status == 'pending' ? <Chip
                                        label="Pending"
                                        color='info'
                                        size="small"
                                        sx={{
                                          ml: 1,
                                          fontSize: '0.8rem',
                                          height: '22px',
                                          minWidth: '50px',
                                        }}
                                      /> : ''
                                    }

                                  </div>
                                </li>
                              )
                            }}
                            getOptionDisabled={(options) => {

                              const serialLength: number = serial_data ? serial_data.length : 0;
                              const productQuantity: number = formik.values.product_quantity ? parseInt(formik.values.product_quantity) : 0;
                              const IsTrue = serialLength >= productQuantity;
                              if (IsTrue) {
                                return IsTrue;
                              }

                              if (options.status != "available") {
                                return true;
                              }
                              else {
                                return false;
                              }
                            }}
                            onChange={async (_, newValue, reason, details) => {
                              setError('');
                              if (details) {
                                if (reason == "selectOption") {
                                  if (details?.option.status != "available") {
                                    setError('This serial has pending issuance / has been issued');
                                    return;
                                  }
                                  if (serial_data.some((item: any) => item.value === details.option.value)) {
                                    setError(`The serial ${details.option.value} is already selected`);

                                    setTimeout(() => {
                                      setError('');
                                    }, 3000)
                                    return;
                                  }
                                }

                                await formik.setFieldValue('serial_data', newValue);
                                setserial_data(newValue);
                              }

                              if (!details) {
                                setserial_data([]);
                                await formik.setFieldValue('serial_data', []);
                                setSerialOptions([]);
                              }
                              formik.setFieldTouched('issuance_serials', true);
                            }}
                            renderInput={(params) => {
                              return (
                                <CustomTextField
                                  {...params}
                                  variant="standard"
                                  error={
                                    formik.touched.issuance_serials &&
                                    Boolean(formik.errors.issuance_serials)
                                  }
                                  helperText={
                                    formik.touched.issuance_serials &&
                                    formik.errors.issuance_serials
                                  }
                                  onChange={(e: any) => {
                                    autocompleteSearchChange(e, 'serial');
                                  }}
                                  size="small"
                                  label="Serial Numbers"
                                  placeholder="Favorites"
                                />
                              );
                            }}
                          />
                        </Grid>
                      ) : ''}
                    </Grid>
                  </Grid>

                  {productHasSerial ? (
                    <>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {serial_data.map((chip, index) => (
                            <Chip
                              key={index}
                              label={chip.value}
                              onDelete={
                                inSystemValue == '1' // Replace with your actual condition
                                  ? handleDelete(chip) // or directly use handleDelete(chip) if it doesn't require event handling
                                  : undefined
                              }
                            />
                          ))}
                        </Box>
                        <Typography variant="caption" color="textSecondary" sx={{ marginTop: 1 }}>
                          {`Serial Numbers You have added ${serial_data.length}/${maxSerials} items.`}
                        </Typography>
                      </Grid>
                    </>
                  ) : ''}

                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                      <Button onClick={addIssuanceIPOClick} startIcon={<Add />}>
                        {editKey ? 'Update' : 'Add'} Item
                      </Button>

                      <Button color="error" onClick={resetClick} startIcon={<Replay />}>
                        Reset Items
                      </Button>
                    </Stack>

                    {/* <Grid container gap={2}>
                      
                      <Button variant="contained" color="primary" onClick={addIssuanceIPOClick}>
                        {editKey ? "Update" : "Add"}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={resetClick}
                      >
                        Reset
                      </Button>
                    </Grid> */}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        onChange={handleIsUsedFirst}
                        disabled={selectedTransaction ? true : false}
                        checked={isUsedFirst}
                        color="primary"
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                      />
                    }
                    label="Is Used First"
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        onChange={onChangeFinalize}
                        checked={isCheckedFinalize}
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                        color="primary"
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                      />
                    }
                    label="Is Finalized"
                  />

                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={isAutoPrint}
                        color="primary"
                        onChange={handlePrintCheckbox}
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                      />
                    }
                    label="Auto Print"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 'bold' }} color={'red'} >Note: <span style={{ fontStyle: 'italic' }}> it will only auto print if finalized and the is auto print is checked </span></Typography>
                </Grid>


              </Grid>

              <Grid item sm={12} lg={8} mt={{ xs: 2, md: 0 }}>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                      <Button onClick={handleClickOpen} startIcon={<IconFile3d />}>
                        {selectedTransaction ? 'Update' : 'Post'} Transaction
                      </Button>

                      <Button color="error" onClick={clearTransaction} startIcon={<IconTrash />}>
                        Clear Transaction
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <BlankCard>
                      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
                        <TableHead sx={{ backgroundColor: 'grey.200' }}>
                          <TableRow>
                            <TableCell sx={{ py: 1, color: 'text.secondary' }}></TableCell>
                            <TableCell sx={{ py: 1, color: 'text.secondary' }}>
                              Product Name
                            </TableCell>
                            <TableCell sx={{ py: 1, color: 'text.secondary' }}></TableCell>
                            <TableCell sx={{ py: 1, color: 'text.secondary' }}></TableCell>
                            <TableCell sx={{ py: 1, color: 'text.secondary' }}>Quantity</TableCell>
                            <TableCell sx={{ py: 1, color: 'text.secondary' }}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {issuanceIPORequest.length > 0 &&
                            issuanceIPORequest.map((transaction: any, index: any) => {
                              return (
                                <RowComponent
                                  returnDetails={transaction}
                                  onAddReturnClick={addReturnClick}
                                  onEditClick={handleEditClick}
                                  onRemoveClick={handleRemoveClick}
                                  key={index}
                                />
                              );
                            })}
                        </TableBody>
                      </Table>
                    </BlankCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>

        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth={'md'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Issuance Details'}</DialogTitle>
          <DialogContent>
            <Backdrop
              sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
              open={isSubmitting}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <ModalReceipt data={receiptData} copy={''} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={selectedTransaction ? updateTransaction : postTransaction} autoFocus>
              {selectedTransaction ? 'Update' : 'Post'}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  },
);

interface GridItemProps {
  data: any;
  copy: any;
}

interface PrintItemProps {
  data: any;
  copy: any;
  theme: any;
}

const PrintItem: FC<PrintItemProps> = ({ data, copy }) => {
  const { formatWithPrefix } = useFormattedNumber();

  const customizer = useSelector((state: AppState) => state.customizer);

  console.log(data);
  const { userData } = useEssentialDataContext();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {customizer.activeMode === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}
          </Box>
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {copy}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            INVOICE #{data?.slip_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data?.reference_number} <br />
            Issuance Date: {data?.date_of_issuance}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {data?.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Customer Code: {data?.customer_code}
            <br />
            Address: {data?.customer_address}
            <br />
            Contact Person: {data?.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left', mb: 1 }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>Location</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>UNIT</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>COST</TableCell>
            {/* <TableCell sx={{ py: 1, color: 'text.secondary' }}>QTY</TableCell> */}
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>AMOUNT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.transactions?.map((transaction: any, index: any) => {
            const stockLoc = transaction.initial_location
              ? transaction.initial_location.filter((result: any) => result.quantity != 0)
              : [];

            if (transaction.stock_location) {
              const stock = transaction.stock_location.filter(
                (result: any) => result.quantity != 0,
              );

              if (stock) {
                stockLoc.concat(stock);
              }
            }

            // const stockLoc = transaction.stock_location
            //     ? transaction.stock_location.filter((result: any) => result.quantity != 0)
            //     : [];

            const serialNumbers = transaction.serial_data.filter(
              (opt: any) => opt.product_id === transaction.product_id,
            );

            let serialIndex = 0;
            let remainingQuantity = transaction.quantity;
            const result = [];

            const uniqueMap: any = new Map();

            if (transaction.serial_data.length > 0) {
              transaction.serial_data.forEach((item: any) => {
                // Generate a key based on the unique properties
                const key = `${item.product_id}-${item.floor}-${item.divider}-${item.price}-${item.location_id}-${item.is_used}`;

                if (uniqueMap.has(key)) {
                  const existingItem = uniqueMap.get(key);
                  existingItem.quantity++;
                  existingItem.serial_numbers.push(item);
                } else {
                  uniqueMap.set(key, { ...item, quantity: 1, serial_numbers: [item] });
                }
              });
              const uniqueItems: any = Array.from(uniqueMap.values());

              for (const item of uniqueItems) {
                const location = stockLoc?.find(
                  (loc: any) =>
                    loc.location_id === item.location_id && loc.product_id === item.product_id,
                );

                result.push({
                  id: item.id,
                  floor: item.floor,
                  divider: item.divider,
                  product_id: item.product_id,
                  location_id: item.location_id,
                  takenNew: item.is_used == 0 ? item.quantity : 0,
                  takenQuantity: item.quantity,
                  takenUsed: item.is_used == 1 ? item.quantity : 0,
                  used_quantity: item.is_used == 1 ? item.quantity : 0,
                  value: item.price,
                  quantity: item.quantity,
                  location: location?.location,
                  serial_numbers: item.serial_numbers,
                });
              }
            } else {
              if (transaction.is_used_first && transaction.serial_data.length == 0) {
                for (const item of transaction.used_stocks) {
                  if (remainingQuantity <= 0) break;

                  let takenUsed = 0;
                  const serialsTaken: any = [];

                  const locationDetails = stockLoc?.find(
                    (loc: any) =>
                      loc.location_id === item.location_id && loc.product_id === item.product_id,
                  );

                  if (item.quantity > 0) {
                    if (item.quantity >= remainingQuantity) {
                      takenUsed = remainingQuantity;
                      serialsTaken.push(
                        ...serialNumbers.slice(serialIndex, serialIndex + takenUsed),
                      );

                      result.push({
                        ...item,
                        takenUsed,
                        takenNew: 0,
                        location: locationDetails?.location,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenUsed = item.quantity;
                      serialsTaken.push(
                        ...serialNumbers.slice(serialIndex, serialIndex + takenUsed),
                      );
                      result.push({
                        ...item,
                        takenUsed,
                        takenNew: 0,
                        location: locationDetails?.location,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity -= takenUsed;
                    }
                    serialIndex += takenUsed;
                  }
                }

                if (remainingQuantity > 0) {
                  for (const item of stockLoc) {
                    if (remainingQuantity <= 0) break;

                    let takenNew = 0;
                    const serialsTaken: any = [];

                    const new_stock_qty = item.quantity - item.used_quantity;

                    if (new_stock_qty > 0) {
                      if (new_stock_qty >= remainingQuantity) {
                        takenNew = remainingQuantity;
                        serialsTaken.push(
                          ...serialNumbers.slice(serialIndex, serialIndex + takenNew),
                        );
                        result.push({
                          ...item,
                          takenUsed: 0,
                          value: transaction.cost,
                          takenNew,
                          takenQuantity: takenNew,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity = 0;
                      } else {
                        takenNew = new_stock_qty;
                        serialsTaken.push(
                          ...serialNumbers.slice(serialIndex, serialIndex + takenNew),
                        );
                        result.push({
                          ...item,
                          takenUsed: 0,
                          value: transaction.cost,
                          takenNew,
                          takenQuantity: takenNew,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity -= takenNew;
                      }
                      serialIndex += takenNew;
                    }
                  }
                }
              } else {
                for (const item of stockLoc) {
                  if (remainingQuantity <= 0) break;

                  let takenNew = 0;
                  const serialsTaken: any = [];

                  const new_stock_qty = item.quantity - item.used_quantity;

                  if (new_stock_qty > 0) {
                    if (new_stock_qty >= remainingQuantity) {
                      takenNew = remainingQuantity;
                      serialsTaken.push(
                        ...serialNumbers.slice(serialIndex, serialIndex + takenNew),
                      );
                      result.push({
                        ...item,
                        takenUsed: 0,
                        value: transaction.cost,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenNew = new_stock_qty;
                      serialsTaken.push(
                        ...serialNumbers.slice(serialIndex, serialIndex + takenNew),
                      );
                      result.push({
                        ...item,
                        takenUsed: 0,
                        value: transaction.cost,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity -= takenNew;
                    }
                    serialIndex += takenNew;
                  }
                }

                if (remainingQuantity > 0) {
                  for (const item of transaction.used_stocks) {
                    if (remainingQuantity <= 0) break;

                    let takenUsed = 0;
                    const serialsTaken: any = [];

                    const locationDetails = stockLoc?.find(
                      (loc: any) =>
                        loc.location_id === item.location_id && loc.product_id === item.product_id,
                    );

                    if (item.quantity > 0) {
                      if (item.quantity >= remainingQuantity) {
                        takenUsed = remainingQuantity;
                        serialsTaken.push(
                          ...serialNumbers.slice(serialIndex, serialIndex + takenUsed),
                        );

                        result.push({
                          ...item,
                          takenUsed,
                          takenNew: 0,
                          location: locationDetails?.location,
                          takenQuantity: takenUsed,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity = 0;
                      } else {
                        takenUsed = item.quantity;
                        serialsTaken.push(
                          ...serialNumbers.slice(serialIndex, serialIndex + takenUsed),
                        );
                        result.push({
                          ...item,
                          takenUsed,
                          takenNew: 0,
                          location: locationDetails?.location,
                          takenQuantity: takenUsed,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity -= takenUsed;
                      }
                      serialIndex += takenUsed;
                    }
                  }
                }
              }

              if (remainingQuantity > 0) {
                console.log(remainingQuantity);
                console.log('Not enough stock available');
              }
            }

            console.log(result);

            return (
              <>
                {remainingQuantity > 0 && transaction.serial_data.length == 0 && (
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      {transaction.product_name}
                      ({transaction.product_sku})
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>
                      No Record
                      {/* {item.location?.name}
                                            {item.divider} Floor {item.floor}
                                            {item.serial_numbers.length > 0 && (
                                                <>
                                                <br />
                                                Serial Numbers:
                                                {item.serial_numbers.map((serials: any) => {
                                                    return ' ' + serials.value + ', ';
                                                })}
                                                </>
                                            )} */}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>
                      {remainingQuantity} {transaction.product_unit}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.cost)}</TableCell>
                    {/* <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell> */}
                    <TableCell sx={{ py: 1 }}>
                      {formatWithPrefix(remainingQuantity * transaction.cost)}
                    </TableCell>
                  </TableRow>
                )}
                {result.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell sx={{ py: 1 }}>{transaction.product_name}  ({transaction.product_sku})</TableCell>
                    <TableCell sx={{ py: 1 }}>
                      {item.location?.name}
                      {item.divider} Floor {item.floor}
                      {item.serial_numbers.length > 0 && (
                        <>
                          <br />
                          Serial Numbers:
                          {item.serial_numbers.map((serials: any) => {
                            return ' ' + serials.value + ', ';
                          })}
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>
                      {item.takenQuantity}{' '}
                      {transaction.serial_data.length > 0 ? '' : item.takenNew > 0 ? 'new' : 'old'}{' '}
                      {transaction.product_unit}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>{formatWithPrefix(item.value)}</TableCell>
                    {/* <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell> */}
                    <TableCell sx={{ py: 1 }}>
                      {formatWithPrefix(item.takenQuantity * item.value)}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            );
          })}
        </TableBody>
      </Table>

      <Divider sx={{ borderColor: '#acacac' }} />
      <Grid container columnSpacing={5} sx={{ fontSize: { md: '8pt', lg: '12pt' } }}>
        {/* <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <MUIDivider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid> */}
        <Grid item xs={5} className="fontSize8pt">
          Prepared By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columnSpacing={1}>
            <Grid item className="fontSize8pt">
              Checked By:
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={2}>
                <Grid item className="fontSize8pt">
                  Checker
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Hauler Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Customer Authorized Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container columnSpacing={1} justifyContent={'between'}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Received the above goods in full and good condition.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Received By:
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <Divider sx={{ borderColor: 'black' }} />
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
              {dayjs().format('MM/DD/YYYY h:mm:ss A')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              User: {userData?.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const ModalReceipt: FC<GridItemProps> = ({ data, copy }) => {
  const { formatWithPrefix } = useFormattedNumber();

  const customizer = useSelector((state: AppState) => state.customizer);

  console.log(data);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          {customizer.activeMode === 'dark' ? (
            <LogoLight height={'auto'} />
          ) : (
            <LogoDark height={'auto'} />
          )}
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {copy}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            INVOICE #{data?.slip_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data?.reference_number} <br />
            Issuance Date: {data?.date_of_issuance}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {data?.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Customer Code: {data?.customer_code}
            <br />
            Address: {data?.customer_address}
            <br />
            Contact Person: {data?.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>Location</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>UNIT</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>COST</TableCell>
            {/* <TableCell sx={{ py: 1, color: 'text.secondary' }}>QTY</TableCell> */}
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>AMOUNT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.transactions?.map((transaction: any, index: any) => {

            const stockLoc = transaction.initial_location
              ? transaction.initial_location.filter((result: any) => result.quantity != 0)
              : [];

            if (transaction.stock_location) {
              const stock = transaction.stock_location.filter(
                (result: any) => result.quantity != 0,
              );

              if (stock) {
                stockLoc.concat(stock);
              }
            }

            // const stockLoc = transaction.stock_location
            //     ? transaction.stock_location.filter((result: any) => result.quantity != 0)
            //     : [];

            const serialNumbers = transaction.serial_data.filter(
              (opt: any) => opt.product_id === transaction.product_id,
            );

            let serialIndex = 0;
            let remainingQuantity = transaction.quantity;
            const result = [];

            const uniqueMap: any = new Map();

            if (transaction.serial_data.length > 0) {
              transaction.serial_data.forEach((item: any) => {
                // Generate a key based on the unique properties
                const key = `${item.product_id}-${item.floor}-${item.divider}-${item.price}-${item.location_id}-${item.is_used}`;

                if (uniqueMap.has(key)) {
                  const existingItem = uniqueMap.get(key);
                  existingItem.quantity++;
                  existingItem.serial_numbers.push(item);
                } else {
                  uniqueMap.set(key, { ...item, quantity: 1, serial_numbers: [item] });
                }
              });
              const uniqueItems: any = Array.from(uniqueMap.values());

              for (const item of uniqueItems) {
                const location = stockLoc?.find(
                  (loc: any) =>
                    loc.location_id === item.location_id && loc.product_id === item.product_id,
                );

                result.push({
                  id: item.id,
                  floor: item.floor,
                  divider: item.divider,
                  product_id: item.product_id,
                  location_id: item.location_id,
                  takenNew: item.is_used == 0 ? item.quantity : 0,
                  takenQuantity: item.quantity,
                  takenUsed: item.is_used == 1 ? item.quantity : 0,
                  used_quantity: item.is_used == 1 ? item.quantity : 0,
                  value: item.price,
                  quantity: item.quantity,
                  location: location?.location,
                  serial_numbers: item.serial_numbers,
                });
              }
            } else {
              if (transaction.is_used_first && transaction.serial_data.length == 0) {
                for (const item of transaction.used_stocks) {
                  if (remainingQuantity <= 0) break;

                  let takenUsed = 0;
                  const serialsTaken: any = [];

                  const locationDetails = stockLoc?.find(
                    (loc: any) =>
                      loc.location_id === item.location_id && loc.product_id === item.product_id,
                  );

                  if (item.quantity > 0) {
                    if (item.quantity >= remainingQuantity) {
                      takenUsed = remainingQuantity;
                      serialsTaken.push(
                        ...serialNumbers.slice(serialIndex, serialIndex + takenUsed),
                      );

                      result.push({
                        ...item,
                        takenUsed,
                        takenNew: 0,
                        location: locationDetails?.location,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenUsed = item.quantity;
                      serialsTaken.push(
                        ...serialNumbers.slice(serialIndex, serialIndex + takenUsed),
                      );
                      result.push({
                        ...item,
                        takenUsed,
                        takenNew: 0,
                        location: locationDetails?.location,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity -= takenUsed;
                    }
                    serialIndex += takenUsed;
                  }
                }

                if (remainingQuantity > 0) {
                  for (const item of stockLoc) {
                    if (remainingQuantity <= 0) break;

                    let takenNew = 0;
                    const serialsTaken: any = [];

                    const new_stock_qty = item.quantity - item.used_quantity;

                    if (new_stock_qty > 0) {
                      if (new_stock_qty >= remainingQuantity) {
                        takenNew = remainingQuantity;
                        serialsTaken.push(
                          ...serialNumbers.slice(serialIndex, serialIndex + takenNew),
                        );
                        result.push({
                          ...item,
                          takenUsed: 0,
                          value: transaction.cost,
                          takenNew,
                          takenQuantity: takenNew,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity = 0;
                      } else {
                        takenNew = new_stock_qty;
                        serialsTaken.push(
                          ...serialNumbers.slice(serialIndex, serialIndex + takenNew),
                        );
                        result.push({
                          ...item,
                          takenUsed: 0,
                          value: transaction.cost,
                          takenNew,
                          takenQuantity: takenNew,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity -= takenNew;
                      }
                      serialIndex += takenNew;
                    }
                  }
                }
              } else {
                for (const item of stockLoc) {
                  if (remainingQuantity <= 0) break;

                  let takenNew = 0;
                  const serialsTaken: any = [];

                  const new_stock_qty = item.quantity - item.used_quantity;

                  if (new_stock_qty > 0) {
                    if (new_stock_qty >= remainingQuantity) {
                      takenNew = remainingQuantity;
                      serialsTaken.push(
                        ...serialNumbers.slice(serialIndex, serialIndex + takenNew),
                      );
                      result.push({
                        ...item,
                        takenUsed: 0,
                        value: transaction.cost,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenNew = new_stock_qty;
                      serialsTaken.push(
                        ...serialNumbers.slice(serialIndex, serialIndex + takenNew),
                      );
                      result.push({
                        ...item,
                        takenUsed: 0,
                        value: transaction.cost,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity -= takenNew;
                    }
                    serialIndex += takenNew;
                  }
                }

                if (remainingQuantity > 0) {
                  for (const item of transaction.used_stocks) {
                    if (remainingQuantity <= 0) break;

                    let takenUsed = 0;
                    const serialsTaken: any = [];

                    const locationDetails = stockLoc?.find(
                      (loc: any) =>
                        loc.location_id === item.location_id && loc.product_id === item.product_id,
                    );

                    if (item.quantity > 0) {
                      if (item.quantity >= remainingQuantity) {
                        takenUsed = remainingQuantity;
                        serialsTaken.push(
                          ...serialNumbers.slice(serialIndex, serialIndex + takenUsed),
                        );

                        result.push({
                          ...item,
                          takenUsed,
                          takenNew: 0,
                          location: locationDetails?.location,
                          takenQuantity: takenUsed,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity = 0;
                      } else {
                        takenUsed = item.quantity;
                        serialsTaken.push(
                          ...serialNumbers.slice(serialIndex, serialIndex + takenUsed),
                        );
                        result.push({
                          ...item,
                          takenUsed,
                          takenNew: 0,
                          location: locationDetails?.location,
                          takenQuantity: takenUsed,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity -= takenUsed;
                      }
                      serialIndex += takenUsed;
                    }
                  }
                }
              }

              if (remainingQuantity > 0) {
                console.log(remainingQuantity);
                console.log('Not enough stock available');
              }
            }

            console.log(result);

            return (
              <>
                {remainingQuantity > 0 && transaction.serial_data.length == 0 && (
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>{transaction.product_name}  ({transaction.product_sku})</TableCell>
                    <TableCell sx={{ py: 1 }}>
                      No Record
                      {/* {item.location?.name}
                                            {item.divider} Floor {item.floor}
                                            {item.serial_numbers.length > 0 && (
                                                <>
                                                <br />
                                                Serial Numbers:
                                                {item.serial_numbers.map((serials: any) => {
                                                    return ' ' + serials.value + ', ';
                                                })}
                                                </>
                                            )} */}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>
                      {remainingQuantity} {transaction.product_unit}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.cost)}</TableCell>
                    {/* <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell> */}
                    <TableCell sx={{ py: 1 }}>
                      {formatWithPrefix(remainingQuantity * transaction.cost)}
                    </TableCell>
                  </TableRow>
                )}
                {result.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell sx={{ py: 1 }}>{transaction.product_name}  ({transaction.product_sku})</TableCell>
                    <TableCell sx={{ py: 1 }}>
                      {item.location?.name}
                      {item.divider} Floor {item.floor}
                      {item.serial_numbers.length > 0 && (
                        <>
                          <br />
                          Serial Numbers:
                          {item.serial_numbers.map((serials: any) => {
                            return ' ' + serials.value + ', ';
                          })}
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>
                      {item.takenQuantity}{' '}
                      {transaction.serial_data.length > 0 ? '' : item.takenNew > 0 ? 'new' : 'old'}{' '}
                      {transaction.product_unit}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>{formatWithPrefix(item.value)}</TableCell>
                    {/* <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell> */}
                    <TableCell sx={{ py: 1 }}>
                      {formatWithPrefix(item.takenQuantity * item.value)}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            );
          })}
        </TableBody>
      </Table>

      <Divider sx={{ borderColor: '#acacac', display: 'none', displayPrint: 'block' }} />
      <Grid
        container
        columnSpacing={5}
        sx={{ fontSize: { md: '8pt', lg: '12pt' }, display: 'none', displayPrint: 'block' }}
      >
        <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={2.5} className="fontSize8pt">
          Prepared By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columnSpacing={1}>
            <Grid item className="fontSize8pt">
              Checked By:
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={2}>
                <Grid item className="fontSize8pt">
                  Checker
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Hauler Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Customer Authorized Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container columnSpacing={1} justifyContent={'between'}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Received the above goods in full and good condition.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Received By:
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
        mt={1}
      >
        <Divider sx={{ borderColor: 'black' }} />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
        mt={1}
      >
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
              {dayjs().format('MM/DD/YYYY h:mm:ss A')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              User: name of user
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
const RowComponent: React.FC<{
  returnDetails: any;
  onAddReturnClick: any;
  onEditClick: any;
  onRemoveClick: any;
}> = React.memo(({ returnDetails, onAddReturnClick, onEditClick, onRemoveClick }) => {
  const [open, setOpen] = React.useState(true);

  const addReturnClick = (details: any) => {
    onAddReturnClick(details);
  };

  const handleRemoveClick = (transaction: any) => {
    onRemoveClick(transaction);
  };
  const handleEditClick = (transaction: any, returnDetails: any) => {

    console.log(transaction);
    console.log(returnDetails);
    onEditClick(transaction, returnDetails);
  }
  const { userData } = useEssentialDataContext();

  const userlevel = userData?.userlevel;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ py: 1, color: 'text.secondary' }}>{returnDetails.product_name}</TableCell>
        <TableCell sx={{ py: 1, color: 'text.secondary' }}></TableCell>
        <TableCell sx={{ py: 1, color: 'text.secondary' }}></TableCell>
        <TableCell sx={{ py: 1, color: 'text.secondary' }}>
          {returnDetails.remaining_quantity}
        </TableCell>
        <TableCell sx={{ py: 1, color: 'text.secondary' }}>
          <Button variant="outlined" size="small" onClick={() => addReturnClick(returnDetails)}>
            Add Issuance
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Issuance Items
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Product Name</TableCell>
                    {/* <TableCell>Location</TableCell> */}
                    <TableCell>Serial Numbers</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {returnDetails.transaction &&
                    returnDetails.transaction.map((trans: any, index: any) => {

                      return (
                        <TableRow key={index}>
                          <TableCell scope="row">
                            {/* <Avatar>
                              <img
                                src={trans.product_image}
                                alt=""
                                height={'auto'}
                                width={'100%'}
                              ></img>
                            </Avatar> */}
                            <Image
                              width={35}
                              alt=''
                              src={trans.product_image}
                              preview={{
                                width: 'auto',
                                src: trans.product_image,
                              }}
                            />
                          </TableCell>
                          <TableCell> {trans.product_name} </TableCell>
                          {/* <TableCell>{trans.return_location_string}{trans.return_product_divider}, Floor {trans.return_product_location}</TableCell> */}
                          {/* <TableCell>{trans.return_location_string}{trans.return_product_divider}, Floor {trans.return_product_location}</TableCell> */}

                          <TableCell>
                            {trans.serial_data
                              ? trans.serial_data.map((serial: any) => {
                                return `${serial.value}, `;
                              })
                              : 'N/A'}
                          </TableCell>
                          <TableCell>{trans.quantity}</TableCell>
                          <TableCell>
                            <Grid container alignItems={'center'}>
                              <Grid item>
                                {trans.has_serial == 1
                                  ? trans.serial_data[0].notice != 0 && (
                                    <>
                                      <IconButton
                                        aria-label="comments"
                                        onClick={() => {
                                          handleEditClick(trans, returnDetails);
                                        }}
                                      >
                                        <IconPencil width={20} height={20} />
                                      </IconButton>
                                      <IconButton
                                        aria-label="comments"
                                        onClick={() => {
                                          handleRemoveClick(trans);
                                        }}
                                      >
                                        <Close width={20} height={20} />
                                      </IconButton>
                                    </>

                                  )
                                  : trans.notice != 0 && (
                                    <>

                                      <IconButton
                                        aria-label="comments"
                                        onClick={() => {
                                          handleEditClick(trans, returnDetails);
                                        }}
                                      >
                                        <IconPencil width={20} height={20} />
                                      </IconButton>
                                      <IconButton
                                        aria-label="comments"
                                        onClick={() => {
                                          handleRemoveClick(trans);
                                        }}
                                      >
                                        <Close width={20} height={20} />
                                      </IconButton>
                                    </>

                                  )}
                              </Grid>
                              <Grid item>
                                {userlevel === 'encoder' && (
                                  <Popover
                                    content={
                                      trans.has_serial == 1 ? (
                                        trans.serial_data && trans.serial_data.length > 0 ? (
                                          trans.serial_data[0].notice == 0 ? (
                                            'OK'
                                          ) : trans.serial_data[0].notice == 1 ? (
                                            <>
                                              Stock Mismatch <br />
                                              {'Note: ' + trans.serial_data[0].note}
                                            </>
                                          ) : trans.serial_data[0].notice == 2 ? (
                                            'Invalid Serial'
                                          ) : (
                                            'No notice'
                                          )
                                        ) : (
                                          'No notice'
                                        )
                                      ) : trans.notice == 0 ? (
                                        'OK'
                                      ) : trans.notice == 1 ? (
                                        <>Stock Mismatch {trans.note}</>
                                      ) : trans.notice == 2 ? (
                                        'Invalid Serial'
                                      ) : (
                                        'No notice'
                                      )
                                    }
                                    title={'Notice'}
                                    trigger="hover"
                                    placement="right"
                                  >
                                    <ANTButton
                                      shape="circle"
                                      icon={
                                        (
                                          trans.has_serial == 1
                                            ? trans.serial_data &&
                                            trans.serial_data.length > 0 &&
                                            trans.serial_data[0].notice == 0
                                            : trans.notice == 0
                                        ) ? (
                                          <CheckCircleOutlineRounded />
                                        ) : (
                                          <NotificationsOutlined />
                                        )
                                      }
                                      className={`pulse-button notice-${trans.has_serial == 1
                                        ? trans.serial_data && trans.serial_data.length > 0
                                          ? trans.serial_data[0].notice == 1 ||
                                            trans.serial_data[0].notice == 2
                                            ? 'red'
                                            : trans.serial_data[0].notice == 0
                                              ? 'green'
                                              : 'orange'
                                          : 'orange'
                                        : trans.notice == 1 || trans.notice == 2
                                          ? 'red'
                                          : trans.notice == 0
                                            ? 'green'
                                            : trans.notice == null || trans.notice === undefined
                                              ? 'orange'
                                              : 'default'
                                        }`}
                                    ></ANTButton>
                                  </Popover>
                                )}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});

interface IssuanceEntryFormHandles {
  postTransaction: () => void;
  clearTransaction: () => void;
  updateTransaction: () => void;
}
function IpoIssuance({ }: Props) {
  const [selectedTransaction, setSelectedTransaction] = useState<any | null>(null);
  const handleOpenFloatingIssuance = useCallback((transaction: IssuanceEntryTransactions | null) => {
    console.log(transaction);
    setSelectedTransaction(transaction);
    console.log('Data:', transaction);
  }, []);
  const issuanceEntryFormRef = useRef<{
    postTransaction: () => void;
    clearTransaction: () => void;
    updateTransaction: () => void;
  }>(null);

  const transactionsTableRef = useRef<{ refetch: () => void }>(null);
  const { userData } = useEssentialDataContext();
  const userlevel = userData?.userlevel;

  const handlePostTransaction = () => {
    console.log('hello posted');
    issuanceEntryFormRef.current?.postTransaction();
  };

  const handleUpdateTransaction = () => {
    console.log('hello updated');
    issuanceEntryFormRef.current?.updateTransaction();
  };

  const handleClearTransaction = () => {
    console.log('hello cleared');
    setSelectedTransaction(null);
    issuanceEntryFormRef.current?.clearTransaction();
  };

  const refreshTable = () => {
    setSelectedTransaction(null);
    transactionsTableRef.current?.refetch();
  };

  const [printDataWithPrice, setPrintDataWithPrice] = useState();
  const [printData, setPrintData] = useState();
  const [printAuto, setPrintAuto] = useState(false);

  useEffect(() => {
    if (printAuto == true) {
      const printButton = document.getElementById('print-button');
      if (printButton) {
        printButton.click();
        setPrintAuto(false);
      }
    }
  }, [printAuto]);

  const customizer = useSelector((state: AppState) => state.customizer);
  const componentRef = useRef(null);
  const currentTheme = customizer.activeMode;

  const finalizeData = (isFinalized: any, printData: any) => {
    console.log("Finalizing Data");

    if (isFinalized == true) {
      setPrintData(printData);
      setPrintAuto(true);
    }
  }

  console.log('Userlevel: ', userlevel);

  return (
    <PageContainer title="RKCWMS - Product Adjustment" description="this is Sample page">
      {/* <Card sx={{ mt: { xs: 0, md: 5 } }} elevation={12}>
                <MainComponent />
            </Card> */}
      {userlevel !== 'superadmin' && userlevel !== 'encoder' ? (
        <></>
      ) : (
        <Card sx={{ mt: { xs: 0, md: 5 }, mb: 2 }} elevation={12}>
          <CardActions>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6">IPO Issuance</Typography>
              </Grid>
            </Grid>
          </CardActions>
          <Box>
            <MainComponent
              ref={issuanceEntryFormRef}
              refreshTable={refreshTable}
              finalizeData={finalizeData}
              selectedTransaction={selectedTransaction}
            />

            {/* <IssuanceEntryForm
                            ref={issuanceEntryFormRef}
                            selectedTransaction={selectedTransaction}
                            onRefreshTransactions={handleRefreshTransactions}
                            userlevel={userlevel}
                        /> */}
          </Box>
        </Card>
      )}

      <Grid
        item
        xs={12}
        md={9}
        ref={componentRef}
        sx={{ display: 'none', displayPrint: 'block' }}
      >
        <Box>
          <PrintItem data={printData} copy={'Receiver Copy'} theme={currentTheme} />
        </Box>
        <div className="pageBreak"></div>
        <Box>
          <PrintItem data={printData} copy={'Encoder Copy'} theme={currentTheme} />
        </Box>
        <div className="pageBreak"></div>
        <Box>
          <PrintItem data={printData} copy={'Warehouse Copy'} theme={currentTheme} />
        </Box>
        <div className="pageBreak"></div>
        <Box>
          <PrintItem data={printData} copy={'Checker Copy'} theme={currentTheme} />
        </Box>
        <div className="pageBreak"></div>
        <Box>
          <PrintItem data={printData} copy={'Accounting Copy'} theme={currentTheme} />
        </Box>
      </Grid>

      <ReactToPrint
        trigger={() => (
          <button id="print-button" style={{ display: 'none' }}>
            Print
          </button>
        )}
        content={() => componentRef.current}
        pageStyle={`
          @media print {
            body {
              -webkit-print-color-adjust: exact;
              margin: 20mm;
              padding: 50px;
            }
            @page {
              margin: 20mm;
            }
            .printTable th,
            .printTable td {
              padding: 2px;
            }
            header, footer {
              display: none;
            }
            .pageBreak {
              page-break-after: always;
            }
            .fontSize8pt {
              font-size: 8pt !important;
            }
            tbody tr:not(:last-child) td {
              border-bottom: 1px solid #acacac;
            }
            .printable-logo {
              fill: #000000 !important; /* Ensure the logo prints in black */
            }
          }
        `}
      />

      <IpoIssuanceTableView
        onOpenFloatingIssuance={handleOpenFloatingIssuance}
        onRefetchRef={transactionsTableRef}
        userlevel={userlevel}
      />
    </PageContainer>
  );
}

export default IpoIssuance;
