import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Box, Button } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { Download, Email, PlayArrow, PrintSharp } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import 'src/styles/reports_print.css';
import {
  Table,
  Typography as TypographyAnt,
  Space,
  Image,
  Button as ButtonAnt,
  Tooltip as TooltipAnt,
  ConfigProvider,
  Row,
  Col,
} from 'antd';
import DateSelector from 'src/components/shared/AntDateSelector';
import dayjs, { Dayjs } from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';
import LogoDark from 'src/assets/images/logos/dark-logo.svg';
import LogoLight from 'src/assets/images/logos/light-logo.svg';

const { Title, Text } = TypographyAnt;
type SelectedSupplierPurchaseApiResponse = {
  supplier_details: {
    id: number;
    name: string;
  };
  data: Array<SelectedSupplierPurchase>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type SelectedSupplierPurchase = {
  id: number;
  name: string;
  code: string;
  purchase_count: string;
  total_amount: string;
};

const PrintableTable = React.forwardRef<
  HTMLDivElement,
  {
    data: SelectedSupplierPurchase[];
    columnsPrint: any[];
    dateRange: string;
    supplier_name: string;
  }
>(({ data, columnsPrint, dateRange, supplier_name }, ref) => {
  const customizer = useSelector((state: AppState) => state.customizer);

  return (
    <div ref={ref} style={{ margin: '0 20px' }}>
      <Space direction="vertical" size="middle" style={{ display: 'flex', marginTop: '20px' }}>
        <Row>
          <Col span={12}>
            <Space direction="vertical" size={0}>
              <Title level={4} style={{ margin: 0 }}>
                RAEL KITZ CORPORATION - WMS IS
              </Title>
              <Text type="secondary">
                RRF Building, San Miguel Calasiao, Pangasinan
                <br />
                Tel No: +1 (123) 456 7891, +44 (876) 543 2198
              </Text>
            </Space>
          </Col>

          <Col span={12} style={{ left: '100px' }}>
            {customizer.activeMode === 'dark' ? (
              <img src={LogoLight} alt="Logo" height={'40px'} />
            ) : (
              <img src={LogoDark} alt="Logo" height={'40px'} />
            )}
          </Col>
        </Row>

        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
        >
          <Title level={5} style={{ margin: 0 }}>
            Purchases Report from [Supplier] {supplier_name}
          </Title>
          <Text style={{ fontSize: '14px' }}>{dateRange}</Text>
        </div>

        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellFontSizeSM: 10,
                borderColor: '#403C3C',
                headerColor: '#403C3C',
                headerBg: '#ebebeb',
              },
            },
          }}
        >
          <Table
            columns={columnsPrint}
            dataSource={data}
            pagination={false}
            bordered
            size="small"
            rowKey={(record) => record.id}
          />
        </ConfigProvider>

        <div
          style={{
            position: 'fixed',
            bottom: '10px',
            left: '20px',
            right: '20px',
            textAlign: 'center',
            fontSize: '10px',
            color: '#666',
          }}
        >
          {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION} by{' '}
          {process.env.REACT_APP_DEVELOPER}
        </div>
      </Space>
    </div>
  );
});

type SelectedSupplierPurchaseTableProps = {
  supplierId: number;
  dateRange: any;
};

const SelectedSupplierPurchaseTable: React.FC<SelectedSupplierPurchaseTableProps> = ({
  supplierId,
  dateRange,
}) => {
  const navigate = useNavigate();

  // // Helper to normalize dateRange to ISO string format
  // const normalizeDateRange = (range: [any, any]) => {
  //   return range.map((date) => {
  //     // If it's already a dayjs object, convert to ISO string
  //     return dayjs.isDayjs(date) ? date.toISOString() : date;
  //   }) as [string, string];
  // };

  // Normalize the date range before passing it to the query
  const normalizedDateRange = useMemo(() => {
    return dateRange.map((date: any) => {
      return dayjs.isDayjs(date) ? date.toISOString() : date;
    }) as [string, string];
  }, [dateRange]);

  console.log('XX supplierId: ', supplierId);
  console.log('XX dateRange: ', normalizedDateRange); // Should now log ISO strings

  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);

  const { formatWithPrefix } = useFormattedNumber();

  useEffect(() => {
    if (!supplierId) {
      console.warn('No transaction ID found, redirecting to purchase report');
      navigate('/report-purchases-by-supplier');
    }
  }, [supplierId, navigate]);

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const {
    data: { data = [], pagination: meta, supplier_details } = {},
    isError,
    isRefetching,
    isLoading,
  } = useQuery<SelectedSupplierPurchaseApiResponse>({
    queryKey: ['selected-supplier-purchase', supplierId, normalizedDateRange],
    queryFn: async () => {
      if (!supplierId || !normalizedDateRange[0] || !normalizedDateRange[1]) {
        throw new Error('Supplier ID and date range are required');
      }

      // Prepare the params object for the query string
      const params: { [key: string]: string } = {
        start_date: normalizedDateRange[0],
        end_date: normalizedDateRange[1],
      };

      // Make the API call with the parameters
      try {
        const response = await axiosClient.get<SelectedSupplierPurchaseApiResponse>(
          `/supplier-purchase-report/${supplierId}`,
          { params },
        );

        return response.data;
      } catch (error) {
        console.error('Error fetching supplier purchase report:', error);
        throw error;
      }
    },

    enabled: !!supplierId && !!normalizedDateRange[0] && !!normalizedDateRange[1], // Enable query only when both supplierId and dateRange are valid
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: true, // Refetch when the window is refocused
    staleTime: 0, // Force data to be considered stale immediately
  });

  // Destructure data with defaults to avoid errors

  const { setTransactionId } = useTransaction();

  const handleViewPurchaseEntryTransaction = (row: any) => {
    setTransactionId(row.id);
    navigate('/purchases-entry-view-transaction');
  };

  const supplierName = supplier_details?.name || '';

  const columns = useMemo<MRT_ColumnDef<SelectedSupplierPurchase>[]>(
    () => [
      {
        accessorKey: 'reference_number',
        header: 'Reference #',
        size: 180,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link onClick={() => handleViewPurchaseEntryTransaction(row.original)}>
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'invoice_number',
        header: 'Invoice #',
        size: 50,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'date_of_purchase',
        header: 'Purchase Date',
        grow: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => {
          return <span>{renderedCellValue}</span>;
        },
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row.total_amount,
        id: 'total_amount',
        header: 'Total Amount',
        size: 100,
        Cell: ({ renderedCellValue, row }) => {
          const value = typeof renderedCellValue === 'number' ? renderedCellValue : 0;
          return <span>{formatWithPrefix(value)}</span>;
        },
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const printableTableRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printableTableRef.current,
    pageStyle: `
    @media print {
      @page {
        size: landscape;
        margin: 0;
      }
      body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      html, body {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
      }
    }
  `,
  });

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true, density: 'compact' },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        {/* <DateSelector onDateChange={setDateRange} />

        <Tooltip arrow title="Extract Report">
          <Button
            onClick={handleRunReport}
            startIcon={
              <>
                <PlayArrow />
              </>
            }
          >
            Run Report
          </Button>
        </Tooltip> */}

        <Tooltip arrow title="Print">
          <IconButton onClick={handlePrint}>
            <PrintSharp />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Download as Document">
          <span>
            <IconButton disabled>
              <Download />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip arrow title="Send to Email">
          <span>
            <IconButton disabled>
              <Email />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const columnsPrint = useMemo(
    () => [
      {
        title: 'Reference #',
        dataIndex: 'reference_number',
        key: 'reference_number',
      },
      {
        title: 'Invoice #',
        dataIndex: 'invoice_number',
        key: 'invoice_number',
      },
      {
        title: 'Purchase Date',
        dataIndex: 'date_of_purchase',
        key: 'date_of_purchase',
      },
      {
        title: 'Total Amount',
        dataIndex: 'total_amount',
        key: 'total_amount',
        render: (text: number) => (
          <>
            <span style={{ textAlign: 'right', display: 'block' }}>{formatWithPrefix(text)}</span>
          </>
        ),
      },
    ],
    [formatWithPrefix],
  );

  const formattedPrintData = useMemo(() => {
    return data.map((item) => ({
      ...item,
      total_amount: item.total_amount,
    }));
  }, [data, formatWithPrefix]);

  const formattedDateRange = useMemo(() => {
    if (Array.isArray(dateRange) && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = dayjs(dateRange[0]).format('MMMM DD, YYYY');
      const endDate = dayjs(dateRange[1]).format('MMMM DD, YYYY');
      return `[${startDate} - ${endDate}]`;
    }
    return '';
  }, [dateRange]);

  return (
    <>
      <MaterialReactTable table={table} />

      <div style={{ display: 'none' }}>
        <PrintableTable
          ref={printableTableRef}
          data={formattedPrintData}
          columnsPrint={columnsPrint}
          dateRange={formattedDateRange}
          supplier_name={supplierName}
        />
      </div>
    </>
  );
};

const queryClient = new QueryClient();

const SelectedSupplierPurchaseView: React.FC<SelectedSupplierPurchaseTableProps> = ({
  supplierId,
  dateRange,
}) => (
  <QueryClientProvider client={queryClient}>
    <SelectedSupplierPurchaseTable supplierId={supplierId} dateRange={dateRange} />
  </QueryClientProvider>
);

export default SelectedSupplierPurchaseView;
