import { Check, RefreshOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { IconEdit, IconTrash } from '@tabler/icons';
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { Image } from 'antd';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table';
import React, { FC, useMemo, useState } from 'react';
import { AnyIfEmpty, useSelector } from 'react-redux';
import axiosClient from 'src/axios_client';
import PageContainer from 'src/components/container/PageContainer';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import { formatDate, formatDateWithTime } from 'src/globals/PublicFunctions';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import { AppState } from 'src/store/Store';
import { IpoRequestForm } from './IpoRequest';
import { LogoDark, LogoLight } from 'src/globals/LogoExports';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import dayjs from 'dayjs';

type Props = {};

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'IPO Transactions',
  },
];

const queryClient = new QueryClient();

function IpoTransactions({}: Props) {
  const customizer = useSelector((state: AppState) => state.customizer);
  const theme = useTheme();
  const borderColor = theme.palette.grey[100];
  const isSmallScreen = useMediaQuery('(max-width:375px)');

  return (
    <PageContainer title="RKCWMS - IPO Master Page" description="This is the IPO Page">
      {/* breadcrumb */}
      <Breadcrumb title="IPO Master Page" items={BCrumb} />
      {/* end breadcrumb */}
      <Card
        sx={{
          padding: 0,
          border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
          maxWidth: isSmallScreen ? '345px' : 'auto',
        }}
        style={{ height: '100%', position: 'relative' }}
      >
        <QueryClientProvider client={queryClient}>
          <IPOTableList />
        </QueryClientProvider>
      </Card>
    </PageContainer>
  );
}

type IPO = {
  id: number;
  user_id: string;
  ipo_number: string;
  destination: string;
  department: any;
  project_site: any;
  purpose: any;
  requested_by: any;
  approved_by: any;
  image_name: any;
  image_url: any;
  status: any;
  created_at: any;
  ipo_requests: any[];
  issuance_requests: any;
};

type IPOApiResponse = {
  data: Array<IPO>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

interface DataType {
  key: string;
  id: number;
  name: string;
  description: string;
  unit: string;
  quantity: number;
  unit_price: number;
  price: any;
  amount: number;
  serial_numbers: any;
  takenQuantity: any;
  notice: any;
}

interface Customer {
  id: string;
  code: string;
  name: string;
  address: string;
  contact_person: string;
}

// status == 0 ? 'Pending' : status == 1 ? 'Approved' : status == 2 ? 'Rejected' : status == 3 ? 'Incomplete' : 'Completed'
const statusSelect = [
  { label: 'Pending', value: '0' },
  { label: 'Approved', value: '1' },
  { label: 'Rejected', value: '2' },
  { label: 'Incomplete', value: '3' },
  { label: 'Completed', value: '4' },
];
const IPOTableList = () => {
  const { userData } = useEssentialDataContext();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([
    {
      id: 'status',
      value: 0,
    },
  ]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<IPOApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axiosClient.get<IPOApiResponse>('/ipo_transaction', { params });
        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<IPO>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Transaction Number',
        size: 180,
        Cell: ({ row }) => {
          return (
            <Typography sx={{ fontWeight: 'bold' }}>
              IPO-{row.original.id.toString().padStart(5, '0')}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'image_url',
        header: 'Receipt',
        size: 40,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <Image
              width={50}
              alt=""
              src={row.original.image_url}
              preview={{
                width: 'auto',
                src: row.original.image_url,
              }}
            />
          );
        },
      },
      {
        accessorKey: 'destination',
        header: 'Destination Name',
        size: 180,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'department',
        header: 'Department Name',
        size: 180,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'project_site',
        header: 'Project Site Name',
        size: 180,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'requested_by',
        header: 'Requested By',
        size: 180,
      },

      {
        accessorKey: 'status',
        header: 'Status',
        filterVariant: 'select',
        filterSelectOptions: statusSelect,
        size: 20,
        Cell: ({ row }) => {
          const status = row.original.status;
          const statusText =
            status == 0
              ? 'Pending'
              : status == 1
              ? 'Approved'
              : status == 2
              ? 'Rejected'
              : status == 3
              ? 'Incomplete'
              : 'Completed';

          return (
            <Chip
              label={statusText}
              color={
                status == 0
                  ? 'info'
                  : status == 1
                  ? 'success'
                  : status == 2
                  ? 'error'
                  : status == 3
                  ? 'warning'
                  : 'success'
              }
              size="small"
              sx={{
                ml: 1,
                fontSize: '0.8rem',
                height: '22px',
                minWidth: '50px',
              }}
            />
          );
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Date Created',
        size: 40,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => {
          return <>{formatDate(row.original.created_at)}</>;
        },
      },

      {
        accessorKey: 'action',
        header: 'Action',
        size: 20,
        Cell: ({ row }) => {
          return (
            <>
              <Grid container spacing={2} display={row.original.status == 0 ? 'flex' : 'none'}>
                <Grid item xs={6}>
                  <Button
                    onClick={() => approvedClick(row.original.id)}
                    size="small"
                    variant="contained"
                    color="success"
                  >
                    <Check />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => declineClick(row.original.id)}
                    size="small"
                    variant="contained"
                    color="error"
                  >
                    <ClearIcon />
                  </Button>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    ],
    [],
  );

  const approvedClick = (passedResult: any) => {
    console.log(passedResult);
    axiosClient
      .put(`/ipo_transaction/${passedResult}`, {
        approved: true,
      })
      .then((response) => {
        refetch();
      })
      .catch((error) => {
        console.error('Failed to update IPO Transaction:', error);
      });
  };
  const declineClick = (passedResult: any) => {
    console.log(passedResult);

    axiosClient
      .put(`/ipo_transaction/${passedResult}`, {
        approved: false,
      })
      .then((response) => {
        refetch();
      })
      .catch((error) => {
        console.error('Failed to update IPO Transaction:', error);
      });
  };

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      columnPinning: {
        left: ['mrt-row-expand'],
      },
      columnVisibility: {
        action:
          userData?.userlevel == 'approver' || userData?.userlevel == 'superadmin' ? true : false,
      },
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.info(row);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Add IPO
        </Button>
      </Box>
    ),
    renderDetailPanel: ({ row }) => {
      const transactionRequests = row.original.issuance_requests;

      const issuanceRequests: any = [];

      transactionRequests.forEach((transactionReq: any) => {
        const setThisData: any = [];
        const customer = transactionReq.customer;

        const uniqueProductIdsInSerial = Object.values(
          transactionReq.transactions.serial_numbers.reduce((acc: any, product: any) => {
            acc[product.product_id] = product;
            return acc;
          }, {}),
        );
        uniqueProductIdsInSerial.forEach((transaction: any) => {
          // const stockLoc = transactionReq.transactions.stsock_location.filter(
          //   (opt: any) => opt.product_id == transaction.product_id,
          // );

          const serialNumbers = transactionReq.transactions.serial_numbers.filter(
            (opt: any) => opt.product_id === transaction.product_id,
          );

          let locationStocks = transactionReq.transactions.initial_location.filter(
            (filter: any) => filter.product_id == transaction.product_id,
          );

          if (transaction.status == 'posted') {
            locationStocks = transactionReq.transactions.initial_location
              .filter((filter: any) => filter.product_id === transaction.product_id)
              .map((item: any) => item)
              .flat();
          }

          const stockLoc = locationStocks ? locationStocks : [];

          let remainingQuantity = serialNumbers.length;
          let serialIndex = 0;
          const result = [];

          for (const item of stockLoc) {
            if (remainingQuantity <= 0) break;
            const serialsTaken = [];
            let takenQuantity;

            if (item.quantity >= remainingQuantity) {
              takenQuantity = remainingQuantity;
              serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
              result.push({
                ...item,
                takenQuantity,
                serial_numbers: serialsTaken,
              });
              setThisData.push({
                ...item,
                key: serialNumbers[serialIndex].id,
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity,
                serial_numbers: serialsTaken,
              });
              remainingQuantity = 0;
            } else {
              takenQuantity = item.quantity;
              serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
              result.push({ ...item, takenQuantity, serial_numbers: serialsTaken });
              setThisData.push({
                ...item,
                key: serialNumbers[serialIndex].id,
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity,
                serial_numbers: serialsTaken,
              });
              remainingQuantity -= takenQuantity;
            }
            serialIndex += takenQuantity;
          }
        });
        transactionReq.transactions.stocks.map((transaction: any, index: any) => {
          let locationStocks = transactionReq.transactions.initial_location.filter(
            (filter: any) => filter.product_id == transaction.product_id && filter.quantity != 0,
          );

          if (transaction.status == 'posted') {
            locationStocks = transactionReq.transactions.stock_location.filter(
              (filter: any) => filter.product_id == transaction.product_id && filter.quantity != 0,
            );
          }

          const stockLoc = locationStocks ? locationStocks : [];
          let remainingQuantity = transaction.quantity;
          const result = [];

          for (const item of stockLoc) {
            if (remainingQuantity <= 0) break;

            if (item.quantity >= remainingQuantity) {
              result.push({
                ...item,
                serial_numbers: [],
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: remainingQuantity,
              });
              setThisData.push({
                ...item,
                serial_numbers: [],
                key: transaction.id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: remainingQuantity,
              });
              remainingQuantity = 0;
            } else {
              result.push({
                ...item,
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: item.quantity,
              });
              setThisData.push({
                ...item,
                serial_numbers: [],
                key: transaction.id,
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: item.quantity,
              });
              remainingQuantity -= item.quantity;
            }
          }

          if (remainingQuantity > 0) {
            setThisData.push({
              ...transaction,
              serial_numbers: [],
              key: transaction.id,
              product_name: transaction.product_name,
              product_model: transaction.product_model,
              price: transaction.price,
              notice: transaction.notice,
              product_unit: transaction.product_unit,
              takenQuantity: remainingQuantity,
            });
            console.log(remainingQuantity);
            console.log('Not enough stock available');
          }
        });
        const invoiceData = {
          id: transactionReq?.id || '',
          invoice_number: transactionReq?.invoice_number || '',
          reference_number: transactionReq?.reference_number || '',
          date_of_issuance: transactionReq?.date_of_issuance || '',
          created_by_userid: transactionReq?.created_by.id || '',
          created_by_name: transactionReq?.created_by.name || '',
          slip_number: transactionReq.slip_number || '',
          customer_code: customer?.code || '',
          customer_name: customer?.name || '',
          customer_address: customer?.address || '',
          status: transactionReq.status || '',
          stock_location_record: transactionReq.stock_location_record || '',
          customer_contact_person: customer?.contact_person || '',
          transactions: setThisData.map((item: any) => ({
            ...item,
            notice: item.notice,
            keys: Array.from(new Set([item.key, ...item.serial_numbers.map((sn: any) => sn.id)])),
          })),
          subtotal: setThisData.reduce((sum: any, product: any) => {
            return (sum += product.takenQuantity * product.price);
          }, 0),
          newSubTotal:
            setThisData.reduce((sum: any, product: any) => {
              return (sum += product.takenQuantity * product.price);
            }, 0) - transactionReq.total_lost_amount,
          total_lost_amount: transactionReq.total_lost_amount,
          created_by: transactionReq.created_by,
          created_at: transactionReq.created_at,
          checked_at: transactionReq.checked_at,
          checked_by: transactionReq.checked_by,
          checked_note: transactionReq.checked_note,
          released_by: transactionReq.released_by,
          released_at: transactionReq.released_at,
          released_note: transactionReq.released_note,
          received_at: transactionReq.received_at,
        };

        issuanceRequests.push(invoiceData);
      });

      return (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card elevation={6}>
              <>
                <CardHeader
                  title={
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h3">RAEL KITZ CORPORATION</Typography>
                      <Typography variant="subtitle2">
                        Centrum Fuel Statio, Brgy. Talibaew, Calasiao, Pangasinan
                      </Typography>
                      <Typography variant="subtitle2">{`Telefax: (075) 653-0008`}</Typography>
                    </Box>
                  }
                />
                <Divider />
              </>
              <CardContent>
                <Grid container spacing={0.5}>
                  <Grid item xs={12}>
                    <Grid container sx={{ alignItems: 'center' }}>
                      <Grid item xs={8}>
                        <Typography variant="h6">INTERNAL PURCHASE ORDER FORM</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                          IPT-{row.original.id.toString().padStart(5, '0')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>TO:</Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>{row.original.destination}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    <Chip
                      label={formatDate(row.original.created_at)}
                      color={'info'}
                      variant="outlined"
                      size="small"
                      sx={{
                        ml: 1,
                        fontSize: '0.8rem',
                        height: '22px',
                        minWidth: '50px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>Department:</Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>{row.original.department}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>Project Site:</Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>{row.original.project_site}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>Purpose:</Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>{row.original.purpose}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mt={3}>
                    <TableContainer component={Paper} elevation={12}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>QTY</TableCell>
                            <TableCell align="right">Item Name</TableCell>
                            <TableCell align="right">Unit Price</TableCell>
                            <TableCell align="right">Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.original.ipo_requests.map((row) => (
                            <TableRow
                              key={row.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.quantity}
                              </TableCell>
                              <TableCell align="right">{row.product_name}</TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12} mt={3}>
                    <Grid container spacing={{ sm: 1, md: 5 }}>
                      <Grid item xs={6} sx={{ textAlign: 'center' }}>
                        <Typography>{row.original.requested_by}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                        <Typography variant="h6">Requested By</Typography>
                      </Grid>

                      <Grid item xs={6} sx={{ textAlign: 'center' }}>
                        <Typography>{row.original.approved_by}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                        <Typography variant="h6">Approved By</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Typography variant='body2' sx={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
                      Note: The empty item fields or the quantity 0 won't be saved in request
                    </Typography>
                  </Grid> */}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={8}>
            {issuanceRequests.map((issuanceData: any, index: any) => {
              return (
                <Card elevation={6} key={index}>
                  <IpoIssuances data={issuanceData} />
                </Card>
              );
            })}
          </Grid>
        </Grid>
      );
    },
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IpoRequestForm refetch={refetch} />
      </Dialog>
      <MaterialReactTable table={table} />
    </>
  );
};

interface IssuanceProps {
  data: any;
}
const IpoIssuances: FC<IssuanceProps> = ({ data }) => {
  const { formatWithPrefix } = useFormattedNumber();

  const theme = useTheme();

  const validUserLevels = ['stockman', 'encoder', 'superadmin', 'customer'];

  const isValidUserLevel = (currentUserLevel: any) => validUserLevels.includes(currentUserLevel);

  const customizer = useSelector((state: AppState) => state.customizer);

  const { userData } = useEssentialDataContext();

  const lostQuantityArrays = data.transactions.filter((data: any) => data.lostQuantity > 0);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {customizer.activeMode === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}

            {/* <RECEIPT
              style={{
                fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
              }}
              className="printable-logo"
            /> */}
          </Box>
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>

          {/* <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {copy}
          </Typography> */}
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            INVOICE #{data.slip_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data.reference_number} <br />
            Issuance Date: {data.date_of_issuance}
            {/* Transaction ID: {data.id} */}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {data.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Customer Code: {data.customer_code}
            <br />
            Address: {data.customer_address}
            <br />
            Contact Person: {data.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              PRODUCT NAME
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              MODEL
            </TableCell>
            {isValidUserLevel(userData?.userlevel) && (
              <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                LOCATION
              </TableCell>
            )}
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              SERIAL NO.
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              QTY
            </TableCell>
            {isValidUserLevel(userData?.userlevel) && (
              <>
                <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                  COST
                </TableCell>
                <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                  AMOUNT
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.transactions.map((transaction: any, index: any) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_name}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_model}
              </TableCell>
              {isValidUserLevel(userData?.userlevel) && (
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.location?.name ? (
                    <>
                      {transaction.location.name}
                      {transaction.divider} Floor {transaction.floor}
                    </>
                  ) : (
                    'No stock location record'
                  )}
                </TableCell>
              )}
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.serial_numbers.length > 0 &&
                  transaction.serial_numbers.map((serial: any) => serial.serial_number).join(', ')}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.takenQuantity} {transaction.product_unit}
              </TableCell>
              {isValidUserLevel(userData?.userlevel) && (
                <>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    {formatWithPrefix(transaction.price)}
                  </TableCell>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    {formatWithPrefix(transaction.takenQuantity * transaction.price)}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
          {lostQuantityArrays.map((transaction: any, index: any) => (
            <TableRow
              key={index}
              sx={{ '.MuiTableCell-root': { color: 'red', fontWeight: 'bold' } }}
            >
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_name}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_model}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                LOST IN TRANSIT
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.serial_numbers.length > 0 &&
                  transaction.serial_numbers.map((serial: any) => serial.serial_number).join(', ')}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.lostQuantity} {transaction.product_unit} LOST
              </TableCell>
              {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
               {transaction.product_unit}
             </TableCell> */}
              {isValidUserLevel(userData?.userlevel) && (
                <>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    -{formatWithPrefix(transaction.price)}
                  </TableCell>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    -{formatWithPrefix(transaction.lostQuantity * transaction.price)}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
          <TableRow>
            <TableCell sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Total: {data.transactions.length}
              </Typography>
            </TableCell>
            <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}></TableCell>
            {isValidUserLevel(userData?.userlevel) && <TableCell sx={{ py: 1 }}></TableCell>}
            <TableCell sx={{ py: 1 }}></TableCell>
            <TableCell sx={{ py: 1 }}></TableCell>
            {isValidUserLevel(userData?.userlevel) && (
              <>
                <TableCell sx={{ py: 1 }}></TableCell>
                <TableCell sx={{ py: 1, fontWeight: 'bold' }} className="fontSize8pt">
                  {formatWithPrefix(data.newSubTotal)}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>

      <Divider sx={{ borderColor: '#acacac' }} />
      <Grid
        container
        columnSpacing={5}
        sx={{ fontSize: { md: '8pt', lg: '10pt' }, display: 'none', displayPrint: 'flex' }}
      >
        {/* <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid> */}
        <Grid item xs={5} className="fontSize8pt">
          Prepared By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columnSpacing={1}>
            <Grid item className="fontSize8pt">
              Checked By:
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={2}>
                <Grid item className="fontSize8pt">
                  Checker
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Hauler Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Customer Authorized Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container columnSpacing={1} justifyContent={'between'}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Received the above goods in full and good condition.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Received By:
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <Divider sx={{ borderColor: 'black' }} />
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
              {formatDateWithTime(data.created_at)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              User: {data.created_by.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IpoTransactions;
