import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Grid,
    InputAdornment,
    IconButton,
    FormControl,
    Input,
    Box,
    Typography,
    Divider,
    FormControlLabel,
    Checkbox,
    FormLabel,
    Tooltip,
    Stack,
    Button,
    Autocomplete,
    Alert,
    TextField,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListSubheader,
    FormHelperText,
    Card,
    Backdrop,
    CircularProgress,
    Chip,
    RadioGroup,
    Radio,
} from '@mui/material';
import { IconBeach, IconBriefcase, IconEdit, IconFile3d, IconMessage, IconPhoto, IconTrash, IconUpload } from '@tabler/icons';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import axiosClient from 'src/axios_client';
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Close, RefreshOutlined } from '@mui/icons-material';
import { isError } from 'lodash';
import { QueryClient, QueryClientProvider, keepPreviousData, useQuery } from '@tanstack/react-query';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { any } from 'prop-types';
import CustomCheckbox from 'src/components/theme-elements/CustomCheckbox';
import { NumericFormatCustom, adjustAndFormat, formatCurrency } from 'src/globals/NumericFormat';
import BlankCard from 'src/components/shared/BlankCard';
import * as Yup from 'yup';

type Adjustment = {
    id: number;
    product_id: number;
    product_name: string;
    sku: string;
    is_price_adjustment: number;
    is_quantity_adjustment: number;
    previous_selling: number,
    previous_buying: number;
    status: any;
    reason: string;
};

type AdjustmentApiResponse = {
    data: Array<Adjustment>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};

type AdjustmentOptions = {
    id: string,
    name: any,
    sku: any,
    has_serial: any,
    unit: any,
    model: any,
    brand: any,
    category: any,
    description: any,
    image: any,
    buying_price: any,
    selling_price: any,
    qty_before: any,
    quantity: any
};
const ProductLocationDivider = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
];
const ProductLocationFloor = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
];
const ProductReturnStatusOptions = [
    { value: 'new', label: 'Brand New' },
    { value: 'broken', label: 'Broken' },
    { value: 'lost', label: 'Lost' },
];

type SerialData = {
    value: any;
    inSystem: any
    id: any;
};


const MainComponent = () => {
    const [productHasSerial, setProductHasSerial] = useState(false);
    const [inSystemValue, setInSystemValue] = React.useState('0');
    const [maxSerials, setMaxSerials] = useState(0);
    const [serial_data, setserial_data] = useState<SerialData[]>([]);
    const [serialOptions, setSerialOptions] = useState<SerialData[]>([]);

    const validationSchema = Yup.object().shape({
        product_id: Yup.string().required('Please provide product'),
        reason: Yup.string().required('Please provide a valid reason'),
        reference_number: Yup.string().required('Please provide a reference number'),
        transaction_number: Yup.string().required('Please provide a transaction number'),
        // location_id: Yup.string().required('Please provide a location'),
        // divider: Yup.string().required('Please provide a divider'),
        // floor: Yup.string().required('Please provide a floor'),
        return_serials: Yup.string().test(
            'unique-check',
            'Serial number is already added',
            function (value) {
                const { serial_data, return_quantity } = this.parent;
                if (productHasSerial && return_quantity) {
                    if (inSystemValue == '1') {
                        if (maxSerials > 0 && serial_data.length == maxSerials) {
                            // setError('Serial Numbers max limit reached');
                            return true;
                        }
                        else if (serial_data.length < maxSerials && !serial_data.some((item: any) => item.value === value)) {
                            return this.createError({ message: 'Please add more serial numbers' });
                        }
                        else if (serial_data.length > maxSerials) {
                            return this.createError({ message: 'Please make sure Quantity is equal to Serial Numbers' });
                        }
                        else {
                            console.log("Hello din");
                            return !serial_data.some((item: any) => item.value === value) && serial_data.length < maxSerials;
                        }
                    }
                    else {
                        if (serial_data.length < maxSerials && !serial_data.some((item: any) => item.value === value)) {
                            return this.createError({ message: 'Please add more serial numbers' });
                        }
                        else if (serial_data.length > maxSerials) {
                            return this.createError({ message: 'Please remove some serial numbers' });
                        }
                        else {
                            return true;
                        }
                    }

                }
                else {
                    return true;
                }

            }
        ),
        // return_quantity: Yup.number()
        //     .required('Please provide a quantity')
        //     .test(
        //         'quantity-check', // A unique name for the test
        //         function (value) {
        //             if (value == 0) {
        //                 return this.createError({
        //                     message: 'Quantity must be greater than zero',
        //                 });
        //             }

        //             console.log(value);
        //             setMaxSerials(value ?? 0);
        //             return true;
        //         }
        //     ),

        location_id: Yup.string().when('is_quantity_adjustment', {
            is: 1,
            then: Yup.string().required('Please provide a location'),
        }),
        divider: Yup.string().when('is_quantity_adjustment', {
            is: 1,
            then: Yup.string().required('Please provide a divider'),
        }),
        floor: Yup.string().when('is_quantity_adjustment', {
            is: 1,
            then: Yup.string().required('Please provide a floor'),
        }),
        product_status: Yup.string().nullable().when('is_quantity_adjustment', {
            is: 1,
            then: Yup.string().nullable().required('Please provide the status of the product'),
        }),
        // product_status: Yup.string().nullable().required('Please provide the status of the product'),
        serial_data: Yup.array().of(Yup.object()),
        is_price_adjustment: Yup.number().test(
            'oneOfRequired',
            'Either Price Adjustment or Quantity Adjustment must be checked',
            function (value) {
                const { is_quantity_adjustment } = this.parent;
                return value === 1 || is_quantity_adjustment === 1;
            }
        ),
        is_quantity_adjustment: Yup.number().test(
            'oneOfRequired',
            'Either Price Adjustment or Quantity Adjustment must be checked',
            function (value) {
                const { is_price_adjustment } = this.parent;
                return value === 1 || is_price_adjustment === 1;
            }
        ),
        return_quantity: Yup.number().when('is_quantity_adjustment', {
            is: 1,
            then: Yup.number()
                .required('Please provide a quantity')
                .test(
                    'quantity-check', // A unique name for the test
                    function (value) {
                        if (value == 0) {
                            return this.createError({
                                message: 'Quantity must be greater than zero',
                            });
                        }

                        console.log(value);
                        setMaxSerials(value ?? 0);
                        return true;
                    }
                ),
        }),
        requested_selling: Yup.number().when('is_price_adjustment', {
            is: 1,
            then: Yup.number().required('Selling Price is required when price adjustment is checked'),
        }),
        requested_buying: Yup.number().when('is_price_adjustment', {
            is: 1,
            then: Yup.number().required('Buying Price is required when price adjustment is checked'),
        }),
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        formik.setFieldValue('serial_data', []);
        setError('');
        setserial_data([]);
        setInSystemValue((event.target as HTMLInputElement).value);
    };
    const handleDelete = (chipToDelete: any) => () => {

        console.log(chipToDelete);
        const updatedChips = serial_data.filter((chip) => chip !== chipToDelete);
        setError('');
        setserial_data(updatedChips);
        formik.setFieldValue('serial_data', updatedChips);
    };

    const handleSerialInputChange = (e: any) => {
        setError('');
        formik.setFieldValue('return_serials', e.target.value);
        formik.setFieldError('return_serials', ''); // Clear error when input changes
    };

    const handleSerialKeyDown = (e: any) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setError('');
            const value = formik.values.return_serials.trim();
            if (value) {
                if (serial_data.some(item => item.value === value)) {
                    console.log("Item added");
                    formik.setFieldError('return_serials', 'Item is already added.');
                    formik.setFieldTouched('return_serials', true);
                } else if (serial_data.length >= maxSerials) {
                    formik.setFieldError('return_serials', `You can only add up to ${maxSerials} items.`);
                    formik.setFieldTouched('return_serials', true);

                } else {
                    const updatedChips = [...serial_data, { value: value, id: null, inSystem: inSystemValue == '0' ? true : false }];
                    setserial_data(updatedChips);

                    formik.setFieldValue('serial_data', updatedChips);
                    formik.setFieldValue('return_serials', '');
                }

            }
        }
    };

    const [selectedFiles, setSelectedFiles] = useState<{ file: File; preview: string }[]>([]);
    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files || []);
        if (files.length + selectedFiles.length > 10) {
            alert('You can only upload up to 10 files.');
            return;
        }
        const newFiles = files.map(file => ({
            file,
            preview: URL.createObjectURL(file),
        }));
        setSelectedFiles(prev => [...prev, ...newFiles]);
    };
    const handleDeleteFile = (index: number) => {
        setSelectedFiles(prev => {
            const newFiles = [...prev];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };
    const [isSearching, setIsSearching] = useState(false);
    const [isEmptySearch, setIsEmptySearch] = useState(false);
    const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [productOptions, setProductOptions] = useState<AdjustmentOptions[]>([]);
    const [adjustmentList, setAdjustmentList] = useState<any>([]);
    const adjustmentListRef = useRef(adjustmentList);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [sucess, setSucess] = useState('');
    const [error, setError] = useState('');
    const [isQuantityAdjustment, setIsQuantityAdjustment] = useState(true);
    const [isPriceAdjustment, setIsPriceAdjustment] = useState(true);

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            product_id: '',
            product_name: '',
            product_description: '',
            return_serials: '',
            serial_data: [],
            return_quantity: '',
            product_image: '',
            current_buying: '',
            current_selling: '',
            current_quantity: '',
            requested_selling: 0,
            requested_buying: 0,
            product_status: null,
            reference_number: '',
            transaction_number: '',
            location_id: '',
            divider: '',
            floor: '',
            sku: '',
            unit: '',
            has_serial: 0,
            is_price_adjustment: 1,
            is_quantity_adjustment: 1,
            reason: '',
        },
        validateOnChange: false,
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            if (formik.isValid) {
                const formattedValues = {
                    ...values,
                };

                console.log(adjustmentList);

                axiosClient.post(`/adjustments`, formattedValues, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then((response) => {

                        if (response.status === 201) {
                            console.log(response.data.data.message);
                            setSucess(response.data.message);
                            setError('');
                            setSelectedFiles([]);

                            setTimeout(() => {
                                setSucess('');
                            }, 5000);
                            formik.resetForm();
                        }
                        console.log(response);
                    })
                    .catch((error) => {
                        setError(error.response.data.message);
                        console.log(error);
                    });
            }
            else {
                console.log("Form invalid");
            }
        },
    });
    const autocompleteSearchChange = (searchText: any, apiText: any) => {
        setIsSearching(true);
        setIsEmptySearch(false);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            const value = searchText.target.value;
            fetchOptions(value, apiText);
        }, 500);
    };

    const fetchOptions = async (searchText: string, apiText: string) => {
        try {
            const filterForAdjustment = [
                { "id": "name", "value": searchText },
                { "id": "is_adjusting", "value": 1 }
            ];

            const filter = [
                { "id": "name", "value": searchText },
            ];
            const productsFilter = [
                { "id": "name", "value": searchText },
                { "id": "has_serial", "value": 1 },
                { "id": "is_adjusting", "value": 1 }
            ];
            const { status, data } = await axiosClient.get(`/${apiText == 'serial' ? 'check_serial_number' : apiText}`, {
                params:
                    apiText == 'serial' ? { globalFilter: searchText, serial_status: 'available', product_id: formik.values.product_id, per_page: 10 }
                        : apiText == 'products' ? { filters: JSON.stringify(productsFilter), per_page: 10 }
                            : { filters: JSON.stringify(apiText == 'locations' ? filter : filterForAdjustment), per_page: 10 },
            });

            if (status == 200) {
                setIsSearching(false);
                if (data.data.length == 0) {
                    setIsEmptySearch(true);
                }

                if (apiText == 'locations') {
                    const fetchedData = data.data.map((innerData: any) => ({
                        id: innerData.id,
                        value: innerData.name,
                    }));

                    setLocationOptions(fetchedData);

                }
                else if (apiText == 'serial') {
                    const fetchedData = data.data.map((innerData: any) => ({
                        id: innerData.id,
                        value: innerData.serial_number,
                        inSystem: true,
                    }));
                    setSerialOptions(fetchedData);
                }
                else {
                    const fetchedData = data.data.map((innerData: any) => ({
                        id: innerData.id,
                        name: innerData.name,
                        description: innerData.description,
                        has_serial: innerData.has_serial,
                        image: innerData.image,
                        unit: innerData.unit,
                        model: innerData.model,
                        brand: innerData.brand_name,
                        category: innerData.category_name,
                        sku: innerData.sku,
                        qty_before: innerData.qty_before,
                        buying_price: innerData.buying_price,
                        selling_price: innerData.selling_price,
                        quantity: innerData.quantity
                    }));
                    setProductOptions(fetchedData);
                }


            }

        } catch (error) {
            console.error('Failed to fetch product options:', error);
        }
    };


    const handleQuantityCheckbox = (event: any) => {
        setIsQuantityAdjustment(event.target.checked);
        if (event.target.checked) {
            formik.setFieldValue('is_quantity_adjustment', 1);
        }
        else {
            // formik.setFieldValue('return_quantity', 0);
            setserial_data([]);
            setSerialOptions([]);
            formik.resetForm({
                values: {
                    ...formik.values,
                    location_id: '',
                    divider: '',
                    floor: '',
                    return_quantity: '',
                    serial_data: [],
                    is_quantity_adjustment: 0,
                }
            });
            // formik.setFieldValue('is_quantity_adjustment', 0);
        }
    }

    const handlePriceCheckbox = (event: any) => {
        setIsPriceAdjustment(event.target.checked);
        if (event.target.checked) {
            formik.setFieldValue('is_price_adjustment', 1);
        }
        else {
            formik.setFieldValue('requested_buying', 0);
            formik.setFieldValue('requested_selling', 0);
            formik.setFieldValue('is_price_adjustment', 0);
        }
    }

    const addAdjustmentClick = async () => {
        const errors = await formik.validateForm();
        console.log(errors);
        formik.setTouched({
            ['reason']: true,
            ['product_status']: true,
            ['reference_number']: true,
            ['transaction_number']: true,
            ['product_id']: true,
            ['requested_buying']: true,
            ['requested_selling']: true,
            ['location_id']: true,
            ['divider']: true,
            ['floor']: true,
            ['return_serials']: true,
            ['return_quantity']: true
        });

        if (Object.keys(errors).length === 0) {
            console.log(adjustmentList);
            const detectUnique = adjustmentList.some((item: any) => item.product_id == formik.values.product_id && item.product_status == formik.values.product_status);

            if (detectUnique) {
                setError("Duplicate Product is selected. Please select different product");
            }

            else {
                setError('');

                setAdjustmentList([...adjustmentList, {
                    ...formik.values,
                    adjustmentCount: adjustmentList.length + 1
                }]);

                setSerialOptions([]);
                setserial_data([]);
                setMaxSerials(0);

                formik.resetForm({
                    values: {
                        ...formik.values,
                        product_id: '',
                        product_status: null,
                        current_buying: '',
                        current_selling: '',
                        requested_selling: 0,
                        requested_buying: 0,
                        divider: '',
                        floor: '',
                        location_id: '',
                        return_quantity: '',
                        sku: '',
                    },
                });
            }
        }
        console.log(errors);
    };

    useEffect(() => {

        adjustmentListRef.current = adjustmentList;

    }, [adjustmentList])

    const postTransaction = () => {

        if (formik.values.reference_number && formik.values.transaction_number && formik.values.reason && adjustmentList.length > 0) {
            setSubmitting(true);
            const formData = new FormData();
            formData.append('reason', formik.values.reason);
            formData.append('reference_number', formik.values.reference_number);
            formData.append('transaction_number', formik.values.transaction_number);

            // Append adjustments data
            adjustmentList.forEach((adjustment: any, index: any) => {
                Object.keys(adjustment).forEach(key => {
                    switch (key) {
                        case "serial_data":
                            adjustment[key].forEach((serial: any, serial_index: any) => {
                                Object.keys(serial).forEach(serial_key => {
                                    console.log(key);
                                    formData.append(`arrayAdjustments[${index}][${key}][${serial_index}][${serial_key}]`, serial[serial_key]);
                                })
                            })
                            break;
                        default:
                            formData.append(`arrayAdjustments[${index}][${key}]`, adjustment[key]);
                    }

                });
            });


            axiosClient.post(`/serial_adjustment`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {

                    // console.log(response);
                    // setSubmitting(false);

                    if (response.status === 201 || response.status === 200) {
                        setSubmitting(false);
                        setSucess(response.data.message);
                        setError('');
                        setSelectedFiles([]);
                        setAdjustmentList([]);
                        setTimeout(() => {
                            setSucess('');
                        }, 3000);
                        formik.resetForm();
                    }
                    console.log(response);
                })
                .catch((error) => {
                    setSubmitting(false);
                    setError(error.response.data.message);
                    console.log(error);
                });

        } else {
            setError('Please check fields and adjustment list to proceed');
        }
    }
    const handleRemoveClick = React.useCallback((passedAdj: any) => {
        removeAdjustment(passedAdj.adjustmentCount)
    }, []);

    const removeAdjustment = (id: any) => {

        const updatedArray = adjustmentListRef.current.filter((item: any) => item.adjustmentCount !== id);
        setAdjustmentList(updatedArray);
    }

    const [locationOptions, setLocationOptions] = useState<
        { value: string; id: string; }[]
    >([]);
    const [locationString, setLocationString] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);

    return (
        <Box>
            <Backdrop
                sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={isSubmitting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <form onSubmit={formik.handleSubmit}>
                <Grid container padding={3} columnSpacing={2}>
                    <Grid item sm={12} lg={4}>
                        {sucess &&
                            <Alert
                                variant="filled"
                                severity="success"
                                sx={{ alignItems: "center" }}
                            >
                                {sucess}
                            </Alert>
                        }

                        {error &&
                            <Alert
                                variant="filled"
                                severity="error"
                                sx={{ alignItems: "center" }}
                            >
                                {error}
                            </Alert>
                        }
                        <FormLabel component="legend">
                            <Typography variant={'h4'}>Adjustment Details</Typography>
                        </FormLabel>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <CustomCheckbox
                                            checked={isPriceAdjustment}
                                            color="primary"
                                            onChange={handlePriceCheckbox}
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                        />
                                    }
                                    label="Price Adjustment"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <CustomCheckbox
                                            checked={isQuantityAdjustment}
                                            color="primary"
                                            onChange={handleQuantityCheckbox}
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                        />
                                    }
                                    label="Quantity Adjustment"
                                />
                            </Grid>
                            {
                                !isQuantityAdjustment && !isPriceAdjustment ?
                                    <Grid item xs={12}>
                                        <FormHelperText sx={{ color: 'red' }}>{formik.errors.is_price_adjustment}</FormHelperText>
                                    </Grid> : null
                            }
                            <Grid item xs={6}>
                                <CustomTextField
                                    id="reference_number"
                                    size="small"
                                    variant="standard"
                                    label="Reference Number"
                                    value={formik.values.reference_number}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.reference_number && Boolean(formik.errors.reference_number)}
                                    helperText={formik.touched.reference_number && formik.errors.reference_number}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    id="transaction_number"
                                    size="small"
                                    variant="standard"
                                    label="Transaction Number"
                                    value={formik.values.transaction_number}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.transaction_number && Boolean(formik.errors.transaction_number)}
                                    helperText={formik.touched.transaction_number && formik.errors.transaction_number}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    id="reason"
                                    size="small"
                                    variant="standard"
                                    label="Reason of adjustment"
                                    multiline
                                    maxRows={6}
                                    value={formik.values.reason}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.reason && Boolean(formik.errors.reason)}
                                    helperText={formik.touched.reason && formik.errors.reason}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>


                        <FormLabel component="legend" sx={{ mt: 3 }}>
                            <Typography variant={'h4'}>Product Adjustment Details</Typography>
                        </FormLabel>
                        <Grid container rowSpacing={1.5} columnSpacing={2}>
                            <Grid item xs={9}>
                                <Autocomplete
                                    id="product_id"
                                    noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                                    value={formik.values.product_id || null}
                                    onChange={(_, newValue) => {
                                        formik.setFieldValue('product_id', newValue);

                                        const selectedProduct = productOptions.find(p => p.id === newValue);

                                        if (selectedProduct) {
                                            formik.setFieldValue('sku', selectedProduct.sku);
                                            formik.setFieldValue('unit', selectedProduct.unit);
                                            formik.setFieldValue('has_serial', selectedProduct.has_serial);
                                            formik.setFieldValue('product_description', selectedProduct.description);
                                            formik.setFieldValue('product_image', selectedProduct.image);
                                            formik.setFieldValue('product_name', selectedProduct.name);
                                            formik.setFieldValue('current_buying', selectedProduct.buying_price ? selectedProduct.buying_price : 0);
                                            formik.setFieldValue('current_selling', selectedProduct.selling_price ? selectedProduct.selling_price : 0);
                                            // formik.setFieldValue('current_quantity', selectedProduct.qty_before ? selectedProduct.qty_before : 0);

                                            // formik.setFieldValue('current_quantity', selectedProduct.quantity ? selectedProduct.quantity : 0);

                                            formik.setFieldValue('requested_buying', selectedProduct.buying_price ? selectedProduct.buying_price : 0);
                                            // formik.setFieldValue('requested_selling', selectedProduct.selling_price ? selectedProduct.selling_price : 0);
                                            // formik.setFieldValue('requested_quantity', selectedProduct.quantity ? selectedProduct.quantity : 0);
                                            setProductHasSerial(selectedProduct.has_serial);
                                        }

                                        if (newValue === null) {
                                            formik.resetForm();
                                            setProductOptions([]);
                                        }
                                    }}
                                    onBlur={() => formik.setFieldTouched('product_id', true)}
                                    options={productOptions.map(p => p.id)}
                                    fullWidth
                                    getOptionLabel={(option) => {
                                        const productOpt = productOptions.find((p) => p.id === option);
                                        return productOpt ? `${productOpt.name} [Model: ${productOpt.model}] [SKU: ${productOpt.sku}] [Brand: ${productOpt.brand}]  [Category: ${productOpt.category}]` : '';
                                    }}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            error={formik.touched.product_id && Boolean(formik.errors.product_id)}
                                            helperText={formik.touched.product_id && formik.errors.product_id}
                                            onChange={(e: any) => autocompleteSearchChange(e, 'products')}
                                            variant="standard"
                                            size="small"
                                            label="Product"
                                            placeholder="Product name"
                                            aria-label="Product name"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Autocomplete
                                    id='product_status'
                                    disabled={!isQuantityAdjustment || !formik.values.product_id}
                                    value={ProductReturnStatusOptions.find(option => option.value === formik.values.product_status) || null}
                                    size='small'
                                    fullWidth
                                    options={ProductReturnStatusOptions}
                                    getOptionLabel={(option) => {
                                        return `${option.label}`;
                                    }}
                                    onBlur={formik.handleBlur}
                                    onChange={(_, newValue) => {
                                        if (newValue) {
                                            formik.setFieldValue('product_status', newValue.value);
                                        }

                                        if (newValue === null) {
                                            formik.setFieldValue('product_status', '');
                                        }

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='standard'
                                            error={formik.touched.product_status && Boolean(formik.errors.product_status)}
                                            helperText={formik.touched.product_status && formik.errors.product_status}
                                            label="Product status"
                                            placeholder="Favorites"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    id="location_id"
                                    size='small'
                                    disabled={!isQuantityAdjustment || !formik.values.product_id}
                                    noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                                    options={locationOptions}
                                    value={locationOptions.find(option => option.id === formik.values.location_id) || null}
                                    getOptionLabel={(option) => {
                                        // Display "TET-000001" in the dropdown options
                                        return option.value;
                                    }}

                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(_, newValue) => {

                                        console.log(newValue);
                                        // const selectedProduct = productOptions.find(p => p.id === newValue);
                                        if (newValue) {
                                            setLocationString(newValue.value);
                                            formik.setFieldValue('location_id', newValue.id);
                                        } else {
                                            formik.setFieldValue('location_id', null);
                                        }
                                        if (newValue === null) {
                                            formik.resetForm();
                                            setLocationOptions([]);
                                        }
                                    }}
                                    onBlur={() => formik.setFieldTouched('location_id', true)}
                                    fullWidth
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            error={formik.touched.location_id && Boolean(formik.errors.location_id)}
                                            helperText={formik.touched.location_id && formik.errors.location_id}
                                            onChange={(e: any) => autocompleteSearchChange(e, 'locations')}
                                            variant="standard"
                                            label="Location Stored"
                                            placeholder="Location Stored"
                                            aria-label="Location Stored"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id='divider'
                                    disabled={!isQuantityAdjustment || !formik.values.product_id}
                                    value={ProductLocationDivider.find(option => option.value === formik.values.divider) || null}
                                    size='small'
                                    fullWidth
                                    options={ProductLocationDivider}
                                    getOptionLabel={(option) => {
                                        return `${option.label}`;
                                    }}
                                    onBlur={formik.handleBlur}
                                    onChange={(_, newValue) => {
                                        if (newValue) {
                                            formik.setFieldValue('divider', newValue.value);
                                        }

                                        if (newValue === null) {
                                            formik.setFieldValue('divider', '');
                                        }

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='standard'
                                            error={formik.touched.divider && Boolean(formik.errors.divider)}
                                            helperText={formik.touched.divider && formik.errors.divider}
                                            label="Divider"
                                            placeholder="Divider"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id='floor'
                                    disabled={!isQuantityAdjustment || !formik.values.product_id}
                                    value={ProductLocationFloor.find(option => option.value === formik.values.floor) || null}
                                    size='small'
                                    fullWidth
                                    options={ProductLocationFloor}
                                    getOptionLabel={(option) => {
                                        return `${option.label}`;
                                    }}
                                    onBlur={formik.handleBlur}
                                    onChange={(_, newValue) => {
                                        if (newValue) {
                                            formik.setFieldValue('floor', newValue.value);
                                        }

                                        if (newValue === null) {
                                            formik.setFieldValue('floor', '');
                                        }

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='standard'
                                            error={formik.touched.floor && Boolean(formik.errors.floor)}
                                            helperText={formik.touched.floor && formik.errors.floor}
                                            label="Floor"
                                            placeholder="Floor"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <CustomTextField
                                    id="return_quantity"
                                    size="small"
                                    variant="standard"
                                    type="number"
                                    label="Quantity"
                                    disabled={!formik.values.product_id || !isQuantityAdjustment}
                                    value={formik.values.return_quantity}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{formik.values.unit}</InputAdornment>,
                                    }}
                                    error={formik.touched.return_quantity && Boolean(formik.errors.return_quantity)}
                                    helperText={formik.touched.return_quantity && formik.errors.return_quantity}
                                    fullWidth
                                />
                            </Grid>


                            {inSystemValue == '1' &&
                                <Grid item xs={6}>
                                    <CustomTextField
                                        id='return_serials'
                                        size="small"
                                        disabled={!formik.values.product_id || !productHasSerial || !isQuantityAdjustment}
                                        value={formik.values.return_serials}
                                        onChange={handleSerialInputChange}
                                        onKeyDown={handleSerialKeyDown}
                                        label="Serial Number Input"
                                        placeholder="Type and press enter or space"
                                        variant="standard"
                                        fullWidth
                                        error={formik.touched.return_serials && Boolean(formik.errors.return_serials)}
                                        helperText={formik.touched.return_serials && formik.errors.return_serials}
                                    />
                                </Grid>
                            }



                            {inSystemValue == '0' &&
                                <Grid item xs={6}>
                                    <Autocomplete
                                        multiple
                                        disabled={!formik.values.product_id || !productHasSerial || !isQuantityAdjustment}
                                        id='return_serials'
                                        value={serial_data}
                                        noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                                        size='small'
                                        fullWidth
                                        options={serialOptions}
                                        getOptionLabel={(option) => option.value} // Display label in the dropdown
                                        getOptionDisabled={(options) => {

                                            const IsTrue = serial_data.length >= parseInt(formik.values.return_quantity);
                                            return IsTrue;
                                        }}
                                        onChange={async (_, newValue, reason, details) => {
                                            console.log(newValue);
                                            setError('');
                                            if (details) {
                                                console.log("Hello");
                                                await formik.setFieldValue('serial_data', newValue);

                                                setserial_data(newValue);
                                            }

                                            if (!details) {
                                                setserial_data([]);
                                                await formik.setFieldValue('serial_data', []);
                                                setSerialOptions([]);
                                            }
                                            formik.setFieldTouched('return_serials', true);
                                        }}
                                        renderInput={(params) => {

                                            console.log(params);

                                            return (
                                                <TextField
                                                    {...params}
                                                    variant='standard'
                                                    error={formik.touched.return_serials && Boolean(formik.errors.return_serials)}
                                                    helperText={formik.touched.return_serials && formik.errors.return_serials}
                                                    onChange={(e: any) => {
                                                        autocompleteSearchChange(e, 'serial')

                                                    }}
                                                    label="Serial Numbers"
                                                    placeholder="Favorites"
                                                />
                                            )
                                        }}
                                    />
                                </Grid>
                            }

                            {productHasSerial ?
                                <>
                                    <Grid item xs={12}>

                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={inSystemValue}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="0" control={<Radio />} label="Is in system" />
                                            <FormControlLabel value="1" control={<Radio />} label="Is not in system" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ marginTop: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                            {serial_data.map((chip, index) => (
                                                <Chip
                                                    key={index}
                                                    label={chip.value}
                                                    onDelete={
                                                        inSystemValue == '1' // Replace with your actual condition
                                                            ? handleDelete(chip) // or directly use handleDelete(chip) if it doesn't require event handling
                                                            : undefined
                                                    }
                                                />
                                            ))}
                                        </Box>
                                        <Typography variant="caption" color="textSecondary" sx={{ marginTop: 1 }}>
                                            {`You have added ${serial_data.length}/${maxSerials} items.`}
                                        </Typography>
                                    </Grid>
                                </> : null
                            }
                            <Grid item xs={12}>
                                <Typography variant='h6' sx={{ fontSize: '10pt', color: (theme) => theme.palette.error.main }} >
                                    NOTE: broken/lost status will be deduct the total stocks of selected product
                                </Typography>
                            </Grid>


                            <Grid item xs={12}>
                                <CustomTextField
                                    id="sku"
                                    size="small"
                                    disabled
                                    variant="filled"
                                    label="Sku"
                                    value={formik.values.sku}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.sku && Boolean(formik.errors.sku)}
                                    helperText={formik.touched.sku && formik.errors.sku}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <CustomTextField
                                    id="current_buying"
                                    size="small"
                                    disabled
                                    variant="filled"
                                    label="Buying Price"
                                    value={formik.values.current_buying}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current_buying && Boolean(formik.errors.current_buying)}
                                    helperText={formik.touched.current_buying && formik.errors.current_buying}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom as any,
                                        inputProps: {
                                            name: "current_buying",
                                        },
                                    }}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <CustomTextField
                                    id="requested_buying"
                                    size="small"
                                    variant="standard"
                                    label="Buying Price Adjustment"
                                    disabled={!isPriceAdjustment}
                                    value={formik.values.requested_buying}
                                    onChange={(e: any) => {
                                        const reqBuying = parseFloat(e.target.value);
                                        formik.setFieldValue("requested_selling", reqBuying + (reqBuying * 0.10));
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.requested_buying && Boolean(formik.errors.requested_buying)}
                                    helperText={formik.touched.requested_buying && formik.errors.requested_buying}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom as any,
                                        inputProps: {
                                            name: "requested_buying",
                                        },
                                    }}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container gap={2}>
                                    <Button variant="contained" color="primary" onClick={addAdjustmentClick}>
                                        Add
                                    </Button>
                                    <Button variant="contained" color="primary">
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item sm={12} lg={8} flexGrow={1} mt={{ xs: 2, md: 0 }}>
                        <Card elevation={6}>
                            <Grid item xs={12} sm={12} padding={2}>
                                <Stack direction="row" spacing={2}>
                                    <Button onClick={postTransaction} startIcon={<IconFile3d />} key={0}>
                                        Post Transaction
                                    </Button>
                                    <Button color='error' startIcon={<IconTrash />} key={1}>
                                        Clear Transaction
                                    </Button>
                                </Stack>
                            </Grid>
                            <List
                                subheader={
                                    <ListItem >
                                        <ListItemAvatar >
                                            <Box width={20} height={20} >
                                            </Box>
                                        </ListItemAvatar>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={3}>
                                                <ListItemText primary="Item Details" />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <ListItemText primary="Serial Numbers" />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ListItemText primary="Status" />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ListItemText primary="Selling Price" />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ListItemText primary="Buying Price" />
                                            </Grid>

                                        </Grid>

                                    </ListItem>
                                }
                            >
                                {
                                    adjustmentList.map(
                                        (adj: any) => {
                                            return (
                                                <>
                                                    <AdjustmentListComponent adjustmentDetails={adj} onRemoveClick={handleRemoveClick} />
                                                </>
                                            )
                                        }
                                    )
                                }
                            </List>
                        </Card>
                    </Grid>
                </Grid>
            </form>


        </Box >
    );
};
const queryClient = new QueryClient();

const AdjustmentListComponent: React.FC<{
    adjustmentDetails: any;
    onRemoveClick: any;
}> = React.memo(({ adjustmentDetails, onRemoveClick }) => {

    console.log(adjustmentDetails);
    const handleRemoveClick = () => {
        onRemoveClick(adjustmentDetails);
    };

    const baseQuant = adjustmentDetails.current_quantity ? adjustmentDetails.current_quantity : 0;
    const reqQuant = adjustmentDetails.requested_quantity ? adjustmentDetails.requested_quantity : 0;

    const baseQuantity = typeof baseQuant === 'string' ? parseFloat(baseQuant) : baseQuant;
    const quantityAdjustment = typeof reqQuant === 'string' ? parseFloat(reqQuant) : reqQuant;

    return (
        <>
            <ListItem
                secondaryAction={
                    <IconButton edge="end" aria-label="comments" onClick={handleRemoveClick}>
                        <Close width={20} height={20} />
                    </IconButton>
                }
            >
                <ListItemAvatar>
                    <Avatar>
                        <img src={adjustmentDetails.product_image} height={'auto'} width={'100%'}></img>
                    </Avatar>
                </ListItemAvatar>
                <Grid container columnSpacing={1} sx={{ alignItems: 'center' }}>
                    <Grid item xs={3}>
                        <ListItemText primary={adjustmentDetails.product_name} secondary={adjustmentDetails.product_description} />
                    </Grid>
                    <Grid item xs={3}>
                        <ListItemText
                            primary={
                                <>
                                    <Typography variant="body2">
                                        Serial numbers:
                                        {adjustmentDetails.serial_data.map((serial: any) => {

                                            return (
                                                ` ${serial.value},`
                                            )
                                        })}
                                    </Typography>

                                </>
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <ListItemText
                            primary={
                                <>
                                    <Typography variant="body2">
                                        {adjustmentDetails.product_status}
                                    </Typography>
                                </>
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <ListItemText
                            primary={
                                <>
                                    <Typography variant="body2">
                                        Current: {formatCurrency(adjustmentDetails.current_selling)}
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        Adjustment: {formatCurrency(parseFloat(adjustmentDetails.requested_selling))}
                                    </Typography>
                                </>
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <ListItemText
                            primary={
                                <>
                                    <Typography variant="body2">
                                        Current: {formatCurrency(adjustmentDetails.current_buying)}
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        Adjustment: {formatCurrency(adjustmentDetails.requested_buying)}
                                    </Typography>
                                </>
                            }
                        />
                    </Grid>
                </Grid>
            </ListItem>
            <Divider component="li" />
        </>
    );
});
const AdjustmentSerialNumbers = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <MainComponent />
        </QueryClientProvider>
    );
};
export default AdjustmentSerialNumbers;
