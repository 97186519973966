import React from 'react';

import { Helmet } from 'react-helmet';

type Props = {
  description?: string;
  children: JSX.Element | JSX.Element[];
  title?: string;
};

const PageContainer = ({ title, description, children }: Props) => (
  <div>
    <Helmet>
      <title>{title}</title>
      {/* <meta name="description" content={description} /> */}
      <meta
        name="description"
        content="RKCWMS - Warehouse Management System"
        data-react-helmet="true"
      />
      <link rel="canonical" href="https://wms1.raelkitzcorp.com" data-react-helmet="true" />
      <meta property="og:title" content={title} data-react-helmet="true" />
      <meta property="og:description" content={description} data-react-helmet="true" />
      <meta property="og:url" content="https://wms1.raelkitzcorp.com" data-react-helmet="true" />
      <meta property="og:type" content="website" data-react-helmet="true" />

      <meta name="twitter:title" content={title} data-react-helmet="true" />
      <meta name="twitter:description" content={description} data-react-helmet="true" />
      <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
    </Helmet>
    {children}
  </div>
);

export default PageContainer;
