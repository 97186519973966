import React, { useEffect, useState } from 'react';
import { Alert, Box } from '@mui/material';

interface StatusAlertProps {
  statusCode: number | null;
  statusData: string[] | string | { message: string };
}

type AlertColor = 'success' | 'info' | 'warning' | 'error';

const StatusAlert: React.FC<StatusAlertProps> = ({ statusCode, statusData }) => {
  const [visible, setVisible] = useState(false);
  let severity: AlertColor = 'info';
  let message = '';

  // Determine severity based on status code
  if (statusCode && statusCode >= 200 && statusCode < 300) {
    severity = 'success';
    message = 'Success!';
  } else if (statusCode && statusCode >= 400 && statusCode < 500) {
    severity = 'error';
    message = 'Error!';
  }

  // Extract error message from statusData
  const getErrorMessage = () => {
    if (!statusData) return '';
    
    if (Array.isArray(statusData)) {
      return statusData[0] || '';
    }
    if (typeof statusData === 'string') {
      return statusData;
    }
    if (typeof statusData === 'object' && 'message' in statusData) {
      return statusData.message;
    }
    return '';
  };

  useEffect(() => {
    if (statusCode) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [statusCode]);

  if (!visible) {
    return null;
  }

  return (
    <Box marginBottom={1}>
      <Alert variant="filled" severity={severity}>
        {message} {severity === 'error' && getErrorMessage()}
      </Alert>
    </Box>
  );
};

export default StatusAlert;