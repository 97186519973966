import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  Button,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import axiosClient from 'src/axios_client';
import StatusAlert from 'src/components/shared/AlertResponse';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
});

type DialogFormProps = {
  isOpen: boolean;
  isEditing: boolean;
  initialData: { id: number; name: string; description: string } | null;
  onClose: () => void;
  onSubmit: (payload: { id: number | null; name: string; description: string }) => void;
  refetch: () => void;
};

const DialogForm: React.FC<DialogFormProps> = ({
  isOpen,
  isEditing,
  initialData,
  onClose,
  onSubmit,
  refetch,
}) => {
  const [alertState, setAlertState] = useState({
    show: false,
    statusCode: 0,
    message: ''
  });

  const clearAlert = () => {
    setAlertState({
      show: false,
      statusCode: 0,
      message: ''
    });
  };

  const showAlert = (statusCode: number, message: string) => {
    setAlertState({
      show: true,
      statusCode,
      message
    });

    // Auto-hide alert after 5 seconds
    setTimeout(clearAlert, 5000);
  };

  const formik = useFormik({
    initialValues: {
      id: initialData?.id || null,
      name: initialData?.name || '',
      description: initialData?.description || '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        id: isEditing ? values.id : 0,
        name: values.name,
        description: values.description,
      };

      try {
        if (isEditing) {
          const response = await axiosClient.put(`/locations/${payload?.id}`, {
            name: payload.name,
            description: payload.description,
          });

          showAlert(response.status, 'Location updated successfully');
        } else {
          const response = await axiosClient.post('/locations', {
            name: payload.name,
            description: payload.description,
          });

          showAlert(response.status, 'Location added successfully');
        }

        resetForm();
        refetch();
        onClose();
        onSubmit(payload);
      } catch (error: any) {
        console.error('Error:', error);
        
        if (error.response) {
          const errorMessage = error.response.data?.message || 
            (typeof error.response.data === 'string' ? error.response.data : 'An error occurred. Please try again.');
          
          showAlert(error.response.status, errorMessage);
        } else {
          showAlert(500, 'An unexpected error occurred');
        }
      }
    },
  });

  // Reset form when initialData changes
  useEffect(() => {
    formik.setValues({
      id: initialData?.id || null,
      name: initialData?.name || '',
      description: initialData?.description || '',
    });
  }, [initialData]);

  // Clear alert when dialog closes
  // useEffect(() => {
  //   if (!isOpen) {
  //     clearAlert();
  //   }
  // }, [isOpen]);
  
  return (
    <>
      {alertState.show && (
        <StatusAlert 
          statusCode={alertState.statusCode} 
          statusData={{ message: alertState.message }} 
        />
      )}
      <Dialog open={isOpen} onClose={onClose}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            {isEditing ? `Edit Location [${initialData?.name}]` : 'Add Location'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>You are editing the device setup.</DialogContentText>
            <Box mt={2}>
              <CustomTextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                fullWidth
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                fullWidth
                value={formik.values.description}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button color="error" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">{isEditing ? 'Update' : 'Add'}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default React.memo(DialogForm);