import React, {
  FC,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Divider,
  Table,
  Space,
  Button,
  AutoComplete,
  Tag,
  message,
  ConfigProvider,
  DatePicker,
  Flex,
  Modal,
  Checkbox,
  Typography as TypographyAnt,
  Popover,
  InputNumber,
  Badge,
} from 'antd';
import type { CheckboxProps, TableProps } from 'antd';
import axiosClient from 'src/axios_client';
import CustomMultiInput, { TagItem } from './CustomMultiInput';
import locale from 'antd/locale/en_US';
import dayjs from 'dayjs';
import 'dayjs/locale/tl-ph';
import {
  CheckCircleOutlineRounded,
  CircleNotifications,
  DeleteOutline,
  EditOutlined,
  NotificationsOutlined,
} from '@mui/icons-material';
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Table as TableMUI,
  useTheme,
  Grid,
  Divider as MUIDivider,
  Alert,
  Stepper,
  StepLabel,
  Paper,
  Step,
  Chip,
  Autocomplete,
  TextField,
} from '@mui/material';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import _ from 'lodash';
import { IssuanceEntryTransactions } from './IssuanceEntryTransactionsTable';
import { useSelector, useDispatch } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import { theme } from 'antd/lib';
import ReactToPrint from 'react-to-print';
import { Console } from 'console';
import './form.css';
import { IconCircleCheck, IconNotification } from '@tabler/icons';
import { formatCurrency } from 'src/globals/NumericFormat';
import { ReactComponent as RECEIPT } from 'src/assets/images/svgs/rkc_wms_dark_receiptS.svg';
import { ReactComponent as LogoDark } from 'src/assets/images/logos/dark-logo.svg';
import { ReactComponent as LogoLight } from 'src/assets/images/logos/light-logo.svg';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';

interface CustomFormLabelProps {
  children: React.ReactNode;
  htmlFor?: string;
}

interface DataType {
  key: string;
  id: number;
  name: string;
  description: string;
  product_unit: string;
  quantity: number;
  unit_price: number;
  floating_quantity: any;
  amount: number;
  serial_numbers: TagItem[];
  product_available_stock?: number;
  product_has_serial?: number;
  stock_location: any;
  used_stocks: any;
  initial_locations: any;
  initial_quantity: any;
  is_used_first: any;
  notice?: any;
  note?: any;
}

interface Customer {
  id: number;
  code: string;
  name: string;
  address: string;
}

interface CustomerOption {
  value: string;
  label: string;
  customer: Customer;
}

const initialData: DataType[] = [];

const CustomFormLabel: FC<CustomFormLabelProps> = ({ children, htmlFor }) => (
  <label htmlFor={htmlFor} style={{ display: 'block', marginBottom: 4 }}>
    {children}
  </label>
);

interface IssuanceEntryFormHandles {
  postTransaction: () => void;
  clearTransaction: () => void;
  updateTransaction: () => void;
}

type InvoiceData = {
  id: string;
  slip_number: string;
  reference_number: string;
  date_of_issuance: string;
  customer_code: string;
  customer_name: string;
  customer_address: string;
  customer_contact_person: string;
  transactions: {
    product_name: string;
    product_id: any;
    is_used_first: any;
    description: string;
    product_unit: string;
    cost: number;
    quantity: number;
    serial_numbers: any;
    stock_location: any;
    used_stocks: any;
    amount: number;
    notice?: any;
  }[];
  processor: string;
  subtotal: number;
};

interface GridItemProps {
  data: InvoiceData | undefined;
  copy: any;
  theme: any;
}

type ProductOptionsSerial = {
  id: string,
  serial_number: any,
  status: any,
};

const { confirm } = Modal;

const GridItem: FC<GridItemProps> = ({ data, copy, theme }) => {
  const { formatWithPrefix } = useFormattedNumber();

  console.log(data);
  const { userData } = useEssentialDataContext();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {theme === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}
            {/* <RECEIPT
              style={{
                fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
              }}
              className="printable-logo"
            /> */}
          </Box>
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {copy}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            INVOICE #{data?.slip_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data?.reference_number} <br />
            Issuance Date: {data?.date_of_issuance}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {data?.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Customer Code: {data?.customer_code}
            <br />
            Address: {data?.customer_address}
            <br />
            Contact Person: {data?.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <TableMUI className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>Location</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>UNIT</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>COST</TableCell>
            {/* <TableCell sx={{ py: 1, color: 'text.secondary' }}>QTY</TableCell> */}
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>AMOUNT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.transactions.map((transaction, index) => {
            const stockLoc = transaction.stock_location
              ? transaction.stock_location.filter((result: any) => result.quantity != 0)
              : [];

            console.log(transaction);
            console.log(stockLoc);


            const serialNumbers = transaction.serial_numbers.filter(
              (opt: any) => opt.product_id === transaction.product_id,
            );

            let serialIndex = 0;
            let remainingQuantity = transaction.quantity;
            const result = [];

            const getUsedStockResult = [];
            const uniqueMap: any = new Map();

            if (transaction.serial_numbers.length > 0) {
              transaction.serial_numbers.forEach((item: any) => {
                // Generate a key based on the unique properties
                const key = `${item.product_id}-${item.floor}-${item.divider}-${item.price}-${item.location_id}-${item.is_used}`;

                if (uniqueMap.has(key)) {
                  const existingItem = uniqueMap.get(key);
                  existingItem.quantity++;
                  existingItem.serial_numbers.push(item);
                } else {
                  uniqueMap.set(key, { ...item, quantity: 1, serial_numbers: [item] });
                }
              });
              const uniqueItems: any = Array.from(uniqueMap.values());

              for (const item of uniqueItems) {

                const location = stockLoc?.find((loc: any) =>
                  loc.location_id === item.location_id && loc.product_id === item.product_id
                );

                result.push({
                  id: item.id,
                  floor: item.floor,
                  divider: item.divider,
                  product_id: item.product_id,
                  location_id: item.location_id,
                  takenNew: item.is_used == 0 ? item.quantity : 0,
                  takenQuantity: item.quantity,
                  takenUsed: item.is_used == 1 ? item.quantity : 0,
                  used_quantity: item.is_used == 1 ? item.quantity : 0,
                  value: item.price,
                  quantity: item.quantity,
                  location: location?.location,
                  serial_numbers: item.serial_numbers,
                });
              }
            }
            else {
              if (transaction.is_used_first && transaction.serial_numbers.length == 0) {
                for (const item of transaction.used_stocks) {
                  if (remainingQuantity <= 0) break;

                  let takenUsed = 0;
                  const serialsTaken: any = [];

                  const locationDetails = stockLoc?.find((loc: any) =>
                    loc.location_id === item.location_id && loc.product_id === item.product_id
                  );

                  if (item.quantity > 0) {
                    if (item.quantity >= remainingQuantity) {
                      takenUsed = remainingQuantity;
                      serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));


                      result.push({
                        ...item,
                        takenUsed,
                        takenNew: 0,
                        location: locationDetails?.location,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenUsed = item.quantity;
                      serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));
                      result.push({
                        ...item,
                        takenUsed,
                        takenNew: 0,
                        location: locationDetails?.location,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity -= takenUsed;
                    }
                    serialIndex += takenUsed;
                  }
                }

                if (remainingQuantity > 0) {
                  for (const item of stockLoc) {
                    if (remainingQuantity <= 0) break;

                    let takenNew = 0;
                    const serialsTaken: any = [];

                    const new_stock_qty = item.quantity - item.used_quantity;

                    if (new_stock_qty > 0) {
                      if (new_stock_qty >= remainingQuantity) {
                        takenNew = remainingQuantity;
                        serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenNew));
                        result.push({
                          ...item,
                          takenUsed: 0,
                          value: transaction.cost,
                          takenNew,
                          takenQuantity: takenNew,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity = 0;
                      } else {
                        takenNew = new_stock_qty;
                        serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenNew));
                        result.push({
                          ...item,
                          takenUsed: 0,
                          value: transaction.cost,
                          takenNew,
                          takenQuantity: takenNew,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity -= takenNew;
                      }
                      serialIndex += takenNew;
                    }
                  }
                }

                // DO NOT DELETE THIS CODEEEEEEEEEEE

                // for (const item of stockLoc) {
                //   if (remainingQuantity <= 0) break;

                //   let takenUsed = 0;
                //   const serialsTaken: any = [];

                //   if (item.used_quantity > 0) {
                //     if (item.used_quantity >= remainingQuantity) {
                //       takenUsed = remainingQuantity;
                //       serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));
                //       result.push({
                //         ...item,
                //         takenUsed,
                //         takenNew: 0,
                //         takenQuantity: takenUsed,
                //         serial_numbers: serialsTaken,
                //       });
                //       remainingQuantity = 0;
                //     } else {
                //       takenUsed = item.used_quantity;
                //       serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));
                //       result.push({
                //         ...item,
                //         takenUsed,
                //         takenNew: 0,
                //         takenQuantity: takenUsed,
                //         serial_numbers: serialsTaken,
                //       });
                //       remainingQuantity -= takenUsed;
                //     }
                //     serialIndex += takenUsed;
                //   }
                // }
              }
              else {
                for (const item of stockLoc) {
                  if (remainingQuantity <= 0) break;

                  let takenNew = 0;
                  const serialsTaken: any = [];

                  const new_stock_qty = item.quantity - item.used_quantity;

                  if (new_stock_qty > 0) {
                    if (new_stock_qty >= remainingQuantity) {
                      takenNew = remainingQuantity;
                      serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenNew));
                      result.push({
                        ...item,
                        takenUsed: 0,
                        value: transaction.cost,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenNew = new_stock_qty;
                      serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenNew));
                      result.push({
                        ...item,
                        takenUsed: 0,
                        value: transaction.cost,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity -= takenNew;
                    }
                    serialIndex += takenNew;
                  }
                }

                if (remainingQuantity > 0) {
                  for (const item of transaction.used_stocks) {
                    if (remainingQuantity <= 0) break;

                    let takenUsed = 0;
                    const serialsTaken: any = [];

                    const locationDetails = stockLoc?.find((loc: any) =>
                      loc.location_id === item.location_id && loc.product_id === item.product_id
                    );

                    if (item.quantity > 0) {
                      if (item.quantity >= remainingQuantity) {
                        takenUsed = remainingQuantity;
                        serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));


                        result.push({
                          ...item,
                          takenUsed,
                          takenNew: 0,
                          location: locationDetails?.location,
                          takenQuantity: takenUsed,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity = 0;
                      } else {
                        takenUsed = item.quantity;
                        serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));
                        result.push({
                          ...item,
                          takenUsed,
                          takenNew: 0,
                          location: locationDetails?.location,
                          takenQuantity: takenUsed,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity -= takenUsed;
                      }
                      serialIndex += takenUsed;
                    }
                  }
                }
              }
            }

            if (remainingQuantity > 0) {
              console.log('Not enough stock available');
            }

            console.log(result);

            return (
              <>
                {remainingQuantity > 0 &&
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>{transaction.product_name}</TableCell>
                    <TableCell sx={{ py: 1 }}>

                      No Record
                      {/* {item.location?.name}
                      {item.divider} Floor {item.floor}
                      {item.serial_numbers.length > 0 && (
                        <>
                          <br />
                          Serial Numbers:
                          {item.serial_numbers.map((serials: any) => {
                            return ' ' + serials.value + ', ';
                          })}
                        </>
                      )} */}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>
                      {remainingQuantity} {transaction.product_unit}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.cost)}</TableCell>
                    {/* <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell> */}
                    <TableCell sx={{ py: 1 }}>
                      {formatWithPrefix(remainingQuantity * transaction.cost)}
                    </TableCell>
                  </TableRow>
                }
                {result.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell sx={{ py: 1 }}>{transaction.product_name}</TableCell>
                    <TableCell sx={{ py: 1 }}>
                      {item.location?.name}
                      {item.divider} Floor {item.floor}
                      {item.serial_numbers.length > 0 && (
                        <>
                          <br />
                          Serial Numbers:
                          {item.serial_numbers.map((serials: any) => {
                            return ' ' + serials.value + ', ';
                          })}
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>
                      {item.takenQuantity} {transaction.serial_numbers.length > 0 ? '' : item.takenNew > 0 ? 'new' : 'old'} {transaction.product_unit}
                    </TableCell>
                    <TableCell sx={{ py: 1 }}>{formatWithPrefix(item.value)}</TableCell>
                    {/* <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell> */}
                    <TableCell sx={{ py: 1 }}>
                      {formatWithPrefix(item.takenQuantity * item.value)}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            );
          })}
        </TableBody>
      </TableMUI>

      <MUIDivider sx={{ borderColor: '#acacac', display: 'none', displayPrint: 'block' }} />
      <Grid
        container
        columnSpacing={5}
        sx={{ fontSize: { md: '8pt', lg: '12pt' }, display: 'none', displayPrint: 'block' }}
      >
        <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <MUIDivider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={2.5} className="fontSize8pt">
          Prepared By:
          <MUIDivider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columnSpacing={1}>
            <Grid item className="fontSize8pt">
              Checked By:
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={2}>
                <Grid item className="fontSize8pt">
                  Checker
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <MUIDivider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Hauler Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <MUIDivider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Customer Authorized Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <MUIDivider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container columnSpacing={1} justifyContent={'between'}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Received the above goods in full and good condition.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Received By:
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <MUIDivider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
        mt={1}
      >
        <MUIDivider sx={{ borderColor: 'black' }} />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
        mt={1}
      >
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
              {dayjs().format('MM/DD/YYYY h:mm:ss A')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              User: {userData?.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const PrintItem: FC<GridItemProps> = ({ data, copy }) => {
  const { formatWithPrefix } = useFormattedNumber();

  const customizer = useSelector((state: AppState) => state.customizer);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {customizer.activeMode === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}
          </Box>
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {copy}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            INVOICE #{data?.slip_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data?.reference_number} <br />
            Issuance Date: {data?.date_of_issuance}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {data?.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Customer Code: {data?.customer_code}
            <br />
            Address: {data?.customer_address}
            <br />
            Contact Person: {data?.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <TableMUI className="printTable" sx={{ width: '100%', textAlign: 'left', mb: 1 }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>Location</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>UNIT</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>COST</TableCell>
            {/* <TableCell sx={{ py: 1, color: 'text.secondary' }}>QTY</TableCell> */}
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>AMOUNT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.transactions.map((transaction, index) => {
            const stockLoc = transaction.stock_location
              ? transaction.stock_location.filter((result: any) => result.quantity != 0)
              : [];

            const serialNumbers = transaction.serial_numbers.filter(
              (opt: any) => opt.product_id === transaction.product_id,
            );

            let serialIndex = 0;
            let remainingQuantity = transaction.quantity;
            const result = [];




            if (transaction.is_used_first && transaction.serial_numbers.length == 0) {
              for (const item of stockLoc) {
                if (remainingQuantity <= 0) break;

                let takenUsed = 0;
                const serialsTaken: any = [];

                if (item.used_quantity > 0) {
                  if (item.used_quantity >= remainingQuantity) {
                    takenUsed = remainingQuantity;
                    serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));
                    result.push({
                      ...item,
                      takenUsed,
                      takenNew: 0,
                      takenQuantity: takenUsed,
                      serial_numbers: serialsTaken,
                    });
                    remainingQuantity = 0;
                  } else {
                    takenUsed = item.used_quantity;
                    serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));
                    result.push({
                      ...item,
                      takenUsed,
                      takenNew: 0,
                      takenQuantity: takenUsed,
                      serial_numbers: serialsTaken,
                    });
                    remainingQuantity -= takenUsed;
                  }
                  serialIndex += takenUsed;
                }
              }
            }



            for (const item of stockLoc) {
              if (remainingQuantity <= 0) break;

              let takenNew = 0;
              const serialsTaken: any = [];

              if (item.quantity > 0) {
                if (item.quantity >= remainingQuantity) {
                  takenNew = remainingQuantity;
                  serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenNew));
                  result.push({
                    ...item,
                    takenUsed: 0,
                    takenNew,
                    takenQuantity: takenNew,
                    serial_numbers: serialsTaken,
                  });
                  remainingQuantity = 0;
                } else {
                  takenNew = item.quantity;
                  serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenNew));
                  result.push({
                    ...item,
                    takenUsed: 0,
                    takenNew,
                    takenQuantity: takenNew,
                    serial_numbers: serialsTaken,
                  });
                  remainingQuantity -= takenNew;
                }
                serialIndex += takenNew;
              }
            }

            // for (const item of stockLoc) {
            //   if (remainingQuantity <= 0) break;
            //   const serialsTaken: any = [];
            //   let takenQuantity;

            //   if (item.quantity >= remainingQuantity) {
            //     takenQuantity = remainingQuantity;
            //     serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
            //     result.push({
            //       ...item,
            //       takenQuantity: remainingQuantity,
            //       serial_numbers: serialsTaken,
            //     });
            //     remainingQuantity = 0;
            //   } else {
            //     takenQuantity = item.quantity;
            //     serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
            //     result.push({
            //       ...item,
            //       takenQuantity: item.quantity,
            //       serial_numbers: serialsTaken,
            //     });
            //     remainingQuantity -= item.quantity;
            //   }
            //   serialIndex += takenQuantity;
            // }
            if (remainingQuantity > 0) {
              console.log('Not enough stock available');
            }

            return result.map((item) => (
              <TableRow key={item.id}>
                <TableCell sx={{ py: 1 }}>{transaction.product_name}</TableCell>
                <TableCell sx={{ py: 1 }}>
                  {item.location?.name}
                  {item.divider} Floor {item.floor}
                  {item.serial_numbers.length > 0 && (
                    <>
                      <br />
                      Serial Numbers:
                      {item.serial_numbers.map((serials: any) => {
                        return ' ' + serials.value + ', ';
                      })}
                    </>
                  )}
                </TableCell>
                <TableCell sx={{ py: 1 }}>
                  {item.takenQuantity} {transaction.serial_numbers.length > 0 ? '' : item.takenNew > 0 ? 'new' : 'used'} {transaction.product_unit}
                </TableCell>
                <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.cost)}</TableCell>
                {/* <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell> */}
                <TableCell sx={{ py: 1 }}>
                  {formatWithPrefix(item.takenQuantity * transaction.cost)}
                </TableCell>
              </TableRow>
            ));
          })}
          <TableRow>
            <TableCell sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Total: {data?.transactions.length}
              </Typography>{' '}
            </TableCell>
            <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}></TableCell>
            <TableCell sx={{ py: 1 }}></TableCell>
            <TableCell sx={{ py: 1 }}></TableCell>
            <TableCell sx={{ py: 1 }}></TableCell>
            {/* <TableCell sx={{ py: 1, fontWeight: 'bold' }} className='fontSize8pt'>{formatWithPrefix(123)}</TableCell> */}
          </TableRow>
        </TableBody>
      </TableMUI>

      <MUIDivider sx={{ borderColor: '#acacac' }} />
      <Grid container columnSpacing={5} sx={{ fontSize: { md: '8pt', lg: '12pt' } }}>
        {/* <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <MUIDivider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid> */}
        <Grid item xs={5} className="fontSize8pt">
          Prepared By:
          <MUIDivider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columnSpacing={1}>
            <Grid item className="fontSize8pt">
              Checked By:
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={2}>
                <Grid item className="fontSize8pt">
                  Checker
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <MUIDivider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Hauler Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <MUIDivider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Customer Authorized Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <MUIDivider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container columnSpacing={1} justifyContent={'between'}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Received the above goods in full and good condition.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Received By:
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <MUIDivider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <MUIDivider sx={{ borderColor: 'black' }} />
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
              {dayjs().format('MM/DD/YYYY h:mm:ss A')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              User: name of user
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
interface IssuanceEntryFormProps {
  selectedTransaction?: {
    id: number;
    reference_number: string;
    slip_number: string;
    customer_id: number;
    date_of_issuance: string;
    customer: {
      id: number;
      name: string;
      code: string;
      address: string;
      contact_person: string;
    };
    transactions: {
      stocks: {
        id: number;
        product_id: number;
        product_name: string;
        product_description: string;
        product_unit: string;
        product_available_stock: number;
        product_has_serial: number;
        quantity: number;
        is_used_first: any;
        floating_quantity: any;
        initial_quantity: any;
        price: number;
        notice: any;
        note: any;
      }[];
      serial_numbers: {
        id: number;
        product_id: number;
        is_used_first: any;
        product_name: string;
        product_description: string;
        product_unit: string;
        product_available_stock: number;
        product_has_serial: number;
        serial_number: string;
        price: number;
        is_used: any;
        floor: any;
        divider: any;
        location_id: any;
        serial_number_id: number;
        notice: any;
        note: any;
      }[];
      stock_locations: any;
      initial_location: any;
      used_stocks: any;
    };
    status: string;
  } | null;

  onRefreshTransactions: () => void;
  userlevel: any;
}

const IssuanceEntryForm = forwardRef<IssuanceEntryFormHandles, IssuanceEntryFormProps>(
  ({ selectedTransaction, onRefreshTransactions, userlevel }, ref) => {
    useImperativeHandle(ref, () => ({
      postTransaction,
      clearTransaction,
      updateTransaction,
    }));

    const [isSearching, setIsSearching] = useState(false);
    const [isEmptySearch, setIsEmptySearch] = useState(false);
    const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [productSerialNumber, setProductSerialNumber] = useState<ProductOptionsSerial[]>([]);

    const [selectedSerialNumber, setSelectedSerialNumber] = useState<ProductOptionsSerial[]>([]);
    const autocompleteSearchChange = (searchText: any, apiText: any) => {
      setIsSearching(true);
      setIsEmptySearch(false);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        const value = searchText.target.value;
        fetchOptions(value, apiText);
      }, 500);
    };

    const fetchOptions = async (searchText: string, apiText: string) => {
      try {


        const { status, data } = await axiosClient.get(`/check_serial_number?globalFilter=${searchText}&product_id=${productId}`);
        if (status == 200) {
          setIsSearching(false);
          if (data.data.length == 0) {
            setIsEmptySearch(true);
          }

          console.log(data.data);
          const fetchedData = data.data.map((innerData: any) => ({
            id: innerData.id,
            name: innerData.serial_number,
            serial_number: innerData.serial_number,
            warranty_period: innerData.warranty_period,
            status: innerData.status,
            is_floating: innerData.is_floating,
            product_id: innerData.product_id
          }));
          setProductSerialNumber(fetchedData);

        }

      } catch (error) {
        console.error('Failed to fetch product options:', error);
      }
    };

    const [isCheckedFinalize, setIsCheckedFinalize] = useState(false);

    useEffect(() => {
      if (selectedTransaction) {
        populateFormWithSelectedTransaction(selectedTransaction);
      }
    }, [selectedTransaction]);

    const populateFormWithSelectedTransaction = async (
      transaction: NonNullable<IssuanceEntryFormProps['selectedTransaction']>,
    ) => {
      console.log(transaction);

      setError('');
      form.setFieldsValue({
        reference_number: transaction.reference_number,
        slip_number: transaction.slip_number,
        date_of_issuance: dayjs(transaction.date_of_issuance),
        customer_code: `${transaction.customer.code} - ${transaction.customer.name}`,
        customer_name: transaction.customer.name,
        customer_address: transaction.customer.address,
      });

      form.resetFields([
        'product_name',
        'product_unit',
        'product_quantity',
        'product_unit_price',
        'product_amount',
        'product_description',
        'serial_number',
        'product_available_stock',
        'floating_quantity',
      ]);

      setCustomerId(transaction.customer_id);
      setCustomerDisplay(`${transaction.customer.code} - ${transaction.customer.name}`);

      const productMap = new Map();

      for (const item of transaction.transactions.stocks) {
        try {

          console.log(item);
          // const response = await axiosClient.get(`/stocks_location/product/${item.product_id}`);

          productMap.set(item.product_id, {
            key: item.product_id.toString(),
            id: item.product_id,
            name: item.product_name,
            description: item.product_description,
            product_unit: item.product_unit,
            product_available_stock: item.product_available_stock,
            product_has_serial: item.product_has_serial,
            floating_quantity: item.floating_quantity,
            initial_quantity: item.initial_quantity,
            quantity: item.quantity,
            unit_price: item.price,
            amount: item.quantity * item.price,
            notice: item.notice,
            note: item.note,
            is_used_first: item.is_used_first,
            stock_location: transaction.transactions.stock_locations.filter((filter: any) => filter.product_id == item.product_id),
            initial_locations: transaction.transactions.initial_location.filter((filter: any) => filter.product_id == item.product_id),
            used_stocks: transaction.transactions.used_stocks.filter((filter: any) => filter.product_id == item.product_id),
            serial_numbers: [],
          });
        } catch (error) {
          console.error(`Failed to fetch stock location for product ID ${item.product_id}:`, error);
        }
      }

      console.log(transaction.transactions.serial_numbers);

      transaction.transactions.serial_numbers.forEach((item) => {
        if (productMap.has(item.product_id)) {
          const existingProduct = productMap.get(item.product_id);
          existingProduct.quantity += 1;
          existingProduct.amount += item.price;
          existingProduct.serial_numbers.push({
            id: item.id,
            location_id: item.location_id,
            floor: item.floor,
            divider: item.divider,
            product_id: item.product_id,
            is_used: item.is_used,
            value: item.serial_number,
            serial_number_id: item.serial_number_id,
            price: item.price,
            notice: item.notice,
          });
          existingProduct.stock_location = transaction.transactions.stock_locations.filter(
            (result: any) => result.product_id == item.product_id,
          );
        } else {
          productMap.set(item.product_id, {
            key: item.product_id.toString(),
            id: item.product_id,
            name: item.product_name,
            description: item.product_description,
            product_unit: item.product_unit,
            product_available_stock: item.product_available_stock,
            product_has_serial: item.product_has_serial,
            quantity: 1,
            unit_price: item.price,
            amount: item.price,
            is_used_first: item.is_used_first,
            stock_location: transaction.transactions.stock_locations.filter((filter: any) => filter.product_id == item.product_id),
            initial_locations: transaction.transactions.initial_location.filter((filter: any) => filter.product_id == item.product_id),
            used_stocks: transaction.transactions.used_stocks.filter((filter: any) => filter.product_id == item.product_id),
            serial_numbers: [
              {
                id: item.id,
                value: item.serial_number,
                product_id: item.product_id,
                is_used: item.is_used,
                location_id: item.location_id,
                floor: item.floor,
                divider: item.divider,
                serial_number_id: item.serial_number_id,
                price: item.price,
                notice: item.notice,
                note: item.note,
              },
            ],
          });
        }
      });

      const transactionData = Array.from(productMap.values());
      console.log(transactionData);

      setData(transactionData);

      setCustomerOptions((prevOptions: any) => {
        const existingOption = prevOptions.find((opt: any) => opt.id === transaction.customer.id);
        if (!existingOption) {
          return [
            ...prevOptions,
            {
              value: transaction.customer.name,
              label: `${transaction.customer.code} - ${transaction.customer.name}`,
              customer: transaction.customer,
            },
          ];
        }
        return prevOptions;
      });
    };

    const [form] = Form.useForm();
    const [data, setData] = useState<DataType[]>(initialData);
    const [productOptions, setProductOptions] = useState<
      { value: string; id: number; product: any }[]
    >([]);
    const [productId, setProductId] = useState<number>(0);
    const [serialNumberTextDisabled, setSerialNumberTextDisabled] = useState(true);
    const [maximumTags, setMaximumTags] = useState(5);
    const [editKey, setEditKey] = useState<string | null>(null);
    const [printData, setPrintData] = useState<InvoiceData>();
    const selectedProductRef = useRef<any>(null);

    useEffect(() => {
      if (productId) {
        const selectedProduct = productOptions.find((option) => option.id === productId);
        if (selectedProduct) {
          selectedProductRef.current = selectedProduct.product;
          form.setFieldsValue({
            product_name: selectedProduct.product.name,
            product_description: selectedProduct.product.description,
            product_unit: selectedProduct.product.product_unit,
            product_available_stock: selectedProduct.product.product_available_stock,
            product_unit_price: selectedProduct.product.product_unit_price,
          });
          setProductDisplay(selectedProduct.product.name);
          setSerialNumberTextDisabled(selectedProduct.product.has_serial === 0);
        }
      }
      if (form) {
        form.setFieldsValue({
          date_of_issuance: dayjs(),
        });
      }
    }, [productId, form, productOptions]);

    const fetchProductOptions = async (searchText: string) => {
      try {
        const response = await axiosClient.get('/product-test', {
          params: { globalFilter: searchText, per_page: 10 },
        });

        const products = response.data.data.map((product: any) => ({
          value: product.name,
          id: product.id,
          product: {
            ...product,
            product_unit: product.product_unit,
            product_unit_price: product.selling_price,
          },
        }));
        setProductOptions(products);
        console.log('Search product:', products);
      } catch (error) {
        console.error('Failed to fetch product options:', error);
      }
    };

    const debouncedFetchProductOptions = useCallback(
      _.debounce((searchText: string) => {
        fetchProductOptions(searchText);
      }, 300), // Adjust the delay (in milliseconds) as needed
      [],
    );

    const onProductCodeChange = (searchText: string) => {
      setProductDisplay(searchText);
      debouncedFetchProductOptions(searchText);
    };

    const getExistingSerialNumbers = (currentKey: string | null) => {
      return data
        .filter((item) => item.key !== currentKey)
        .flatMap((item) => item.serial_numbers.map((serial) => serial.value));
    };

    const showConfirmation = (
      action: () => void,
      title: string,
      content: ReactNode,
      width: number,
    ) => {
      confirm({
        title,
        content,
        icon: null,
        onOk() {
          action();
        },
        width,
      });
    };

    const addOrUpdateProduct = () => {

      form
        .validateFields()
        .then((values) => {
          console.log(values);
          console.log(selectedProductRef);
          console.log(selectedTransaction);

          const quantity = Number(values.product_quantity);
          const unit_price = Number(values.product_unit_price);
          const serialNumbers = multiInputRef.current?.getTags() || [];
          // const serialNumbers = multiInputRef.current?.getTags() || [];

          console.log(serialNumbers);


          if (quantity <= 0) {
            message.error(`Insert valid quantity`);
            return;
          }



          if (selectedProductRef.current && selectedProductRef.current.has_serial === 1) {
            if (serialNumbers.length !== quantity) {
              message.error(
                `The number of serial numbers (${serialNumbers.length}) does not match the quantity (${quantity}).`,
              );
              return;
            }
          }

          const existingSerialNumbers = getExistingSerialNumbers(editKey);

          const duplicateSerials = serialNumbers.filter((serial) =>
            existingSerialNumbers.includes(serial.value),
          );
          if (duplicateSerials.length > 0) {
            message.error(
              `Serial number(s) ${duplicateSerials.map((s) => s.value).join(', ')} already exist.`,
            );
            return;
          }

          console.log(usedStocks);
          const addOrUpdate = () => {
            if (editKey) {
              const existingProductIndex = data.findIndex((item) => item.key === editKey);
              const initialUsed = selectedTransaction?.transactions.used_stocks.filter((stat: any) => stat.product_id == selectedProductRef.current.id);
              const initialLocation = selectedTransaction?.transactions.initial_location.filter((stat: any) => stat.product_id == selectedProductRef.current.id);

              if (existingProductIndex !== -1) {
                const existingProduct = data[existingProductIndex];
                const updatedProduct: DataType = {
                  ...existingProduct,
                  quantity,
                  unit_price,
                  is_used_first: values.is_used_first,
                  amount: quantity * unit_price,
                  serial_numbers: serialNumbers,
                  stock_location: stocksLocation,
                  initial_locations: initialLocation ? initialLocation.concat(stocksLocation) : stocksLocation,
                  initial_quantity: quantity,
                  used_stocks: initialUsed ? initialUsed.concat(usedStocks) : usedStocks,
                };


                const updatedData = [...data];
                updatedData[existingProductIndex] = updatedProduct;
                setData(updatedData);
              }



            } else {
              const existingProductIndex = data.findIndex(
                (item) => item.id === selectedProductRef.current.id,
              );

              if (data[existingProductIndex] && data[existingProductIndex].notice == 0) {
                form.setFields([
                  {
                    name: 'product_name',
                    errors: [`You can't add another product that has been checked by checker!`],
                  },
                ]);
                return;
              }

              if (existingProductIndex !== -1) {
                const existingProduct = data[existingProductIndex];
                const availableStock = selectedProductRef.current.product_available_stock;
                let floatingStock = selectedProductRef.current.floating_quantity;



                if (selectedTransaction) {
                  const filteredStocks = selectedTransaction?.transactions.stocks.filter((stat: any) => stat.product_id == selectedProductRef.current.id);
                  const totalQuantity = filteredStocks?.reduce((sum: number, stock: any) => sum + stock.initial_quantity, 0) || 0;

                  floatingStock = floatingStock > totalQuantity ? floatingStock - totalQuantity : totalQuantity - floatingStock;

                }


                if (existingProduct.quantity + quantity > availableStock - floatingStock) {
                  form.setFields([
                    {
                      name: 'product_quantity',
                      errors: [
                        'You have exceeded the quantity limit please edit or delete the record below',
                      ],
                    },
                  ]);

                  return;
                }

                const updatedProduct: DataType = {
                  ...existingProduct,
                  quantity: existingProduct.quantity + quantity,
                  // floating_quantity: selectedProductRef.current.foating_quantity + quantity,
                  floating_quantity: selectedProductRef.current.floating_quantity,
                  unit_price,
                  is_used_first: values.is_used_first,
                  amount: (existingProduct.quantity + quantity) * unit_price,
                  serial_numbers: [...existingProduct.serial_numbers, ...serialNumbers],
                };

                const updatedData = [...data];
                updatedData[existingProductIndex] = updatedProduct;
                setData(updatedData);
              } else {

                const initialUsed = selectedTransaction?.transactions.used_stocks.filter((stat: any) => stat.product_id == selectedProductRef.current.id);
                const initialLocation = selectedTransaction?.transactions.initial_location.filter((stat: any) => stat.product_id == selectedProductRef.current.id);

                const newProduct: DataType = {
                  key: selectedProductRef.current.id,
                  id: selectedProductRef.current.id,
                  name: selectedProductRef.current.name,
                  description: selectedProductRef.current.description,
                  product_unit: selectedProductRef.current.product_unit,
                  product_has_serial: selectedProductRef.current.has_serial,
                  floating_quantity: selectedProductRef.current.floating_quantity,
                  is_used_first: values.is_used_first,
                  quantity,
                  unit_price,
                  notice: null,
                  amount: quantity * unit_price,
                  serial_numbers: serialNumbers,
                  product_available_stock: selectedProductRef.current.product_available_stock,
                  stock_location: stocksLocation,
                  initial_locations: initialLocation ? initialLocation.concat(stocksLocation) : [], //stocksLocation,
                  initial_quantity: quantity,
                  used_stocks: initialUsed ? initialUsed.concat(usedStocks) : usedStocks,
                };

                setData([...data, newProduct]);
              }
            }

            form.resetFields([
              'product_name',
              'product_unit',
              'product_quantity',
              'product_unit_price',
              'product_amount',
              'product_description',
              'serial_number',
              'product_available_stock',
              'floating_quantity',
            ]);

            console.log(data);
            multiInputRef.current?.clearTags();
            setEditKey(null);
            setProductId(0);
            selectedProductRef.current = null;
          };

          showConfirmation(
            addOrUpdate,
            editKey ? 'Update Product' : 'Add Product',
            `Are you sure you want to ${editKey ? 'update' : 'add'} this product?`,
            400,
          );
        })
        .catch((errorInfo) => {
          // Show form validation errors in a message
          const errors = errorInfo.errorFields.map((err: any) => err.errors.join(', ')).join('; ');
          message.error(`Form validation failed: ${errors}`);
        });
    };

    const editProduct = (key: string, product_id: number, _: any) => {
      const product = data.find((item) => item.key === key);
      console.log('Product ID: ', product_id);
      console.log('Any: ', _);
      console.log('Testing Product: ', product);

      if (product) {

        const amount = _.quantity * _.unit_price;

        getProductLocations(product_id);

        form.setFieldsValue({
          product_quantity: _.quantity,
          product_available_stock: _.product_available_stock,
          floating_quantity: selectedTransaction ? (product.floating_quantity > _.initial_quantity ? product.floating_quantity - _.initial_quantity : 0) : product.floating_quantity,
          product_amount: amount,
        });

        const tags: TagItem[] = product.serial_numbers.map((serial) => ({
          id: serial.id,
          value: serial.value,
          location_id: serial.location_id,
          floor: serial.floor,
          divider: serial.divider,
          product_id: product_id,
          is_used: serial.is_used,
          serial_number_id: serial.serial_number_id,
          price: serial.price,
        }));

        multiInputRef.current?.setTags(tags);
        setProductId(product_id);
        setEditKey(key);
        setMaximumTags(product.quantity);
        setSerialNumberTextDisabled(product.product_has_serial === 0); // Changed from _.product_has_serial
        setProductDisplay(product.name); // Add this line

        // Update productOptions to include the current product
        setProductOptions((prevOptions) => {
          const existingOptionIndex = prevOptions.findIndex((opt) => opt.id === product_id);
          if (existingOptionIndex !== -1) {
            // Update existing option
            const updatedOptions = [...prevOptions];
            updatedOptions[existingOptionIndex] = {
              value: product.name,
              id: product_id,
              product: {
                id: product.id,
                name: product.name,
                description: product.description,
                product_unit: product.product_unit,
                product_unit_price: product.unit_price,
                has_serial: product.product_has_serial,
                floating_quantity: product.floating_quantity,
                product_available_stock: product.product_available_stock,
              },
            };
            return updatedOptions;
          } else {
            // Add new option
            return [
              ...prevOptions,
              {
                value: product.name,
                id: product_id,
                product: {
                  id: product.id,
                  name: product.name,
                  description: product.description,
                  product_unit: product.product_unit,
                  product_unit_price: product.unit_price,
                  has_serial: product.product_has_serial,
                  floating_quantity: product.floating_quantity,
                  product_available_stock: product.product_available_stock,
                },
              },
            ];
          }
        });
      }
    };

    const deleteProduct = (key: string) => {

      setEditKey(null);
      setData(data.filter((item) => item.key !== key));
    };

    const onValuesChange = (changedValues: any, allValues: any) => {
      const { product_quantity, product_unit_price } = allValues;
      if (product_quantity !== undefined && product_unit_price !== undefined) {
        const quantity = Number(product_quantity);
        const unit_price = Number(product_unit_price);
        const amount = quantity * unit_price;
        form.setFieldsValue({ product_amount: amount });
        setMaximumTags(quantity);
      }
    };

    const validateQuantity = (_: any, value: any) => {
      const availableStock = form.getFieldValue('product_available_stock');
      const floatingStock = form.getFieldValue('floating_quantity');
      if (value > availableStock - floatingStock) {
        return Promise.reject(new Error('Quantity exceeds available stock'));
      }
      if (value <= 0) {
        return Promise.reject(new Error('No stock available'));
      }
      return Promise.resolve();
    };

    const calculateTotals = () => {
      const totalAmount = data.reduce((sum, item) => sum + item.amount, 0);

      const formattedTotalAmount = totalAmount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return { totalAmount: formattedTotalAmount };
    };
    const componentRef = useRef(null);

    const [printAuto, setPrintAuto] = useState(false);
    const [error, setError] = useState('');

    const postTransaction = () => {
      form
        .validateFields([
          'slip_number',
          'date_of_issuance',
          'customer_code',
          'customer_name',
          'customer_address',
        ])
        .then((values) => {

          if (data.length === 0) {
            message.error('The Temporary Table must have data before posting.');
            return;
          }

          const hasNotice = data.some((item) => item.notice !== 0);
          if (isCheckedFinalize) {
            setError("You can't finalize this issuance when it's not yet checked");
            return;
          }

          // let totalAmount = 0;

          const transactionData = {
            is_finalized: isCheckedFinalize,
            slip_number: values.slip_number,
            date_of_issuance: values.date_of_issuance.format('YYYY-MM-DD'),
            customer_id: customerId,
            customer_name: values.customer_name,
            customer_address: values.customer_address,
            products: data.map((item) => {

              const stockLoc = item.stock_location
                ? item.stock_location.filter((result: any) => result.quantity != 0)
                : [];
              let remainingQuantity = item.quantity;
              const result = [];

              const getUsedStockResult = [];
              const uniqueMap: any = new Map();

              // if (item.serial_numbers.length > 0) {
              //   item.serial_numbers.forEach((item: any) => {
              //     // Generate a key based on the unique properties
              //     const key = `${item.product_id}-${item.floor}-${item.divider}-${item.price}-${item.location_id}-${item.is_used}`;

              //     if (uniqueMap.has(key)) {
              //       const existingItem = uniqueMap.get(key);
              //       existingItem.quantity++;
              //       existingItem.serial_numbers.push(item);
              //     } else {
              //       uniqueMap.set(key, { ...item, quantity: 1, serial_numbers: [item] });
              //     }
              //   });
              //   const uniqueItems: any = Array.from(uniqueMap.values());

              //   for (const item of uniqueItems) {

              //     const location = stockLoc?.find((loc: any) =>
              //       loc.location_id === item.location_id && loc.product_id === item.product_id
              //     );
              //     console.log(location);

              //     totalAmount = totalAmount + (item.price * item.quantity);

              //     result.push({
              //       id: item.id,
              //       floor: item.floor,
              //       divider: item.divider,
              //       product_id: item.product_id,
              //       location_id: item.location_id,
              //       takenNew: item.is_used == 0 ? item.quantity : 0,
              //       takenQuantity: item.quantity,
              //       takenUsed: item.is_used == 1 ? item.quantity : 0,
              //       used_quantity: item.is_used == 1 ? item.quantity : 0,
              //       value: item.price,
              //       quantity: item.quantity,
              //       location: location?.location,
              //       serial_numbers: item.serial_numbers,
              //     });
              //   }
              // }
              // else {
              //   if (item.is_used_first && item.serial_numbers.length == 0) {

              //     for (const itemDetail of item.used_stocks) {
              //       if (remainingQuantity <= 0) break;

              //       let takenUsed = 0;
              //       const serialsTaken: any = [];

              //       if (itemDetail.quantity > 0) {

              //         const locationDetails = stockLoc?.find((loc: any) =>
              //           loc.location_id === itemDetail.location_id && loc.product_id === itemDetail.product_id
              //         );


              //         if (itemDetail.quantity >= remainingQuantity) {
              //           takenUsed = remainingQuantity;
              //           result.push({
              //             ...itemDetail,
              //             location: locationDetails?.location,
              //             takenUsed,
              //             takenNew: 0,
              //             takenQuantity: takenUsed,
              //             serial_numbers: serialsTaken,
              //           });

              //           getUsedStockResult.push({
              //             ...itemDetail,
              //             quantity: takenUsed,
              //           });

              //           remainingQuantity = 0;
              //         } else {
              //           takenUsed = itemDetail.quantity;
              //           result.push({
              //             ...itemDetail,
              //             location: locationDetails?.location,
              //             takenUsed,
              //             takenNew: 0,
              //             takenQuantity: takenUsed,
              //             serial_numbers: serialsTaken,
              //           });

              //           getUsedStockResult.push({
              //             ...itemDetail,
              //             quantity: takenUsed,
              //           });

              //           remainingQuantity -= takenUsed;
              //         }
              //       }
              //     }

              //     if (remainingQuantity > 0) {
              //       for (const item of stockLoc) {
              //         if (remainingQuantity <= 0) break;

              //         let takenNew = 0;
              //         const serialsTaken: any = [];

              //         if (item.quantity > 0) {
              //           if (item.quantity >= remainingQuantity) {
              //             takenNew = remainingQuantity;
              //             result.push({
              //               ...item,
              //               takenUsed: 0,
              //               takenNew,
              //               takenQuantity: takenNew,
              //               serial_numbers: serialsTaken,
              //             });
              //             remainingQuantity = 0;
              //           } else {
              //             takenNew = item.quantity;
              //             result.push({
              //               ...item,
              //               takenUsed: 0,
              //               takenNew,
              //               takenQuantity: takenNew,
              //               serial_numbers: serialsTaken,
              //             });
              //             remainingQuantity -= takenNew;
              //           }
              //         }
              //       }
              //     }
              //   }
              //   else {

              //     for (const item of stockLoc) {
              //       if (remainingQuantity <= 0) break;

              //       let takenNew = 0;
              //       const serialsTaken: any = [];

              //       if (item.quantity > 0) {
              //         if (item.quantity >= remainingQuantity) {
              //           takenNew = remainingQuantity;
              //           result.push({
              //             ...item,
              //             takenUsed: 0,
              //             takenNew,
              //             takenQuantity: takenNew,
              //             serial_numbers: serialsTaken,
              //           });
              //           remainingQuantity = 0;
              //         } else {
              //           takenNew = item.quantity;
              //           result.push({
              //             ...item,
              //             takenUsed: 0,
              //             takenNew,
              //             takenQuantity: takenNew,
              //             serial_numbers: serialsTaken,
              //           });
              //           remainingQuantity -= takenNew;
              //         }
              //       }
              //     }

              //     if (remainingQuantity > 0) {
              //       for (const itemDetail of item.used_stocks) {
              //         if (remainingQuantity <= 0) break;

              //         let takenUsed = 0;
              //         const serialsTaken: any = [];

              //         if (itemDetail.quantity > 0) {

              //           const locationDetails = stockLoc?.find((loc: any) =>
              //             loc.location_id === itemDetail.location_id && loc.product_id === itemDetail.product_id
              //           );


              //           if (itemDetail.quantity >= remainingQuantity) {
              //             takenUsed = remainingQuantity;
              //             result.push({
              //               ...itemDetail,
              //               location: locationDetails?.location,
              //               takenUsed,
              //               takenNew: 0,
              //               takenQuantity: takenUsed,
              //               serial_numbers: serialsTaken,
              //             });

              //             getUsedStockResult.push({
              //               ...itemDetail,
              //               quantity: takenUsed,
              //             });
              //             remainingQuantity = 0;
              //           } else {
              //             takenUsed = itemDetail.quantity;
              //             result.push({
              //               ...itemDetail,
              //               location: locationDetails?.location,
              //               takenUsed,
              //               takenNew: 0,
              //               takenQuantity: takenUsed,
              //               serial_numbers: serialsTaken,
              //             });
              //             getUsedStockResult.push({
              //               ...itemDetail,
              //               quantity: takenUsed,
              //             });
              //             remainingQuantity -= takenUsed;
              //           }
              //         }
              //       }
              //     }
              //   }
              // }

              if (item.is_used_first && item.serial_numbers.length == 0) {

                for (const itemDetail of item.used_stocks) {
                  if (remainingQuantity <= 0) break;

                  let takenUsed = 0;
                  const serialsTaken: any = [];

                  if (itemDetail.quantity > 0) {

                    const locationDetails = stockLoc?.find((loc: any) =>
                      loc.location_id === itemDetail.location_id && loc.product_id === itemDetail.product_id
                    );


                    if (itemDetail.quantity >= remainingQuantity) {
                      takenUsed = remainingQuantity;
                      result.push({
                        ...itemDetail,
                        location: locationDetails?.location,
                        takenUsed,
                        takenNew: 0,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });

                      getUsedStockResult.push({
                        ...itemDetail,
                        quantity: takenUsed,
                      });

                      remainingQuantity = 0;
                    } else {
                      takenUsed = itemDetail.quantity;
                      result.push({
                        ...itemDetail,
                        location: locationDetails?.location,
                        takenUsed,
                        takenNew: 0,
                        takenQuantity: takenUsed,
                        serial_numbers: serialsTaken,
                      });

                      getUsedStockResult.push({
                        ...itemDetail,
                        quantity: takenUsed,
                      });

                      remainingQuantity -= takenUsed;
                    }
                  }
                }

                if (remainingQuantity > 0) {
                  for (const item of stockLoc) {
                    if (remainingQuantity <= 0) break;

                    let takenNew = 0;
                    const serialsTaken: any = [];

                    if (item.quantity > 0) {
                      if (item.quantity >= remainingQuantity) {
                        takenNew = remainingQuantity;
                        result.push({
                          ...item,
                          takenUsed: 0,
                          takenNew,
                          takenQuantity: takenNew,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity = 0;
                      } else {
                        takenNew = item.quantity;
                        result.push({
                          ...item,
                          takenUsed: 0,
                          takenNew,
                          takenQuantity: takenNew,
                          serial_numbers: serialsTaken,
                        });
                        remainingQuantity -= takenNew;
                      }
                    }
                  }
                }
              }
              else {

                for (const item of stockLoc) {
                  if (remainingQuantity <= 0) break;

                  let takenNew = 0;
                  const serialsTaken: any = [];

                  if (item.quantity > 0) {
                    if (item.quantity >= remainingQuantity) {
                      takenNew = remainingQuantity;
                      result.push({
                        ...item,
                        takenUsed: 0,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity = 0;
                    } else {
                      takenNew = item.quantity;
                      result.push({
                        ...item,
                        takenUsed: 0,
                        takenNew,
                        takenQuantity: takenNew,
                        serial_numbers: serialsTaken,
                      });
                      remainingQuantity -= takenNew;
                    }
                  }
                }

                if (remainingQuantity > 0) {
                  for (const itemDetail of item.used_stocks) {
                    if (remainingQuantity <= 0) break;

                    let takenUsed = 0;
                    const serialsTaken: any = [];

                    if (itemDetail.quantity > 0) {

                      const locationDetails = stockLoc?.find((loc: any) =>
                        loc.location_id === itemDetail.location_id && loc.product_id === itemDetail.product_id
                      );


                      if (itemDetail.quantity >= remainingQuantity) {
                        takenUsed = remainingQuantity;
                        result.push({
                          ...itemDetail,
                          location: locationDetails?.location,
                          takenUsed,
                          takenNew: 0,
                          takenQuantity: takenUsed,
                          serial_numbers: serialsTaken,
                        });

                        getUsedStockResult.push({
                          ...itemDetail,
                          quantity: takenUsed,
                        });
                        remainingQuantity = 0;
                      } else {
                        takenUsed = itemDetail.quantity;
                        result.push({
                          ...itemDetail,
                          location: locationDetails?.location,
                          takenUsed,
                          takenNew: 0,
                          takenQuantity: takenUsed,
                          serial_numbers: serialsTaken,
                        });
                        getUsedStockResult.push({
                          ...itemDetail,
                          quantity: takenUsed,
                        });
                        remainingQuantity -= takenUsed;
                      }
                    }
                  }
                }
              }


              return {
                id: item.id,
                name: item.name,
                quantity: item.quantity,
                unit_price: item.unit_price,
                amount: item.amount,
                floating_quantity: item.floating_quantity,
                taken_results: result,
                used_stocks: getUsedStockResult,
                // used_stocks: item.used_stocks,
                is_used_first: item.is_used_first,
                serial_numbers: item.serial_numbers.map((serial) => ({
                  id: serial.id,
                  is_used: serial.is_used,
                  value: serial.value,
                  price: serial.price,
                })),

              };
            }),
            total_amount: data.reduce((sum, item) => sum + item.amount, 0),
          };

          console.log(data);
          console.log(transactionData);

          const invoiceData: InvoiceData = {
            id: '', // Assuming ID is generated on the server side or not required here
            reference_number: values.reference_number,
            slip_number: values.slip_number,
            date_of_issuance: values.date_of_issuance.format('YYYY-MM-DD'),
            customer_code: values.customer_code,
            customer_name: values.customer_name,
            customer_address: values.customer_address,
            customer_contact_person: '', // Add the contact person if available
            transactions: data.map((item) => ({
              product_name: item.name,
              is_used_first: item.is_used_first,
              product_id: item.id,
              description: item.description, // Assuming you have description in item
              product_unit: item.product_unit, // Assuming you have unit in item
              cost: item.unit_price,
              floating_quantity: item.floating_quantity,
              serial_numbers: item.serial_numbers,
              quantity: item.quantity,
              stock_location: item.stock_location,
              used_stocks: item.used_stocks,
              amount: item.amount,
            })),
            processor: '', // Add the processor if available
            subtotal: data.reduce((sum, item) => sum + item.amount, 0),
          };


          const post = () => {
            // if (isCheckedFinalize) {
            //   setPrintData(invoiceData);
            //   setPrintAuto(true);
            // }

            axiosClient
              .post('/ie_transactions', transactionData)
              .then((response) => {
                message.success('Transaction posted successfully!');

                form.resetFields([
                  'slip_number',
                  'customer_code',
                  'customer_name',
                  'customer_address',
                  'product_name',
                  'product_unit',
                  'product_quantity',
                  'product_unit_price',
                  'product_amount',
                  'product_description',
                  'serial_number',
                  'floating_quantity',
                  'product_available_stock',
                ]);
                setData(initialData);
                setProductOptions([]);
                setProductId(0);
                setCustomerId(0);
                setCustomerDisplay('');
                setEditKey(null);
                selectedProductRef.current = null;
                onRefreshTransactions();
                setError('');
                if (isCheckedFinalize) {
                  setPrintData(invoiceData);
                  setPrintAuto(true);
                }
              })
              .catch((error) => {
                console.error('Failed to post transaction:', error);
                message.error('Failed to post transaction. Please try again.');
              });
          };

          showConfirmation(
            post,
            'Post Transaction',
            <>
              <GridItem data={invoiceData} copy={'Your Copy'} theme={currentTheme} />
            </>,
            1000,
          );
        })
        .catch((error) => {
          console.error('Validation failed:', error);
          message.error('Please fill in all required fields.');
        });
    };
    const clearTransaction = () => {
      showConfirmation(
        () => {
          form.resetFields();
          setData(initialData);
          setCustomerId(0);
          setCustomerDisplay('');
          setEditKey(null);
          selectedProductRef.current = null;
        },
        'Clear Transaction',
        'Are you sure you want to clear this transaction?',
        400,
      );
    };

    const clearTransactionNoConfirm = () => {
      form.resetFields();
      setData(initialData);
      setCustomerId(0);
      setCustomerDisplay('');
      setEditKey(null);
      selectedProductRef.current = null;
    };

    const columns: TableProps<DataType>['columns'] = [
      {
        title: 'Temp ID',
        dataIndex: 'key',
        key: 'key',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Product Code',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Unit',
        dataIndex: 'product_unit',
        key: 'product_unit',
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Unit Price',
        dataIndex: 'unit_price',
        key: 'unit_price',
        render: (_, record) => {
          return formatCurrency(record.unit_price);
        },
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount',
        render: (_, record) => {
          return formatCurrency(record.amount);
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => {
          const notice =
            record.product_has_serial == 1
              ? record.serial_numbers && record.serial_numbers.length > 0
                ? record.serial_numbers[0].notice
                : null
              : record.notice;

          const showButtons = notice == 0 ? false : true;

          console.log(record);

          return (
            <Flex gap="small" wrap>
              {showButtons && (
                <>
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => editProduct(record.key, record.id, _)}
                  >
                    Edit
                  </Button>
                  <Button
                    type="primary"
                    icon={<DeleteOutline />}
                    onClick={() => deleteProduct(record.key)}
                    danger
                  >
                    Delete
                  </Button>
                </>
              )}
              {userlevel === 'encoder' && (
                <Popover
                  content={
                    record.product_has_serial == 1 ? (
                      record.serial_numbers && record.serial_numbers.length > 0 ? (
                        record.serial_numbers[0].notice == 0 ? (
                          'OK'
                        ) : record.serial_numbers[0].notice == 1 ? (
                          <>
                            Stock Mismatch <br />
                            {'Note: ' + record.serial_numbers[0].note}
                          </>
                        ) : record.serial_numbers[0].notice == 2 ? (
                          'Invalid Serial'
                        ) : (
                          'No notice'
                        )
                      ) : (
                        'No notice'
                      )
                    ) : record.notice == 0 ? (
                      'OK'
                    ) : record.notice == 1 ? (
                      <>Stock Mismatch {record.note}</>
                    ) : record.notice == 2 ? (
                      'Invalid Serial'
                    ) : (
                      'No notice'
                    )
                  }
                  title={'Notice'}
                  trigger="hover"
                  placement="right"
                >
                  <Button
                    shape="circle"
                    icon={
                      (
                        record.product_has_serial == 1
                          ? record.serial_numbers &&
                          record.serial_numbers.length > 0 &&
                          record.serial_numbers[0].notice == 0
                          : record.notice == 0
                      ) ? (
                        <CheckCircleOutlineRounded />
                      ) : (
                        <NotificationsOutlined />
                      )
                    }
                    className={`pulse-button notice-${record.product_has_serial == 1
                      ? record.serial_numbers && record.serial_numbers.length > 0
                        ? record.serial_numbers[0].notice == 1 ||
                          record.serial_numbers[0].notice == 2
                          ? 'red'
                          : record.serial_numbers[0].notice == 0
                            ? 'green'
                            : 'orange'
                        : 'orange'
                      : record.notice == 1 || record.notice == 2
                        ? 'red'
                        : record.notice == 0
                          ? 'green'
                          : record.notice == null || record.notice === undefined
                            ? 'orange'
                            : 'default'
                      }`}
                  ></Button>
                </Popover>
              )}
            </Flex>
          );
        },
      },
    ];

    const expandedRowRender = (record: DataType) => (
      <Table
        columns={[
          {
            title: 'Serial Numbers',
            dataIndex: 'serial_numbers',
            key: 'serial_numbers',
            render: (_, { serial_numbers }) => (
              <>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {serial_numbers.map((number, index) => (
                    <Tag key={index} style={{ margin: '4px', minWidth: '50px', maxWidth: '300px' }}>
                      {number.value}
                    </Tag>
                  ))}
                </div>
              </>
            ),
          },
        ]}
        dataSource={[record]}
        pagination={false}
        rowKey="key"
      />
    );

    const multiInputRef = useRef<{
      getTags: () => TagItem[];
      setTags: (tags: TagItem[]) => void;
      clearTags: () => void;
    }>(null);

    const { totalAmount } = calculateTotals();

    const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([]);
    const [customerId, setCustomerId] = useState<number>(0);
    const [customerDisplay, setCustomerDisplay] = useState<string>('');

    const fetchCustomerOptions = async (searchText: string) => {
      try {
        const response = await axiosClient.get('/customers', {
          params: { globalFilter: searchText, per_page: 10 },
        });
        const customers = response.data.data.map((customer: Customer) => ({
          value: customer.id,
          label: `${customer.code} - ${customer.name}`,
          customer,
        }));
        setCustomerOptions(customers);
      } catch (error) {
        console.error('Failed to fetch customer options:', error);
      }
    };

    const debouncedFetchCustomerOptions = useCallback(
      _.debounce((searchText: string) => {
        fetchCustomerOptions(searchText);
      }, 300),
      [],
    );

    const onCustomerCodeChange = (searchText: string) => {
      setCustomerDisplay(searchText);
      debouncedFetchCustomerOptions(searchText);
    };

    const [productDisplay, setProductDisplay] = useState<string>('');

    const validateProduct = (_: any, value: any) => {
      if (!productOptions.find((option) => option.product.name === value)) {
        return Promise.reject(new Error('Please select a valid product'));
      }
      return Promise.resolve();
    };

    const validateCustomer = (_: any, value: any) => {
      if (!customerOptions.find((option) => option.label === value)) {
        return Promise.reject(new Error('Please select a valid customer'));
      }
      return Promise.resolve();
    };

    const onDateChange = (date: any, dateString: string | string[]) => {
      form.setFieldsValue({ date_of_issuance: date });
    };

    const updateTransaction = async () => {
      const formValues = form.getFieldsValue();
      if (data.length === 0) {
        message.error('The Temporary Table must have data before updating.');
        return;
      }
      let isValid = true;


      const updatedTransactionData = {
        is_finalized: isCheckedFinalize,
        id: selectedTransaction?.id,
        reference_number: formValues.reference_number,
        slip_number: formValues.slip_number,
        date_of_issuance: formValues.date_of_issuance.format('YYYY-MM-DD'),
        customer_id: customerId,
        customer_name: formValues.customer_name,
        customer_address: formValues.customer_address,
        products: data.map((item) => {
          // const stockLoc = item.stock_location
          //   ? item.stock_location.filter((result: any) => result.quantity != 0)
          //   : [];



          let stockLoc;
          if (item.initial_locations) {
            stockLoc = item.initial_locations ? item.initial_locations.filter((result: any) => result.quantity != 0) : [];

            if (item.stock_location) {
              stockLoc = stockLoc.concat(item.stock_location.filter((result: any) => result.quantity != 0).map((result: any) => ({
                ...result,
                is_from_new: true,
              })));
            }
          }
          else {
            stockLoc = item.stock_location ? item.stock_location.filter((result: any) => result.quantity != 0) : [];
            if (item.initial_locations) {
              stockLoc = stockLoc.concat(item.initial_locations.filter((result: any) => result.quantity != 0).map((result: any) => ({
                ...result,
                is_from_new: true,
              })));
            }
          }

          let remainingQuantity = item.quantity;
          const result = [];
          const getUsedStockResult = [];

          if (item.is_used_first && item.serial_numbers.length == 0) {

            for (const itemDetails of item.used_stocks) {

              if (remainingQuantity <= 0) break;

              let takenUsed = 0;

              if (itemDetails.quantity > 0) {
                if (itemDetails.quantity >= remainingQuantity) {
                  takenUsed = remainingQuantity;
                  remainingQuantity = 0;
                } else {
                  takenUsed = itemDetails.quantity;
                  remainingQuantity -= itemDetails.quantity;
                }
              }

              if (takenUsed > 0) {
                const locationDetails = stockLoc?.find((loc: any) =>
                  loc.location_id === itemDetails.location_id && loc.product_id === itemDetails.product_id
                );

                getUsedStockResult.push({
                  ...itemDetails,
                  quantity: takenUsed
                });

                result.push({
                  ...itemDetails,
                  location: locationDetails?.location,
                  takenUsed,
                  takenNew: 0,
                  takenQuantity: takenUsed,
                });
              }
            }

            if (remainingQuantity > 0) {
              for (const item of stockLoc) {
                if (remainingQuantity <= 0) break;

                let takenNew = 0;

                const new_stock_qty = item.quantity - item.used_quantity;

                if (new_stock_qty > 0) {
                  if (new_stock_qty >= remainingQuantity) {
                    takenNew = remainingQuantity;
                    remainingQuantity = 0;
                  } else {
                    takenNew = new_stock_qty;
                    remainingQuantity -= new_stock_qty;
                  }
                }

                if (takenNew > 0) {
                  result.push({
                    ...item,
                    value: item.unit_price,
                    takenUsed: 0,
                    takenNew,
                    takenQuantity: takenNew,
                  });
                }
              }
            }
          }
          else {
            for (const item of stockLoc) {
              if (remainingQuantity <= 0) break;

              let takenNew = 0;

              const new_stock_qty = item.quantity - item.used_quantity;

              if (new_stock_qty > 0) {
                if (new_stock_qty >= remainingQuantity) {
                  takenNew = remainingQuantity;
                  remainingQuantity = 0;
                } else {
                  takenNew = new_stock_qty;
                  remainingQuantity -= new_stock_qty;
                }
              }

              if (takenNew > 0) {
                result.push({
                  ...item,
                  value: item.unit_price,
                  takenUsed: 0,
                  takenNew,
                  takenQuantity: takenNew,
                });
              }
            }

            if (remainingQuantity > 0 && item.used_stocks) {
              for (const itemDetails of item.used_stocks) {
                if (remainingQuantity <= 0) break;

                let takenUsed = 0;

                if (itemDetails.quantity > 0) {
                  if (itemDetails.quantity >= remainingQuantity) {
                    takenUsed = remainingQuantity;
                    remainingQuantity = 0;
                  } else {
                    takenUsed = itemDetails.quantity;
                    remainingQuantity -= itemDetails.quantity;
                  }
                }


                if (takenUsed > 0) {


                  const locationDetails = stockLoc?.find((loc: any) =>
                    loc.location_id === itemDetails.location_id && loc.product_id === itemDetails.product_id
                  );

                  getUsedStockResult.push({
                    ...itemDetails,
                    quantity: takenUsed
                  });
                  result.push({
                    ...itemDetails,
                    location: locationDetails?.location,
                    takenUsed,
                    takenNew: 0,
                    takenQuantity: takenUsed,
                  });
                }
              }
            }
          }

          // for (const item of stockLoc) {
          //   if (remainingQuantity <= 0) break;

          //   let takenUsed = 0;
          //   const serialsTaken: any = [];

          //   if (item.used_quantity > 0) {
          //     if (item.used_quantity >= remainingQuantity) {
          //       takenUsed = remainingQuantity;
          //       result.push({
          //         ...item,
          //         takenUsed,
          //         takenNew: 0,
          //         takenQuantity: takenUsed,
          //         serial_numbers: serialsTaken,
          //       });
          //       remainingQuantity = 0;
          //     } else {
          //       takenUsed = item.used_quantity;
          //       result.push({
          //         ...item,
          //         takenUsed,
          //         takenNew: 0,
          //         takenQuantity: takenUsed,
          //         serial_numbers: serialsTaken,
          //       });
          //       remainingQuantity -= takenUsed;
          //     }
          //   }
          // }

          // for (const item of stockLoc) {
          //   if (remainingQuantity <= 0) break;

          //   let takenNew = 0;
          //   const serialsTaken: any = [];

          //   if (item.quantity > 0) {
          //     if (item.quantity >= remainingQuantity) {
          //       takenNew = remainingQuantity;
          //       result.push({
          //         ...item,
          //         takenUsed: 0,
          //         takenNew,
          //         takenQuantity: takenNew,
          //         serial_numbers: serialsTaken,
          //       });
          //       remainingQuantity = 0;
          //     } else {
          //       takenNew = item.quantity;
          //       result.push({
          //         ...item,
          //         takenUsed: 0,
          //         takenNew,
          //         takenQuantity: takenNew,
          //         serial_numbers: serialsTaken,
          //       });
          //       remainingQuantity -= takenNew;
          //     }
          //   }
          // }


          if (item.product_has_serial == 0) {
            if (isCheckedFinalize) {
              if (item.notice !== 0) {
                isValid = false;
                setError("You can't finalize this issuance when it's not yet checked");
                return;
              }
            }
          }

          return {
            id: item.id,
            name: item.name,
            quantity: item.quantity,
            unit_price: item.unit_price,
            amount: item.amount,
            taken_results: result,
            is_used_first: item.is_used_first,
            stock_location: item.stock_location,
            used_stocks: getUsedStockResult,
            // used_stocks: item.used_stocks,
            serial_numbers: item.serial_numbers.map((serial) => {
              if (serial.notice !== 0) {
                if (isCheckedFinalize) {
                  isValid = false;
                  setError("You can't finalize this issuance when it's not yet checked");
                  return;
                }
              }
              return {
                id: serial.serial_number_id,
                product_id: item.id,
                value: serial.value,
                price: serial.price,
              };
            }),
          };
        }),
        total_amount: data.reduce((sum, item) => sum + item.amount, 0),
      };

      const invoiceData: InvoiceData = {
        id: '', // Assuming ID is generated on the server side or not required here
        reference_number: formValues.reference_number,
        slip_number: formValues.slip_number,
        date_of_issuance: formValues.date_of_issuance.format('YYYY-MM-DD'),
        customer_code: formValues.customer_code,
        customer_name: formValues.customer_name,
        customer_address: formValues.customer_address,
        customer_contact_person: '', // Add the contact person if available
        transactions: data.map((item) => ({
          product_name: item.name,
          is_used_first: item.is_used_first,
          description: item.description, // Assuming you have description in item
          product_id: item.id,
          product_unit: item.product_unit, // Assuming you have unit in item
          cost: item.unit_price,
          quantity: item.quantity,
          floating_quantity: item.floating_quantity,
          serial_numbers: item.serial_numbers,
          stock_location: item.stock_location,
          used_stocks: item.used_stocks,
          amount: item.amount,
        })),
        processor: '', // Add the processor if available
        subtotal: data.reduce((sum, item) => sum + item.amount, 0),
      };

      // Perform manual validation for required fields
      const requiredFields = [
        'slip_number',
        'date_of_issuance',
        'customer_name',
        'customer_address',
      ];
      const missingFields = requiredFields.filter((field) => !formValues[field]);

      if (missingFields.length > 0) {
        message.error(`Please fill in the following required fields: ${missingFields.join(', ')}`);
        return;
      }

      if (!isValid) {
        return;
      }
      showConfirmation(
        () => {
          console.log('Transaction confirmed: ', updatedTransactionData);

          // setError('');
          // if (isCheckedFinalize) {
          //   setPrintData(invoiceData);
          //   setPrintAuto(true);
          // }


          // Uncomment the following lines when ready to send the update to the server

          axiosClient
            .put(`/ie_transactions/${selectedTransaction?.id}`, updatedTransactionData)
            .then((response) => {
              message.success('Transaction updated successfully!');

              console.log(response);

              clearTransactionNoConfirm();
              onRefreshTransactions();
              setProductOptions([]);

              setError('');

              if (isCheckedFinalize) {
                setPrintData(invoiceData);
                setPrintAuto(true);
              }
            })
            .catch((error) => {
              console.error('Failed to update transaction:', error);
              message.error('Failed to update transaction. Please try again.');
            });
        },
        'Update Transaction',
        'Are you sure you want to update this transaction?',
        400,
      );
    };

    function onChangeFinalize(e: any) {
      const checked = e.target.checked;
      setIsCheckedFinalize(checked);
    }

    const { Title, Paragraph, Text, Link } = TypographyAnt;

    const customizer = useSelector((state: AppState) => state.customizer);
    const currentTheme = customizer.activeMode;

    const [isDarkMode, setIsDarkMode] = useState(false);
    const { defaultAlgorithm, darkAlgorithm } = theme;
    useEffect(() => {
      setIsDarkMode(currentTheme === 'dark');
    }, [currentTheme]);


    const [stocksLocation, setStocksLocation] = useState([]);
    const [usedStocks, setUsedStocks] = useState([]);
    const getProductLocations = async (productId: any) => {
      const response = await axiosClient.get(`/stocks_location/product/${productId}`);

      setStocksLocation(response.data.stock_location);
      setUsedStocks(response.data.used_stocks);

    };

    useEffect(() => {
      if (printAuto == true) {
        const printButton = document.getElementById('print-button');
        if (printButton) {
          printButton.click();
          setPrintAuto(false);
        }
      }
    }, [printAuto]);

    const [isUsedFirst, setIsUsedFirst] = useState(true);

    const handleIsUsedFirst = (event: any) => {
      setIsUsedFirst(event.target.checked);
      if (event.target.checked) {
        form.setFieldValue('is_used_first', 1);
      }
      else {
        form.setFieldValue('is_used_first', 0);
      }
    }


    const [activeStep, setActiveStep] = useState<number>(0);

    // Function to handle step change when a label is clicked
    const handleStepClick = (index: number) => () => {
      setActiveStep(index);
    };

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
      setActiveStep(0);
    };

    const steps = ['Transaction Detail', 'Product Details'];

    return (
      <ConfigProvider
        theme={{
          algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        }}
      >

        <Grid
          item
          xs={12}
          md={9}
          ref={componentRef}
          sx={{ display: 'none', displayPrint: 'block' }}
        >
          <Box>
            <PrintItem data={printData} copy={'Receiver Copy'} theme={currentTheme} />
          </Box>
          <div className="pageBreak"></div>
          <Box>
            <PrintItem data={printData} copy={'Encoder Copy'} theme={currentTheme} />
          </Box>
          <div className="pageBreak"></div>
          <Box>
            <PrintItem data={printData} copy={'Warehouse Copy'} theme={currentTheme} />
          </Box>
          <div className="pageBreak"></div>
          <Box>
            <PrintItem data={printData} copy={'Checker Copy'} theme={currentTheme} />
          </Box>
          <div className="pageBreak"></div>
          <Box>
            <PrintItem data={printData} copy={'Accounting Copy'} theme={currentTheme} />
          </Box>
        </Grid>

        <ReactToPrint
          trigger={() => (
            <button id="print-button" style={{ display: 'none' }}>
              Print
            </button>
          )}
          content={() => componentRef.current}
          pageStyle={`
          @media print {
            body {
              -webkit-print-color-adjust: exact;
              margin: 20mm;
              padding: 50px;
            }
            @page {
              margin: 20mm;
            }
            .printTable th,
            .printTable td {
              padding: 2px;
            }
            header, footer {
              display: none;
            }
            .pageBreak {
              page-break-after: always;
            }
            .fontSize8pt {
              font-size: 8pt !important;
            }
            tbody tr:not(:last-child) td {
              border-bottom: 1px solid #acacac;
            }
            .printable-logo {
              fill: #000000 !important; /* Ensure the logo prints in black */
            }
          }
        `}
        />

        <Form layout="vertical" form={form} onValuesChange={onValuesChange}>

          <Row gutter={16}>
            <Col sm={24} md={12} lg={8}>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step
                      key={label}
                      onClick={handleStepClick(index)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Paper elevation={3} sx={{ padding: 3, marginTop: 3, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: 'none', backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff', color: isDarkMode ? '#ffffff' : '#000000', }}>
                  <Row gutter={8} style={{ display: activeStep === 1 ? 'none' : 'flex' }}>

                    <Col span={24}>
                      <div style={{
                        borderBottom: '1px solid #e8e8e8',
                        paddingBottom: '8px',
                        marginBottom: '16px',
                        fontWeight: 'normal',
                        fontSize: '18px',
                        color: isDarkMode ? '#ffffff' : '#606060'
                      }}>
                        <span style={{ color: isDarkMode ? '#ffffff' : '#606060' }}>Transaction Details</span>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-26px' }}>
                          <Flex justify="end">
                            <Checkbox
                              onChange={onChangeFinalize}
                              checked={isCheckedFinalize}
                              style={{ fontSize: '16px', fontWeight: 'bold' }}
                            >
                              Finalize Issuance
                            </Checkbox>
                          </Flex>
                          {error && (
                            <Alert variant="filled" severity="error" sx={{ alignItems: 'center' }}>
                              {error}
                            </Alert>
                          )}
                        </div>
                      </div>
                    </Col>


                    <Col xs={24} sm={24} lg={8}>
                      <Form.Item
                        label={
                          <CustomFormLabel htmlFor="reference_number">Reference Number</CustomFormLabel>
                        }
                        name="reference_number"
                        rules={[{ required: false, message: 'Please enter the reference number' }]}
                      >
                        <Input
                          id="reference_number"
                          placeholder="Reference Number is system generated"
                          disabled
                          style={{ fontWeight: '600' }}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} lg={9}>
                      <Form.Item
                        label={<CustomFormLabel htmlFor="slip_number">Slip Number</CustomFormLabel>}
                        name="slip_number"
                        rules={[{ required: true, message: 'Please enter the slip number' }]}
                      >
                        <Input id="slip_number" placeholder="Enter Slip Number" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} lg={7}>
                      <Form.Item
                        label={
                          <CustomFormLabel htmlFor="date_of_issuance">Date of Issuance</CustomFormLabel>
                        }
                        name="date_of_issuance"
                        rules={[{ required: true, message: 'Please enter the date of Issuance' }]}
                      >
                        <ConfigProvider locale={locale}>
                          <DatePicker
                            disabled
                            format="YYYY-MM-DD"
                            onChange={onDateChange}
                            defaultValue={dayjs()}
                          />
                        </ConfigProvider>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} lg={24}>
                      <CustomFormLabel>Customer Details</CustomFormLabel>
                      <Row gutter={16} style={{ paddingTop: '8px' }}>
                        <Col xs={24} sm={12} lg={9}>
                          <Form.Item
                            name="customer_code"
                            rules={[
                              { required: true, message: 'Please enter the customer code' },
                              { validator: validateCustomer },
                            ]}
                          >
                            <AutoComplete
                              id="customer_code"
                              value={customerDisplay}
                              options={customerOptions.map((option) => ({
                                value: option.label,
                                label: option.label,
                              }))}
                              onSearch={onCustomerCodeChange}
                              onSelect={(label: string, option: any) => {
                                const selectedCustomer = customerOptions.find(
                                  (opt) => opt.label === label,
                                )?.customer;
                                if (selectedCustomer) {
                                  setCustomerId(selectedCustomer.id);
                                  form.setFieldsValue({
                                    customer_name: selectedCustomer.name,
                                    customer_address: selectedCustomer.address,
                                  });
                                  setCustomerDisplay(label);
                                }
                              }}
                              onChange={(value: string) => {
                                setCustomerDisplay(value);
                                setCustomerId(0);
                              }}
                              placeholder="Search Customer [code/name]"
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={7}>
                          <Form.Item name="customer_name">
                            <Input
                              id="customer_name"
                              placeholder="Customer Name Here"
                              disabled
                              style={{ fontWeight: '600' }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={8}>
                          <Form.Item name="customer_address">
                            <Input
                              id="customer_address"
                              placeholder="Customer Address Here"
                              disabled
                              style={{ fontWeight: '600' }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row gutter={8} style={{ display: activeStep === 0 ? 'none' : 'flex' }}>

                    <Col span={24}>
                      <div style={{
                        borderBottom: '1px solid #e8e8e8',
                        paddingBottom: '8px',
                        marginBottom: '16px',
                        fontWeight: 'normal',
                        fontSize: '18px',
                        color: '#606060'
                      }}>
                        <span style={{ color: isDarkMode ? '#ffffff' : '#606060' }}>Product Details</span>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-30px' }}>
                          <Button style={{ marginRight: '8px' }} onClick={addOrUpdateProduct}>
                            {editKey ? 'Update Product' : 'Add Product'}
                          </Button>
                          <Button
                            onClick={() =>
                              showConfirmation(
                                () => {
                                  form.resetFields([
                                    'product_name',
                                    'product_unit',
                                    'product_quantity',
                                    'product_unit_price',
                                    'product_amount',
                                    'product_description',
                                    'serial_number',
                                  ]);
                                  multiInputRef.current?.clearTags();
                                  setData([]);
                                  setEditKey(null);
                                },
                                'Clear Table',
                                'Are you sure you want to clear the table?',
                                400,
                              )
                            }
                            danger
                          >
                            Clear Table
                          </Button>
                        </div>
                      </div>
                    </Col>



                    <Col xs={24} sm={24} lg={24}>
                      <Row gutter={16}>
                        <Col xs={24} sm={12} lg={9}>
                          <Form.Item
                            label={<CustomFormLabel htmlFor="product_name">Product Name</CustomFormLabel>}
                            name="product_name"
                            rules={[
                              { required: true, message: 'Please enter the product code' },
                              { validator: validateProduct },
                            ]}
                          >
                            <AutoComplete
                              value={productDisplay}

                              options={productOptions.map((option) => {

                                return {
                                  value: option.product.name,
                                  label:
                                    (
                                      <div>
                                        {option.product.name} [Model: {option.product.model}]
                                        [SKU: {option.product.sku}]
                                        [Brand: {option.product.brand}]
                                        {option.product.is_adjusting == 1 && <Chip
                                          label="Adjusting"
                                          color='info'
                                          size="small"
                                          sx={{
                                            ml: 1,
                                            fontSize: '0.8rem',
                                            height: '22px',
                                            minWidth: '50px',
                                          }}
                                        />}
                                      </div>
                                    )
                                }
                              }
                              )}
                              onSelect={(value: string, option: any) => {
                                const selectedProduct = productOptions.find(
                                  (opt) => opt.product.name === value,
                                )?.product;

                                if (selectedProduct) {
                                  setProductId(selectedProduct.id);
                                  let subtractThis = 0;
                                  if (selectedTransaction) {
                                    const productData = selectedTransaction.transactions.stocks.find(
                                      (item) => item.product_id === selectedProduct.id,
                                    );

                                    subtractThis = productData ? productData.quantity : 0;
                                  }
                                  form.setFieldsValue({
                                    product_name: selectedProduct.name,
                                    product_description: selectedProduct.description,
                                    floating_quantity: selectedProduct.floating_quantity - subtractThis,
                                    product_unit: selectedProduct.product_unit,
                                    product_unit_price: selectedProduct.product_unit_price,
                                  });
                                  setSerialNumberTextDisabled(selectedProduct.has_serial === 0);
                                  setProductDisplay(selectedProduct.name);

                                  getProductLocations(selectedProduct.id);
                                }
                              }}
                              onChange={(value: string) => {
                                setProductDisplay(value);
                                setProductId(0);
                              }}
                              onSearch={onProductCodeChange}
                              placeholder="Search Product [name/description/sku/part number]"
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={9}>
                          <Form.Item
                            label={<CustomFormLabel htmlFor="product_quantity">Quantity</CustomFormLabel>}
                            name="product_quantity"
                            rules={[
                              { required: true, message: 'Please enter the quantity' },
                              { validator: validateQuantity },
                            ]}
                          >
                            <InputNumber
                              id="product_quantity"
                              placeholder="0"
                              formatter={(value) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => Number(value?.replace(/\$\s?|(,*)/g, ''))}
                              controls={false}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={6}>
                          <Form.Item
                            label={
                              <CustomFormLabel htmlFor="product_available_stock">
                                Total Stock
                              </CustomFormLabel>
                            }
                            name="product_available_stock"
                            rules={[{ required: false, message: 'OK' }]}
                          >
                            <Input id="product_available_stock" placeholder="0" disabled />
                          </Form.Item>
                        </Col>


                        <Col xs={24} sm={12} lg={6}>
                          <Form.Item
                            label={
                              <CustomFormLabel htmlFor="floating_quantity">Floating Stock</CustomFormLabel>
                            }
                            name="floating_quantity"
                            rules={[{ required: false, message: 'OK' }]}
                          >
                            <Input id="floating_quantity" placeholder="0" disabled />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={6}>
                          <Form.Item
                            label={<CustomFormLabel htmlFor="product_unit">Unit</CustomFormLabel>}
                            name="product_unit"
                            rules={[{ required: true, message: 'Please enter the unit' }]}
                          >
                            <Input id="product_unit" placeholder="Unit [KG/PCS/M]" disabled />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={6}>
                          <Form.Item
                            label={
                              <CustomFormLabel htmlFor="product_unit_price">Unit Price</CustomFormLabel>
                            }
                            name="product_unit_price"
                            rules={[{ required: true, message: 'Please enter the unit price' }]}
                          >
                            <Input id="product_unit_price" disabled placeholder="0" />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={6}>
                          <Form.Item
                            label={<CustomFormLabel htmlFor="product_amount">Amount</CustomFormLabel>}
                            name="product_amount"
                            rules={[{ required: false, message: 'Please enter the amount' }]}
                          >
                            <Input id="product_amount" placeholder="0" disabled />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={24}>
                          <Form.Item
                            name="is_used_first"
                            initialValue={1}
                            rules={[{ required: false }]}
                          >
                            <Checkbox onChange={handleIsUsedFirst} checked={isUsedFirst}>Is Used First</Checkbox>
                          </Form.Item>
                        </Col>

                        <Col xs={14}>

                          {/* <Autocomplete
                            multiple
                            id="product_id"
                            options={productSerialNumber}
                            getOptionLabel={(option) => option.serial_number}
                            disabled={serialNumberTextDisabled}
                            fullWidth
                            onChange={(_, newValue) => {

                              setSelectedSerialNumber(newValue);

                            }}
                            noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                            isOptionEqualToValue={(option, value) => option === value}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // error={formik.touched.product_id && Boolean(formik.errors.product_id)}
                                // helperText={formik.touched.product_id && formik.errors.product_id}
                                onChange={(e: any) => autocompleteSearchChange(e, 'products')}
                                variant="standard"
                                placeholder="Serial Number"
                                aria-label="Serial Number"
                              />
                            )}
                          /> */}


                          <Form.Item
                            label={<CustomFormLabel htmlFor="serial_number">Serial Number</CustomFormLabel>}
                            name="serial_number"
                            rules={[{ required: false, message: 'Please enter the serial number' }]}
                          >


                            <CustomMultiInput
                              productId={productId}
                              maximumTags={maximumTags}
                              disabled={serialNumberTextDisabled}
                              ref={multiInputRef}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={10}>
                          <Form.Item
                            label={
                              <CustomFormLabel htmlFor="product_description">
                                Product Description
                              </CustomFormLabel>
                            }
                            name="product_description"
                            rules={[{ required: true, message: 'Please enter the product description' }]}
                          >
                            <Input
                              id="product_description"
                              placeholder="Product description will be displayed here..."
                              disabled
                            />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Flex justify="end">
                            <Checkbox
                              onChange={onChangeFinalize}
                              checked={isCheckedFinalize}
                              style={{ marginTop: '10px', fontSize: '16px', fontWeight: 'bold' }}
                            >
                              Finalize Issuance
                            </Checkbox>
                          </Flex>
                        </Col>

                      </Row>
                    </Col>
                  </Row>

                  {/* {activeStep === steps.length && (
                    <Box>
                      <Typography variant="h6">All steps completed - you're finished!</Typography>
                      <Button onClick={handleReset}>
                        Reset
                      </Button>
                    </Box>
                  )} */}
                </Paper>

              </Box>
            </Col>


            <Col sm={24} md={12} lg={16}>
              <Table
                style={{
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  border: 'none'
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                expandable={{
                  expandedRowRender: (record) => {
                    console.log(record);
                    const serialNumbers = record.serial_numbers;
                    let stockLoc;
                    if (record.initial_locations) {
                      stockLoc = record.initial_locations ? record.initial_locations.filter((result: any) => result.quantity != 0) : [];

                      if (record.stock_location) {
                        console.log("Has initial");
                        stockLoc = stockLoc.concat(record.stock_location.filter((result: any) => result.quantity != 0));
                      }
                    }
                    else {
                      stockLoc = record.stock_location ? record.stock_location.filter((result: any) => result.quantity != 0) : [];
                      if (record.initial_locations) {
                        console.log("Has initial");
                        stockLoc = stockLoc.concat(record.initial_locations.filter((result: any) => result.quantity != 0));
                      }
                    }

                    let remainingQuantity = record.quantity;

                    const result = [];
                    const noLocationResult = [];

                    const uniqueMap: any = new Map();

                    console.log(record.serial_numbers);

                    if (record.serial_numbers.length > 0) {
                      record.serial_numbers.forEach((item: any) => {
                        // Generate a key based on the unique properties
                        const key = `${item.product_id}-${item.floor}-${item.divider}-${item.price}-${item.location_id}-${item.is_used}`;

                        if (uniqueMap.has(key)) {
                          // If the item exists in the map, increment its quantity
                          uniqueMap.get(key).quantity++;
                        } else {
                          // If it's a new unique item, add it to the map with a quantity of 1
                          uniqueMap.set(key, { ...item, quantity: 1 });
                        }
                      });
                      const uniqueItems: any = Array.from(uniqueMap.values());
                      for (const item of uniqueItems) {

                        const location = stockLoc?.find((loc: any) =>
                          loc.location_id === item.location_id && loc.product_id === item.product_id
                        );


                        result.push({
                          id: item.id,
                          floor: item.floor,
                          divider: item.divider,
                          product_id: item.product_id,
                          location_id: item.location_id,
                          takenNew: item.is_used == 0 ? item.quantity : 0,
                          takenQuantity: item.quantity,
                          takenUsed: item.is_used == 1 ? item.quantity : 0,
                          used_quantity: item.is_used == 1 ? item.quantity : 0,
                          value: item.price,
                          quantity: item.quantity,
                          location: location?.location,
                        });

                      }
                    }
                    else {

                      if (record.is_used_first && record.serial_numbers.length == 0) {
                        for (const item of record.used_stocks) {

                          console.log(item);

                          if (remainingQuantity <= 0) break;

                          let takenUsed = 0;

                          if (item.quantity > 0) {
                            if (item.quantity >= remainingQuantity) {
                              takenUsed = remainingQuantity;
                              remainingQuantity = 0;
                            } else {
                              takenUsed = item.quantity;
                              remainingQuantity -= item.quantity;
                            }
                          }

                          if (takenUsed > 0) {
                            const locationDetails = stockLoc?.find((loc: any) =>
                              loc.location_id === item.location_id && loc.product_id === item.product_id
                            );

                            result.push({
                              ...item,
                              location: locationDetails?.location,
                              takenUsed,
                              takenNew: 0,
                              takenQuantity: takenUsed,
                            });
                          }
                        }
                        if (remainingQuantity > 0) {
                          for (const item of stockLoc) {
                            if (remainingQuantity <= 0) break;

                            let takenNew = 0;

                            const new_stock_qty = item.quantity - item.used_quantity;

                            if (new_stock_qty > 0) {
                              if (new_stock_qty >= remainingQuantity) {
                                takenNew = remainingQuantity;
                                remainingQuantity = 0;
                              } else {
                                takenNew = new_stock_qty;
                                remainingQuantity -= new_stock_qty;
                              }
                            }

                            if (takenNew > 0) {
                              result.push({
                                ...item,
                                value: record.unit_price,
                                takenUsed: 0,
                                takenNew,
                                takenQuantity: takenNew,
                              });
                            }
                          }
                        }



                        // DO NOT DELETE THIS CODEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE


                        // for (const item of stockLoc) {
                        //   if (remainingQuantity <= 0) break;

                        //   let takenUsed = 0;

                        //   if (item.used_quantity > 0) {
                        //     if (item.used_quantity >= remainingQuantity) {
                        //       takenUsed = remainingQuantity;
                        //       remainingQuantity = 0;
                        //     } else {
                        //       takenUsed = item.used_quantity;
                        //       remainingQuantity -= item.used_quantity;
                        //     }
                        //   }

                        //   if (takenUsed > 0) {

                        //     result.push({
                        //       ...item,
                        //       takenUsed,
                        //       takenNew: 0,
                        //       takenQuantity: takenUsed,
                        //     });
                        //   }
                        // }
                      }
                      else {


                        for (const item of stockLoc) {
                          if (remainingQuantity <= 0) break;

                          console.log(item);
                          let takenNew = 0;

                          const new_stock_qty = item.quantity - item.used_quantity;

                          if (new_stock_qty > 0) {
                            if (new_stock_qty >= remainingQuantity) {
                              takenNew = remainingQuantity;
                              remainingQuantity = 0;
                            } else {
                              takenNew = new_stock_qty;
                              remainingQuantity -= new_stock_qty;
                            }
                          }

                          if (takenNew > 0) {
                            result.push({
                              ...item,
                              value: record.unit_price,
                              takenUsed: 0,
                              takenNew,
                              takenQuantity: takenNew,
                            });
                          }
                        }

                        if (remainingQuantity > 0 && record.used_stocks) {
                          for (const item of record.used_stocks) {
                            if (remainingQuantity <= 0) break;

                            let takenUsed = 0;

                            if (item.quantity > 0) {
                              if (item.quantity >= remainingQuantity) {
                                takenUsed = remainingQuantity;
                                remainingQuantity = 0;
                              } else {
                                takenUsed = item.quantity;
                                remainingQuantity -= item.quantity;
                              }
                            }


                            if (takenUsed > 0) {


                              const locationDetails = stockLoc?.find((loc: any) =>
                                loc.location_id === item.location_id && loc.product_id === item.product_id
                              );

                              result.push({
                                ...item,
                                location: locationDetails?.location,
                                takenUsed,
                                takenNew: 0,
                                takenQuantity: takenUsed,
                              });
                            }
                          }
                        }
                      }

                      if (remainingQuantity > 0) {

                        console.log('Not enough stock available');
                      }
                    }

                    console.log(result);


                    return (
                      <>
                        {result.map((item, index) => {

                          return (
                            <Typography variant="h6" key={index}>
                              Take {item.takenQuantity} {serialNumbers.length > 0 ? '' : (item.takenNew > 0 ? 'new' : 'used')}  stocks in {item.location?.name}
                              {item.divider} Floor {item.floor} {formatCurrency(item.value)}
                            </Typography>)
                        })}

                        {remainingQuantity > 0 &&

                          <Typography variant="h6">
                            The remaining {remainingQuantity} stock has no location record.
                          </Typography>

                        }

                        {serialNumbers.length > 0 && (
                          <>
                            <Typography variant="h6" mt={3}>
                              Serial Numbers
                            </Typography>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                              {serialNumbers.map((number, index) => (
                                <Space size="large" key={index} style={{ margin: '4px' }}>
                                  <Badge dot status={number.is_used ? 'warning' : 'success'} title={number.is_used ? 'Used' : 'New'}>
                                    <Tag
                                      style={{ margin: '0px', minWidth: '50px', maxWidth: '300px' }}
                                    >
                                      {number.value}
                                    </Tag>
                                  </Badge>
                                </Space>
                              ))}
                            </div>
                          </>
                        )}
                      </>
                    );
                  },
                  rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
                footer={() => (
                  <div style={{ textAlign: 'right', paddingRight: '16px' }}>
                    <b>Total Amount:</b> ₱{totalAmount}
                  </div>
                )}
              />
            </Col>
          </Row>

        </Form>
      </ConfigProvider>
    );
  },
);

export default IssuanceEntryForm;
