import { RefreshOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table';
import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosClient from 'src/axios_client';
import { LogoDark, LogoLight } from 'src/globals/LogoExports';
import { formatCurrency } from 'src/globals/NumericFormat';
import { formatDate } from 'src/globals/PublicFunctions';
import { AppState } from 'src/store/Store';

type Props = {};

type Quotation = {
  id: number;
  transaction_number: string;
  customer_name: string;
  total_amount: string;
  status: any;
  created_at: any;
  quotations: any[];
};

type QuotationApiResponse = {
  data: Array<Quotation>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

const statusSelect = [
  { label: 'Approved', value: '1' },
  { label: 'Pending', value: '0' },
];
const QuotationTableList = () => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<QuotationApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<QuotationApiResponse>(
          '/quotation_transaction', // Relative URL path
          { params },
        );

        console.log(response.data);

        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },

    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<Quotation>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'DB ID',
        minSize: 10,
        maxSize: 10,
        size: 50,
      },
      {
        accessorKey: 'transaction_number',
        header: 'Transaction Number',
        size: 180,
      },
      {
        accessorKey: 'customer_name',
        header: 'Customer Name',
        size: 180,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        filterVariant: 'select',
        filterSelectOptions: statusSelect,
        size: 20,
        Cell: ({ row }) => {
          const status = row.original.status;
          const statusText = status == 0 ? 'Pending' : 'Accepted';

          return (
            <Chip
              label={statusText}
              color={status == 0 ? 'info' : 'success'}
              size="small"
              sx={{
                ml: 1,
                fontSize: '0.8rem',
                height: '22px',
                minWidth: '50px',
              }}
            />
          );
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Date Quoted',
        size: 10,
      },

      // {
      //   accessorKey: 'actions',
      //   header: 'Actions',
      //   size: 120,
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   Cell: ({ row }) => (
      //     <Grid container spacing={2}>
      //       <Grid item>
      //         <Button onClick={() => console.log("Edit clicked")} variant="outlined">
      //           <IconEdit />
      //         </Button>
      //       </Grid>
      //       <Grid item>
      //         <Button onClick={() => console.log("Delete clicked")} variant="outlined">
      //           <IconTrash />
      //         </Button>
      //       </Grid>
      //     </Grid>
      //   ),
      // },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      columnPinning: {
        left: ['mrt-row-expand'],
      },
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.info(row);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderDetailPanel: ({ row }) => {
      return (
        <Container>
          <Card elevation={6}>
            <QuotationModalReceipt
              data={row.original.quotations}
              copy={'Customer copy'}
              customer={row.original.customer_name}
              transaction_id={row.original.transaction_number}
            />
          </Card>
        </Container>
      );
    },
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });
  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

interface GridItemProps {
  data: any;
  copy: any;
  customer: any;
  transaction_id: any;
}
const QuotationModalReceipt: FC<GridItemProps> = ({ data, copy, customer, transaction_id }) => {
  // const theme = useTheme();

  console.log(data);
  const customizer = useSelector((state: AppState) => state.customizer);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          {customizer.activeMode === 'dark' ? (
            <LogoLight height={'auto'} />
          ) : (
            <LogoDark height={'auto'} />
          )}
          {/* <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
              <RECEIPT
                style={{
                  fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
                }}
                className="printable-logo"
              />
            </Box> */}
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {copy ?? ''}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            INVOICE #{transaction_id ?? ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data?.reference_number} <br />
            Quoted Date: {formatDate(data[0]?.created_at)}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {customer ?? ''}
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>QUANTITY</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>COST</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>AMOUNT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 &&
            data?.map((transaction: any, index: any) => {
              console.log(transaction);
              return (
                <TableRow key={transaction?.product_id}>
                  <TableCell sx={{ py: 1 }}>{transaction?.product_name}</TableCell>
                  <TableCell sx={{ py: 1 }}>{transaction?.quotation_quantity}</TableCell>
                  <TableCell sx={{ py: 1 }}>
                    {formatCurrency(transaction?.current_selling)}
                  </TableCell>
                  <TableCell sx={{ py: 1 }}>
                    {formatCurrency(transaction?.quotation_selling)}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      <Divider sx={{ borderColor: '#acacac', display: 'none', displayPrint: 'block' }} />
      <Grid
        container
        columnSpacing={5}
        sx={{ fontSize: { md: '8pt', lg: '12pt' }, display: 'none', displayPrint: 'block' }}
      >
        <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={2.5} className="fontSize8pt">
          Prepared By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columnSpacing={1}>
            <Grid item className="fontSize8pt">
              Checked By:
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={2}>
                <Grid item className="fontSize8pt">
                  Checker
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Hauler Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Customer Authorized Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container columnSpacing={1} justifyContent={'between'}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Received the above goods in full and good condition.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Received By:
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
        mt={1}
      >
        <Divider sx={{ borderColor: 'black' }} />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
        mt={1}
      >
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
              {dayjs().format('MM/DD/YYYY h:mm:ss A')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              User: name of user
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
const queryClient = new QueryClient();

const QuotationList = (props: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <QuotationTableList />
    </QueryClientProvider>
  );
};

export default QuotationList;
