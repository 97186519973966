import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Box } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

// Types
type InventoryAgingSummaryApiResponse = {
  data: Array<InventoryAgingSummary>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type InventoryAgingSummary = {
  'Product ID': number;
  'Product Name': string;
  SKU: string;
  Category: string;
  Unit: string;
  'Stock on Hand': string;
  '0-30 Days': string;
  '31-60 Days': string;
  '61-90 Days': string;
  '90+ Days': string;
};

interface InventoryAgingChartProps {
  data: InventoryAgingSummary[];
  isDarkMode: boolean;
}

const InventoryAgingChart: React.FC<InventoryAgingChartProps> = ({ data, isDarkMode }) => {
  const chartData = useMemo(() => {
    const series = [
      {
        name: '0-30 Days',
        data: data.map((item) => parseInt(item['0-30 Days'])),
      },
      {
        name: '31-60 Days',
        data: data.map((item) => parseInt(item['31-60 Days'])),
      },
      {
        name: '61-90 Days',
        data: data.map((item) => parseInt(item['61-90 Days'])),
      },
      {
        name: '90+ Days',
        data: data.map((item) => parseInt(item['90+ Days'])),
      },
    ];

    const options: ApexOptions = {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: true,
        },
        background: 'transparent',
      },
      theme: {
        mode: isDarkMode ? 'dark' : 'light',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '70%',
        },
      },
      colors: ['#00E396', '#FEB019', '#FF4560', '#775DD0'],
      xaxis: {
        categories: data.map((item) => item['Product Name']),
        labels: {
          style: {
            fontSize: '12px',
          },
          rotate: -45,
          trim: true,
          maxHeight: 100,
        },
      },
      yaxis: {
        title: {
          text: 'Quantity',
        },
        labels: {
          formatter: (value) => value.toLocaleString(),
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
      },
      tooltip: {
        y: {
          formatter: (value) => value.toLocaleString(),
        },
      },
      dataLabels: {
        enabled: false,
      },
    };

    return { series, options };
  }, [data, isDarkMode]);

  return (
    <div className="w-full mt-4">
      <Chart options={chartData.options} series={chartData.series} type="bar" height={350} />
    </div>
  );
};

const InventoryAgingSummaryTable = () => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<InventoryAgingSummaryApiResponse>({
    queryKey: [
      'inventory-aging-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<InventoryAgingSummaryApiResponse>(
          '/aging-summary-report', // Relative URL path
          { params },
        );

        return response.data;
      } catch (error) {
        console.error('Error fetching inventory aging summary:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo<MRT_ColumnDef<InventoryAgingSummary>[]>(
    () => [
      {
        accessorKey: 'Product ID',
        header: 'ID',
        size: 80,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'Product Name',
        header: 'Product Name',
        size: 200,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'SKU',
        header: 'SKU',
        size: 120,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'Category',
        header: 'Category',
        size: 150,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'Unit',
        header: 'Unit',
        size: 80,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'Stock on Hand',
        header: 'Stock on Hand',
        size: 120,
        Cell: ({ cell }) => parseInt(cell.getValue<string>()).toLocaleString(),
        enableColumnFilter: false,
      },
      {
        accessorKey: '0-30 Days',
        header: '0-30 Days',
        size: 120,
        Cell: ({ cell }) => parseInt(cell.getValue<string>()).toLocaleString(),
        enableColumnFilter: false,
      },
      {
        accessorKey: '31-60 Days',
        header: '31-60 Days',
        size: 120,
        Cell: ({ cell }) => parseInt(cell.getValue<string>()).toLocaleString(),
        enableColumnFilter: false,
      },
      {
        accessorKey: '61-90 Days',
        header: '61-90 Days',
        size: 120,
        Cell: ({ cell }) => parseInt(cell.getValue<string>()).toLocaleString(),
        enableColumnFilter: false,
      },
      {
        accessorKey: '90+ Days',
        header: '90+ Days',
        size: 120,
        Cell: ({ cell }) => parseInt(cell.getValue<string>()).toLocaleString(),
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: false, density: 'compact' },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <InventoryAgingChart data={data} isDarkMode={isDarkMode} />
    </>
  );
};

const queryClient = new QueryClient();

const InventoryAgingSummaryView = () => (
  <QueryClientProvider client={queryClient}>
    <InventoryAgingSummaryTable />
  </QueryClientProvider>
);

export default InventoryAgingSummaryView;
