import React from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import CustomersTable from './components/CustomersTable'

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Customers Page',
  },
];

const Customers = () => {

  return (
    <PageContainer title="RKCWMS - Customers Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Customers Page" items={BCrumb} />
      {/* end breadcrumb */}
      <CustomersTable/>
    </PageContainer>
  );
};

export default Customers;
