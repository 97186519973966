import React from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import SalesByProductTable from './SalesByProduct/Table';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Issuances By Product Page',
  },
];

const SalesByProduct = () => {
  return (
    <PageContainer title="RKCWMS - Issuances By Product Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Issuances By Product Page" items={BCrumb} />
      {/* end breadcrumb */}
      <Box>
        <SalesByProductTable />
      </Box>
    </PageContainer>
  );
};

export default SalesByProduct;
