import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import CloudUpload from '@mui/icons-material/CloudUpload';
import axiosClient from 'src/axios_client';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

interface ProductUpdateDialogProps {
  open: boolean;
  onClose: () => void;
  productId: number;
  refreshProductData: () => void;
}

const ProductUpdateDialog: React.FC<ProductUpdateDialogProps> = ({
  open,
  onClose,
  productId,
  refreshProductData,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        // Compression options
        const options = {
          maxSizeMB: 1, // max file size in MB
          maxWidthOrHeight: 800, // max width or height
          useWebWorker: true, // for better performance
        };

        // Compress the selected file
        const compressedFile = await imageCompression(file, options);

        // Create a new File object with the correct type and name
        const newFile = new File([compressedFile], file.name, {
          type: compressedFile.type,
          lastModified: new Date().getTime(),
        });

        setSelectedFile(newFile);
        setUploadError(null);
      } catch (error) {
        setUploadError('Failed to compress the image. Please try again.');
      }
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;
    setIsUploading(true);

    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      await axiosClient.post(`/products/${productId}/image`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      refreshProductData();
      onClose();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage =
          (error.response?.data as { message?: string })?.message ||
          'Failed to upload the image. Please try again.';
        setUploadError(errorMessage);
      } else {
        setUploadError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsUploading(false);
      setSelectedFile(null);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setUploadError(null);
        setSelectedFile(null);
      }}
    >
      <DialogTitle>Update Product Image</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select an image file to update the product image.
        </DialogContentText>
        {uploadError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {uploadError}
          </Alert>
        )}
        <Box sx={{ mt: 2 }}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="image-file-upload"
            type="file"
            onChange={handleFileSelect}
          />
          <label htmlFor="image-file-upload">
            <Button variant="outlined" component="span" startIcon={<CloudUpload />} fullWidth>
              Select File
            </Button>
          </label>
          {selectedFile && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected file: {selectedFile.name}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            setUploadError(null);
            setSelectedFile(null);
          }}
          color="error"
        >
          Cancel
        </Button>
        <Button onClick={handleUpload} disabled={!selectedFile || isUploading} color="primary">
          {isUploading ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductUpdateDialog;
