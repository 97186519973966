import { PlusOutlined } from '@ant-design/icons';
import { CloudCircleOutlined, DeleteOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GetProp, Image, Upload, UploadFile, message } from 'antd';
import { UploadProps } from 'antd/lib';
import type { RcFile } from 'antd/es/upload';
import dayjs from 'dayjs';
import { FormikProps, useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import axiosClient from 'src/axios_client';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import * as Yup from 'yup';
import imageCompression from 'browser-image-compression';

type Props = {
  refetch: () => void;
};

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

interface SupplyCenter {
  id: number;
  name: string;
  description: string;
  api: string;
  is_disabled: boolean;
}

interface ApiResponse {
  data: any;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
}

interface FormValues {
  destination_name: string;
  [key: string]: any; // for other form fields
}

interface AutocompleteOption {
  label: string;
  id: any;
  api: string;
  is_disabled: boolean;
}

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const validationSchema = Yup.object().shape({
  destination_name: Yup.string().required('Please provide destination'),
  department: Yup.string().required('Provide department'),
  project_site: Yup.string().required('Provide project site'),
  purpose: Yup.string().required('Provide purpose'),
  requested_by: Yup.string().required('Enter HRIS ID.'),
  approved_by: Yup.string().required('Select Your Approver'),
});

function IpoRequest() {
  const refetch = () => {
    console.log('Halo');
  };

  return (
    <>
      <IpoRequestForm refetch={refetch} />
    </>
  );
}

function IpoRequestForm({ refetch }: Props) {
  const [textFields, setTextFields] = useState([{ value: '', quantity: 1 }]);
  const [imageOpen, setImageOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleAddIPO = () => {
    setTextFields([...textFields, { value: '', quantity: 1 }]);
  };

  const handleProductRequestChange = (index: any, event: any) => {
    const values = [...textFields];
    values[index].value = event.target.value;
    setTextFields(values);
  };

  const handleProductQuantityRequestChange = (index: any, event: any) => {
    const values = [...textFields];
    values[index].quantity = event.target.value;
    setTextFields(values);
  };
  const [sucess, setSucess] = useState('');
  const [error, setError] = useState('');

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      destination_name: '',
      ipo_number: '',
      department: '',
      project_site: '',
      purpose: '',
      requested_by: '',
      approved_by: '',
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  const handleDeleteProductRequest = (index: any) => {
    const values = [...textFields];
    values.splice(index, 1);
    setTextFields(values);
  };

  const getNonEmptyTextFields = () => {
    return textFields.filter(
      (textField) => textField.value.trim() !== '' && textField.quantity > 0,
    );
  };

  const handleIPOSubmit = async () => {
    const errors = await formik.validateForm();
    formik.setTouched({
      ['destination_name']: true,
      ['ipo_number']: true,
      ['department']: true,
      ['project_site']: true,
      ['purpose']: true,
      ['requested_by']: true,
      ['approved_by']: true,
    });
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      if (getNonEmptyTextFields().length > 0) {
        const formData = new FormData();
        const textFieldData = getNonEmptyTextFields();
        formData.append(`ipo_number`, '1');
        formData.append(`destination_name`, formik.values.destination_name.toString());
        formData.append(`department`, formik.values.department);
        formData.append(`project_site`, formik.values.project_site);
        formData.append(`purpose`, formik.values.purpose.toString());
        formData.append(`requested_by`, formik.values.requested_by);
        formData.append(`approved_by`, formik.values.approved_by.toString());
        textFieldData.forEach((ipo: any, index: any) => {
          Object.keys(ipo).forEach((key) => {
            formData.append(`ipo_list[${index}][${key}]`, ipo[key]);
          });
        });
        if (fileList.length > 0) {
          // The file is already compressed in handleImageChange
          formData.append('ipo_approval', fileList[0].originFileObj as Blob);
        }
        // else {
        //   setError('Please upload proof of approval');
        //   return;
        // }
        setSubmitting(true);
        axiosClient
          .post(`/ipo_transaction`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (response.status == 201) {
              formik.resetForm({
                values: {
                  ...formik.values,
                  destination_name: '',
                  ipo_number: '',
                  purpose: '',
                  requested_by: '',
                  approved_by: '',
                },
              });
              setFileList([]);
              setPreviewImage('');
              setError('');
              setSucess('IPO Submitted Successfully');
              setTextFields([{ value: '', quantity: 1 }]);
              setTimeout(() => {
                setSucess('');
              }, 3000);
              setSubmitting(false);
              refetch();
            }
            console.log(response);
          })
          .catch((error) => {
            setSubmitting(false);
            setError(error.response.data.message);
            console.log(error);
          });
      } else {
        setError('Please check the ipo items');
      }
    }
    console.log(getNonEmptyTextFields());
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleImagePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setImageOpen(true);
  };

  const compressImage = async (file: RcFile): Promise<File> => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      return new File([compressedFile], file.name, { type: compressedFile.type });
    } catch (error) {
      console.error('Error compressing image:', error);
      message.error('Error compressing image. Using original file.');
      return file;
    }
  };

  const handleImageChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    const updatedFileList: UploadFile<any>[] = await Promise.all(
      newFileList.map(async (file) => {
        if (file.originFileObj && file.originFileObj.type.startsWith('image/')) {
          const compressedFile = await compressImage(file.originFileObj);
          return {
            ...file,
            originFileObj: compressedFile,
          } as UploadFile<any>;
        }
        return file;
      }),
    );
    setFileList(updatedFileList);
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const [isLoading, setIsLoading] = useState(true);
  const [supplyCenterOptions, setSupplyCenterOptions] = useState<AutocompleteOption[]>([]);
  const [ipoPurposesOptions, setIpoPurposesOptions] = useState<AutocompleteOption[]>([]);
  const [approverOptions, setApproverOptions] = useState<AutocompleteOption[]>([]);

  const fetchSupplyCenters = useCallback(async (filter = '') => {
    try {
      const params = {
        page: '1',
        per_page: '10',
        filters: JSON.stringify([]), // If needed, replace [] with actual filter data
        sorting: JSON.stringify([]), // Replace [] with actual sorting if necessary
        globalFilter: filter, // Use the filter passed as a parameter
      };

      const response = await axiosClient.get<ApiResponse>('/supply_centers', { params });
      const json = response.data;

      setSupplyCenterOptions(
        json.data.map((center: any) => ({
          label: center.name,
          id: center.id,
          api: center.api,
          is_disabled: center.is_disabled,
        })),
      );
    } catch (error) {
      console.error('Error fetching supply centers:', error);
    }
  }, []);

  const fetchIpoPurposes = useCallback(async (filter = '') => {
    try {
      const params = {
        page: '1',
        per_page: '5',
        filters: JSON.stringify([]), // Modify if filters are needed
        sorting: JSON.stringify([]), // Modify if sorting is required
        globalFilter: filter, // Apply the filter passed as an argument
      };

      const response = await axiosClient.get<ApiResponse>('/ipo_purposes', { params });
      const json = response.data;

      setIpoPurposesOptions(
        json.data.map((purpose: any) => ({
          label: purpose.name,
          id: purpose.id,
        })),
      );
    } catch (error) {
      console.error('Error fetching IPO purposes:', error);
    }
  }, []);

  const fetchApprovers = useCallback(async (filter = '') => {
    try {
      const params = {
        page: '1',
        per_page: '5',
        filters: JSON.stringify([]), // Modify if filters are needed
        sorting: JSON.stringify([]), // Modify if sorting is required
        globalFilter: filter, // Apply the filter passed as an argument
      };

      const response = await axiosClient.get<ApiResponse>('/approvers', { params });
      const json = response.data;

      setApproverOptions(
        json.data.map((approver: any) => ({
          label: approver.name,
          id: approver.id,
        })),
      );
    } catch (error) {
      console.error('Error fetching approvers:', error);
    }
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const userAllDetails = localStorage.getItem('userAllDetails');
        const storedUserDetails = userAllDetails ? JSON.parse(userAllDetails) : null;
        if (storedUserDetails) {
          // Use axiosClient with only the relative URL
          const response = await axiosClient.get(`/customers/${storedUserDetails.is_customer}`);
          const json = response.data;
          formik.setFieldValue('department', json.data.name);
          formik.setFieldValue('project_site', json.data.address);
        }

        // Fetch initial data for autocompletes
        await Promise.all([fetchSupplyCenters(), fetchIpoPurposes(), fetchApprovers()]);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [fetchSupplyCenters, fetchIpoPurposes, fetchApprovers]);

  return (
    <div>
      <Backdrop
        sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isLoading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid item xs={12} display="flex" alignItems="stretch">
            <Card
              elevation={12}
              sx={{ padding: 0, borderColor: (theme: any) => theme.palette.divider }}
            >
              <>
                <CardHeader
                  title={
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h3">RAEL KITZ CORPORATION</Typography>
                      <Typography variant="subtitle2">
                        Centrum Fuel Statio, Brgy. Talibaew, Calasiao, Pangasinan
                      </Typography>
                      <Typography variant="subtitle2">{`Telefax: (075) 653-0008`}</Typography>
                    </Box>
                  }
                />
                <Divider />
              </>
              <CardContent>
                <Grid container columnSpacing={2} rowSpacing={1}>
                  <Grid item xs={12}>
                    {sucess && (
                      <Alert variant="filled" severity="success" sx={{ alignItems: 'center' }}>
                        {sucess}
                      </Alert>
                    )}

                    {error && (
                      <Alert variant="filled" severity="error" sx={{ alignItems: 'center' }}>
                        {error}
                      </Alert>
                    )}
                    <Grid container sx={{ alignItems: 'center' }}>
                      <Grid item xs={8}>
                        <Typography variant="h6">INTERNAL PURCHASE ORDER FORM</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomTextField
                          InputProps={{
                            startAdornment: <InputAdornment position="start">IPO </InputAdornment>,
                          }}
                          sx={{
                            '& .MuiInputBase-root:before': {
                              borderBottom: '1px dashed rgba(0, 0, 0, 0.42)', // Dashed border for unfocused state
                            },
                            '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                              borderBottom: '1px dashed rgba(0, 0, 0, 0.87)', // Dashed border for hover state
                            },
                            '& .MuiInputBase-root:after': {
                              borderBottom: '1px solid rgba(0, 0, 0, 0.87)', // Solid border for focused state
                            },
                          }}
                          value={formik.values.ipo_number}
                          name={'ipo_number'}
                          id={'ipo_number'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.ipo_number && Boolean(formik.errors.ipo_number)}
                          helperText={formik.touched.ipo_number && formik.errors.ipo_number}
                          variant="standard"
                          placeholder="Number"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>TO:</Typography>
                      <Autocomplete<AutocompleteOption, false, false, false>
                        sx={{
                          marginLeft: 1,
                          flex: 1,
                          '& .MuiInputBase-root:before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.42)',
                          },
                          '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.87)',
                          },
                          '& .MuiInputBase-root:after': {
                            borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                          },
                        }}
                        options={supplyCenterOptions}
                        getOptionDisabled={(option) => option.is_disabled}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="standard"
                            size="small"
                            placeholder="Enter Destination"
                            error={
                              formik.touched.destination_name &&
                              Boolean(formik.errors.destination_name)
                            }
                            helperText={
                              formik.touched.destination_name && formik.errors.destination_name
                            }
                          />
                        )}
                        value={
                          supplyCenterOptions.find(
                            (option) => option.id === formik.values.destination_name,
                          ) || null
                        }
                        onChange={(
                          event: React.SyntheticEvent,
                          newValue: AutocompleteOption | null,
                        ) => {
                          formik.setFieldValue('destination_name', newValue ? newValue.id : '');
                        }}
                        onBlur={formik.handleBlur}
                        id="destination_name"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        value={dayjs()}
                        disabled
                        slotProps={{ textField: { size: 'small', variant: 'standard' } }}
                        sx={{ width: '100%' }}
                        views={['year', 'month', 'day']}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>DEPARTMENT:</Typography>
                      <TextField
                        sx={{
                          marginLeft: 1,
                          '& .MuiInputBase-root:before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.42)', // Dashed border for unfocused state
                          },
                          '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.87)', // Dashed border for hover state
                          },
                          '& .MuiInputBase-root:after': {
                            borderBottom: '1px solid rgba(0, 0, 0, 0.87)', // Solid border for focused state
                          },
                        }}
                        required
                        fullWidth
                        variant="standard"
                        placeholder="Enter Department"
                        size="small"
                        value={formik.values.department}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.department && Boolean(formik.errors.department)}
                        helperText={formik.touched.department && formik.errors.department}
                        id="department"
                        disabled={true}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ textWrap: 'nowrap' }}>Project Site:</Typography>
                      <TextField
                        sx={{
                          marginLeft: 1,
                          '& .MuiInputBase-root:before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.42)', // Dashed border for unfocused state
                          },
                          '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.87)', // Dashed border for hover state
                          },
                          '& .MuiInputBase-root:after': {
                            borderBottom: '1px solid rgba(0, 0, 0, 0.87)', // Solid border for focused state
                          },
                        }}
                        required
                        fullWidth
                        variant="standard"
                        size="small"
                        id="project_site"
                        placeholder="Enter Project site"
                        value={formik.values.project_site}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.project_site && Boolean(formik.errors.project_site)}
                        helperText={formik.touched.project_site && formik.errors.project_site}
                        disabled={true}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={8}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>Purpose:</Typography>
                      <Autocomplete<AutocompleteOption, false, false, false>
                        sx={{
                          marginLeft: 1,
                          flex: 1,
                          '& .MuiInputBase-root:before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.42)',
                          },
                          '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.87)',
                          },
                          '& .MuiInputBase-root:after': {
                            borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                          },
                        }}
                        options={ipoPurposesOptions}
                        getOptionDisabled={(option) => option.is_disabled}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="standard"
                            size="small"
                            placeholder="Enter Purpose"
                            error={formik.touched.purpose && Boolean(formik.errors.purpose)}
                            helperText={formik.touched.purpose && formik.errors.purpose}
                          />
                        )}
                        value={
                          ipoPurposesOptions.find(
                            (option) => option.id === formik.values.purpose,
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          formik.setFieldValue('purpose', newValue ? newValue.id : '');
                        }}
                        onInputChange={(event, newInputValue) => {
                          fetchIpoPurposes(newInputValue);
                        }}
                        onBlur={formik.handleBlur}
                        id="purpose"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} mt={{ sx: 0, md: 3 }}>
                    <TableContainer component={Paper} elevation={12}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell width="15%">QTY</TableCell>
                            <TableCell width="75%">Item Name</TableCell>
                            <TableCell width="10%"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {textFields.map((textField: any, index: any) => (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                <TextField
                                  variant="standard"
                                  type="number"
                                  size="small"
                                  placeholder="Enter Quantity"
                                  fullWidth
                                  value={textField.quantity}
                                  onChange={(event) =>
                                    handleProductQuantityRequestChange(index, event)
                                  }
                                  style={{ flex: 1 }}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  size="small"
                                  placeholder="Enter Item Name"
                                  value={textField.value}
                                  onChange={(event) => handleProductRequestChange(index, event)}
                                  style={{ flex: 1 }}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <IconButton onClick={() => handleDeleteProductRequest(index)}>
                                  <DeleteOutline />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12} mt={{ sx: 0, md: 3 }}>
                    <Grid container spacing={5}>
                      <Grid item xs={6} sx={{ textAlign: 'center' }}>
                        <TextField
                          sx={{
                            '& .MuiInputBase-root:before': {
                              borderBottom: '1px dashed rgba(0, 0, 0, 0.42)', // Dashed border for unfocused state
                            },
                            '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                              borderBottom: '1px dashed rgba(0, 0, 0, 0.87)', // Dashed border for hover state
                            },
                            '& .MuiInputBase-root:after': {
                              borderBottom: '1px solid rgba(0, 0, 0, 0.87)', // Solid border for focused state
                            },
                          }}
                          inputProps={{ style: { textAlign: 'center' } }}
                          required
                          fullWidth
                          variant="standard"
                          size="small"
                          id="requested_by"
                          value={formik.values.requested_by}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.requested_by && Boolean(formik.errors.requested_by)}
                          helperText={formik.touched.requested_by && formik.errors.requested_by}
                        />
                        <Typography variant="h6">Requested By</Typography>
                      </Grid>

                      <Grid item xs={6} sx={{ textAlign: 'center' }}>
                        <Autocomplete<AutocompleteOption, false, false, false>
                          sx={{
                            marginLeft: 1,
                            flex: 1,
                            '& .MuiInputBase-root:before': {
                              borderBottom: '1px dashed rgba(0, 0, 0, 0.42)',
                            },
                            '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                              borderBottom: '1px dashed rgba(0, 0, 0, 0.87)',
                            },
                            '& .MuiInputBase-root:after': {
                              borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                            },
                          }}
                          options={approverOptions}
                          getOptionDisabled={(option) => option.is_disabled}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              variant="standard"
                              size="small"
                              error={
                                formik.touched.approved_by && Boolean(formik.errors.approved_by)
                              }
                              helperText={formik.touched.approved_by && formik.errors.approved_by}
                            />
                          )}
                          value={
                            approverOptions.find(
                              (option) => option.id === formik.values.approved_by,
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            formik.setFieldValue('approved_by', newValue ? newValue.id : '');
                          }}
                          onInputChange={(event, newInputValue) => {
                            fetchApprovers(newInputValue);
                          }}
                          onBlur={formik.handleBlur}
                          id="approved_by"
                        />
                        <Typography variant="h6">Approved By</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mt={{ xs: 0, md: 3 }}>
                    <Upload
                      beforeUpload={() => false}
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handleImagePreview}
                      onChange={handleImageChange}
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        alt=""
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                          visible: imageOpen,
                          onVisibleChange: (visible) => setImageOpen(visible),
                          afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}
                    >
                      Note: The empty item fields or the quantity 0 won't be saved in request
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          onClick={handleAddIPO}
                        >
                          Add Item
                        </Button>
                      </Grid>

                      <Grid item xs={6}>
                        <LoadingButton
                          fullWidth
                          loading={isSubmitting}
                          variant="contained"
                          color="secondary"
                          onClick={handleIPOSubmit}
                        >
                          Submit
                        </LoadingButton>
                        {/* <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleIPOSubmit}
                      >
                        Submit
                      </Button> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <p>Total Items: {textFields.length}</p>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export { IpoRequest, IpoRequestForm };
