import React from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import ProductCategoriesTable from './components/ProductCategoriesTable'

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Product Categories Page',
  },
];

const ProductCategories = () => {

  return (
    <PageContainer title="RKCWMS - Product Categories Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="ProductCategories Page" items={BCrumb} />
      {/* end breadcrumb */}
      <ProductCategoriesTable/>
    </PageContainer>
  );
};

export default ProductCategories;
