

function formatDate(inputDateString: any) {
    // Convert string to Date object
    const inputDate = new Date(inputDateString);

    // Format date to desired format
    const options = { year: 'numeric', month: 'short', day: '2-digit' } as Intl.DateTimeFormatOptions;
    const datePart = inputDate.toLocaleDateString('en-US', options);

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    } as Intl.DateTimeFormatOptions;
    const timePart = inputDate.toLocaleTimeString('en-US', timeOptions);

    return `${datePart}`;
}
function formatDateWithTime(inputDateString: any) {
    // Convert string to Date object
    const inputDate = new Date(inputDateString);

    // Format date to desired format
    const options = { year: 'numeric', month: 'short', day: '2-digit' } as Intl.DateTimeFormatOptions;
    const datePart = inputDate.toLocaleDateString('en-US', options);

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    } as Intl.DateTimeFormatOptions;
    const timePart = inputDate.toLocaleTimeString('en-US', timeOptions);

    return `${datePart} ${timePart}`;
}

export { formatDate, formatDateWithTime };