import React from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import IpoPurposesTable from './components/IpoPurposesTable';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Ipo Purposes Page',
  },
];

const IpoPurposes = () => {
  return (
    <PageContainer title="RKCWMS - Ipo Purposes Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Ipo Purposes Page" items={BCrumb} />
      {/* end breadcrumb */}
      <IpoPurposesTable />
    </PageContainer>
  );
};

export default IpoPurposes;
