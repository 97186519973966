import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import PurchaseEntryFormStatic from './components/PurchaseEntryFormStatic';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';
import { useNavigate } from 'react-router-dom';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Purchase Entry View Transaction',
  },
];

const PurchaseEntryViewTransaction: React.FC = () => {
  const { transactionId } = useTransaction();
  const navigate = useNavigate();

  useEffect(() => {
    if (!transactionId) {
      console.warn('No transaction ID found, redirecting to purchase entry');
      navigate('/purchases');
    }
  }, [transactionId, navigate]);

  if (!transactionId) {
    return null; // or show a loading spinner/message
  }

  return (
    <PageContainer title="RKCWMS - Purchase Entry ViewTransaction">
      <Breadcrumb title="Purchase Entry - View Transaction" items={BCrumb} />
      <Box>
        <PurchaseEntryFormStatic transactionId={transactionId} />
      </Box>
    </PageContainer>
  );
};

export default PurchaseEntryViewTransaction;
