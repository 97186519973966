import { FC } from 'react';
import {
  Box,
  Grid,
  Typography as TypographyMUI,
  Table as TableMUI,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
} from '@mui/material';
import { ReactComponent as RECEIPT } from 'src/assets/images/svgs/rkc_wms_dark_receiptS.svg';

export const GridItem: FC<{ data: any }> = ({ data }) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, }}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 4 }}
      >
        <Box>
          <Box sx={{ width: '250px', marginBottom: '5px' }}>
            <RECEIPT
              style={{
                fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
              }}
            />
          </Box>
          <TypographyMUI
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
          >
            RAEL KITZ CORPORATION - WMS IS
          </TypographyMUI>
          <TypographyMUI variant="body2" sx={{ color: 'text.secondary' }}>
            RRF Building San Miguel Calasiao, Pangasinan
          </TypographyMUI>
        </Box>
      </Box>
      <TableMUI className="printTable" sx={{ width: '100%', textAlign: 'left', mb: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>QUANTITY</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>SERIAL NO.</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>BRAND</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>MODEL</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>STATUS</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>REFERENCE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.products.map((product: any, index: number) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }}>{product.name}</TableCell>
              <TableCell sx={{ py: 1 }}>{product.quantity}</TableCell>
              <TableCell sx={{ py: 1 }}>{product.serial_number}</TableCell>
              <TableCell sx={{ py: 1 }}>{product.brand}</TableCell>
              <TableCell sx={{ py: 1 }}>{product.model}</TableCell>
              <TableCell sx={{ py: 1 }}>{product.status === 0 ? 'DAMAGED' : 'GOOD'}</TableCell>
              <TableCell sx={{ py: 1 }}>{product.reference}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableMUI>
      <Box>
        <TypographyMUI variant="body2" sx={{ color: 'text.secondary' }} fontWeight={800}>
          Pickup By: {data.pickup_by}
        </TypographyMUI>
        <TypographyMUI variant="body2" sx={{ color: 'text.secondary' }} fontWeight={800}>
          Destination: {data.destination === 1 ? 'Warehouse - San Miguel Calasiao' : 'Unknown'}
        </TypographyMUI>
        <TypographyMUI variant="body2" sx={{ color: 'text.secondary' }} fontWeight={800}>
          Notes: {data.notes}
        </TypographyMUI>
      </Box>
    </Box>
  );
};