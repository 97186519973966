import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Box, Button } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { Download, Email, PlayArrow, PrintSharp } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import 'src/styles/reports_print.css';
import {
  Table,
  Typography as TypographyAnt,
  Space,
  Image,
  Button as ButtonAnt,
  Tooltip as TooltipAnt,
  ConfigProvider,
  Row,
  Col,
} from 'antd';
import DateSelector from 'src/components/shared/AntDateSelector';
import dayjs, { Dayjs } from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/ProductSalesContext';
import LogoDark from 'src/assets/images/logos/dark-logo.svg';
import LogoLight from 'src/assets/images/logos/light-logo.svg';

const { Title, Text } = TypographyAnt;
type SalesByProductApiResponse = {
  data: Array<SalesByProduct>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type SalesByProduct = {
  id: number;
  name: string;
  sku: string;
  total_quantity_sold: string;
  total_sales: string;
};

const PrintableTable = React.forwardRef<
  HTMLDivElement,
  { data: SalesByProduct[]; columnsPrint: any[]; dateRange: string }
>(({ data, columnsPrint, dateRange }, ref) => {
  const customizer = useSelector((state: AppState) => state.customizer);

  return (
    <div ref={ref} style={{ margin: '0 20px' }}>
      <Space direction="vertical" size="middle" style={{ display: 'flex', marginTop: '20px' }}>
        <Row>
          <Col span={12}>
            <Space direction="vertical" size={0}>
              <Title level={4} style={{ margin: 0 }}>
                RAEL KITZ CORPORATION - WMS IS
              </Title>
              <Text type="secondary">
                RRF Building, San Miguel Calasiao, Pangasinan
                <br />
                Tel No: +1 (123) 456 7891, +44 (876) 543 2198
              </Text>
            </Space>
          </Col>

          <Col span={12} style={{ left: '100px' }}>
            {customizer.activeMode === 'dark' ? (
              <img src={LogoLight} alt="Logo" height={'40px'} />
            ) : (
              <img src={LogoDark} alt="Logo" height={'40px'} />
            )}
          </Col>
        </Row>

        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
        >
          <Title level={5} style={{ margin: 0 }}>
            Sales Report for Product
          </Title>
          <Text style={{ fontSize: '14px' }}>{dateRange}</Text>
        </div>

        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellFontSizeSM: 10,
                borderColor: '#403C3C',
                headerColor: '#403C3C',
                headerBg: '#ebebeb',
              },
            },
          }}
        >
          <Table
            columns={columnsPrint}
            dataSource={data}
            pagination={false}
            bordered
            size="small"
            rowKey={(record) => record.id}
          />
        </ConfigProvider>

        <div
          style={{
            position: 'fixed',
            bottom: '10px',
            left: '20px',
            right: '20px',
            textAlign: 'center',
            fontSize: '10px',
            color: '#666',
          }}
        >
          {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION} by{' '}
          {process.env.REACT_APP_DEVELOPER}
        </div>
      </Space>
    </div>
  );
});

const SalesByProductTable = () => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>([
    dayjs().startOf('day'),
    dayjs().endOf('day'),
  ]);
  const dateRangeRef = useRef(dateRange);

  useEffect(() => {
    dateRangeRef.current = dateRange;
  }, [dateRange]);

  const { formatWithPrefix } = useFormattedNumber();

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<SalesByProductApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
          start_date: dateRange[0] ? dateRange[0].toISOString() : undefined,
          end_date: dateRange[1] ? dateRange[1].toISOString() : undefined,
        };
    
        const response = await axiosClient.get<SalesByProductApiResponse>('/product-sales-report', { params });
    
        return response.data;
      } catch (error) {
        console.error('Error fetching product purchase report:', error);
        throw error;
      }
    },    
    placeholderData: keepPreviousData,
  });

  const handleRunReport = useCallback(async () => {
    refetch();
  }, [refetch]);

  const navigate = useNavigate();
  const { setProductId, setDR } = useTransaction();

  useEffect(() => {
    console.log('Updated Daterange1', dateRange[0]?.toISOString());
    console.log('Updated Daterange2', dateRange[1]?.toISOString());
  }, [dateRange]);

  const handleViewSalesOfProduct = useCallback(
    (row: any) => {
      setProductId(row.id);
      setDR(dateRangeRef.current);
      navigate('/report-sales-by-selected-product');
    },
    [setProductId, setDR, navigate],
  );

  const columns = useMemo<MRT_ColumnDef<SalesByProduct>[]>(
    () => [
      // {
      //   accessorKey: 'id',
      //   header: 'DB ID',
      //   minSize: 50,
      //   maxSize: 50,
      //   size: 50,
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   enableColumnDragging: false,
      // },
      {
        accessorKey: 'name',
        header: 'Product Name',
        size: 180,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link onClick={() => handleViewSalesOfProduct(row.original)}>{renderedCellValue}</Link>
          );
        },
      },
      {
        accessorKey: 'sku',
        header: 'SKU',
        size: 180,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'total_quantity_sold',
        header: 'Purchase Count',
        grow: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => {
          return <span>{renderedCellValue}</span>;
        },
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row.total_sales,
        id: 'total_sales',
        header: 'Total Purchase Amount',
        size: 100,
        Cell: ({ renderedCellValue, row }) => {
          const value = typeof renderedCellValue === 'number' ? renderedCellValue : 0;
          return <span>{formatWithPrefix(value)}</span>;
        },
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const printableTableRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printableTableRef.current,
    pageStyle: `
    @media print {
      @page {
        size: landscape;
        margin: 0;
      }
      body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      html, body {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
      }
    }
  `,
  });

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true, density: 'compact' },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <DateSelector onDateChange={setDateRange} />

        <Tooltip arrow title="Extract Report">
          <Button
            onClick={handleRunReport}
            startIcon={
              <>
                <PlayArrow />
              </>
            }
          >
            Run Report
          </Button>
        </Tooltip>

        <Tooltip arrow title="Print">
          <IconButton onClick={handlePrint}>
            <PrintSharp />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Download as Document">
          <span>
            <IconButton disabled>
              <Download />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip arrow title="Send to Email">
          <span>
            <IconButton disabled>
              <Email />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const columnsPrint = useMemo(
    () => [
      // {
      //   title: 'DB ID',
      //   dataIndex: 'id',
      //   key: 'id',
      // },
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
      },
      {
        title: 'Purchase Count',
        dataIndex: 'total_quantity_sold',
        key: 'total_quantity_sold',
      },
      {
        title: 'Total Purchase Amount',
        dataIndex: 'total_sales',
        key: 'total_sales',
        render: (text: number) => (
          <>
            <span style={{ textAlign: 'right', display: 'block' }}>{formatWithPrefix(text)}</span>
          </>
        ),
      },
    ],
    [formatWithPrefix],
  );

  const formattedPrintData = useMemo(() => {
    return data.map((product) => ({
      ...product,
      total_sales: product.total_sales,
    }));
  }, [data, formatWithPrefix]);

  const formattedDateRange = useMemo(() => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0].format('MMMM DD, YYYY');
      const endDate = dateRange[1].format('MMMM DD, YYYY');
      return `[${startDate} - ${endDate}]`;
    }
    return '';
  }, [dateRange]);

  return (
    <>
      <MaterialReactTable table={table} />

      <div style={{ display: 'none' }}>
        <PrintableTable
          ref={printableTableRef}
          data={formattedPrintData}
          columnsPrint={columnsPrint}
          dateRange={formattedDateRange}
        />
      </div>
    </>
  );
};

const queryClient = new QueryClient();

const SalesByProductView = () => (
  <QueryClientProvider client={queryClient}>
    <SalesByProductTable />
  </QueryClientProvider>
);

export default SalesByProductView;
