import React from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import InventoryAgingSummaryTable from './InventoryAgingSummary/Table';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'InventoryAgingSummary Page',
  },
];

const InventoryAgingSummary = () => {
  return (
    <PageContainer title="RKCWMS - InventoryAgingSummary Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Inventory Aging Summary Page" items={BCrumb} />
      {/* end breadcrumb */}
      <InventoryAgingSummaryTable />
    </PageContainer>
  );
};

export default InventoryAgingSummary;
