import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Divider,
} from '@mui/material';
import axiosClient from 'src/axios_client';
import dayjs from 'dayjs';
import Invoice from './Invoice'; // Adjust the import path as needed
import _ from 'lodash';

interface DataType {
  key: string;
  id: number;
  name: string;
  description: string;
  unit: string;
  quantity: number;
  unit_price: number;
  price: any;
  amount: number;
  serial_numbers: any;
  takenQuantity: any;
  notice: any;
}

interface Customer {
  id: string;
  code: string;
  name: string;
  address: string;
  contact_person: string;
}

const initialData: DataType[] = [];

interface IssuanceEntryFormStaticProps {
  transactionId: number | null;
  userlevel: any;
}

const IssuanceEntryFormStatic: FC<IssuanceEntryFormStaticProps> = ({
  transactionId,
  userlevel,
}) => {
  const [data, setData] = useState(initialData);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [transaction, setTransaction] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  console.log(transactionId);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosClient.get(`ie_transactions/${transactionId}`);
        const transactionData = response.data.data;
        setTransaction(transactionData);

        const uniqueProductIdsInSerial = Object.values(
          transactionData.transactions.serial_numbers.reduce((acc: any, product: any) => {
            acc[product.product_id] = product;
            return acc;
          }, {}),
        );

        const setThisData: any = [];

        uniqueProductIdsInSerial.forEach((transaction: any) => {
          console.log(transactionData.transactions);
          console.log(transaction);

          // const stockLoc = transactionData.transactions.stsock_location.filter(
          //   (opt: any) => opt.product_id == transaction.product_id,
          // );

          const serialNumbers = transactionData.transactions.serial_numbers.filter(
            (opt: any) => opt.product_id === transaction.product_id,
          );

          let locationStocks = transactionData.transactions.initial_location.filter(
            (filter: any) => filter.product_id == transaction.product_id,
          );


          console.log(locationStocks);


          if (transactionData.status == 'posted') {
            locationStocks = transactionData.transactions.initial_location
              .filter((filter: any) => filter.product_id === transaction.product_id)
              .map((item: any) => item)
              .flat();
          }


          const stockLoc = locationStocks ? locationStocks : [];

          let remainingQuantity = serialNumbers.length;
          let serialIndex = 0;
          const result = [];

          for (const item of stockLoc) {

            if (remainingQuantity <= 0) break;
            const serialsTaken = [];
            let takenQuantity;

            if (item.quantity >= remainingQuantity) {
              takenQuantity = remainingQuantity;
              serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
              result.push({
                ...item,
                takenQuantity,
                serial_numbers: serialsTaken,
              });
              setThisData.push({
                ...item,
                key: serialNumbers[serialIndex].id,
                product_id: transaction.product_id,
                product_image: transaction.product_image,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity,
                serial_numbers: serialsTaken,
              });
              remainingQuantity = 0;
            } else {
              takenQuantity = item.quantity;
              serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
              result.push({ ...item, takenQuantity, serial_numbers: serialsTaken });
              setThisData.push({
                ...item,
                key: serialNumbers[serialIndex].id,
                product_id: transaction.product_id,
                product_image: transaction.product_image,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity,
                serial_numbers: serialsTaken,
              });
              remainingQuantity -= takenQuantity;
            }
            serialIndex += takenQuantity;
          }


        });


        transactionData.transactions.stocks.map((transaction: any, index: any) => {
          const locationStocks = transactionData.transactions.initial_location.filter(
            (filter: any) => filter.product_id == transaction.product_id && filter.quantity != 0,
          );

          // if (transactionData.status == 'posted') {
          //   locationStocks = transactionData.transactions.stock_location.filter(
          //     (filter: any) => filter.product_id == transaction.product_id && filter.quantity != 0,
          //   );
          // }

          const stockLoc = locationStocks ? locationStocks : [];
          let remainingQuantity = transaction.quantity;
          const result = [];

          for (const item of stockLoc) {
            if (remainingQuantity <= 0) break;

            if (item.quantity >= remainingQuantity) {
              result.push({
                ...item,
                serial_numbers: [],
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_image: transaction.product_image,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: remainingQuantity,
              });
              setThisData.push({
                ...item,
                serial_numbers: [],
                key: transaction.id,
                product_name: transaction.product_name,
                product_image: transaction.product_image,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: remainingQuantity,
              });
              remainingQuantity = 0;
            } else {
              result.push({
                ...item,
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_image: transaction.product_image,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: item.quantity,
              });
              setThisData.push({
                ...item,
                serial_numbers: [],
                key: transaction.id,
                product_id: transaction.product_id,
                product_image: transaction.product_image,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: item.quantity,
              });
              remainingQuantity -= item.quantity;
            }
          }

          if (remainingQuantity > 0) {

            setThisData.push({
              ...transaction,
              serial_numbers: [],
              key: transaction.id,
              product_name: transaction.product_name,
              product_image: transaction.product_image,
              product_model: transaction.product_model,
              price: transaction.price,
              notice: transaction.notice,
              product_unit: transaction.product_unit,
              takenQuantity: remainingQuantity,
            });
            console.log(remainingQuantity);
            console.log('Not enough stock available');
          }
        });

        console.log(setThisData);
        setData(setThisData);

        setCustomer(transactionData.customer);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [transactionId]);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }
  
  const invoiceData = {
    id: transaction?.id || '',
    sc_id: transaction?.sc_id,
    invoice_number: transaction?.invoice_number || '',
    reference_number: transaction?.reference_number || '',
    date_of_issuance: transaction?.date_of_issuance || '',
    created_by_userid: transaction?.created_by.id || '',
    created_by_name: transaction?.created_by.name || '',
    slip_number: transaction?.slip_number || '',
    customer_code: customer?.code || '',
    customer_name: customer?.name || '',
    customer_address: customer?.address || '',
    status: transaction?.status || '',
    stock_location_record: transaction?.stock_location_record || '',
    customer_contact_person: customer?.contact_person || '',
    transactions: data.map((item) => ({
      ...item,
      notice: item.notice,
      keys: Array.from(new Set([item.key, ...item.serial_numbers.map((sn: any) => sn.id)])),
    })),
    subtotal: data.reduce((sum, product) => {
      return (sum += product.takenQuantity * product.price);
    }, 0),
    newSubTotal: transaction.total_amount - transaction.total_lost_amount - transaction.total_excess_amount,
    total_lost_amount: transaction.total_lost_amount,
    total_excess_amount: transaction.total_excess_amount,
    created_by: transaction.created_by,
    created_at: transaction.created_at,
    checked_at: transaction.checked_at,
    checked_by: transaction.checked_by,
    checked_note: transaction.checked_note,
    released_by: transaction.released_by,
    released_at: transaction.released_at,
    released_note: transaction.released_note,
    received_at: transaction.received_at,
    hauler: transaction.hauler,
    excess_transaction_date: transaction.excess_transaction_date,
  };
  console.log(data);

  console.log(invoiceData);

  const fetchData = async () => {
    if (!transactionId) return;

    try {
      const response = await axiosClient.get(`ie_transactions/${transactionId}`);
      setTransaction(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <Invoice data={invoiceData} userlevel={userlevel} onAcknowledgeSuccess={fetchData} />
    </>
  );
};

export default IssuanceEntryFormStatic;
