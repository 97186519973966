import React, { useEffect, useMemo, useState, useCallback, useImperativeHandle } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Grid, Button, Box } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { IconEdit, IconEye, IconPlus, IconTrash } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';

type PurchaseEntryTransactionsApiResponse = {
  data: Array<PurchaseEntryTransactions>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type PurchaseEntryTransactions = {
  id: number;
  name: string;
  description: string;
  supplier: any;
  status: 'posted' | 'floating' | 'cancelled';
};

interface PurchaseEntryTransactionsTableProps {
  onRefetchRef: React.RefObject<{ refetch: () => void }>;
  onOpenFloatingPurchase?: (transaction: PurchaseEntryTransactions) => void;
  userlevel?: string;
}

const PurchaseEntryTransactionsTable: React.FC<PurchaseEntryTransactionsTableProps> = ({
  onRefetchRef,
  onOpenFloatingPurchase,
  userlevel,
}) => {
  useImperativeHandle(onRefetchRef, () => ({
    refetch: () => {
      if (refetch) {
        refetch();
      }
    },
  }));

  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<PurchaseEntryTransactionsApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<PurchaseEntryTransactionsApiResponse>(
          '/pe_transactions', // Use the relative path
          { params },
        );

        return response.data;
      } catch (error) {
        console.error('Error fetching transactions:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });

  const handleTransactionAction = (transaction: PurchaseEntryTransactions) => {
    handleViewPurchaseEntryTransaction(transaction);

    // if (transaction.status === 'floating' && onOpenFloatingPurchase) {
    //   onOpenFloatingPurchase(transaction);
    // } else if (transaction.status === 'posted') {
    //   handleViewPurchaseEntryTransaction(transaction);
    // }
  };

  const columns = useMemo<MRT_ColumnDef<PurchaseEntryTransactions>[]>(
    () => [
      {
        accessorKey: 'reference_number',
        header: 'Reference Number',
        size: 180,
      },
      {
        accessorKey: 'invoice_number',
        header: 'Invoice Number',
        size: 180,
      },
      {
        accessorKey: 'supplier',
        header: 'Supplier',
        size: 180,
        Cell: ({ row }) => row.original.supplier.name,
      },
      // {
      //   accessorKey: 'status',
      //   header: 'Status',
      //   size: 120,
      // },
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 120,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => {
          const { original } = row;
          const { status } = original;

          const renderButton = () => {
            if (status === 'posted') {
              return (
                <Button onClick={() => handleTransactionAction(original)} variant="outlined">
                  <IconEye />
                </Button>
              );
            }

            if (status === 'floating') {
              return (
                <Button
                  onClick={() => handleTransactionAction(original)}
                  variant="outlined"
                  color="warning"
                >
                  <IconEdit />
                </Button>
              );
            }

            return null;
          };

          return (
            <Grid container spacing={2}>
              <Grid item>
                <Button onClick={() => handleTransactionAction(original)} variant="outlined">
                  <IconEye />
                </Button>
                {/* {renderButton()} */}
              </Grid>
            </Grid>
          );
        },
      },
    ],
    [onOpenFloatingPurchase],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  const handleViewPurchaseEntryTransaction = (row: any) => {
    setTransactionId(row.id);
    navigate('/purchases-entry-view-transaction');
  };

  return <MaterialReactTable table={table} />;
};

const queryClient = new QueryClient();

const PurchaseEntryTransactionsView: React.FC<PurchaseEntryTransactionsTableProps> = (props) => (
  <QueryClientProvider client={queryClient}>
    <PurchaseEntryTransactionsTable {...props} />
  </QueryClientProvider>
);

export default PurchaseEntryTransactionsView;
