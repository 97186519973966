import { QrCode2, RefreshOutlined, ThumbDown, ThumbUp } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { formatDate } from './PublicFunctions';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Image } from 'antd';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';
import Link from 'antd/es/typography/Link';
import axios from 'axios';

type Adjustments = {
  id: number;
  product_id: number;
  product_name: string;
  sku: string;
  created_at: any;
  status: any;
  is_price_adjustment: any;
  is_quantity_adjustment: any;
  transaction_number: any;
  buying_price: number;
  selling_price: number;
  quantity: number;
  requested_selling: number;
  requested_buying: number;
  requested_quantity: number;
  reason: string;
};

type AdjustmentApiResponse = {
  data: Array<Adjustments>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type Transactions = {
  id: number;
  reference_number: number;
  invoice_number: string;
  supplier_id: string;
  date_of_purchase: any;
  image: any;
  created_by: {
    id: any;
    name: any;
  };
  created_at: any;
  updated_at: number;
  selling_price: number;
  supplier: {
    id: any;
    name: any;
  };
};

type TransactionsApiResponse = {
  data: Array<Transactions>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};
type Issuance = {
  id: number;
  reference_number: number;
  slip_number: string;
  customer_id: string;
  date_of_issuance: any;
  created_by: {
    id: any;
    name: any;
  };
  created_at: any;
  updated_at: number;
  total_amount: number;
};

type IssuanceApiResponse = {
  data: Array<Issuance>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type Serial = {
  id: number;
  product_id: any;
  serial_number: any;
  warranty_period: any;
  created_at: any;
  status: any;
};

type SerialApiResponse = {
  data: Array<Serial>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

const queryClient = new QueryClient();

type Props = {};

type Returns = {
  id: number;
  transaction_id: number | null;
  product_id: number;
  product_name: string;
  product_model: string;
  product_unit: string;
  product_image: string | null;
  product_description: string;
  quantity: number;
  transmital_transaction_number: string;
  return_transaction_id: number | null;
  location_id: number;
  location_name: string;
  divider: string;
  floor: string;
  condition: string;
  serial_numbers: string | null;
};

type ReturnTransactionsApiResponse = {
  data: Array<Returns>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};
const RetTable = () => {
  const { id } = useParams();
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([
    { id: 'sku', value: id },
  ]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<ReturnTransactionsApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<ReturnTransactionsApiResponse>(
          '/transmital_returns',
          { params },
        );

        console.log(response.data);

        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<Returns>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'DB ID',
        size: 80,
      },
      {
        accessorKey: 'transmital_transaction_number',
        header: 'Return Transaction',
      },
      {
        accessorKey: 'location_id',
        header: 'Location',
        size: 120,
        Cell: ({ row }) => {
          return (
            <Typography variant="subtitle1">
              {row.original.location_name} {row.original.divider} Floor {row.original.floor}
            </Typography>
          );
        },
      },

      // {
      //     accessorKey: 'actions',
      //     header: 'Actions',
      //     size: 120,
      //     enableSorting: false,
      //     enableColumnActions: false,
      //     Cell: ({ row }) => (
      //         <Grid container spacing={2}>
      //             <Grid item>
      //                 <Button onClick={() => console.log("Edit clicked")} variant="outlined">
      //                     <IconEdit />
      //                 </Button>
      //             </Grid>
      //             <Grid item>
      //                 <Button onClick={() => console.log("Delete clicked")} variant="outlined">
      //                     <IconTrash />
      //                 </Button>
      //             </Grid>
      //         </Grid>
      //     ),
      // },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: 'grid',
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 9001, //allow columns to get larger than default
      size: 180, //make columns wider by default
    },
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
      },
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: false,
    enableColumnActions: false,
    enableSorting: false,
    enableTopToolbar: false,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.log(row.original);
        // navigate(`/adjustment-transactions/view/${row.original.transaction_number}`);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });
  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};
const AdjTable = () => {
  const { id } = useParams();
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([
    { id: 'sku', value: id },
  ]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<AdjustmentApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<AdjustmentApiResponse>('/adjustments', { params });

        console.log(response.data);

        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },

    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<Adjustments>[]>(
    () => [
      {
        accessorKey: 'sku',
        header: 'SKU',
        size: 100,
      },
      {
        accessorKey: 'created_at',
        header: 'Date',
        size: 40,
        Cell: ({ row }) => {
          return <div>{formatDate(row.original.created_at)}</div>;
        },
      },
      {
        accessorKey: 'transaction_number',
        header: 'Transaction Number',
        size: 100,
      },
      {
        accessorKey: 'is_price_adjustment',
        header: 'Price Adjustment?',
        enableColumnFilter: false,
        enableColumnActions: false,
        size: 60,
        Cell: ({ row }) => {
          const isPrice = row.original.is_price_adjustment;
          const isPriceAdjustment = isPrice == 1 ? 'Yes' : 'No';

          return isPriceAdjustment;
        },
      },
      {
        accessorKey: 'is_quantity_adjustment',
        header: 'Qty. Adjustment?',
        size: 60,
        enableColumnFilter: false,
        enableColumnActions: false,
        Cell: ({ row }) => {
          const isQuantity = row.original.is_quantity_adjustment;
          const isQuantityAdjustment = isQuantity == 1 ? 'Yes' : 'No';
          return isQuantityAdjustment;
        },
      },
      {
        accessorKey: 'status',
        header: 'Current Status',
        size: 60,
        Cell: ({ row }) => {
          const status = row.original.status;
          const statusText =
            status >= 1 && status <= 3
              ? 'Pending'
              : status >= 4 && status <= 6
              ? 'Approved'
              : 'Unknown';

          return (
            <Chip
              label={statusText}
              color={
                statusText == 'Pending'
                  ? 'secondary'
                  : statusText == 'Approved'
                  ? 'success'
                  : 'error'
              }
            />
          );
        },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: 'grid',
    enableColumnDragging: false,
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 9001, //allow columns to get larger than default
      size: 180, //make columns wider by default
    },
    initialState: {
      showColumnFilters: true,
      columnVisibility: { sku: false },
    },
    positionToolbarAlertBanner: 'none',
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        window.open(`/adjustment-transactions/view/${row.original.transaction_number}`, '_blank');
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <MaterialReactTable table={table} />

    // <>
    //     <MaterialReactTable table={table} />
    // </>
  );
};

const PurTable = () => {
  const { id } = useParams();
  const [purchasesFilter, setPurchaseFilter] = useState<MRT_ColumnFiltersState>([]);
  const [purchaseglobalFilter, setPurchaseGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  const handleViewPurchaseEntryTransaction = (row: any) => {
    setTransactionId(row.id);
    navigate('/purchases-entry-view-transaction');
  };

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<TransactionsApiResponse>({
    queryKey: [
      'table-data',
      purchasesFilter,
      purchaseglobalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(purchasesFilter ?? []),
          globalFilter: purchaseglobalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<TransactionsApiResponse>(
          `/pe_transactions/view/${id}`,
          { params },
        );

        console.log(response.data);

        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },

    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<Transactions>[]>(
    () => [
      {
        accessorKey: 'created_at',
        header: 'Date',
        size: 40,
        Cell: ({ row }) => {
          return <div>{formatDate(row.original.created_at)}</div>;
        },
      },
      {
        accessorKey: 'invoice_number',
        header: 'Invoice Number',
        size: 40,
      },
      {
        accessorKey: 'reference_number',
        header: 'Reference #',
        size: 40,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link onClick={() => handleViewPurchaseEntryTransaction(row.original)}>
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'image',
        header: 'Receipt',
        size: 40,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <Image
              width={50}
              alt=""
              src={row.original.image}
              preview={{
                width: 'auto',
                src: row.original.image,
              }}
            />
          );
        },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: 'grid',
    enableColumnDragging: false,
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 9001, //allow columns to get larger than default
      size: 180, //make columns wider by default
    },
    initialState: {
      showColumnFilters: true,
      columnVisibility: { id: false },
    },
    positionToolbarAlertBanner: 'none',
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setPurchaseFilter,
    onGlobalFilterChange: setPurchaseGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    rowCount: meta?.total ?? 0,
    state: {
      columnFilters: purchasesFilter,
      globalFilter: purchaseglobalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <MaterialReactTable table={table} />

    // <>
    //     <MaterialReactTable table={table} />
    // </>
  );
};

const IssTable = () => {
  const { id } = useParams();
  const [issuanceFilter, setIssuanceFilter] = useState<MRT_ColumnFiltersState>([]);
  const [issuanceGlobalFilter, setIssuanceGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  const handleViewIssuanceEntryTransaction = (row: any) => {
    setTransactionId(row.id);
    navigate('/issuance-entry-view-transaction');
  };

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<IssuanceApiResponse>({
    queryKey: [
      'table-data',
      issuanceFilter,
      issuanceGlobalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(issuanceFilter ?? []),
          globalFilter: issuanceGlobalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<IssuanceApiResponse>(`/ie_transactions/view/${id}`, {
          params,
        });

        console.log(response.data);

        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<Issuance>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 40,
      },
      {
        accessorKey: 'date_of_issuance',
        header: 'Issued Date',
        size: 40,
        Cell: ({ row }) => {
          return <div>{formatDate(row.original.date_of_issuance)}</div>;
        },
      },
      {
        accessorKey: 'reference_number',
        header: 'Reference #',
        size: 40,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link onClick={() => handleViewIssuanceEntryTransaction(row.original)}>
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'created_by',
        header: 'Creator',
        size: 40,
        Cell: ({ row }) => {
          console.log(row.original);
          return <div>test</div>;
        },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: 'grid',
    enableColumnDragging: false,
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 9001, //allow columns to get larger than default
      size: 180, //make columns wider by default
    },
    initialState: {
      showColumnFilters: true,
      columnVisibility: { id: false },
    },
    positionToolbarAlertBanner: 'none',
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setIssuanceFilter,
    onGlobalFilterChange: setIssuanceGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    rowCount: meta?.total ?? 0,
    state: {
      columnFilters: issuanceFilter,
      globalFilter: issuanceGlobalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <MaterialReactTable table={table} />

    // <>
    //     <MaterialReactTable table={table} />
    // </>
  );
};

const SerialTable = () => {
  const { id } = useParams();
  const [issuanceFilter, setIssuanceFilter] = useState<MRT_ColumnFiltersState>([]);
  const [issuanceGlobalFilter, setIssuanceGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [productId, setProductId] = useState('');
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<SerialApiResponse>({
    queryKey: [
      'table-data',
      issuanceFilter,
      issuanceGlobalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(issuanceFilter ?? []),
          globalFilter: issuanceGlobalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<SerialApiResponse>('/serial_numbers/view/' + id, {
          params,
        });

        setProductId(response.data.data[0].product_id); // Assuming your response data structure is similar
        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<Serial>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 40,
      },
      {
        accessorKey: 'created_at',
        header: 'Created Date',
        size: 40,
        Cell: ({ row }) => {
          return <div>{formatDate(row.original.created_at)}</div>;
        },
      },
      {
        accessorKey: 'serial_number',
        header: 'Serial Number',
        size: 40,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 40,
      },
      {
        accessorKey: 'warranty_period',
        header: 'Warranty Period',
        size: 40,
        Cell: ({ row }) => {
          return <div>{formatDate(row.original.warranty_period)}</div>;
        },
      },
    ],
    [],
  );

  const downloadQRCodePdf = async () => {
    try {
      const response = await axiosClient.get(`/generate-snqrcode-pdf/${productId}/download`, {
        responseType: 'blob', // Ensures the response is a Blob (binary data)
      });

      // Create a link element, set URL, and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'products_with_qrcodes.pdf'); // Set the filename
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: 'grid',
    enableColumnDragging: false,
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 9001, //allow columns to get larger than default
      size: 180, //make columns wider by default
    },
    initialState: {
      showColumnFilters: true,
      columnVisibility: { id: false },
    },
    positionToolbarAlertBanner: 'none',
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setIssuanceFilter,
    onGlobalFilterChange: setIssuanceGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ p: '0px', display: 'flex', justifyContent: 'space-between', paddingLeft: '8px' }}>
        <Button variant="contained" onClick={downloadQRCodePdf} endIcon={<QrCode2 />}>
          Generate QR
        </Button>
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={() => refetch()}>
              <RefreshOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    ),

    rowCount: meta?.total ?? 0,
    state: {
      columnFilters: issuanceFilter,
      globalFilter: issuanceGlobalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <MaterialReactTable table={table} />

    // <>
    //     <MaterialReactTable table={table} />
    // </>
  );
};
function AdjustmentsTable() {
  return (
    <QueryClientProvider client={queryClient}>
      <AdjTable />
    </QueryClientProvider>
  );
}
function PurchasesTable() {
  return (
    <QueryClientProvider client={queryClient}>
      <PurTable />
    </QueryClientProvider>
  );
}

function IssuanceTable() {
  return (
    <QueryClientProvider client={queryClient}>
      <IssTable />
    </QueryClientProvider>
  );
}
function SerialNumberTable() {
  return (
    <QueryClientProvider client={queryClient}>
      <SerialTable />
    </QueryClientProvider>
  );
}

function ReturnsTable() {
  return (
    <QueryClientProvider client={queryClient}>
      <RetTable />
    </QueryClientProvider>
  );
}

export { AdjustmentsTable, PurchasesTable, IssuanceTable, SerialNumberTable, ReturnsTable };
