import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import axiosClient from 'src/axios_client';
import StatusAlert from 'src/components/shared/AlertResponse';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  address: yup.string().required('Address is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  contact_person: yup.string(),
  mobile_number: yup.string(),
  is_individual: yup.boolean(),
});

type DialogFormProps = {
  isOpen: boolean;
  isEditing: boolean;
  initialData: {
    id: number;
    name: string;
    address: string;
    contact_person: string;
    email: string;
    mobile_number: string;
    is_individual: number;
  } | null;
  onClose: () => void;
  onSubmit: (payload: {
    id: number | null;
    name: string;
    address: string;
    contact_person: string;
    email: string;
    mobile_number: string;
    is_individual: number;
  }) => void;
  refetch: () => void;
};

const DialogForm: React.FC<DialogFormProps> = ({
  isOpen,
  isEditing,
  initialData,
  onClose,
  onSubmit,
  refetch,
}) => {
  const [statusCode, setStatusCode] = useState<number | null>(null);
  const [statusData, setStatusData] = useState<any>(null);

  const formik = useFormik({
    initialValues: {
      id: initialData?.id || null,
      name: initialData?.name || '',
      address: initialData?.address || '',
      email: initialData?.email || '',
      contact_person: initialData?.contact_person || '',
      mobile_number: initialData?.mobile_number || '',
      is_individual: initialData?.is_individual === 1, // Ensure it's a boolean
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setStatusCode(null);
      setStatusData(null);

      const payload = {
        id: isEditing ? values.id : 0,
        name: values.name,
        address: values.address,
        email: values.email,
        contact_person: values.contact_person,
        mobile_number: values.mobile_number,
        is_individual: values.is_individual ? 1 : 0, // Convert true/false back to 1/0
      };

      try {
        let response;
        if (isEditing) {
          response = await axiosClient.put(`/customers/${payload.id}`, payload);
        } else {
          response = await axiosClient.post('/customers', payload);
        }

        console.log('Server Response:', response.data);
        setStatusCode(response.status);
        setStatusData(response.data);
        resetForm();
        refetch();
        setTimeout(onClose, 2000);
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          setStatusData(error.response.data);
        } else {
          setStatusData({ message: 'An error occurred. Please try again.' });
        }
        setStatusCode(error.response?.status || 500);
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      id: initialData?.id || null,
      name: initialData?.name || '',
      address: initialData?.address || '',
      email: initialData?.email || '',
      contact_person: initialData?.contact_person || '',
      mobile_number: initialData?.mobile_number || '',
      is_individual: !!initialData?.is_individual, // Explicit boolean conversion
    });
  }, [initialData]);

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            {isEditing ? `Edit Customer [${initialData?.name}]` : 'Add Customer'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {statusCode !== null && (
                <StatusAlert statusCode={statusCode} statusData={statusData} />
              )}
            </DialogContentText>
            <Box mt={2}>
              <CustomTextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                fullWidth
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="address"
                name="address"
                label="Address"
                type="text"
                fullWidth
                value={formik.values.address}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="email"
                name="email"
                label="Email"
                type="text"
                fullWidth
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="contact_person"
                name="contact_person"
                label="Contact Person"
                type="text"
                fullWidth
                value={formik.values.contact_person}
                error={formik.touched.contact_person && Boolean(formik.errors.contact_person)}
                helperText={formik.touched.contact_person && formik.errors.contact_person}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="mobile_number"
                name="mobile_number"
                label="Mobile Number"
                type="text"
                fullWidth
                value={formik.values.mobile_number}
                error={formik.touched.mobile_number && Boolean(formik.errors.mobile_number)}
                helperText={formik.touched.mobile_number && formik.errors.mobile_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!formik.values.is_individual} // Force boolean conversion
                    onChange={(event) =>
                      formik.setFieldValue('is_individual', event.target.checked)
                    }
                    name="is_individual"
                  />
                }
                label="Is Individual"
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button color="error" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">{isEditing ? 'Update' : 'Add'}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default React.memo(DialogForm);
